import { AnyAction } from 'redux';
import { useDispatch } from "react-redux";
import { AppAction } from "../definitions/Action";
import { ApiError } from "../services/api.service";
import { ThunkDispatch } from "redux-thunk";
import { ApplicationState } from "../reducers/store";

export * from "./user.actions";
export * from "./unit.actions";
export * from "./building.actions";
export * from "./club.actions";
export * from "../pages/NewsStream/newsstream.actions";
export * from "./landing.actions";
export * from "./family.actions";
export * from "./admin/admin.landing.actions";
export * from "./club.actions";
export * from "./subscriptions.actions";

export const operationFailedActionGeneral = (
  payload: unknown,
  type: string
): AppAction => {
  let message = String(payload);
  if (payload instanceof Error) {
    message = (payload as Error).message;
   
    //prevent to send error message which is caused of using AbortController inside useEffect with React Strict Mode in developer mode
    if ((payload as Error).name === "AbortError" && process.env.NODE_ENV === "development") {
      message = "";
    }
  }

  if ((payload as ApiError).statusText) {
    message = (payload as ApiError).statusText;
  }

  return {
    type: type,
    payload: message,
  };
};

//This is an example how to get rid of redux connected elements and switch to hooks
export const useAppDispatch = () =>
  useDispatch<ThunkDispatch<ApplicationState, any, AnyAction>>();
