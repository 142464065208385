import { Reducer, Action } from "redux";

import { AppAction } from "../../definitions/Action";
import { AdminActionTypes } from "../../constants/admin/admin.actiontypes";
import { AdminCommuneActionTypes } from "../../constants/admin/admin.commune.actiontypes";
import { FormBuilderDTO, BenefitClub } from "../../definitions/Landing";
import { LandingArticleDto } from "../../definitions/Landing";
import { DashboardEntryModel } from "../../definitions/model/Dashboard";
import User, { RemovedUserLogItemModel } from "../../definitions/model/User";
import { ClubModel } from "../../definitions/model/Club";
import { SubscriptionModel } from "../../definitions/model/Subscriptions";
import { SubscriptionsActions } from "../../constants";
import { CommentModel } from "../../definitions/NewsStream";
import { CommentConstants } from "../../constants/comment.constants";
import { AdminCompanyActionTypes } from "../../constants/admin/admin.company.actiontypes";
import { CommuneLocation } from "../../definitions/model/unit";
import {
  AdminInfoAggregate,
  AdminInfoGroup,
  AdminUserinfo,
} from "../../pages/AdminPanel/pages/AggregateViewPage/AggregateViewPage";
import { WorkerModel } from "../../definitions/model/Worker";
import { AdminCraftsmanActionTypes } from "../../constants/admin/admin.craftsman.actiontypes";
import { AdminCO2PlanActionTypes } from "../../constants/admin/admin.co2plan.actiontypes";
import { SearchResult } from "../../definitions/model/SearchResult";
import { useSelector } from "react-redux";
import { ApplicationState } from "../store";
import { SystemMetaModel } from "../../definitions/model/SystemMetaModel";
import { CompanyAdModel, CompanyModel, CompanyTestimonialModel } from "../../definitions/Company";
import { AdminCompanyAdActionTypes } from "../../constants/admin/admin.company-ad.actiontypes";
import { AdminCompanyTestimonialActionTypes } from "../../constants/admin/admin.company-testimonial.actiontypes";

export interface AdminStoreState {
  loading: boolean;
  error: string | null;
  movedIn: any[];
  newsList: Array<LandingArticleDto>;
  benefitClubsList: Array<BenefitClub>;
  clubsList: Array<ClubModel>;
  editingLanding: FormBuilderDTO | null;
  administrators: User[];
  addAdminAutocompleteOptions: User[];
  communes: CommuneLocation[];
  productSubscriptions: SubscriptionModel[];
  subscriptionsCount: number;
  entries: DashboardEntryModel[];
  commentsToModerate: CommentModel[];
  commentsToModerateCount: number;
  companies: CompanyModel[];
  companyAds: CompanyAdModel[];
  companyTestimonials: CompanyTestimonialModel[];
  userInfo: AdminUserinfo | null;
  userGroups: AdminInfoGroup[];
  userAggregate: { [key: string]: AdminInfoAggregate[] } | null;
  userAggregateLoading: boolean;
  systemMeta: SystemMetaModel[];
  craftsmen: {
    Results: WorkerModel[];
    Count: number;
  };
  allUsers: SearchResult<User>;
  removedUsersLog: SearchResult<RemovedUserLogItemModel>;
}

export const initialState: AdminStoreState = {
  loading: false,
  error: null,
  movedIn: [],
  newsList: [],
  benefitClubsList: [],
  clubsList: [],
  editingLanding: null,
  administrators: [],
  addAdminAutocompleteOptions: [],
  communes: [],
  productSubscriptions: [],
  subscriptionsCount: 0,
  entries: [],
  commentsToModerate: [],
  commentsToModerateCount: 0,
  companies: [],
  companyAds: [],
  companyTestimonials: [],
  userInfo: null,
  userGroups: [],
  userAggregateLoading: false,
  userAggregate: null,
  craftsmen: {
    Results: [],
    Count: 0,
  },
  allUsers: {
    Results: [],
    Count: 0,
  },
  removedUsersLog: {
    Results: [],
    Count: 0,
  },
  systemMeta: [],
};

const adminReducer: Reducer<AdminStoreState, Action> = (
  state: AdminStoreState = initialState,
  action: AppAction
): AdminStoreState => {
  switch (action.type) {
    case AdminActionTypes.ADMIN_OPERATION_FAILED:
      return { ...state, loading: false, error: action.payload };
    case AdminActionTypes.GET_MOVED_IN:
    case AdminActionTypes.ADD_NEWS_TEMPLATE:
    case AdminActionTypes.GET_NEWS_TEMPLATES:
    case AdminActionTypes.DELETE_NEWS_TEMPLATE:
    case AdminActionTypes.GET_NEWS_TEMPLATE:
    case AdminActionTypes.GET_NEWS:
    case AdminActionTypes.ADD_NEWS:
    case AdminActionTypes.DELETE_NEWS:
    case AdminActionTypes.SET_NEWS_PUBLISH:
    case AdminActionTypes.GET_NEWS_EDITOR:
    case AdminActionTypes.GET_ADMIN_USERS:
    case AdminActionTypes.UPDATE_USER_ROLE:
    case AdminActionTypes.GET_DASHBOARD_ENTRIES:
    case AdminActionTypes.GET_BENEFIT_CLUBS:
    case AdminActionTypes.ADD_BENEFIT_CLUB:
    case AdminActionTypes.GET_CLUBS:
    case AdminActionTypes.SET_BENEFIT_CLUB_PUBLISH:
    case AdminActionTypes.DELETE_BENEFIT_CLUB:
    case AdminActionTypes.GET_PRODUCT_SUBSCRIPTIONS:
    case AdminActionTypes.CREATE_TEST_SUBSCRIPTION:
    case AdminActionTypes.REORDER_BENEFIT_CLUBS:
    case SubscriptionsActions.CANCEL_SUBSCRIPTION:
    case AdminActionTypes.GET_NOT_APPROVED_COMMENTS:
    case AdminCompanyActionTypes.GET_COMPANIES:
    case AdminCompanyAdActionTypes.GET_COMPANY_ADS:
    case AdminCompanyTestimonialActionTypes.GET_COMPANY_TESTIMONIALS:
    case AdminActionTypes.UPDATE_DASHBOARD_ENTRY:
    case AdminActionTypes.CREATE_DASHBOARD_ENTRY:
    case AdminActionTypes.DELETE_DASHBOARD_ENTRY:
    case AdminCommuneActionTypes.GET_COMMUNES:
    case AdminCraftsmanActionTypes.GET_CRAFTSMEN:
    case AdminActionTypes.ADMIN_GET_ALL_USERS:
    case AdminActionTypes.GET_REMOVED_USERS_LOG:
      return { ...state, loading: true };
    case AdminActionTypes.GET_ADMIN_USER_INFO_SUCCEEDED:
      return {
        ...state,
        loading: false,
        userInfo: action.payload[0],
        userGroups: action.payload[1],
      };
    case AdminActionTypes.GET_ADMIN_USER_INFO:
      return {
        ...state,
        loading: true,
      };
    case AdminActionTypes.GET_ADMIN_USER_GROUPS_AGGREGATE:
      return {
        ...state,
        userAggregateLoading: true,
      };
    case AdminActionTypes.GET_ADMIN_USER_GROUPS_AGGREGATE_SUCCEEDED:
      return {
        ...state,
        userAggregateLoading: false,
        userAggregate: action.payload,
      };
    case AdminActionTypes.GET_MOVED_IN_SUCCEEDED:
      return {
        ...state,
        loading: false,
        movedIn: action.payload,
      };
    case AdminActionTypes.GET_NEWS_TEMPLATE_SUCCEEDED:
    case AdminActionTypes.GET_NEWS_EDITOR_SUCCEEDED:
      return {
        ...state,
        loading: false,
        editingLanding: action.payload,
      };
    case AdminActionTypes.ADD_NEWS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        newsList: [...state.newsList, action.payload],
      };
    case AdminActionTypes.GET_NEWS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        newsList: action.payload,
      };
    case AdminActionTypes.DELETE_NEWS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        newsList: state.newsList.filter((x) => x.Id !== action.payload.Id),
      };
    case AdminActionTypes.SET_NEWS_PUBLISH_SUCCEEDED: {
      const newList = state.newsList.map((item) => {
        if (item.Id === action.payload.Id) {
          return action.payload;
        } else {
          return item;
        }
      });
      return {
        ...state,
        newsList: newList,
        loading: false,
      };
    }
    case AdminActionTypes.GET_CLUBS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        clubsList: action.payload,
      };
    case AdminActionTypes.GET_BENEFIT_CLUBS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        benefitClubsList: action.payload,
      };
    case AdminActionTypes.SET_BENEFIT_CLUB_PUBLISH_SUCCEEDED: {
      const newList = state.benefitClubsList.map((item) => {
        if (item.Id === action.payload.Id) {
          return action.payload;
        } else {
          return item;
        }
      });
      return {
        ...state,
        loading: false,
        benefitClubsList: newList,
      };
    }
    case AdminActionTypes.ADD_BENEFIT_CLUB_SUCCEEDED:
      return {
        ...state,
        loading: false,
        benefitClubsList: [...state.benefitClubsList, action.payload],
      };
    case AdminActionTypes.DELETE_BENEFIT_CLUB_SUCCEEDED:
      return {
        ...state,
        loading: false,
        benefitClubsList: state.benefitClubsList.filter((x) => x.Id !== action.payload),
      };
    case AdminActionTypes.REORDER_BENEFIT_CLUBS_SUCCEDED: {
      return {
        ...state,
        loading: false,
        benefitClubsList: action.payload,
      };
    }
    case AdminActionTypes.GET_ADMIN_USERS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        administrators: action.payload,
      };
    case AdminActionTypes.UPDATE_USER_ROLE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        administrators: state.administrators.map((x) => (x.Id === action.payload.Id ? action.payload : x)),
      };
    case AdminActionTypes.ADMIN_JOIN_USER_TO_COMPANY_SUCCEEDED:
    case AdminActionTypes.ADMIN_REMOVE_USER_FROM_COMPANY_SUCCEEDED:
      return {
        ...state,
        loading: false,
        allUsers: {
          ...state.allUsers,
          Results: state.allUsers.Results.map((x) => (x.Id === action.payload.Id ? action.payload : x)),
        },
      };
    case AdminActionTypes.GET_USERS_BY_EMAIL_PART_SUCCEEDED:
      return {
        ...state,
        addAdminAutocompleteOptions: action.payload,
      };
    case AdminCommuneActionTypes.GET_COMMUNES_SUCCEEDED:
      return {
        ...state,
        communes: action.payload,
      };
    case AdminActionTypes.GET_DASHBOARD_ENTRIES_SUCCEEDED:
      return {
        ...state,
        loading: false,
        entries: action.payload,
      };
    case AdminActionTypes.CHANGE_DASHBOARD_SUBSCRIPTION:
      return {
        ...state,
        entries: state.entries.map((entry: DashboardEntryModel) => {
          if (entry.Id === action.payload.entryId) {
            if (action.payload.isAlarm) {
              entry.AlarmSubscription = action.payload.isActive;
            } else {
              entry.Subscribed = action.payload.isActive;
              if (action.payload.frequency || action.payload.frequency === 0)
                entry.SubscriptionFrequency = action.payload.frequency;
            }
            return entry;
          }
          return entry;
        }),
      };
    case AdminActionTypes.UPDATE_DASHBOARD_ENTRY_SUCCEEDED:
      return {
        ...state,
        loading: false,
        entries: state.entries.map((x) => (x.Id === action.payload.Id ? (action.payload as DashboardEntryModel) : x)),
      };
    case AdminActionTypes.DELETE_DASHBOARD_ENTRY_SUCCEEDED:
      return {
        ...state,
        loading: false,
        entries: state.entries.filter((x) => x.Id !== action.payload),
      };
    case AdminActionTypes.CREATE_DASHBOARD_ENTRY_SUCCEEDED:
      return {
        ...state,
        loading: false,
        entries: [...state.entries, action.payload],
      };
    case AdminActionTypes.GET_PRODUCT_SUBSCRIPTIONS_SUCCEEDED:
      return {
        ...state,
        productSubscriptions: action.payload.Results,
        subscriptionsCount: action.payload.Count,
        loading: false,
      };
    case AdminActionTypes.CLEAR_PRODUCT_SUBSCRIPTION:
      return {
        ...state,
        productSubscriptions: [],
        subscriptionsCount: 0,
      };
    case AdminActionTypes.CANCEL_SUBSCRIPTION_SUCCEEDED:
      return {
        ...state,
        productSubscriptions: state.productSubscriptions.map((subscription) =>
          subscription.Id === action.payload.Id ? { ...action.payload, IsActive: false } : subscription
        ),
        loading: false,
      };
    case AdminActionTypes.CREATE_TEST_SUBSCRIPTION_SUCCEEDED:
      return {
        ...state,
        productSubscriptions: [action.payload, ...state.productSubscriptions],
        loading: false,
      };
    case SubscriptionsActions.CANCEL_SUBSCRIPTION_SUCCEEDED:
      return {
        ...state,
        productSubscriptions: (state.productSubscriptions || []).map((s) =>
          s.Id === action.payload.Id ? action.payload : s
        ),
        loading: false,
      };
    case CommentConstants.DELETE_COMMENT_SUCCEEDED:
      return {
        ...state,
        commentsToModerate: state.commentsToModerate.filter((c) => c.Id !== action.payload),
        loading: false,
      };
    case AdminActionTypes.GET_NOT_APPROVED_COMMENTS_SUCCEEDED:
      return {
        ...state,
        commentsToModerate: action.payload.Results,
        commentsToModerateCount: action.payload.Count,
        loading: false,
      };
    case AdminActionTypes.COMMENT_APRROVE_SUCCEEDED:
      return {
        ...state,
        commentsToModerate: state.commentsToModerate.filter((c) => c.Id !== action.payload),
      };
    case AdminCompanyActionTypes.GET_COMPANIES_SUCCEEDED:
      return {
        ...state,
        companies: action.payload,
        loading: false,
      };
    case AdminCompanyActionTypes.DELETE_COMPANY_SUCCEEDED:
      return {
        ...state,
        companies: state.companies.filter((company) => company.Id !== action.payload),
      };
    case AdminCompanyActionTypes.EDIT_COMPANY_SUCCEEDED:
      return {
        ...state,
        companies: state.companies.map((company) => (company.Id === action.payload.Id ? action.payload : company)),
      };
    case AdminCompanyActionTypes.ADD_COMPANY_SUCCEEDED:
      return {
        ...state,
        companies: [...state.companies, action.payload],
      };
    case AdminCompanyActionTypes.ASSING_COMPANY_RATING_SUCCEEDED:
      return {
        ...state,
        companies: state.companies.map((company) =>
          company.Id === action.payload.id ? { ...company, Rating: action.payload.rating } : company
        ),
      };
    case AdminCO2PlanActionTypes.GET_CO2_SPONSOR_SOLUTIONS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        companies: action.payload.Companies,
      };
    case AdminCompanyAdActionTypes.GET_COMPANY_ADS_SUCCEEDED:
      return {
        ...state,
        companyAds: action.payload,
        loading: false,
      };
    case AdminCompanyAdActionTypes.ADD_COMPANY_AD_SUCCEEDED:
      return {
        ...state,
        companyAds: [...state.companyAds, action.payload],
      };
    case AdminCompanyAdActionTypes.EDIT_COMPANY_AD_SUCCEEDED:
      return {
        ...state,
        companyAds: state.companyAds.map((ad) => (ad.Id === action.payload.Id ? action.payload : ad)),
      };
    case AdminCompanyAdActionTypes.DELETE_COMPANY_AD_SUCCEEDED:
      return {
        ...state,
        companyAds: state.companyAds.filter((ad) => ad.Id !== action.payload),
      };
    case AdminCompanyTestimonialActionTypes.GET_COMPANY_TESTIMONIALS_SUCCEEDED:
      return {
        ...state,
        companyTestimonials: action.payload,
        loading: false,
      };
    case AdminCompanyTestimonialActionTypes.ADD_COMPANY_TESTIMONIAL_SUCCEEDED:
      return {
        ...state,
        companyTestimonials: [action.payload, ...state.companyTestimonials],
      };
    case AdminCompanyTestimonialActionTypes.EDIT_COMPANY_TESTIMONIAL_SUCCEEDED:
      return {
        ...state,
        companyTestimonials: state.companyTestimonials.map((t) => (t.Id === action.payload.Id ? action.payload : t)),
      };
    case AdminCompanyTestimonialActionTypes.DELETE_COMPANY_TESTIMONIAL_SUCCEEDED:
      return {
        ...state,
        companyTestimonials: state.companyTestimonials.filter((t) => t.Id !== action.payload),
      };
    case AdminCraftsmanActionTypes.GET_CRAFTSMEN_SUCCEEDED:
      return { ...state, craftsmen: action.payload };
    case AdminCraftsmanActionTypes.TOGGLE_CRAFTSMAN_GREENTAG_SUCCEEDED:
      return {
        ...state,
        craftsmen: {
          ...state.craftsmen,
          Results: state.craftsmen.Results.map((x) => {
            if (x.Id === action.payload) {
              x.GreenTag = !x.GreenTag;
            }
            return x;
          }),
        },
      };
    case AdminActionTypes.ADMIN_GET_ALL_USERS_SUCCEEDED:
      return { ...state, allUsers: action.payload, loading: false };
    case AdminActionTypes.ADMIN_REMOVE_USER_SUCCEEDED:
      return {
        ...state,
        loading: false,
        allUsers: {
          Count: state.allUsers.Count - 1,
          Results: state.allUsers.Results.filter((x) => x.Id !== action.payload),
        },
      };
    case AdminActionTypes.ADMIN_SET_USER_RATING_SUCCEEDED:
      return {
        ...state,
        loading: false,
        allUsers: {
          ...state.allUsers,
          Results: state.allUsers.Results.map((x) => {
            if (x.Id === action.payload.Id) {
              x.Rating = action.payload.Rating;
            }
            return x;
          }),
        },
      };
      return { ...state, allUsers: action.payload, loading: false };
    case AdminActionTypes.GET_REMOVED_USERS_LOG_SUCCEEDED:
      return { ...state, removedUsersLog: action.payload, loading: false };

    case AdminActionTypes.CREATE_SYSTEM_META:
    case AdminActionTypes.GET_SYSTEM_META_LIST:
    case AdminActionTypes.DELETE_SYSTEM_META:
    case AdminActionTypes.UPDATE_SYSTEM_META:
      return { ...state, loading: true };
    case AdminActionTypes.GET_SYSTEM_META_LIST_SUCCEEDED:
      return { ...state, systemMeta: action.payload, loading: false };
    case AdminActionTypes.DELETE_SYSTEM_META_SUCCEEDED:
      return {
        ...state,
        systemMeta: state.systemMeta.filter((metaItem) => metaItem.Id !== action.payload),
        loading: false,
      };
    case AdminActionTypes.CREATE_SYSTEM_META_SUCCEEDED:
      return {
        ...state,
        systemMeta: [...state.systemMeta, action.payload],
        loading: false,
      };

    case AdminActionTypes.UPDATE_SYSTEM_META_SUCCEEDED:
      return {
        ...state,
        systemMeta: state.systemMeta.map((metaItem) => {
          if (metaItem.Id === action.payload.Id) return action.payload;
          return metaItem;
        }),
        loading: false,
      };
    default:
      return state;
  }
};

export default adminReducer;
