import React, { useEffect, useReducer, useRef } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NIL } from "uuid";

import Unit, {
  PriceEstimation,
  // PriceEstimationUnitTypes,
  UnitNeighbourHouse,
} from "../../../definitions/model/unit/Unit";
import HouseInfo from "./components/HouseInfo";
import HouseTimeline from "../HouseTimeline/HouseTimeline";
import { TimelineModel } from "../../../definitions/Timeline";
import HousePrices from "../HousePrices/HousePrices";
// import HouseLoans from "./components/HouseLoans";
import { useUnit } from "../../../actions/unit.actions";
import { trackInitiateMoveIn, useUser } from "../../../actions/user.actions";
import { UserType } from "../../../constants/enums";
import HouseNeighbourhood from "../HousePrices/HouseNeighbourhood";
// import HouseExtraPriceUserInput from "../HousePrices/HouseExtraPriceUserInput";
import Folders from "../Documents/Folders";
import { DocumentSourceType } from "../../../definitions/Document";
import SendSuggestionForm from "./components/SendSuggestionForm";
import { ArrowBack } from "@mui/icons-material";

type PropertyPageState = {
  unit: Unit | null;
  loading: boolean;
  neighboursLoading: boolean;
  marketPriceLoading: boolean;
  neighbourHouses: UnitNeighbourHouse[];
  timeline: TimelineModel;
  lastEstimation: PriceEstimation | null;
  scrollTo: string | null;
};

const initialState: PropertyPageState = {
  unit: null,
  loading: false,
  neighboursLoading: false,
  marketPriceLoading: false,
  neighbourHouses: [],
  timeline: {},
  lastEstimation: null,
  scrollTo: null,
};

const reducer = (state: PropertyPageState, action: { type: string; payload: unknown }) => {
  if (action.type === "resetState") {
    return {
      ...state,
      ...initialState,
    };
  }
  return { ...state, [action.type]: action.payload };
};

const PropertyPageComponent = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { addressURL } = useParams<{ addressURL: string }>();
  const [{ unit, brokers }, unitActions] = useUnit();
  const navigate = useNavigate();
  const location = useLocation();
  const housePricesRef = useRef<HTMLDivElement>(null);
  const [{ user }, userActions] = useUser();

  const { t } = useTranslation("translation");

  useEffect(() => {
    if (state.unit && !state.unit.IsRental) {
      dispatch({ type: "marketPriceLoading", payload: true });
      unitActions
        .getHouseMarketPrice(state.unit.Id)
        .finally(() => dispatch({ type: "marketPriceLoading", payload: false }));

      dispatch({ type: "neighboursLoading", payload: true });
      unitActions
        .getNeighbourHouses(state.unit.Id, 20)
        .then((houses) => {
          if (houses) dispatch({ type: "neighbourHouses", payload: houses });
        })
        .finally(() => dispatch({ type: "neighboursLoading", payload: false }));
      const lastEstimation = state.unit.EstimationData.sort(
        (a, b) => new Date(b.CalculationDateTime).getTime() - new Date(a.CalculationDateTime).getTime()
      )[0];

      dispatch({ type: "lastEstimation", payload: lastEstimation });
    }
  }, [state.unit]);

  useEffect(() => {
    if (addressURL) {
      const addressParts = addressURL.split("_");
      const id = addressParts[addressParts.length - 1];
      // if we have id of unit that we search and this id not match with movedInProperty to avoid re-rendering
      if (id && (!state.unit || state.unit.Id !== id)) {
        dispatch({ type: "loading", payload: true });
        unitActions
          .getFullUnit(id)
          .then((unit) => dispatch({ type: "unit", payload: unit }))
          .finally(() => dispatch({ type: "loading", payload: false }));
        unitActions.getUnitTimeline(id).then((items) => dispatch({ type: "timeline", payload: items }));
      }
    }
  }, [addressURL]);

  useEffect(() => {
    if (!state.loading && addressURL) {
      const addressParts = addressURL.split("_");
      const id = addressParts[addressParts.length - 1];
      if (id == unit?.Id) dispatch({ type: "unit", payload: unit });
    }
  }, [state.loading, unit, addressURL]);

  useEffect(() => {
    if (state.scrollTo && state.unit) {
      if (state.scrollTo === "housePrices" && housePricesRef) {
        setTimeout(
          () =>
            housePricesRef.current!.scrollIntoView({
              behavior: "smooth",
              block: "start",
            }),
          0
        );
      }
    }
  }, [state.scrollTo, state.unit]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const value = params.has("scrollTo") ? params.get("scrollTo") : null;
    if (value) {
      dispatch({ type: "scrollTo", payload: value });
    }

    return () => {
      dispatch({ type: "resetState", payload: null });
    };
  }, []);

  useEffect(() => {
    //redirect to search result page with short details about property for not owners
    if (!!user?.Role && user?.Role >= UserType.ExternalManager) return;

    if (!unit || !state.unit || unit?.Id === state.unit?.Id) return;

    navigate(`/${addressURL}/search`);
  }, [unit, state.unit, user, addressURL]);

  return (
    <>
        {location.state?.fromBuilding === true && (
          <Button onClick={() => navigate(-1)} variant="outlined" color="secondary">
            <ArrowBack fontSize="small" />
            {t("General.Buttons.Back")}
          </Button>
        )}
        <Box my={3}>
          <Typography variant="h3">
            {t("Pages.PropertyFacts.WelcomeToMyhouse", { hostname: window.location.hostname })}
          </Typography>
          <Typography variant="body1">{t("Pages.PropertyFacts.DataAboutHomeHistory")}</Typography>
        </Box>
        <HouseInfo
          unit={state.unit}
          user={user}
          getOwnedHousesCount={unitActions.getOwnedHousesCount}
          trackMoveInClick={trackInitiateMoveIn}
          isSubscribedToHousePhotoUpdate={userActions.isUserSubscribedToHousePhotoUpdate}
          subscribeOnHousePhotoUpdate={userActions.subscribeOnPhotoUpdate}
        />
        {Object.entries(state.timeline).length > 0 && <HouseTimeline timelineItems={state.timeline} />}
        {/* <Box my={6.25}>
          {state.unit && <HouseAssesment unit={state.unit} />}
        </Box> */}
        {state.unit && !state.unit.IsRental && (
          <div ref={housePricesRef}>
            <HousePrices
              // lastEstimation={state.lastEstimation}
              brokers={brokers}
              isLoading={state.marketPriceLoading}
              unit={state.unit}
              subscribeOnHousePriceChanges={userActions.subscribeOnHousePriceChanges}
            />
          </div>
        )}
        {state.unit?.BuildingId && state.unit.BuildingId !== NIL && (
          <>
            <SendSuggestionForm />
            <Folders
              sourceItemId={state.unit.BuildingId}
              sourceType={DocumentSourceType.Building}
              readonly
              childSourceItemId={state.unit.Id}
            />
          </>
        )}
        {/* {state.unit && (
          <>
            {PriceEstimationUnitTypes.includes(state.unit.PropertyType) && user && (
              <>
                <HouseExtraPriceUserInput
                  unit={state.unit}
                  user={user}
                  estimatedPrice={
                    unitData?.brokers?.find((b) => b.BrokerName.toLowerCase() === "vurderingsportalen")?.Estimation ?? 0
                  }
                  updateExtraPriceUserInput={updateExtraPriceUserInput}
                />
              </>
            )}
          </>
        )} */}
        {state.unit && !state.unit.IsRental && (
          <HouseNeighbourhood neighbourHouses={state.neighbourHouses} isLoading={state.neighboursLoading} />
        )}

        {/* <Box my={6.25}>
        {unitData.economy && state.unit && (
          <HouseLoans 
            unitId={state.unit.Id} 
            economy={unitData.economy} 
            getHouseEconomy={getHouseEconomy}
            getLoanOptimizedCalculation={getLoanOptimizedCalculation}
          />
        )}
      </Box> */}
      </>
  );
};

export default PropertyPageComponent;
