
export const PostBoxActions =  {
 GET_MESSAGES: "GET_MESSAGES",
 GET_MESSAGES_SUCCEEDED: "GET_MESSAGES_SUCCEEDED",
 GET_THREAD: "GET_THREAD",
 GET_THREAD_SUCCEEDED: "GET_THREAD_SUCCEEDED",
 GET_MESSAGE: "GET_MESSAGE",
 GET_MESSAGE_SUCCEEDED: "GET_MESSAGE_SUCCEEDED",
 CREATE_MESSAGE:"CREATE_MESSAGE",
 CREATE_MESSAGE_SUCCEEDED:"CREATE_MESSAGE_SUCCEEDED",
 REPLY_TO_MESSAGE: "REPLY_TO_MESSAGE",
 REPLY_TO_MESSAGE_SUCCEEDED: "REPLY_TO_MESSAGE_SUCCEEDED",
 MOVE_MESSAGE_TO_TRASH: "MOVE_MESSAGE_TO_TRASH",
 MOVE_MESSAGE_TO_TRASH_SUCCEEDED: "MOVE_MESSAGE_TO_TRASH_SUCCEEDED",
 REMOVE_MESSAGE: "REMOVE_MESSAGE",
 REMOVE_MESSAGE_SUCCEEDED: "REMOVE_MESSAGE_SUCCEEDED",
 GET_RECIPIENTS: "GET_RECIPIENTS",
 GET_RECIPIENTS_SUCCEEDED: "GET_RECIPIENTS_SUCCEEDED",
 POSTBOX_OPERATION_FAILED:"POSTBOX_OPERATION_FAILED",
 POSTBOX_NEW_MAIL_RECEIVED: "POSTBOX_NEW_MAIL_RECEIVED"
}
