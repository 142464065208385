import ApiService from "../api.service";
import { FormBuilderDTO, TemplateModel, LandingMetaModel, WidgetMetaModel } from "../../definitions/Landing";
import { TemplateType } from "../../constants/enums";
import { SearchResult } from "../../definitions/model/SearchResult";
import { headers } from "../http.service";

const AdminLandingService = {
  addTemplate: async (title: string, type: TemplateType): Promise<TemplateModel> => {
    const url = `/api/admin/landing/addtemplate`;
    const result = await ApiService.post<TemplateModel>(url, {
      Title: title,
      Type: type,
    });
    return result;
  },
  getTemplates: async (
    searchTerm: string,
    skip: number,
    take: number,
    type?: TemplateType
  ): Promise<SearchResult<TemplateModel>> => {
    let url = `/api/admin/landing/templates?searchTerm=${searchTerm}&skip=${skip}&take=${take}`;
    if (Number.isInteger(type)) url += `&type=${type}`;
    const result = await ApiService.get<SearchResult<TemplateModel>>(url);
    return result;
  },
  updateTemplate: async (template: TemplateModel): Promise<TemplateModel> => {
    const url = `/api/admin/landing/templates/${template.Id}`;
    const result = await ApiService.put<TemplateModel>(url, template);
    return result;
  },
  getTempalte: async (id: string): Promise<TemplateModel> => {
    const url = `/api/admin/landing/templates/${id}`;
    const result = await ApiService.get<TemplateModel>(url);
    return result;
  },
  deleteTemplate: async (id: string): Promise<boolean> => {
    const url = `/api/admin/landing/templates/${id}`;
    const result = await ApiService.delete<boolean>(url);
    return result;
  },
  getTemplateEditor: async (id: string): Promise<FormBuilderDTO> => {
    const url = `/api/admin/landing/${id}/templateeditor`;
    const result = await ApiService.get<FormBuilderDTO>(url);
    return result;
  },
  getLandings: async (searchTerm: string, skip?: number, take?: number): Promise<SearchResult<LandingMetaModel>> => {
    let url = `/api/admin/Landing/landings?searchTerm=${searchTerm}`;
    if (skip) url += `&skip=${skip}`;
    if (take) url += `&take=${take}`;
    const result = await ApiService.get<SearchResult<LandingMetaModel>>(url);
    return result;
  },
  getLanding: async (landingId: string): Promise<LandingMetaModel | null> => {
    const url = `/api/admin/Landing/landing/${landingId}`;
    const result = await ApiService.get<LandingMetaModel | null>(url);
    return result;
  },
  createLanding: async (title: string, uniqueName: string, templateId?: string): Promise<LandingMetaModel> => {
    const url = `/api/admin/Landing/landing`;
    const result = await ApiService.put<LandingMetaModel>(url, {
      Title: title,
      UniqueName: uniqueName,
      TemplateId: templateId
    });
    return result;
  },
  updateLanding: async (landing: LandingMetaModel): Promise<LandingMetaModel> => {
    const url = `/api/admin/Landing/landing`;
    const result = await ApiService.post<LandingMetaModel>(url, landing);
    return result;
  },
  updateFacebookPicture: async (landingId: string, picture: File): Promise<string> => {
    const formData = new FormData();
    const blob = new Blob([picture], { type: picture.type });
    formData.append("File", blob);

    const headersNoContentType = await headers();
    delete headersNoContentType["Content-Type"];

    const response = await fetch(`/api/admin/landing/${landingId}/uploadfacebookimage`, {
      method: "POST",
      body: formData,
      headers: headersNoContentType,
    });
    const result = await response.text();
    if (response.status === 200) return result;
      
    throw result;
  },
  deleteLanding: async (landingId: string): Promise<boolean> => {
    const url = `/api/admin/Landing/landing/${landingId}`;
    const result = await ApiService.delete<boolean>(url);
    return result;
  },
  cloneLanding: async (landingId: string): Promise<LandingMetaModel> => {
    const url = `/api/admin/Landing/landing/clone/${landingId}`;
    const result = await ApiService.put<LandingMetaModel>(url, landingId);
    return result;
  },
  getTermsList: async ( skip?: number, take?: number): Promise<SearchResult<LandingMetaModel>> => {
    let url = `/api/admin/Landing/terms?skip=${skip || 0}`;
    if (take) url += `&take=${take}`;
    const result = await ApiService.get<SearchResult<LandingMetaModel>>(url);
    return result;
  },
  getTerms: async (id: string): Promise<LandingMetaModel | null> => {
    const url = `/api/admin/Landing/terms/${id}`;
    const result = await ApiService.get<LandingMetaModel | null>(url);
    return result;
  },
  createTerms: async (): Promise<LandingMetaModel> => {
    const url = `/api/admin/Landing/terms`;
    const result = await ApiService.post<LandingMetaModel>(url, {
    });
    return result;
  },
  updateTerms: async (terms: LandingMetaModel): Promise<LandingMetaModel> => {
    const url = `/api/admin/Landing/terms`;
    const result = await ApiService.put<LandingMetaModel>(url, terms);
    return result;
  },
  deleteTerms: async (id: string): Promise<boolean> => {
    const url = `/api/admin/Landing/terms/${id}`;
    const result = await ApiService.delete<boolean>(url);
    return result;
  },
  //Don't think it is needed 'cause we generate new terms each time they're changed
  cloneTerms: async (id: string): Promise<LandingMetaModel> => {
    const url = `/api/admin/Landing/terms/${id}/clone`;
    const result = await ApiService.post<LandingMetaModel>(url, id);
    return result;
  },
  addLocalization: async (id: string, languageId: string) => {
    const url = `/api/admin/Landing/landing/${id}/${languageId}`;
    const result = await ApiService.put<LandingMetaModel>(url, null);
    return result;
  },
  addWidgetLocalization: async (id: string, languageId: string) => {
    const url = `/api/admin/Landing/widgets/${id}/${languageId}`;
    const result = await ApiService.put<WidgetMetaModel>(url, null);
    return result;
  },  
  activateTerms: async (terms: LandingMetaModel): Promise<LandingMetaModel> => {
    const url = `/api/admin/Landing/terms/activate`;
    const result = await ApiService.put<LandingMetaModel>(url, terms);
    return result;
  },
};

export default AdminLandingService;
