import { Reducer } from "redux";
import { AppAction } from "../../../../definitions/Action";
import { AdminNavigationActionTypes } from "./admin.navigation.actiontypes";
import { NavigationItem } from '../../../../definitions/Menu';

export interface AdminNavigationStoreState {
  navigationItems: NavigationItem[];
  currentItem: NavigationItem | undefined;
  loading: boolean;
  error: string | null;
}



 export const initialState: AdminNavigationStoreState = {
  navigationItems: [],
  currentItem: undefined,
  loading: false,
  error: null,
};

const navigationAdminReducer: Reducer<AdminNavigationStoreState, AppAction> = (
  state: AdminNavigationStoreState = initialState,
  action: AppAction
): AdminNavigationStoreState => {
  switch (action.type) {
    case AdminNavigationActionTypes.NAVIGATION_OPERATION_FAILED:
      return { ...state, loading: false, error: action.payload };
    case AdminNavigationActionTypes.GET_NAVIGATION_ITEM:
    case AdminNavigationActionTypes.GET_NAVIGATION_ITEMS:
    case AdminNavigationActionTypes.DELETE_NAVIGATION_ITEM:
    case AdminNavigationActionTypes.REORDER_NAVIGATION_ITEMS:
    case AdminNavigationActionTypes.SET_NAVIGATION_ITEM_PUBLISH:
    case AdminNavigationActionTypes.UPDATE_NAVIGATION_ITEM:
    case AdminNavigationActionTypes.CREATE_NAVIGATION_ITEM:
      return { ...state, loading: true };
    case AdminNavigationActionTypes.CREATE_NAVIGATION_ITEM_SUCCEEDED:
      return {
        ...state,
        navigationItems: [...state.navigationItems, action.payload],
      };
    case AdminNavigationActionTypes.GET_NAVIGATION_ITEMS_SUCCEEDED:
      return {
        ...state,
        navigationItems: action.payload,
      };
    case AdminNavigationActionTypes.GET_NAVIGATION_ITEM_SUCCEEDED:
      return {
        ...state,
        currentItem: state.navigationItems.find(
          (item) => item.Id === action.payload
        ),
      };
    case AdminNavigationActionTypes.DELETE_NAVIGATION_ITEM_SUCCEEDED:
      return {
        ...state,
        navigationItems: state.navigationItems
          .filter((item) => item.Id !== action.payload)
          .map((item, index) => ({
            ...item,
            OrderNumber: index
          }))
      };
    case AdminNavigationActionTypes.REORDER_NAVIGATION_ITEMS_SUCCEEDED:
      return {
        ...state,
        navigationItems: action.payload,
      };
    case AdminNavigationActionTypes.SET_NAVIGATION_ITEM_PUBLISH_SUCCEEDED:
    case AdminNavigationActionTypes.UPDATE_NAVIGATION_ITEM_SUCCEEDED:
      return {
        ...state,
        navigationItems: state.navigationItems.map((item) => {
          if (item.Id === action.payload.Id) {
            return action.payload;
          }
          return item;
        }),
      };
    default:
      return state;
  }
};

export default navigationAdminReducer;
