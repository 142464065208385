import React from "react";
import ReactDOM from "react-dom";
import {
  GrapesEditor,
  GrapesTypes,
  LoadComponentOptions,
} from "../../defenitions";
import { Grid, Button, ThemeProvider, Theme, StyledEngineProvider } from "@mui/material";
import AutocompleteRegistration from "../../../Autocomplete/AutocompleteRegistration";
import { Provider } from "react-redux";
import store from "../../../../reducers/store";
import { theme } from "../../../../constants/theme";

// export function AutocompleteForm(): JSX.Element {

//   return (
//     <Grid container>
//       <Grid item xs={12}>
//         <AutocompleteRegistration
//           placeholder={t("Autocomplete.Placeholder")}
//           size="small"
//           simpleRegistration={true}
//           registrationDestination="unit-route"
//         >
//           <Button style={{ width: 150 }} variant="contained" color="secondary" type="submit">
//             Næste
//           </Button>
//         </AutocompleteRegistration>
//       </Grid>
//     </Grid>
//   );
// }

export default function (
  editor: GrapesEditor,
  options: LoadComponentOptions
): void {
  const domComponents = editor.DomComponents;
  const blockManager = editor.BlockManager;
  const defaultType = domComponents.getType("default");
  const { model, view } = defaultType;

  blockManager.add(GrapesTypes.SmsLinkComponent, {
    label: "Link",
    category: options.categoryLabel,
    attributes: {
      class: "fa fa-link",
      "data-gjs-type": `${GrapesTypes.SmsLinkComponent}`,
    },
    content: `<shortlink data-gjs-type="${GrapesTypes.SmsLinkComponent}">Configure link</shortlink>`,
  });

  domComponents.addType(GrapesTypes.SmsLinkComponent, {
    isComponent(el: HTMLElement) {
      if (
        (el.getAttribute &&
          el.getAttribute("data-gjs-type") === GrapesTypes.SmsLinkComponent) ||
        (el.attributes &&
          (el.attributes as any)["data-gjs-type"] ===
            GrapesTypes.SmsLinkComponent)
      ) {
        return {
          type: GrapesTypes.SmsLinkComponent,
          content: el.innerHTML,
          components: [],
        };
      }
    },
    model: {
      defaults: {
        ...model.prototype.defaults,
        tagName: "shortlink",
        droppable: false,
        draggable: "*",
        type: "text",
      },
    },
    // view: view.extend({
    //   // init() {
    //   //   this.listenTo(this.model, "change:label", this.handleChanges);
    //   // },

    //   // handleChanges() {
    //   //   ReactDOM.unmountComponentAtNode(this.el);
    //   //   this.render();
    //   // },

    //   onRender({ el }: { el: HTMLElement }) {
    //     const comps = this.model.get("components");
    //     comps.reset();
    //     const compString = `<span data-gjs-type="${GrapesTypes.SmsLinkComponent}">Configure link</span>`;
    //     comps.add(compString);

    //     ReactDOM.render(<span data-gjs-type={`${GrapesTypes.SmsLinkComponent}`}>Configure link</span>, el);
    //   },
    //}),
  });
}
