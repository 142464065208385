import { FileType } from "../Document";

export interface NeighborGroupAnnouncement {
  Id: string;
  Created: Date;
  Updated: Date;
  OwnerId: string;
  Topic: string;
  Description: string;
  Type: AnnouncementType;
  MaxSubscribers: number;
  Subscribers: EventSubscriberDto[];
  Owner: NeighborGroupMemberDto | undefined;
  Documents: NeighborGroupFile[];
  Picture: FileType;
  Range: number;
}

export interface NeighborGroupAnnouncementContract {
  Id: string;
  Created: Date;
  Updated: Date;
  OwnerId: string;
  Topic: string;
  Description: string;
  Type: AnnouncementType;
  MaxSubscribers: number;
  Subscribers: NeighborGroupMemberDto[];
  Range: number;
}

export interface NeighborGroup {
  Id: string;
  Created: Date;
  Updated: Date;
  Name: string;
  Icon: string;
  Description: string;
  Announcements: NeighborGroupAnnouncement[];
  OwnerId: string;
  Linked: boolean;
  NewMessages: boolean;
  newAnnouncements: boolean;
  MembersCount: number;
  Range: number;
  IsPrivate: boolean;
  Owner?: NeighborGroupMemberDto;
  Administrators: string[];
  Settings: NeighborGroupMemberSettingsDto;
  Documents: FileType[];
  Picture: FileType;
  IsLocal: boolean;
  ExternalInvitedMembers: string[];
}

export interface NeighborGroupMemberSettingsDto {
  ConfirmedMember: boolean;
  ReceiveEmails: boolean;
  ReceiveSms: boolean;
}

export interface NeighborGroupMemberDto {
  Latitude: number;
  Longitude: number;
  AvatarUrl: string;
  Name: string;
  UserId: string;
  Address: string;
  Email: string;
  LastVisited?: Date;
}

export interface NeighborGroupMembersDto {
  X: number;
  Y: number;
  Latitude: number;
  Longitude: number;
  Members: NeighborGroupMemberDto[];
  Count: number;
}

export enum AnnouncementType {
  Other = 0,
  Announcement = 1,
  Event = 2,
}

export enum NeighborGroupFileType {
  Other = 0,
  Document = 1,
  Picture = 2,
}

export interface EventSubscriberDto {
  Id: string;
  Email: string;
  Name: string;
  AvatarUrl: string;
  Address: string;
  Confirmed: boolean;
}

export interface UpdateNeighborGroupContract {
  Id: string;
  Name: string;
  Description: string;
  Range: number;
  IsPrivate: boolean;
  PictureId: string;
  DocumentsAccess: Record<string, boolean>;
}

export interface NeighborGroupFile extends FileType {
  dirty?: boolean;
}
