import React from "react";
import Avatar from "@mui/material/Avatar";
import User from "../../definitions/model/User";

type UserAvatarProps = {
  user: Partial<User>;
  className?: string;
  variant?: "square" | "rounded" | "circular" | undefined;
  selected?: boolean;
  small?: boolean;
};

const UserAvatar = ({ user, className, variant, selected, small }: UserAvatarProps) => {
  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function getAvatar(user: Partial<User>) {
    const tansform = {
      transform: selected ? "scale(1.2)" : "scale(1)",
    };

    const style = small ? { ...tansform, width: 24, height: 24 } : tansform;

    if (user.AvatarUrl) return { src: user.AvatarUrl, sx: { ...style } };

    const name = user.Name ? user.Name.split(" ")[0][0].toLocaleUpperCase() : "A";
    return {
      sx: { ...style, bgcolor: stringToColor(user.Name || "") },
      children: `${name}`,
    };
  }

  return <>{user && <Avatar variant={variant} alt={user.Name} className={className} {...getAvatar(user)} />}</>;
};

export default UserAvatar;
