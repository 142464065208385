export const AdminActionTypes = {
  ADMIN_OPERATION_FAILED: "ADMIN_OPERATION_FAILED",
  GET_MOVED_IN: "GET_MOVED_IN",
  GET_MOVED_IN_SUCCEEDED: "GET_MOVED_IN_SUCCEEDED",
  ADD_NEWS_TEMPLATE: "ADD_NEWS_TEMPLATE",
  ADD_NEWS_TEMPLATE_SUCCEEDED: "ADD_NEWS_TEMPLATE_SUCCEEDED",
  GET_NEWS_TEMPLATES: "GET_NEWS_TEMPLATES",
  GET_NEWS_TEMPLATES_SUCCEEDED: "GET_NEWS_TEMPLATES_SUCCEEDED",
  DELETE_NEWS_TEMPLATE: "DELETE_NEWS_TEMPLATE",
  DELETE_NEWS_TEMPLATE_SUCCEEDED: "DELETE_NEWS_TEMPLATE_SUCCEEDED",
  GET_NEWS_TEMPLATE: "GET_NEWS_TEMPLATE",
  GET_NEWS_TEMPLATE_SUCCEEDED: "GET_NEWS_TEMPLATE_SUCCEEDED",
  ADD_NEWS: "ADD_NEWS",
  ADD_NEWS_SUCCEEDED: "ADD_NEWS_SUCCEEDED",
  GET_NEWS: "GET_ADMIN_NEWS",
  GET_NEWS_SUCCEEDED: "GET_ADMIN_NEWS_SUCCEEDED",
  GET_NEWS_EDITOR: "GET_NEWS_EDITOR",
  GET_NEWS_EDITOR_SUCCEEDED: "GET_NEWS_EDITOR_SUCCEEDED",
  DELETE_NEWS: "DELETE_NEWS",
  DELETE_NEWS_SUCCEEDED: "DELETE_NEWS_SUCCEEDED",
  SET_NEWS_PUBLISH: "SET_NEWS_PUBLISH",
  SET_NEWS_PUBLISH_SUCCEEDED: "SET_NEWS_PUBLISH_SUCCEEDED",
  GET_ADMIN_USERS: "GET_ADMIN_USERS",
  GET_ADMIN_USERS_SUCCEEDED: "GET_ADMIN_USERS_SUCCEEDED",
  UPDATE_USER_ROLE: "UPDATE_USER_ROLE",
  UPDATE_USER_ROLE_SUCCEEDED: "UPDATE_USER_ROLE_SUCCEEDED",
  GET_USERS_BY_EMAIL_PART: "GET_USERS_BY_EMAIL_PART",
  GET_USERS_BY_EMAIL_PART_SUCCEEDED: "GET_USERS_BY_EMAIL_PART_SUCCEEDED",
  GET_DASHBOARD_ENTRIES: "GET_DASHBOARD_ENTRIES",
  GET_DASHBOARD_ENTRIES_SUCCEEDED: "GET_DASHBOARD_ENTRIES_SUCCEEDED",
  UPDATE_DASHBOARD_SUBSCRIPTION: "UPDATE_DASHBOARD_SUBSCRIPTION",
  UPDATE_DASHBOARD_SUBSCRIPTION_SUCCEEDED: "UPDATE_DASHBOARD_SUBSCRIPTION_SUCCEEDED",
  CHANGE_DASHBOARD_SUBSCRIPTION: "CHANGE_DASHBOARD_SUBSCRIPTION",
  REGISTER_CRAFTSMAN_LEAD: "REGISTER_CRAFTSMAN_LEAD",
  REGISTER_CRAFTSMAN_LEAD_SUCCEEDED: "REGISTER_CRAFTSMAN_LEAD_SUCCEEDED",
  SYNC_CRAFTSMEN: "SYNC_CRAFTSMEN",
  SYNC_CRAFTSMEN_SUCCEEDED: "SYNC_CRAFTSMEN_SUCCEEDED",
  SEND_SMS: "SEND_SMS",
  SEND_SMS_SUCCEEDED: "SEND_SMS_SUCCEEDED",
  ADD_MESSAGE_LINK: "ADD_MESSAGE_LINK",
  ADD_MESSAGE_LINK_SUCCEEDED: "ADD_MESSAGE_LINK_SUCCEEDED",
  GET_BENEFIT_CLUB: "GET_BENEFIT_CLUB",
  GET_BENEFIT_CLUB_SUCCEEDED: "GET_BENEFIT_CLUB_SUCCEEDED",
  GET_BENEFIT_CLUBS: "GET_BENEFIT_CLUBS",
  GET_BENEFIT_CLUBS_SUCCEEDED: "GET_BENEFIT_CLUBS_SUCCEEDED",
  ADD_BENEFIT_CLUB: "ADD_BENEFIT_CLUB",
  ADD_BENEFIT_CLUB_SUCCEEDED: "ADD_BENEFIT_CLUB_SUCCEEDED",
  SET_BENEFIT_CLUB_PUBLISH: "SET_BENEFIT_CLUB_PUBLISH",
  SET_BENEFIT_CLUB_PUBLISH_SUCCEEDED: "SET_BENEFIT_CLUB_PUBLISH_SUCCEEDED",
  REORDER_BENEFIT_CLUBS: "REORDER_BENEFIT_CLUBS",
  REORDER_BENEFIT_CLUBS_SUCCEDED: "REORDER_BENEFIT_CLUBS_SUCCEDED",
  UPDATE_BENEFIT_CLUB: "UPDATE_BENEFIT_CLUB",
  UPDATE_BENEFIT_CLUB_SUCCEEDED: "UPDATE_BENEFIT_CLUB_SUCCEEDED",
  DELETE_BENEFIT_CLUB: "DELETE_BENEFIT_CLUB",
  DELETE_BENEFIT_CLUB_SUCCEEDED: "DELETE_BENEFIT_CLUB_SUCCEEDED",
  GET_CLUBS: "GET_ADMIN_CLUBS",
  GET_CLUBS_SUCCEEDED: "GET_ADMIN_CLUBS_SUCCEEDED",
  GET_PRODUCT_SUBSCRIPTIONS: "GET_PRODUCT_SUBSCRIPTIONS",
  GET_PRODUCT_SUBSCRIPTIONS_SUCCEEDED: "GET_PRODUCT_SUBSCRIPTIONS_SUCCEEDED",
  CLEAR_PRODUCT_SUBSCRIPTION:"CLEAR_PRODUCT_SUBSCRIPTION",
  CANCEL_SUBSCRIPTION:"CANCEL_SUBSCRIPTION",
  CANCEL_SUBSCRIPTION_SUCCEEDED:"CANCEL_SUBSCRIPTION_SUCCEEDED",
  CREATE_TEST_SUBSCRIPTION:"CREATE_TEST_SUBSCRIPTION",
  CREATE_TEST_SUBSCRIPTION_SUCCEEDED:"CREATE_TEST_SUBSCRIPTION_SUCCEEDED",
  GET_NOT_APPROVED_COMMENTS: "GET_NOT_APPROVED_COMMENTS",
  GET_NOT_APPROVED_COMMENTS_SUCCEEDED: "GET_NOT_APPROVED_COMMENTS_SUCCEEDED",
  COMMENT_APRROVE_SUCCEEDED: "COMMENT_APRROVE_SUCCEEDED",
  GET_SUBSCRIBED_TO_LEAD_ALARM: "GET_SUBSCRIBED_TO_LEAD_ALARM",
  GET_SUBSCRIBED_TO_LEAD_ALARM_SUCCEEDED: "GET_SUBSCRIBED_TO_LEAD_ALARM_SUCCEEDED",
  GET_UNSUBSCRIBED_TO_LEAD_ALARM_ADMINS: "GET_UNSUBSCRIBED_TO_LEAD_ALARM_ADMINS",
  GET_UNSUBSCRIBED_TO_LEAD_ALARM_ADMINS_SUCCEEDED: "GET_UNSUBSCRIBED_TO_LEAD_ALARM_ADMINS_SUCCEEDED",
  SUBSCRIBE_TO_LEAD_ALARM: "SUBSCRIBE_TO_LEAD_ALARM",
  SUBSCRIBE_TO_LEAD_ALARM_SUCCEEDED: "SUBSCRIBE_TO_LEAD_ALARM_SUCCEEDED",
  UNSUBSCRIBE_FROM_LEAD_ALARM: "UNSUBSCRIBE_FROM_LEAD_ALARM",
  UNSUBSCRIBE_FROM_LEAD_ALARM_SUCCEEDED: "UNSUBSCRIBE_FROM_LEAD_ALARM_SUCCEEDED",
  GET_UTM_SOURCES: "GET_UTM_SOURCES",
  GET_UTM_SOURCES_SUCCEEDED: "GET_UTM_SOURCES_SUCCEEDED",
  ADD_UTM_SOURCES: "ADD_UTM_SOURCES",
  ADD_UTM_SOURCES_SUCCEEDED: "ADD_UTM_SOURCES_SUCCEEDED",
  CREATE_DASHBOARD_ENTRY: "CREATE_DASHBOARD_ENTRY",
  CREATE_DASHBOARD_ENTRY_SUCCEEDED: "CREATE_DASHBOARD_ENTRY_SUCCEEDED",
  UPDATE_DASHBOARD_ENTRY: "UPDATE_DASHBOARD_ENTRY",
  UPDATE_DASHBOARD_ENTRY_SUCCEEDED: "UPDATE_DASHBOARD_ENTRY_SUCCEEDED",
  DELETE_DASHBOARD_ENTRY: "DELETE_DASHBOARD_ENTRY",
  DELETE_DASHBOARD_ENTRY_SUCCEEDED: "DELETE_DASHBOARD_ENTRY_SUCCEEDED",
  GET_ADMIN_USER_INFO: "GET_ADMIN_USER_INFO",
  GET_ADMIN_USER_INFO_SUCCEEDED: "GET_ADMIN_USER_INFO_SUCCEEDED",
  GET_ADMIN_USER_GROUPS_AGGREGATE: "GET_ADMIN_USER_GROUPS_AGGREGATE",
  GET_ADMIN_USER_GROUPS_AGGREGATE_SUCCEEDED: "GET_ADMIN_USER_GROUPS_AGGREGATE_SUCCEEDED",
  ADMIN_GET_ALL_USERS: "ADMIN_GET_ALL_USERS",
  ADMIN_GET_ALL_USERS_SUCCEEDED: "ADMIN_GET_ALL_USERS_SUCCEEDED",
  ADMIN_REMOVE_USER: "ADMIN_REMOVE_USER",
  ADMIN_REMOVE_USER_SUCCEEDED: "ADMIN_REMOVE_USER_SUCCEEDED",
  ADMIN_SET_USER_RATING: "ADMIN_SET_USER_RATING",
  ADMIN_SET_USER_RATING_SUCCEEDED: "ADMIN_SET_USER_RATING_SUCCEEDED",
  GET_REMOVED_USERS_LOG: "GET_REMOVED_USERS_LOG",
  GET_REMOVED_USERS_LOG_SUCCEEDED: "GET_REMOVED_USERS_LOG_SUCCEEDED",
  GET_SYSTEM_META: "GET_SYSTEM_META",
  GET_SYSTEM_META_SUCCEEDED: "GET_SYSTEM_META_SUCCEEDED",
  GET_SYSTEM_META_LIST: "GET_SYSTEM_LIST_META",
  GET_SYSTEM_META_LIST_SUCCEEDED: "GET_SYSTEM_LIST_META_SUCCEEDED",
  CREATE_SYSTEM_META: "CREATE_SYSTEM_META_SUCCEEDED",
  CREATE_SYSTEM_META_SUCCEEDED: "CREATE_SYSTEM_META_SUCCEEDED",
  UPDATE_SYSTEM_META: "UPDATE_SYSTEM_META_SUCCEEDED",
  UPDATE_SYSTEM_META_SUCCEEDED: "UPDATE_SYSTEM_SUCCEEDED",
  DELETE_SYSTEM_META: "DELETE_SYSTEM_META",
  DELETE_SYSTEM_META_SUCCEEDED: "DELETE_SYSTEM_META_SUCCEEDED",
  GET_CO2_SPONSOR_SOLUTIONS_SUCCEEDED: "GET_CO2_SPONSOR_SOLUTIONS_SUCCEEDED",
  ADMIN_JOIN_USER_TO_COMPANY_RATING: "ADMIN_JOIN_USER_TO_COMPANY",
  ADMIN_JOIN_USER_TO_COMPANY_SUCCEEDED: "ADMIN_JOIN_USER_TO_COMPANY_SUCCEEDED",
  ADMIN_REMOVE_USER_FROM_COMPANY: "ADMIN_REMOVE_USER_FROM_COMPANY",
  ADMIN_REMOVE_USER_FROM_COMPANY_SUCCEEDED: "ADMIN_REMOVE_USER_FROM_COMPANY_SUCCEEDED",
};
