import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Grow,
  Tab,
  TextField,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "../../../../reducers/store";
import ClaimsList from "./components/ClaimsList";
import EventsList from "./components/EventsList";
import FindEventsBlock from "./components/FindEventsBlock";
import GroupsList from "./components/GroupsList";
import { useDefaultReducer } from "../../../../helpers/hooks";
import { ExpandMore } from "@mui/icons-material";
import {
  MyHouseWatcher,
  StopWatcherModel,
} from "../../../../definitions/model/StopWatcher";
import { AdminTable, Column } from "../../components/AdminTable/AdminTable";
import { id } from "date-fns/locale";
import { useAdmin } from "../../../../actions/admin/admin.actions";

export type AdminUserinfo = {
  Id: string;
  Email: string;
  Name: string;
  UniqueName: string;
  Address: unknown;
  Claims: [{ Type: string; Value: string }];
};

export type AdminInfoGroup = {
  Id: string;
  Title: string;
  GroupType: number;
};

export type AdminInfoAggregate = {
  Id: string;
  AggregateRootId: string;
  CommandId: string;
  TimeStamp: string;
  EventName?: string;
};

export const isEmail = (str: string) =>
  str.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
export const isId = (str: string) =>
  str.match(
    /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$/
  );

type State = {
  userId: string | null;
  unitId: string | null;
  isUserDataShown: boolean;
  activeTab: "user" | "unit";
  aggregate: {
    id: string;
    type: string;
  } | null;
  userLoading: boolean;
  aggregateLoading: boolean;
  stopWatcher: StopWatcherModel | null;
  stopWatchLoading: boolean;
};

const defaultState: State = {
  userId: null,
  aggregate: null,
  unitId: null,
  isUserDataShown: false,
  activeTab: "user",
  userLoading: false,
  aggregateLoading: false,
  stopWatcher: null,
  stopWatchLoading: false,
};

const AggregateViewPage = (props: UserDataPageConnectedProps) => {
  const { userInfo: user, userGroup: groups, aggregate } = props;
  const [state, dispatch] = useDefaultReducer<State>(defaultState);

  const columns: Column<MyHouseWatcher>[] = useMemo(
    () => [
      {
        name: "Name",
        accessor: "Name",
      },
      {
        name: "Counter",
        accessor: "Counter",
      },
      {
        name: "Average",
        accessor: "Average",
      },
    ],
    []
  );
  const [, adminActions] = useAdmin();

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: "user" | "unit"
  ) => {
    dispatch({ type: "activeTab", payload: newValue });
  };

  const loadEventsHandler = (id: string, type: string) => {
    adminActions.getAdminAggregate(id);
    dispatch({ type: "aggregate", payload: { id, type } });
  };

  const handleUserTextChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: "userId", payload: e.target.value });
  const handleGetUser = async () => {
    if (!state.userId) return;
    dispatch({ type: "userLoading", payload: true });
    await adminActions.getAdminUserInfo(state.userId);
    dispatch({ type: "userLoading", payload: false });
  };

  const handleAggregateTextChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: "unitId", payload: e.target.value });
  const handleEnterKey =
    (callback: () => Promise<void>) =>
    async (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key == "Enter") await callback();
    };

  const handleGetAggregate = async () => {
    if (!state.unitId) return;
    dispatch({ type: "aggregateLoading", payload: true });
    await adminActions.getAdminAggregate(state.unitId);
    dispatch({ type: "aggregateLoading", payload: false });
  };

  useEffect(() => {
    if (!props.eventsLoading && state.isUserDataShown) {
      dispatch({ type: "isUserDataShown", payload: false });
    }
  }, [props.eventsLoading]);

  useEffect(() => {
    loadStopWatchData();
    return () => {};
  }, []);

  const loadStopWatchData = () => {
    dispatch({ type: "stopWatchLoading", payload: true });
    adminActions
      .getStopWatcherData()
      .then((d) => {
        dispatch({ type: "stopWatcher", payload: d });
      })
      .finally(() => dispatch({ type: "stopWatchLoading", payload: false }));
  };

  const handleMonitoringChange = () => {
    dispatch({ type: "stopWatchLoading", payload: true });
    adminActions
      .switchStopWatcher()
      .then((d) => dispatch({ type: "stopWatcher", payload: d }))
      .finally(() => dispatch({ type: "stopWatchLoading", payload: false }));
  };

  return (
    <Container>
      <TabContext value={state.activeTab}>
        <Grid container justifyContent="center">
          <TabList onChange={handleChange}>
            <Tab value="user" label="user" />
            <Tab value="aggregate" label="aggregate" />
            <Tab value="live timings" label="live timings" />
          </TabList>
        </Grid>
        <TabPanel value="user">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ paddingBottom: 1 }}
          >
            <Grid item>
              <Typography align="center">User</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                label="Email / Id"
                onChange={handleUserTextChange}
                onKeyDown={handleEnterKey(handleGetUser)}
              />
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                loading={state.userLoading}
                onClick={handleGetUser}
              >
                FIND USER
              </LoadingButton>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grow
                in={!!user && !state.userLoading}
                mountOnEnter
                unmountOnExit
                timeout={300}
                style={{ transformOrigin: "center top" }}
              >
                <Box>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      User data
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container justifyContent="space-between">
                        <Box>
                          <Typography>{`Email: ${user?.Email}`}</Typography>
                          <Typography>{`Id: ${user?.Id}`}</Typography>
                          <Typography>{`Name: ${user?.Name}`}</Typography>
                          <Typography>{`UniqueName: ${user?.UniqueName}`}</Typography>
                          <Typography>{`Address: ${user?.Address}`}</Typography>
                        </Box>
                        <Grid alignItems="center" style={{ display: "flex" }}>
                          <FindEventsBlock
                            name="user"
                            loading={props.eventsLoading}
                            onClick={loadEventsHandler}
                            id={user?.Id ?? ""}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      Claims
                    </AccordionSummary>
                    <AccordionDetails>
                      <ClaimsList
                        claims={user?.Claims ?? []}
                        loading={props.eventsLoading}
                        findEventHandler={loadEventsHandler}
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      Group
                    </AccordionSummary>
                    <AccordionDetails>
                      <GroupsList
                        groups={groups}
                        findEventHandler={loadEventsHandler}
                        loading={props.eventsLoading}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Grow>
            </Grid>
            <Grow in={!!aggregate}>
              <Grid
                container
                justifyContent="center"
                maxWidth="100%"
                alignItems="center"
              >
                {aggregate && (
                  <EventsList
                    Title={`${state.aggregate?.type}(${state.aggregate?.id})`}
                    events={aggregate}
                  />
                )}
              </Grid>
            </Grow>
          </Grid>
        </TabPanel>
        <TabPanel value="aggregate">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ paddingBottom: 1 }}
          >
            <Grid item>
              <Typography align="center">Id</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                label="Id"
                onChange={handleAggregateTextChange}
                onKeyDown={handleEnterKey(handleGetAggregate)}
              />
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                loading={state.aggregateLoading}
                onClick={handleGetAggregate}
              >
                FIND EVENTS
              </LoadingButton>
            </Grid>
          </Grid>
          <Grow
            in={!!aggregate && !state.aggregateLoading}
            mountOnEnter
            unmountOnExit
            timeout={300}
            style={{ transformOrigin: "center top" }}
          >
            <Box>
              <EventsList Title="" events={aggregate ?? {}} />
            </Box>
          </Grow>
        </TabPanel>
        <TabPanel value="live timings">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ paddingBottom: 1 }}
          >
            <Grid item>
              <Typography align="center">
                <FormControlLabel
                  control={
                    <Switch
                      color="secondary"
                      checked={state.stopWatcher?.IsMonitoring}
                      disabled={state.stopWatchLoading}
                      onChange={handleMonitoringChange}
                    />
                  }
                  label="Collect timings"
                />
              </Typography>
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                loading={state.stopWatchLoading}
                onClick={loadStopWatchData}
              >
                Refresh
              </LoadingButton>
            </Grid>
          </Grid>
          <Grow
            in={!!state.stopWatcher?.Watchers}
            mountOnEnter
            unmountOnExit
            timeout={300}
            style={{ transformOrigin: "center top" }}
          >
            <Grid item container>
              {state.stopWatcher && (
                <AdminTable
                  data={state.stopWatcher.Watchers}
                  columns={columns}
                  pagination={"client"}
                  loading={state.stopWatchLoading}
                />
              )}
            </Grid>
          </Grow>
        </TabPanel>
      </TabContext>
    </Container>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  userInfo: state.admin.userInfo,
  userGroup: state.admin.userGroups,
  aggregate: state.admin.userAggregate,
  loading: state.admin.loading,
  eventsLoading: state.admin.userAggregateLoading,
});

const connector = connect(mapStateToProps, null);

type UserDataPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(AggregateViewPage);
