import React, { useCallback } from "react";
import { Button, Dialog, MobileStepper, styled } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

type Props = {
    open: boolean;
    handleClose: () => void;
    activeStep: number;
    setActiveStep: any;
    images: string[];
}

export const PollCarousel = ({open, handleClose, activeStep, setActiveStep, images}: Props) => {
    const handleNext = useCallback(() => {
        setActiveStep((prevActiveStep: number) => {
            return prevActiveStep === images.length - 1 ? 0 : prevActiveStep + 1
        });
    }, []);

    const handleBack = useCallback(() => {
        setActiveStep((prevActiveStep: number) => {
            return prevActiveStep === 0 ? images.length - 1 : prevActiveStep - 1
        });
    }, []);

    return (
        <StyledDialog 
            open={open} 
            maxWidth={"md"}
            fullWidth={true}
            onClose={handleClose} 
        >
            <img src={images[activeStep]} alt="house style" />
            <StyledMobileStepper
                steps={images.length}
                activeStep={activeStep}
                nextButton={
                    <Button onClick={handleNext}>
                        <KeyboardArrowRight />
                    </Button>
                }
                backButton={
                    <Button onClick={handleBack}>
                        <KeyboardArrowLeft />
                    </Button>
                }
            />
        </StyledDialog>
    )
}

const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paper": {
        display: "flex",
        justifyContent: "center",
        overflow: "hidden"
    }
})

const StyledMobileStepper = styled(MobileStepper)({
    width: "100%",
    padding: 0,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    background: "transparent",
    "& .MuiButtonBase-root": {
        height: 60,
        width: 60,
        padding: 0,
        minWidth: 60,
        borderRadius: 0,
        "& svg": {
            fontSize: 60,
            color: "#32c111"
        }
    },
    "& .MuiMobileStepper-dots": {
        display: "none"
    }
})