export enum UserType {
  Unauthorized = 0,
  User = 1,
  ExternalManager = 2,
  Manager = 3,
  Administrator = 4,
}

export enum TimelineItemType {
  Assesment = "assesment",
  Building = "building",
  MainBuilding = "mainBuilding",
  Sale = "sale",
}

export enum WidgetPosition {
  Top = 0,
  Bottom = 1,
  LeftSide = 2,
  RightSide = 3,
  Stream = 4,
}
export enum WidgetPage {
  NewsStream = 0,
  Clubs = 2,
  Overview = 3,
  Fordelsklubs = 4,
  CO2Calculator = 5,
  FordelsklubsSubscribe = 6,
}

export enum UserHistoryType {
  Unknown = 0,
  UserRegistered = 1,
  Verified = 2,
  MovedIn = 3,
  SettingsChanged = 4,
  Terms2018Accepted = 5,
}

export enum UserInterest {
  Unknown = 0,
  Accommodation = 1,
  DIY = 2,
  Culture = 3,
  Garden = 4,
  Sport = 5,
  HouseDigitalization = 6,
}

export enum DashboardActivityStatus {
  Failed = 0,
  ShouldStartSoon = 1,
  OK = 2,
  InProgress = 3,
}

export enum DashboardUpdatingFrequency {
  Daily = 1,
  Weekly = 7,
  Monthy = 30,
  Quarterly = 90,
  Yearly = 360,
}

export enum DashboardUpdatingType {
  Automatic,
  Manual,
}

export enum DashboardSubscriptionFrequency {
  Daily,
  Weekly,
  Monthly,
  OnlyFailedOrInSprint,
}

export enum GroupType {
  Private = 0,
  Public = 1,
  Family = 2,
  Home = 3,
  CountryLevelGroup = 4,
}

export enum GroupMemberRole {
  Undefined = -1,
  NotValidMember = 0,
  Member = 1,
  Administrator = 2,
  InvitedUser = 3,
  Expert = 4,
  Manager = 5,
  Owner = 6,
  Caretacker = 7,
}

export enum LandingPageDestination {
  Profile = 0,
  Property = 1,
  Noise = 2,
  SchoolsComparison = 3,
  Estimation = 4,
  Sales = 5,
  Assesstment = 6,
  MyWorkers = 7,
  News = 8,
  Map = 9,
  Documents = 10,
  Loans = 11,
  HouseMarketPrice = 12,
  HouseFindWorkers = 13,
  Mailbox = 15,
  NewsStream = 16,
  Family = 17,
  FamilyCalendar = 18,
  Groups = 19,
  HouseSearch = 20,
  Favorites = 21,
  SavedSearches = 22,
  Flooding = 23,
  Radon = 24,
  Ground = 25,
  ServiceBook = 26,
  Economy = 27,
  Servitutes = 29,
  PriceInfo = 30,
  Energy = 31,
  Gymnasium = 32,
  Crime = 33,
  Companies = 34,
  DesignAssistant = 35,
  Download = 36,
  Unsubscribe = 37,
  Landing = 38,
}

export enum TemplateType {
  News = 0,
  Landing = 1,
  Widget = 2,
  Fordelsklub = 3,
  ClubArticle = 4,
}

export enum ArticleCategory {
  Transport = 1,
  Vacation = 2,
  Clothes = 3,
  Food = 4,
  Gadgets = 5,
  Electricity = 6,
  Heating = 7,
}

export enum NewsCategoriesFilter {
  MyhouseNews = 0,
  CommuneSite = 1,
  LocalNews = 2,
  PoliceReports = 3,
  CulturalNews = 4,
}

export enum StatisticsArticleType {
  CommuneNews = 1,
  Success = 2,
  Newspaper = 3,
  CompanyNews = 4,
}

export enum PermissionTarget {
  Owner = 0,
  Group = 1,
  Public = 2,
  Member = 3,
  Anonymous = 4,
}

export enum RoomType {
  Unknown = 0,
  Bathroom = 1,
  Bedroom = 2,
  Kitchen = 3,
  LivingRoom = 4,
}
