import React, { ChangeEvent, SyntheticEvent } from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FolderType } from "../../../definitions/Document";
import { Documents } from "./Folders";
import FilePreview from "./FilePreview";
import { useTranslation } from "react-i18next";
import { AccordionSummary, styled } from "@mui/material";
import { StyledAccordion } from "./components/Folder";

const StyledNestedSummary = styled(AccordionSummary)(() => ({
  "&.MuiAccordionSummary-root": {
    background: "transparent",
    "& p:first-child": {
      fontSize: 14,
    },
    "&$expanded": {
      minHeight: "auto",
    },
  },
  "& .MuiAccordionSummary-content": {
    marginBottom: 5,
    "&$expanded": {
      margin: "12px 0 5px",
    },
  },
}));

const FileStyledAccordion = styled(StyledAccordion)(({ theme }) => ({
  color: theme.palette.primary.main,
  boxShadow: "none",
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  "& p": {
    color: theme.palette.primary.main,
    padding: 0,
  },
}));

const AccordionDetailsStyled = styled(AccordionDetails)(({ theme }) => ({
  "& h5": {
    marginTop: 15,
    color: theme.palette.primary.main,
    fontSize: 14,
  },
  "& form": {
    width: "100%",
  },
}));

const CardStyled = styled(Card)(({ theme }) => ({
  border: "solid 1px #e4e4e4",
  marginBottom: theme.spacing(2),
}));

type FolderCardProps = {
  folder: FolderType;
  folderChecked: () => boolean;
  documentChecked: (id: string) => boolean;
  onFolderSelect: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onDocumentSelect: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  setPreviewDocument: (document: Documents) => void;
};

const FolderCard = (props: FolderCardProps): JSX.Element => {
  const { t } = useTranslation("translation");

  const stopPropagation = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  return (
    <CardStyled>
      <CardHeader
        sx={{ color: "primary.contrastText", backgroundColor: "primary.main" }}
        title={
          <FormControlLabel
            sx={{
              "& label": {
                color: "primary.contrastText",
              },
            }}
            control={
              <Checkbox
                color="secondary"
                sx={{
                  "&.MuiCheckbox-root": {
                    color: "primary.contrastText",
                  },
                }}
                onChange={props.onFolderSelect}
              />
            }
            value={props.folder.Id}
            label={props.folder.Name}
            checked={props.folderChecked()}
          />
        }
        subheader={props.folder.Description}
        subheaderTypographyProps={{
          sx: {
            color: "primary.contrastText",
          },
        }}
      />

      {props.folder.Documents.map((document) => (
        <Box m={2} key={document.Id}>
          <FileStyledAccordion square>
            <StyledNestedSummary expandIcon={<ExpandMoreIcon />}>
              <FormControlLabel
                control={<Checkbox onChange={props.onDocumentSelect} onClick={stopPropagation} />}
                value={document.Id}
                label={document.Name}
                checked={props.documentChecked(document.Id)}
                onClick={stopPropagation}
              />
            </StyledNestedSummary>
            <AccordionDetailsStyled>
              <Grid container direction="column">
                <Grid item>
                  <Typography>{t("Documents.Description")}</Typography>
                  <Typography paragraph>{document.Description}</Typography>
                </Grid>
                {document.Files.length > 0 && (
                  <Grid item>
                    <Typography>{t("Documents.Appendix")}</Typography>
                    {document.Files.map((file) => (
                      <FilePreview key={file.Id} file={file} setShowDocument={props.setPreviewDocument} />
                    ))}
                  </Grid>
                )}
              </Grid>
            </AccordionDetailsStyled>
          </FileStyledAccordion>
        </Box>
      ))}
    </CardStyled>
  );
};

export default FolderCard;
