import { Reducer } from "redux";
import { AppAction } from "../definitions/Action";
import { CommentModel } from "../definitions/NewsStream";
import { CommentConstants } from "../constants/comment.constants";

export interface CommentStoreState {
  comments: CommentModel[];
  loading: boolean;
  error: string | null;
  count: Record<string, number>;
}

export const CommentInitialState: CommentStoreState = {
  comments: [],
  loading: false,
  error: null,
  count: {},
};

const commentReducer: Reducer<CommentStoreState, AppAction> = (
  state: CommentStoreState = CommentInitialState,
  action: AppAction
): CommentStoreState => {
  switch (action.type) {
    case CommentConstants.UPDATE_COMMENT:
    case CommentConstants.GET_COMMENTS:
    case CommentConstants.POST_COMMENT:
    case CommentConstants.DELETE_COMMENT:
      return { ...state, loading: true };
    case CommentConstants.GET_COMMENT_SUCCEEDED:
      return {
        ...state,
        comments: state.comments.map((x) => (x.Id === action.payload.Id ? action.payload : x)),
        loading: false,
      };
    case CommentConstants.GET_COMMENTS_SUCCEEDED: {
      const newComments = action.payload.Results.filter(
        (c: CommentModel) => !state.comments.some((x) => x.Id === c.Id)
      );
      const count = action.payload.Results.filter((c: CommentModel) => c.NestingLevel === 0).length
        ? {
            ...state.count,
            [action.payload.Results.filter((c: CommentModel) => c.NestingLevel === 0)[0].ParentId]:
              action.payload.Count,
          }
        : { ...state.count };
      return {
        ...state,
        comments: [...state.comments, ...newComments],
        count,
        loading: false,
      };
    }
    case CommentConstants.POST_COMMENT_SUCCEEDED:
    case CommentConstants.ADD_COMMENT: {
      if (state.comments.some((comment) => comment.Id === action.payload.Id)) {
        return state;
      }
      const count = { ...state.count };
      const rootParentId = findRootParentId(state.comments, action.payload.ParentId);
      if (Object.hasOwn(count, rootParentId)) {
        count[rootParentId]++;
      } else {
        count[rootParentId] = 1;
      }
      const parent = state.comments.find((c) => c.Id === action.payload.ParentId);
      if (parent) {
        if (parent.ChildsCount == 0) {
          parent.ChildsCount = 1;
        }
      }
      return {
        ...state,
        comments: [...state.comments, action.payload],
        count,
        loading: false,
      };
    }
    case CommentConstants.UPDATE_COMMENT_SUCCEEDED:
      return {
        ...state,
        comments: state.comments.map((x) => (x.Id === action.payload.Id ? action.payload : x)),
        loading: false,
      };
    case CommentConstants.DELETE_COMMENT_SUCCEEDED:
    case CommentConstants.REMOVE_COMMENT: {
      const count = { ...state.count };
      const removed = state.comments.find((c) => c.Id === action.payload.Id);
      if (removed) {
        const rootParentId = findRootParentId(state.comments, removed.ParentId);
        if (Object.hasOwn(count, rootParentId)) {
          count[rootParentId]--;
        }
      }

      return {
        ...state,
        comments: state.comments.filter((c) => c.Id !== action.payload.Id),
        count,
        loading: false,
      };
    }
    case CommentConstants.COMMENT_OPERATION_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default commentReducer;

const findRootParentId = (comments: CommentModel[], currentParentId: string): string => {
  const parentComment = comments.find((c) => c.Id === currentParentId);
  if (!parentComment) {
    // If the parent comment does not exist, then the ParentId is the Id of a post
    return currentParentId;
  }

  // If the parent comment exists, continue searching recursively
  return findRootParentId(comments, parentComment.ParentId);
};
