import React from "react";
import { Box, IconButton, TableCell, TableSortLabel } from "@mui/material";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";

export type Direction = "asc" | "desc";

type CellLabelProps = {
  cellName: string;
  cellKey: string;
  direction: Direction;
  disabled?: boolean;
  currentCellName: string;
  onClick: (name: string) => () => void;
};

export const CellLabel = ({
  cellName,
  cellKey,
  direction,
  disabled,
  currentCellName,
  onClick,
}: CellLabelProps): JSX.Element => {
  const isActive = cellKey === currentCellName;

  return (
    <TableCell>
      <TableSortLabel
        active={isActive}
        onClick={onClick(cellKey)}
        direction={direction}
        disabled={disabled}
      >
        {cellName}
      </TableSortLabel>
    </TableCell>
  );
};

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

export const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }: TablePaginationActionsProps) => {

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box flexShrink={0} ml={2.5}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} size="large">
        <FirstPage />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} size="large">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        size="large">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        size="large">
        <LastPage />
      </IconButton>
    </Box>
  );
};
