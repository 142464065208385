export enum SystemStringType {
  UtmSource = 1,
  ActiveDomains = 2,
}

export interface SystemStringModel {
  Value: string;
  Type: SystemStringType;
  TypeString: string;
}
