import React, { useState } from "react";
import { ServiceBookReport } from "../../../../../definitions/model/ServiceBook";
import { Grid, Typography, Button, List, Divider, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReportSection from "./ReportSection";

export const ReportContext = React.createContext<{ readOnly?: boolean } | undefined>(undefined!);

const ServiceBookReportComponent = (props: ServiceBookReport & { readOnly?: boolean }): JSX.Element => {
  const { t } = useTranslation("translation");

  const [open, setOpen] = useState(false);

  const [currentTab, setCurrentTab] = useState(0);

  const handleOpenAll = () => {
    setOpen(!open);
  };

  const handleChange = (event: React.ChangeEvent<any>, newTab: number) => {
    setCurrentTab(newTab);
  };

  return (
    <Grid container item>
      <Typography paragraph variant="body1">
        {t("ServiceBook.ReportList.AboutReport")}
      </Typography>
      {/* <HouseDataComponent {...props.data} /> */}
      <Grid
        container
        sx={{
          border: "1px solid #DDD",
          borderRadius: "10px",
        }}
      >
        <Grid
          item
          container
          justifyContent="space-between"
          sx={{
            margin: "10px",
          }}
        >
          <Grid item>
            <Typography variant="h6">{t("ServiceBook.ReportList.Categories")}</Typography>
          </Grid>

          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  href={`${process.env.REACT_APP_SERVICEBOOK_API_URL}/api/report/pdf/${props.Id}`}
                >
                  Download PDF
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" onClick={handleOpenAll}>
                  {open ? t("General.Buttons.FoldIn") : t("General.Buttons.FoldOut")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container>
          <ReportContext.Provider value={{ readOnly: props.readOnly }}>
            <Grid item xs={12}>
              {props.Objects.length > 1 && (
                <Tabs
                  value={currentTab}
                  onChange={handleChange}
                  scrollButtons="auto"
                  variant="scrollable"
                  indicatorColor="primary"
                >
                  {props.Objects.map((x) => (
                    <Tab key={x.Id} label={x.Label + ". " + x.Name} />
                  ))}
                </Tabs>
              )}
              <Grid item>
                <Divider variant="middle" />

                {!!props.Objects.length && (
                  <List disablePadding>
                    {props.Objects[currentTab].Sections.map((x) => (
                      <ReportSection key={x.Id} {...x} open={open} />
                    ))}
                  </List>
                )}
              </Grid>
            </Grid>
          </ReportContext.Provider>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ServiceBookReportComponent;
