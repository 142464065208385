import * as React from "react";

export interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
}

export function useAddToHomescreenPrompt(): [
  boolean,
  () => Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>
] {
  const isStandalone =
    ("standalone" in navigator && !!navigator.standalone) || window.matchMedia("(display-mode: standalone)").matches;
  const [hasInstalled, setHasInstalled] = React.useState(localStorage.getItem("pwaInstalled") === "1" || isStandalone);
  if (isStandalone) localStorage.setItem("pwaInstalled", "1");

  const promptToInstall = async () => {
    const promptEvent = window.deferredPrompt;
    if (promptEvent) {
      promptEvent.prompt();
      const { outcome } = await promptEvent.userChoice;
      if (outcome === "accepted") {
        localStorage.setItem("pwaInstalled", "1");
        setHasInstalled(true);
      }
      return Promise.resolve({ outcome, platform: promptEvent.platforms[0] });
    }
    return Promise.reject(new Error('Tried installing before browser sent "beforeinstallprompt" event'));
  };

  React.useEffect(() => {
    const ready = (e: IBeforeInstallPromptEvent) => {
      e.preventDefault();
      window.deferredPrompt = e;
      localStorage.setItem("pwaInstalled", "0");
      setHasInstalled(false);
    };

    const installedReady = (e: IBeforeInstallPromptEvent) => {
      e.preventDefault();
      localStorage.setItem("pwaInstalled", "1");
      setHasInstalled(true);
    };

    window.addEventListener("beforeinstallprompt", ready as any);
    window.addEventListener("appinstalled", installedReady as any);

    return () => {
      window.removeEventListener("beforeinstallprompt", ready as any);
    };
  }, []);

  return [hasInstalled, promptToInstall];
}

const SWHelper = {
  async getWaitingWorker() {
    const registrations = (await navigator?.serviceWorker?.getRegistrations()) || [];
    const registrationWithWaiting = registrations.find(reg => reg.waiting);
    return registrationWithWaiting?.waiting;
  },

  async skipWaiting() {
    return (await SWHelper.getWaitingWorker())?.postMessage({ type: 'SKIP_WAITING_WHEN_SOLO' });
  },

  async prepareCachesForUpdate() {
    return (await SWHelper.getWaitingWorker())?.postMessage({ type: 'PREPARE_CACHES_FOR_UPDATE' });
  },
};

export default SWHelper;
