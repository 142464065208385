import { NIL } from "uuid";
import { ChatConstants, UserConstants } from "../constants";
import { ChatDto, ChatMessage } from "../definitions/model/Chat";

export interface ChatStoreState {
  messages: ChatMessage[];
  chats: ChatDto[];
  activeChatId: string | undefined;
  waitingTimer: NodeJS.Timeout | null;
  loading: boolean;
}

export const initialState: ChatStoreState = {
  messages: [],
  chats: [],
  activeChatId: undefined,
  waitingTimer: null,
  loading: false,
};

const reducer = (state: ChatStoreState = initialState, action: { type: keyof typeof UserConstants; payload: any }) => {
  switch (action.type) {
    case ChatConstants.START_CHAT:
      return {
        ...state,
        activeChatId: action.payload,
        chats: [...state.chats.filter((x) => x.Id !== action.payload.Id), action.payload],
      };
    case ChatConstants.CLEAR_CHAT:
      return {
        ...state,
        chats: state.chats.map((x) => {
          if (x.Id === state.activeChatId && state.messages.length) {
            return { ...x, History: [state.messages[state.messages.length - 1]] };
          }
          return x;
        }),
        messages: [],
        activeChatId: undefined,
      };
    case ChatConstants.RECEIVE_CHAT_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload],
        chats: state.chats.map((x) => {
          if (state.activeChatId !== action.payload.ChatId && x.Id === action.payload.ChatId) {
            return { ...x, newMessages: true };
          }
          return x;
        }),
      };
    case ChatConstants.CONNECT_TO_CHAT:
      return {
        ...state,
        activeChatId: action.payload.Id,
        chats: [...state.chats.filter((x) => x.Id !== action.payload.Id), action.payload],
        messages: [...action.payload.History],
      };
    case ChatConstants.SET_CHATS:
      return {
        ...state,
        chats: action.payload,
        messages: [],
        activeChatId: undefined,
        loading: false,
      };
    case ChatConstants.SET_CHAT_WAIT_INIT_TIMER:
      return {
        ...state,
        waitingTimer: action.payload,
        loading: !!action.payload,
      };
    case ChatConstants.LOAD_CHATS:
      return {
        ...state,
        loading: !!action.payload,
      };
    case ChatConstants.NEW_CHAT: {
      const newChat = { ...action.payload, newMessages: true };
      return {
        ...state,
        chats: [...state.chats, newChat],
      };
    }
    case ChatConstants.CLEAR_NEW_MESSAGES:
      return {
        ...state,
        chats: state.chats.map((x) => {
          if (x.Id === action.payload) {
            return { ...x, newMessages: false };
          }
          return x;
        }),
      };

    case ChatConstants.RECEIVE_CHAT_MESSAGE_CONFIRMATION:
      return {
        ...state,
        messages: state.messages.map((x) => ({
          ...x,
          IsRead: true,
        })),        
        chats: state.chats.map((currentChat) => ({
          ...currentChat,
          History: currentChat.History.map((message) =>
            currentChat.Id === state.activeChatId && currentChat.Id !== NIL
              ? {
                  ...message,
                  IsRead: true,
                }
              : message
          ),
        })),
      };
    default:
      return state;
  }
};

export default reducer;
