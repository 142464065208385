import store from "../reducers/store";
import { headers } from "./http.service";

const ApiService = {
  post: async <T, P = any>(url: string, item: P, overrideHeaders?: any): Promise<T> => {
    return await apiRequest(url, {
      method: "POST",
      mode: "cors",
      headers: overrideHeaders || (await headers()),
      body: JSON.stringify(item),
      credentials: "include",
    });
  },

  put: async <T, P = any>(url: string, item: P, overrideHeaders?: any): Promise<T> => {
    return await apiRequest(url, {
      method: "PUT",
      mode: "cors",
      headers: overrideHeaders || (await headers()),
      body: JSON.stringify(item),
    });
  },

  get: async <T>(url: string, overrideHeaders?: any, signal?: AbortSignal): Promise<T> => {
    return await apiRequest(url, {
      method: "GET",
      mode: "cors",
      headers: overrideHeaders || (await headers()),
      signal,
    });
  },

  delete: async <T>(url: string, overrideHeaders?: any): Promise<T> => {
    return await apiRequest(url, {
      method: "DELETE",
      mode: "cors",
      headers: overrideHeaders || (await headers()),
    });
  },
};

const apiRequest = async <T>(url: string, config: RequestInit): Promise<T> => {
  const response = await fetch(url, config);
  if (response.status !== 200 && response.status !== 204) {
    let statusText = "";
    try {
      statusText = await response.text();
    } catch {
      //do not throw an exception
    }

    //we should show an error message when we get an unauthorized error, except when we get it during authorization
    if (response.status === 401 && url !== "/api/users/authenticate") {
      store.dispatch({
        type: "USERS_LOGOUT",
      });
    }

    if (response.status === 403) {
      store.dispatch({
        type: "ACCEPT_NEW_TERMS",
      });
    }

    if (response.status === 503) {
      // we assume the server has sent us entire offline html page contents
      // and we rewrite current page with it entirely
      store.dispatch({
        type: "SET_IS_MAINTENANCE",
      });
    }
    const error: ApiError = { status: response.status, statusText: statusText };
    throw error;
  }
  if (response.status === 204) return Promise.resolve({} as T);

  const result = await response.json();
  return result;
};

export type ApiError = {
  status: number;
  statusText: string;
};

export default ApiService;
