import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "../../actions";
import { LanguageType } from "../../definitions/Menu";
import LanguageChangeMenu from "./LanguageChangeMenu";

const LanguageChange = () => {
  const { i18n } = useTranslation();
  const [{ user, loading }, userActions] = useUser();

  const [languageId, setLanguageId] = React.useState<LanguageType>("da");

  useMemo(() => {
    const existingLanguageId = user?.Language || userActions.getLanguage();
    if (existingLanguageId) {
      setLanguageId(existingLanguageId);
    }
    return () => {};
  }, []);

  useEffect(() => {
    if(i18n.language === languageId) return;
    
    i18n.changeLanguage(languageId);

    if (!user) {
      userActions.setLanguage(languageId);
    }

    if (user && user.Language != languageId) {
     userActions.updateUserLanguage(languageId);
    }
  }, [languageId]);

  return (
    <LanguageChangeMenu
      loading={loading}
      languageId={languageId}
      setLanguageId={setLanguageId}
    />
  );
};

export default LanguageChange;
