import React, { useEffect, useState } from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import _ from "lodash";
import {
  CraftsmanTask,
  CraftsmanTaskStatus,
} from "../../../../definitions/model/ServiceBook";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type TaskListProgressChartProps = {
  tasks: CraftsmanTask[];
};

type ChartRecord = {
  doneTasksPercentage: number;
  notDoneTasksPercentage: number;
};

const renderCustomizedLabel = (param: any) => {
  const { x, y, value } = param;
  return (
    <text x={x + 10} y={y - 2} fontSize="14" textAnchor="start">
      {Math.round(value)}%
    </text>
  );
};

const TaskListProgressChart = (
  props: TaskListProgressChartProps
): JSX.Element => {
  const [records, setRecords] = useState<ChartRecord[]>([]);
  const { t } = useTranslation("translation");

  useEffect(() => {
    const totalTasks = props.tasks.length;
    const doneTasks = props.tasks.filter(
      (task) => task.Status === CraftsmanTaskStatus.Done
    ).length;
    const doneTasksPercentage = (100 * doneTasks) / totalTasks;
    setRecords([
      {
        doneTasksPercentage,
        notDoneTasksPercentage: 100 - doneTasksPercentage,
      },
    ]);
  }, [props.tasks]);

  return (
    <Grid container direction="column">
      <Typography>{t("ServiceBook.Tasks.CompletionStatus")}:</Typography>
      <ResponsiveContainer height={60 * records.length} width="100%">
        <BarChart data={records} layout="vertical" margin={{ top: 18 }}>
          <XAxis type="number" hide />
          <YAxis type="category" hide />

          <Bar
            dataKey="doneTasksPercentage"
            fill="#0088FE"
            barSize={40}
            label={renderCustomizedLabel}
            stackId="a"
            isAnimationActive={false}
          />
          <Bar
            dataKey="notDoneTasksPercentage"
            stackId="a"
            fill="#F4F4F4"
            barSize={40}
            isAnimationActive={false}
          />
        </BarChart>
      </ResponsiveContainer>
    </Grid>
  );
};

export default TaskListProgressChart;
