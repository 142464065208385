import { CO2PlanMeta } from "./../../pages/ClimatePlan/shared/CO2Plan";
import { CO2EconomyItem } from "../../pages/ClimatePlan/shared/CO2Plan";
import { Reducer } from "redux";
import { AppAction } from "../../definitions/Action";
import { AdminCO2PlanActionTypes } from "../../constants/admin/admin.co2plan.actiontypes";
import {
  CO2EmissionSourceGroupDescription,
  CO2SponsorSolution,
  CO2UnitAddress,
} from "../../pages/ClimatePlan/shared/CO2Plan";

export interface CO2PlanAdminStoreState {
  loading: boolean;
  error: string | null;
  unitsWithCO2Plans: CO2UnitAddress[];
  CO2EmissionSourceGroups: CO2EmissionSourceGroupDescription[];
  CO2SponsorSolutions: CO2SponsorSolution[];
  CO2SponsorSolutionsCount: number;
  CO2EconomyItems: CO2EconomyItem[];
  CO2EconomyItemsCount: number;
  CO2PlanMetaItems: CO2PlanMeta[];
  CO2PlanMetaItemsCount: number;
  ageGroupedItems?: Record<string, number>;
}

export const initialState: CO2PlanAdminStoreState = {
  loading: false,
  error: null,
  unitsWithCO2Plans: [],
  CO2EmissionSourceGroups: [],
  CO2SponsorSolutions: [],
  CO2SponsorSolutionsCount: 0,
  CO2EconomyItems: [],
  CO2EconomyItemsCount: 0,
  CO2PlanMetaItems: [],
  CO2PlanMetaItemsCount: 0,
  ageGroupedItems: undefined,
};

const co2planAdminReducer: Reducer<CO2PlanAdminStoreState, AppAction> = (
  state: CO2PlanAdminStoreState = initialState,
  action: AppAction
): CO2PlanAdminStoreState => {
  switch (action.type) {
    case AdminCO2PlanActionTypes.GET_UNITS_WITH_CO2_PLANS:
    case AdminCO2PlanActionTypes.GET_CO2_SPONSOR_SOLUTIONS:
    case AdminCO2PlanActionTypes.GET_CO2_ECONOMY_ITEMS:
    case AdminCO2PlanActionTypes.ADD_CO2_ECONOMY_ITEM:
    case AdminCO2PlanActionTypes.EDIT_CO2_ECONOMY_ITEM:
    case AdminCO2PlanActionTypes.DELETE_CO2_ECONOMY_ITEM:
    case AdminCO2PlanActionTypes.GET_AGE_GROUPED_ITEMS:
    case AdminCO2PlanActionTypes.GET_CO2_PLAN_META_ITEMS:
      return { ...state, loading: true };
    case AdminCO2PlanActionTypes.GET_UNITS_WITH_CO2_PLANS_SUCCEEDED:
      return {
        ...state,
        unitsWithCO2Plans: action.payload.resetExisting
          ? action.payload.plans
          : [...state.unitsWithCO2Plans, ...action.payload.plans],
        loading: false,
      };
    case AdminCO2PlanActionTypes.GET_CO2_SPONSOR_SOLUTIONS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        CO2SponsorSolutions: action.payload.Solutions.Results,
        CO2SponsorSolutionsCount: action.payload.Solutions.Count,
        CO2EmissionSourceGroups: action.payload.EmissionSourceGroups,
      };
    case AdminCO2PlanActionTypes.ADD_CO2_SPONSOR_SOLUTION_SUCCEEDED: {
      return {
        ...state,
        CO2SponsorSolutions: [...state.CO2SponsorSolutions, action.payload],
        CO2SponsorSolutionsCount: state.CO2SponsorSolutionsCount + 1,
      };
    }
    case AdminCO2PlanActionTypes.EDIT_CO2_SPONSOR_SOLUTION_SUCCEEDED: {
      return {
        ...state,
        CO2SponsorSolutions: state.CO2SponsorSolutions.map((solution) =>
          solution.Id === action.payload.Id ? action.payload : solution
        ),
      };
    }
    case AdminCO2PlanActionTypes.DELETE_CO2_SPONSOR_SOLUTION_SUCCEEDED: {
      return {
        ...state,
        CO2SponsorSolutions: state.CO2SponsorSolutions.filter((solution) => solution.Id !== action.payload),
        CO2SponsorSolutionsCount: state.CO2SponsorSolutionsCount - 1,
      };
    }
    case AdminCO2PlanActionTypes.GET_CO2_ECONOMY_ITEMS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        CO2EconomyItems: action.payload.CO2EconomyItems.Results,
        CO2EconomyItemsCount: action.payload.CO2EconomyItems.Count,
        CO2EmissionSourceGroups: action.payload.EmissionSourceGroups,
      };
    case AdminCO2PlanActionTypes.ADD_CO2_ECONOMY_ITEM_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        CO2EconomyItems: [...state.CO2EconomyItems, action.payload],
        CO2EconomyItemsCount: state.CO2EconomyItemsCount + 1,
      };
    }
    case AdminCO2PlanActionTypes.EDIT_CO2_ECONOMY_ITEM_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        CO2EconomyItems: state.CO2EconomyItems.map((item) => (item.Id === action.payload.Id ? action.payload : item)),
      };
    }
    case AdminCO2PlanActionTypes.DELETE_CO2_ECONOMY_ITEM_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        CO2EconomyItems: state.CO2EconomyItems.filter((item) => item.Id !== action.payload),
        CO2EconomyItemsCount: state.CO2EconomyItemsCount - 1,
      };
    }
    case AdminCO2PlanActionTypes.GET_CO2_PLAN_META_ITEMS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        CO2PlanMetaItems: action.payload.Results,
        CO2PlanMetaItemsCount: action.payload.Count,
      };
    case AdminCO2PlanActionTypes.ADD_CO2_PLAN_META_ITEM_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        CO2PlanMetaItems: [...state.CO2PlanMetaItems, action.payload],
        CO2PlanMetaItemsCount: state.CO2PlanMetaItemsCount + 1,
      };
    }
    case AdminCO2PlanActionTypes.EDIT_CO2_PLAN_META_ITEM_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        CO2PlanMetaItems: state.CO2PlanMetaItems.map((item) => (item.Id === action.payload.Id ? action.payload : item)),
      };
    }
    case AdminCO2PlanActionTypes.DELETE_CO2_PLAN_META_ITEM_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        CO2PlanMetaItems: state.CO2PlanMetaItems.filter((item) => item.Id !== action.payload),
        CO2PlanMetaItemsCount: state.CO2PlanMetaItemsCount - 1,
      };
    }
    case AdminCO2PlanActionTypes.ADMIN_CO2_OPERATION_FAILED:
      return { ...state, error: action.payload, loading: false };

    case AdminCO2PlanActionTypes.GET_AGE_GROUPED_ITEMS_SUCCEEDED: {
      return {
        ...state,
        loading: false,
        ageGroupedItems: action.payload,
      };
    }

    default:
      return state;
  }
};

export default co2planAdminReducer;
