import React from "react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { useUserTheme } from "./helpers/hooks";

const AppThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const userTheme = useUserTheme();

  return (
    <>
      <StyledEngineProvider>
        <ThemeProvider theme={userTheme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default AppThemeProvider;
