import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress, Container, Grid } from "@mui/material";
import UserInfo from "./components/UserInfo";
import UserNotification from "./components/UserNotification";
import UserPassword from "./components/UserPassword";
import UserJournal from "./components/UserJournal";
import { GroupMemberRole } from "../../constants/enums";
import { useUser } from "../../actions";
import MoveOutDialog from "../../components/Dialogs/MoveOutDialog/MoveOutDialog";
import { GroupMemberDto } from "../../definitions/model/Group";
import { replace, useLocation } from "react-router-dom";
import SelectCurrentHouseDialog from "../Family/components/ChangeCurrentHouseDialog";
import { OwnedUnitDto } from "../../definitions/model/unit";
import UserRemove from "./components/UserRemove";
import CircularProgressButton from "../../components/Base/CircularProgressButton";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useUnit } from "../../actions/unit.actions";
import UnitHelper from "../../helpers/unit-helper";
import ChangePropertyDialog, { ChangePropertyData } from "../Family/components/ChangePropertyDialog";
import { useDocument } from "../../actions/document.actions";

const StyledButton = styled(CircularProgressButton)({
  root: {
    justifyContent: "center",
    fontSize: 14,
    fontWeight: 600,
    minWidth: 195,
  },
});

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  root: {
    zIndex: theme.zIndex.modal + 10,
  },
}));

const ProfilePageComponent = () => {
  const [{ user, family, document, error, settings, history }, actions] = useUser();
  const [, documentActions] = useDocument();
  const [{ unit }] = useUnit();
  const location = useLocation();
  const [moveOutDialogOpen, setMoveOutDialogOpen] = useState(false);
  const [isNewOwnerRequired, setIsNewOwnerRequired] = useState(false);
  // const [isCardsLoading, setCardsLoading] = useState(false);
  const [isFamilyLoading, setFamilyLoading] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [openSelectHouse, setOpenSelectHouse] = useState(false);
  const [houses, setHouses] = useState<OwnedUnitDto[]>([]);
  const showMoveOutDialog = () => setMoveOutDialogOpen(true);
  const closeMoveOutDialog = () => setMoveOutDialogOpen(false);
  const [loading, setLoading] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [changePropertyData, setChangePropertyData] = useState<ChangePropertyData | null>(null);
  const [isChangedPropertyDialogOpen, setIsChangedPropertyDilaogOpen] = useState(false);
  const { t } = useTranslation("translation");

  const moveOutUser = async () => {
    if (unit) {
      actions.moveOutUser(unit.Id);
      setHouses(houses.filter((house) => house.Id !== unit.Id));
    }
  };

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const isFromDocumentsRemove = Boolean(params.get("fromDocumentsRemove"));
      if (isFromDocumentsRemove && location.state.selectedUnitId) {
        const externalState = location.state.externalState;

        if (location.state.selectedUnitId === "all") {
          setShowBackdrop(true);
          sendProfileRemoveRequest(externalState.removeAll).finally(() => setShowBackdrop(false));
        } else {
          setShowBackdrop(true);
          sendMoveOutRequests(
            location.state.selectedUnitId,
            externalState.removeAll,
            externalState.newFamilyOwner
          ).finally(() => setShowBackdrop(false));
        }
      }
      replace(location.pathname);
    }
  }, [location.search]);

  useEffect(() => {
    if (unit) {
      setFamilyLoading(true);
      actions
        .getUserFamilies(unit.Id)
        .then((families) => {
          const family = families.find((fam) => fam.UnitId === unit?.Id);
          if (family && family.Members) {
            const approvedMembers = family.Members.filter((m) => m.Role !== GroupMemberRole.NotValidMember);
            const currentUser = approvedMembers.find((x) => x.UserId === user?.Id);
            const isNewOwnerRequired =
              approvedMembers.length > 1 && currentUser?.Role === GroupMemberRole.Administrator;
            setIsNewOwnerRequired(isNewOwnerRequired);
          }
        })
        .finally(() => setFamilyLoading(false));
    }
  }, [unit]);

  useEffect(() => {
    const abortController = new AbortController();
    if (houses.length < 1)
      actions
        .getOwnedHouses(abortController.signal)
        .then(setHouses)
        .catch((error) => console.log(error));

    return () => {
      abortController.abort("Component unmount");
    };
  }, []);

  const onAssignNewOnwer = async (newOwner: GroupMemberDto) => {
    if (unit) await actions.assignNewFamilyOwner(newOwner, unit.Id);
  };

  const sendMoveOutRequests = async (unitId: string, removeAllDocuments?: boolean, newFamilyOwner?: GroupMemberDto) => {
    if (removeAllDocuments) {
      await documentActions.deleteDocuments(unitId, [], true);
    }

    const outAdress = unit ? UnitHelper.GetFullAddress(unit?.Address) : null;

    const changeData: ChangePropertyData = {
      adressIn: undefined,
      adressOut: undefined,
    };
    if (outAdress) {
      changeData.adressOut = outAdress;
    }

    if (newFamilyOwner) {
      await onAssignNewOnwer(newFamilyOwner);
    }
    await moveOutUser();
    const remainingHouses = houses.filter((house) => house.Id !== unitId);

    if (remainingHouses.length > 1) {
      setOpenSelectHouse(true);
    } else {
      if (remainingHouses.length === 1) {
        changeData.adressIn = UnitHelper.GetFullAddress(remainingHouses[0].Address);
      }

      setIsChangedPropertyDilaogOpen(true);
    }

    setChangePropertyData(changeData);
  };

  const sendProfileRemoveRequest = async (removeAll?: boolean) => {
    if (removeAll) {
      await documentActions.deleteDocumentsFromUnits([], [], true);
    }

    await actions.removeUser();
  };

  useEffect(() => {
    const abortController = new AbortController();
    const getuserSettingsAsync = async () => {
      if (!settings) {
        await actions.getUserSettings(abortController.signal);
      }
    };
    getuserSettingsAsync();

    return () => {
      abortController.abort();
    };
  }, [settings]);

  const changeUserPasswordSubmitHandler = (password: string) => {
    setLoading(true);
    setIsPasswordChanged(false);
    actions
      .changeUserPassword(password)
      .then((result) => {
        if (result) setIsPasswordChanged(true);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {!!user && (
        <Container maxWidth="md">
          <UserInfo
            user={user}
            error={error}
            updateUserProfile={actions.updateUserProfile}
            updateUserPicture={actions.updateUserPicture}
            setUserTheme={actions.setUserTheme}
          />
          <UserPassword submit={changeUserPasswordSubmitHandler} loading={loading} isChanged={isPasswordChanged} />
          {settings && (
            <UserNotification
              userId={null}
              values={settings}
              updateNotificationSettigns={actions.updateNotificationSettigns}
              getUserSettingsHistory={actions.getUserSettingsHistory}
            />
          )}
          {/* <UserInterestsCard
            interests={interests}
            updateUserInterests={props.updateUserInterests}
            getUserSettingsHistory={props.getUserSettingsHistory}
          /> */}
          <UserJournal history={history} getUserSettingsHistory={actions.getUserSettingsHistory} />
          {/* <UserCardsManagement
            cards={props.cards}
            isLoading={isCardsLoading}
            addUserCard={props.addUserCard}
            changeDefaultCard={props.changeDefaultCard}
            removeCard={props.removeCard}
          />
          <UserSubscriptions
            subscriptions={props.subscriptions}
            loading={props.subscriptionsLoading}
            cancelSubscription={cancelSubscription}
          /> */}
          <Grid container justifyContent="center" alignItems="center" direction="column" marginBottom="20px">
            {user?.Id && unit && !unit.IsRental && (
              <Grid item>
                <StyledButton
                  id="move-out"
                  variant="text"
                  color="primary"
                  inProgress={isFamilyLoading}
                  onClick={showMoveOutDialog}
                >
                  {t("HouseManagement.MoveOut")}
                </StyledButton>

                <MoveOutDialog
                  open={moveOutDialogOpen}
                  userId={user.Id}
                  family={family.families[0]}
                  unitId={unit.Id}
                  unitWithCo2Plan={unit.HasCO2Plan}
                  from={location.pathname}
                  closeDialog={closeMoveOutDialog}
                  hasDocuments={document.documentFolders.length > 0}
                  isOwnerChangeRequired={isNewOwnerRequired}
                  sendMoveOutRequests={sendMoveOutRequests}
                />
              </Grid>
            )}
            {user?.Id && (
              <UserRemove user={user} getUserFamilies={actions.getUserFamilies} removeUser={actions.removeUser} />
            )}
          </Grid>
          <SelectCurrentHouseDialog
            open={openSelectHouse}
            closeDialog={() => setOpenSelectHouse(false)}
            setCurrentHouse={async (x) => {
              const unit = await actions.changeCurrentHouse(x);
              setChangePropertyData((prevData) => {
                return {
                  ...prevData,
                  adressIn: UnitHelper.GetFullAddress(unit.Address),
                };
              });
              setIsChangedPropertyDilaogOpen(true);
            }}
            houses={houses}
          />
          {changePropertyData && (
            <ChangePropertyDialog
              open={isChangedPropertyDialogOpen}
              closeDialog={() => setIsChangedPropertyDilaogOpen(false)}
              changePropertyData={changePropertyData}
            />
          )}
        </Container>
      )}
      <StyledBackdrop open={showBackdrop}>
        <CircularProgress color="primary" />
      </StyledBackdrop>
      </>
  );
};

export default ProfilePageComponent;
