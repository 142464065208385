import { operationFailedActionGeneral } from ".";
import { SubscriptionsActions } from "../constants/subscriptions.actiontypes";
import { AppAction, AppActionThunk, AppThunkDispatch } from "../definitions/Action";
import { UpdateProductContract, ProductModel, CreateProductRequest, CreatePriceRequest, PaymentChargeContract, SubscriptionModel, CreditCard, ProductPriceDTO } from "../definitions/model/Subscriptions";
import SubscriptionsService from "../services/subscriptions.service";

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(
    payload,
    SubscriptionsActions.SUBSCRIPTIONS_OPERATION_FAILED,
  );
};


export const getProduct = (id: string) => async (
  dispatch: AppThunkDispatch
): Promise<ProductModel> => {
  try {
    dispatch({ type: SubscriptionsActions.GET_PRODUCT });
    const result = await SubscriptionsService.getProduct(id);
    dispatch({ type: SubscriptionsActions.GET_PRODUCT_SUCCEEDED, payload: result });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

export const getProducts = (name: string, skip: number, take: number, isDescending: boolean, sortingColumn: string) => async (
  dispatch: AppThunkDispatch
): Promise<void> => {
  try {
    dispatch({ type: SubscriptionsActions.GET_PRODUCTS });
    const result = await SubscriptionsService.getProducts(name, skip, take, isDescending, sortingColumn);
    dispatch({ 
      type: SubscriptionsActions.GET_PRODUCTS_SUCCEEDED,
      payload: result
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
}

export const clearProducts = () => ({ type: SubscriptionsActions.CLEAR_PRODUCTS });

export const createProduct = (data: CreateProductRequest) => async (
  dispatch: AppThunkDispatch
): Promise<void> => {
  try {
    dispatch({ type: SubscriptionsActions.CREATE_PRODUCT });
    const result = await SubscriptionsService.createProduct(data);
    dispatch({ 
      type: SubscriptionsActions.CREATE_PRODUCT_SUCCEEDED,
      payload: result
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
}

export const updateProduct = (data: UpdateProductContract) => async (
  dispatch: AppThunkDispatch
): Promise<void> => {
  try {
    dispatch({ type: SubscriptionsActions.UPDATE_PRODUCT });
    const result = await SubscriptionsService.updateProduct(data);
    dispatch({ 
      type: SubscriptionsActions.UPDATE_PRODUCT_SUCCEEDED,
      payload: result
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
}

export const deleteProduct = (productId: string) => async (
  dispatch: AppThunkDispatch
): Promise<void> => {
  try {
    dispatch({ type: SubscriptionsActions.DELETE_PRODUCT });
    const result = await SubscriptionsService.deleteProduct(productId);
    dispatch({ 
      type: SubscriptionsActions.DELETE_PRODUCT_SUCCEEDED,
      payload: result
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
}

export const getProductPrices = (productId: string) => async (
  dispatch: AppThunkDispatch
): Promise<ProductPriceDTO[]> => {
  try {
    dispatch({ type: SubscriptionsActions.GET_PRODUCT_PRICES });
    const result = await SubscriptionsService.getProductPrices(productId);
    dispatch({ type: SubscriptionsActions.GET_PRODUCT_PRICES_SUCCEEDED });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
}

export const createProductPrice = (data: CreatePriceRequest) => async (
  dispatch: AppThunkDispatch
): Promise<ProductPriceDTO> => {
  try {
    dispatch({ type: SubscriptionsActions.CREATE_PRODUCT_PRICE });
    const result = await SubscriptionsService.createProductPrice(data);
    dispatch({ type: SubscriptionsActions.CREATE_PRODUCT_PRICE_SUCCEEDED });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
}

export const processSuccededPayment = (
  data: PaymentChargeContract
): AppActionThunk<Promise<unknown>> => async (dispatch) => {
  try {
    dispatch({
      type: SubscriptionsActions.PROCESS_PAYMENT,
    });
    const result = await SubscriptionsService.processSucceededPayment(data);
    dispatch({
      type: SubscriptionsActions.PROCESS_PAYMENT_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

export const getUserSubscriptions = (): AppActionThunk<
  Promise<SubscriptionModel[]>
> => async (dispatch) => {
  try {
    dispatch({ type: SubscriptionsActions.GET_USER_SUBSCRIPTIONS });
    const result = await SubscriptionsService.getUserSubscriptions();
    dispatch({
      type: SubscriptionsActions.GET_USER_SUBSCRIPTIONS_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

export const cancelUserSubscription = (
  subscriptionId: string
): AppActionThunk<Promise<SubscriptionModel>> => async (dispatch) => {
  try {
    dispatch({ type: SubscriptionsActions.CANCEL_SUBSCRIPTION });
    const result = await SubscriptionsService.cancelUserSubscription(subscriptionId);
    dispatch({ 
      type: SubscriptionsActions.CANCEL_SUBSCRIPTION_SUCCEEDED, 
      payload: result 
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

export const getUserCreditCards = (): AppActionThunk<Promise<CreditCard[]>> => async (dispatch) => {
  try {
    dispatch({ type: SubscriptionsActions.GET_USER_CARDS });
    const cards = await SubscriptionsService.getUserCards();
    //if "cards" is empty object
    const result = Object.keys(cards).length ? cards : [];
    dispatch({ type: SubscriptionsActions.GET_USER_CARDS_SUCCEEDED, payload: result });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

export const addUserCard = (token: string, email: string): AppActionThunk<Promise<CreditCard>> => async (dispatch) => {
  try {
    dispatch({ type: SubscriptionsActions.ADD_CREDIT_CARD });
    const result = await SubscriptionsService.addUserCard(token, email);
    dispatch({ type: SubscriptionsActions.ADD_CREDIT_CARD_SUCCEEDED, payload: result });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
}

export const changeDefaultCard = (token: string): AppActionThunk<Promise<CreditCard[]>> => async (dispatch) =>{
  try {
    dispatch({ type: SubscriptionsActions.CHANGE_DEFAULT_CARD });
    const result = await SubscriptionsService.changeDefaultCard(token);
    dispatch({ type: SubscriptionsActions.CHANGE_DEFAULT_CARD_SUCCEEDED, payload: result });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
}

export const removeCreditCard = (token: string): AppActionThunk<Promise<CreditCard>> => async (
  dispatch
) => {
  try {
    dispatch({ type: SubscriptionsActions.REMOVE_CREDIT_CARD });
    const card = await SubscriptionsService.removeUserCard(token);
    dispatch({ type: SubscriptionsActions.REMOVE_CREDIT_CARD_SUCCEEDED, payload: card });
    return card;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};