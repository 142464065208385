import React, { ReactComponentElement } from "react";
import {
  Checkbox,
  CheckboxProps,
  FormControlProps,
  FormControlLabelProps,
  FormControl,
  FormControlLabel,
  Box,
  useTheme,
  styled,
} from "@mui/material";

import { CheckboxCheckedIcon, CheckboxUnCheckedIcon } from "../Icons/CheckboxIcons";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

interface MyhouseCheckboxProps extends CheckboxProps {
  label?: ReactComponentElement<any> | string;
  error?: boolean;
  fullWidth?: boolean;
  formControlProps?: FormControlProps;
  formControlLabelProps?: FormControlLabelProps;
}

export default function MyhouseCheckbox({
  error,
  label,
  fullWidth,
  formControlProps,
  formControlLabelProps,
  ...other
}: MyhouseCheckboxProps): JSX.Element {
  const theme = useTheme();

  return (
    <FormControl error={error} fullWidth={fullWidth} {...formControlProps}>
      <FormControlLabel
        control={
          <StyledCheckbox
            icon={<CheckboxUnCheckedIcon color={error ? "error" : "secondary"} />}
            checkedIcon={<CheckboxCheckedIcon color="secondary" />}
            indeterminateIcon={<RemoveCircleOutlineIcon color={error ? "error" : "secondary"} />}
            {...other}
          ></StyledCheckbox>
        }
        label={<Box color={error ? theme.palette.error.main : theme.palette.primary.dark}>{label}</Box>}
        {...formControlLabelProps}
      />
    </FormControl>
  );
}

const StyledCheckbox = styled(Checkbox)(() => ({
  "&.MuiCheckbox-root": {
    padding: 4,
  },
}));
