import React from "react";
import { ServiceBookReportParameter } from "../../../../../definitions/model/ServiceBook";
import { Grid, Typography, styled, Card, CardContent } from "@mui/material";

type ConditionParameterProps = ServiceBookReportParameter;

const StyledCard = styled(Card)(() => ({
  margin: 5,
}));

const GridStyled = styled(Grid)(() => ({
  paddingTop: 10,
}));

const ConditionParameter = (props: ConditionParameterProps) => {
  return (
    <StyledCard variant="outlined">
      <CardContent>
        <GridStyled item xs={12}>
          <Typography variant="h5">
            {props.Label} {props.Parameter}: {props.Value}
          </Typography>
        </GridStyled>
      </CardContent>
    </StyledCard>
  );
};

export default ConditionParameter;
