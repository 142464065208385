import React, { useMemo } from "react";
import { FileType } from "../../../definitions/Document";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import GetAppIcon from "@mui/icons-material/GetApp";
import DescriptionIcon from "@mui/icons-material/Description";
import { Documents } from "./Folders";
import { styled } from "@mui/material";

const FileStyled = styled("div")(({ theme }) => ({
  cursor: "pointer",
  border: "1px solid #e4e4e4",
  borderRadius: 5,
  height: 30,
  width: "100%",
  display: "flex",
  alignItems: "center",
  paddingLeft: 10,
  marginTop: 8,
  overflow: "hidden",
  fontSize: 12,
  color: theme.palette.primary.dark,
  textDecoration: "none",
  "& p": {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

const FileLinkStyled = styled(Link)(({ theme }) => ({
  cursor: "pointer",
  border: "1px solid #e4e4e4",
  borderRadius: 5,
  height: 30,
  width: "100%",
  display: "flex",
  alignItems: "center",
  paddingLeft: 10,
  marginTop: 8,
  overflow: "hidden",
  fontSize: 12,
  color: theme.palette.primary.dark,
  textDecoration: "none",
  "& p": {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

const images = ["jpg", "jpeg", "bmp", "gif", "png", "tiff"];
const documents = ["doc", "docx", "ppt", "pptx", "xls", "xlsx"];

type FilePreviewProps = {
  file: FileType;
  setShowDocument: (documents: Documents) => void;
};

const FilePreviewComponent = ({
  file,
  setShowDocument,
}: FilePreviewProps): JSX.Element => {
  const extension = useMemo(() => file.Name.split(".").pop(), [file.Name]);

  const isPdf = extension === "pdf";
  const isImage = extension && images.includes(extension);
  const isDoc = extension && documents.includes(extension);
  const canPreview = isPdf || isImage || isDoc;
  const docs: Documents = [
    {
      uri: `${window.location.origin}${file.Url}/${file.Name}`,
    },
  ];

  return (
    <>
      {canPreview ? (
        <FileStyled onClick={() => setShowDocument(docs)}>
          {isPdf ? (
            <PictureAsPdfIcon color="primary" />
          ) : isImage ? (
            <ImageIcon color="primary" />
          ) : (
            <DescriptionIcon color="primary" />
          )}
          <Typography style={{ paddingLeft: 10 }}>{file.Name}</Typography>
        </FileStyled>
      ) : (
        <FileLinkStyled
          href={`${window.location.origin}${file.Url}/${file.Name}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <GetAppIcon color="primary" />
          <Typography style={{ paddingLeft: 10 }}>{file.Name}</Typography>
        </FileLinkStyled>
      )}
    </>
  );
};

export default FilePreviewComponent;
