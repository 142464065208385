import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  ThemeProvider,
  StyledEngineProvider,
  Chip,
  Grid,
  Skeleton,
  Card,
  CardContent,
  Typography,
  CardMedia,
  Button,
  CardActions,
} from "@mui/material";
import store from "../../../../reducers/store";
import { getOptionalTheme } from "../../../../constants/theme";
import { isReactComponent } from ".";
import { GrapesEditor, GrapesTypes, LoadComponentOptions } from "../../defenitions";
import CO2PlanAdminService from "../../../../services/admin/admin.co2plan.service";
import i18next from "i18next";
import { CO2SponsorSolution, EmissionSourceDto } from "../../../../pages/ClimatePlan/shared/CO2Plan";
import { useCO2 } from "../../../../pages/ClimatePlan/shared/co2plan.actions";
import { Trans, useTranslation } from "react-i18next";
import BaseDialog from "../../../Dialogs/BaseDialog";
import ContactMeBox from "../../../Base/ContactMeBox";
import { useLandingActions, useUser } from "../../../../actions";

type CO2CompaniesProps = {
  utmSource: string;
  emissionGroupId: string;
  emissionSubGroupId?: string;
};

export const CO2Companies = (props: CO2CompaniesProps): JSX.Element => {
  const [, planActions] = useCO2();
  const [loading, setLoading] = useState<boolean>(false);
  const [openLeadDialog, setOpenLeadDialog] = useState<boolean>(false);
  const [solutions, setSolutions] = useState<CO2SponsorSolution[]>([]);
  const [selectedSolution, setSelectedSolution] = useState<CO2SponsorSolution>();
  const { t } = useTranslation("translation");
  const landingActions = useLandingActions();
  const [{ user }, { leaveAllCommunes }] = useUser();

  useEffect(() => {
    if ((user == null || !user.IsCommuneUser) && !loading && !solutions.length && props.emissionGroupId) {
      setLoading(true);
      planActions
        .getSponsorSolutions(props.emissionGroupId, props.emissionSubGroupId)
        .then((sols) => setSolutions(sols?.Results || []))
        .catch((e) => console.log(e))
        .finally(() => setLoading(false));
    }
    return () => {};
  }, []);

  const seeCommersialsClick = async () => {
    if (user)
      try {
        setLoading(true);
        await leaveAllCommunes(user.Id);
        const sols = await planActions.getSponsorSolutions(props.emissionGroupId, props.emissionSubGroupId);
        setSolutions(sols?.Results || []);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
  };

  const openLeadDialogClick = async (solution: CO2SponsorSolution) => {
    setSelectedSolution(solution);
    setOpenLeadDialog(true);
  };

  const closeDialog = async () => {
    setOpenLeadDialog(false);
    setSelectedSolution(undefined);
  };

  return (
    <Grid container spacing={1} alignItems="stretch">
      {loading && (
        <Grid item container spacing={0.5}>
          <Grid item>
            <Skeleton variant="rectangular" animation="wave" width={170} height={200} />
          </Grid>
          <Grid item>
            <Skeleton variant="rectangular" animation="wave" width={170} height={200} />
          </Grid>
        </Grid>
      )}
      <Grid item container spacing={0.5}>
        {!loading && user?.IsCommuneUser && (
          <Grid item>
            <Card
              variant="outlined"
              sx={{
                width: 170,
                height: "100%",
              }}
            >
              <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
                <Typography sx={{ wordBreak: "break-all" }} variant="subtitle1">
                  <Trans components={{ sub: <sub /> }} i18nKey="Base.Popovers.SeeCommersialsDescription" />
                </Typography>
                <Typography variant="body2"></Typography>
              </CardContent>
              <CardActions sx={{ paddingTop: 0, paddingBottom: 0 }}>
                <Button onClick={seeCommersialsClick} variant="text" color="secondary">
                  {t("Base.Popovers.SeeCommersials")}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        )}
        {solutions.map((s) => (
          <Grid key={s.Id} item>
            <Card
              variant="outlined"
              sx={{
                width: 170,
                height: "100%",
              }}
            >
              {s.CompanyLogoUrl && (
                <CardMedia sx={{ height: 150 }} component="img" image={s.CompanyLogoUrl} alt=""></CardMedia>
              )}
              <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
                <Typography sx={{ wordBreak: "break-all" }} variant="subtitle1">
                  {s.CompanyName}
                </Typography>
                <Typography variant="body2"></Typography>
              </CardContent>
              <CardActions sx={{ paddingTop: 0, paddingBottom: 0 }}>
                <Button onClick={() => openLeadDialogClick(s)} variant="text" color="secondary">
                  {t("Base.Popovers.Read")}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <BaseDialog titleComponent={selectedSolution?.CompanyName ?? ""} closeDialog={closeDialog} open={openLeadDialog}>
        <Typography paragraph variant="body1">
          {t("Pages.PropertyFacts.HelpDialogLawyers")}
        </Typography>
        <Typography paragraph variant="body1">
          {t("Pages.PropertyFacts.HelpDialogContactText")}
        </Typography>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <ContactMeBox
              utmSource={`${props.utmSource}.${selectedSolution?.CompanyName.replaceAll(" ", "_")}`}
              initialValues={{
                name: user?.Name || "",
                phone: user?.Phone || "",
              }}
              loading={false}
              datePickerType="datetime-local"
              addLead={landingActions.addLead}
              closeDialog={() => closeDialog()}
              keepFormData
              contactButtonText={t("General.Buttons.Send")}
              confirmationMessage={t("Base.ContactMeBox.MessageSentToBeContacted")}
            />
          </Grid>
        </Grid>
      </BaseDialog>
    </Grid>
  );
};

export default function (editor: GrapesEditor, options: LoadComponentOptions, landingTheme?: string): void {
  const domComponents = editor.DomComponents;
  const blockManager = editor.BlockManager;
  const defaultType = domComponents.getType("default");
  const { model, view } = defaultType;
  const compName = "CO2Companies";

  blockManager.add(GrapesTypes.CO2CompaniesComponent, {
    label: "CO2 Companies",
    category: options.categoryLabel,
    attributes: { class: "fa fa-fax" },
    content: `<div data-gjs-type="${GrapesTypes.CO2CompaniesComponent}"></div>`,
  });
  const traits = [
    {
      label: "Utm Source",
      name: "utmSource",
      changeProp: 1,
    },
    {
      type: "select",
      label: "Group",
      name: "emissionGroupId",
      changeProp: 1,
      typeid: 0,
      options: [],
    },
    {
      type: "select",
      label: "Sub Group",
      name: "emissionSubGroupId",
      changeProp: 1,
      typeid: 0,
      options: [],
    },
    {
      attributes: { style: "display: none" },
      label: "emissionGroupName",
      name: "emissionGroupName",
      changeProp: 1,
    },
  ];
  const subscriptions = traits.map((trait) => trait.name);

  const getEmissionGroups = async () => {
    const groups = (await CO2PlanAdminService.getEmissionGroups()).map((g) => {
      //map flat child sources with recusion
      const mapChildSources = (childSources: EmissionSourceDto[]) => {
        let childs = childSources.map((c) => {
          return {
            id: c.Id,
            name: i18next.t(c.TargetName || c.Name),
          };
        });

        if (childSources.length > 0 && childSources[0].ChildSources.length > 0) {
          const childChilds = mapChildSources(childSources[0].ChildSources);
          childs = [...childs, ...childChilds];
        }

        return childs;
      };

      return {
        id: g.Id,
        name: i18next.t(g.ShortName),
        childSources: mapChildSources(g.ChildSources),
      };
    });

    return groups;
  };

  domComponents.addType(GrapesTypes.CO2CompaniesComponent, {
    isComponent(el: HTMLElement) {
      if (
        (el.getAttribute && el.getAttribute("data-gjs-type") === GrapesTypes.CO2CompaniesComponent) ||
        (el.attributes && (el.attributes as any)["data-gjs-type"] === GrapesTypes.CO2CompaniesComponent) ||
        isReactComponent(el, compName)
      ) {
        return {
          type: GrapesTypes.CO2CompaniesComponent,
        };
      }
    },
    model: {
      defaults: {
        ...model.prototype.defaults,
        droppable: false,
        variant: "outlined",
        color: "primary",
        traits: [...model.prototype.defaults.traits, ...traits],
      },
    },
    view: view.extend({
      init(element: any) {
        getEmissionGroups().then((res) => {
          element.model.getTrait("emissionGroupId").set("options", res);
          const selectedGroupId = this.model.getTrait("emissionGroupId").getValue();
          const selectedGroup = res.find((e) => e.id == selectedGroupId);
          if (selectedGroup) {
            element.model.getTrait("emissionSubGroupId").set("options", selectedGroup.childSources);
          }
        });
        subscriptions.forEach((subscription) => {
          this.listenTo(this.model, `change:${subscription}`, this.handleChanges);
        });
      },
      handleChanges() {
        const emissionGroupIdTrait = this.model.getTrait("emissionGroupId");
        if (emissionGroupIdTrait?.getValue()) {
          const emissionGroupId = emissionGroupIdTrait.getValue();
          const emissionGroups = emissionGroupIdTrait.get("options") || [];
          const group = emissionGroups.find((e: { id: string }) => e.id == emissionGroupId);
          if (group) {
            let emissionGroupName = group.name;

            const emissionSubGroupIdTrait = this.model.getTrait("emissionSubGroupId");
            if (emissionSubGroupIdTrait?.getValue()) {
              const emissionSubGroupId = emissionSubGroupIdTrait.getValue();
              const subGroup = group.childSources.find((e: { id: string }) => e.id == emissionSubGroupId);
              if (subGroup) {
                emissionGroupName = `${emissionGroupName} \\ ${subGroup.name}`;
              }
            }

            this.model.getTrait("emissionGroupName").set("value", emissionGroupName);
            this.model.getTrait("emissionSubGroupId").set("options", group.childSources);
          }
        }

        const root = ReactDOM.createRoot(this.el);
        root.unmount();
        this.render();
      },
      onRender() {
        const { el } = this;
        const comps = this.model.get("components");
        const { utmSource, emissionGroupId, emissionSubGroupId, emissionGroupName } = this.model.attributes;
        comps.reset();
        const compString = `<${compName} emissionGroupId=${emissionGroupId} emissionSubGroupId=${emissionSubGroupId}></${compName}>`;
        comps.add(compString);
        const root = ReactDOM.createRoot(el);

        root.render(
          <>
            <StyledEngineProvider>
              <ThemeProvider theme={getOptionalTheme(landingTheme)}>
                <Provider store={store}>
                  <CO2Companies
                    emissionGroupId={emissionGroupId}
                    emissionSubGroupId={emissionSubGroupId}
                    utmSource={utmSource}
                  />
                  {emissionGroupName && (
                    <Chip
                      sx={{ m: 0.5 }}
                      variant="outlined"
                      color="secondary"
                      label={`Emission Group: ${emissionGroupName}`}
                    />
                  )}
                </Provider>
              </ThemeProvider>
            </StyledEngineProvider>
          </>
        );
      },
    }),
  });
}
