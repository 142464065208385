import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import React, { useMemo } from "react";
import { AdminInfoAggregate } from "../AggregateViewPage";
import ReactJson from "react-json-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type EventProps = {
  event: AdminInfoAggregate;
  elevation: number
};

const EventInfo = (props: EventProps) => {
  const title = useMemo(() => {
    const date = props.event.TimeStamp.split(".")[0].split('T').join(' ');
    return props.event.EventName
      ? `${date} (${props.event.EventName})`
      : date;
  }, [props.event]);

  return (
      <Accordion elevation={props.elevation}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
        >
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ReactJson src={props.event}/>
        </AccordionDetails>
      </Accordion>
      
      
 
  );
};

export default EventInfo;
