/* eslint-disable react/prop-types */
import React from "react";
import { Grid, styled, TextField } from "@mui/material";
// import {
//   CardCvcElement,
//   CardExpiryElement,
//   CardNumberElement,
// } from "@stripe/react-stripe-js";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TextFieldConnector } from "./FormConnector";

const CardGrid = styled(Grid)(({ theme }) => ({
  position: "relative",
  padding: "25px",
  boxSizing: "border-box",
  "& .LikeStripeElement": {
    "& .MuiInputBase-root": {
      backgroundColor: "#f0f0f0",
      fontWeight: 500,
      fontSize: 14,
      "&:not(.Mui-error) fieldset, &:not(.Mui-error):hover fieldset": {
        borderColor: "#f0f0f0",
      },
      "& input": {
        padding: "10px 21px 10px 12px",
      },
    },
    "& input::placeholder": {
      color: "#a2a2a2",
      opacity: 1,
      fontWeight: 500,
    },
  },
  "& .StripeElement": {
    padding: "10px 21px 10px 12px",
    backgroundColor: "#f0f0f0",
    boxSizing: "border-box",
  },
  "& .StripeElement, & .LikeStripeElement": {
    margin: "16px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "5px 0 10px",
    },
  },
  "& label": {
    fontSize: 12,
    letterSpacing: 0.69,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: 25,
  },
}));

const NumberInputGrid = styled(Grid)(({ theme }) => ({
  width: 240,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const options = {
  style: {
    base: {
      "::placeholder": {
        color: "#a2a2a2",
      },
    },
    invalid: {
      color: "#ff0000",
    },
  },
};

type CardFormProps = {
  formMethods: any;
  error?: string;
  className?: string;
};

const CreditCardForm = (props: CardFormProps): JSX.Element => {
  const { register, errors } = props.formMethods;
  const { t } = useTranslation("translation");

  return (
    <CardGrid container>
      <Grid item xs={12}>
        <label>EMAIL</label>
        <TextFieldConnector register={register("Email", { required: true })}>
          <TextField
            className="LikeStripeElement"
            variant="outlined"
            type="email"
            placeholder="email@email.com"
            fullWidth
            error={!!errors.Email}
          />
        </TextFieldConnector>
      </Grid>

      <Grid container justifyContent="space-between" item xs={12}>
        <NumberInputGrid item>
          <label>{t("Base.CreditCard.CardNumber")}</label>
          {/* <CardNumberElement options={options} /> */}
        </NumberInputGrid>
        <Grid
          item
          sx={{
            width: "78px",
          }}
        >
          <label>CVC</label>
          {/* <CardCvcElement options={options} /> */}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <label>{t("Base.CreditCard.CardName")}</label>
        <TextFieldConnector register={register("Name", { required: true })}>
          <TextField
            className="LikeStripeElement"
            variant="outlined"
            type="text"
            placeholder={t("Base.CreditCard.FirstLastName") ?? ""}
            fullWidth
            error={!!errors.Name}
          />
        </TextFieldConnector>
      </Grid>
      <Grid item xs={12}>
        <label>{t("Base.CreditCard.ExpirationDate")}</label>
        {/* <CardExpiryElement options={options} /> */}
      </Grid>
      {props.error && (
        <Alert
          sx={{
            width: "100%",
          }}
          severity="error"
        >
          {props.error}
        </Alert>
      )}
    </CardGrid>
  );
};

export default CreditCardForm;
