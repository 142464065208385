import React, { useEffect, useState } from "react";
import {
  Typography,
  Collapse,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Button,
  Grid,
} from "@mui/material";
import {
  CraftsmanTaskList,
  CraftsmanTaskListStatus,
  CraftsmanTaskStatus,
} from "../../../../definitions/model/ServiceBook";
import format from "date-fns/format";
import dalocale from "date-fns/locale/da";
import Task from "./Task";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TaskListProgressChart from "./TaskListProgressChart";
import { useTranslation } from "react-i18next";

type TaskListProps = {
  taskList: CraftsmanTaskList;
  showProgress?: boolean;
  setTaskStatus: (
    taskId: string,
    craftsmanId: string,
    status: CraftsmanTaskStatus
  ) => void;
  setTaskListStatus: (
    taskListId: string,
    status: CraftsmanTaskListStatus
  ) => void;
};

const TaskList = (props: TaskListProps) => {
  const [open, setOpen] = useState(false);
  const [statusText, setStatusText] = useState({ title: "", color: "" });
  const { taskList, setTaskStatus, setTaskListStatus } = props;
  const { t } = useTranslation("translation");

  useEffect(() => {
    switch (props.taskList.Status) {
      case CraftsmanTaskListStatus.Shared:
        setStatusText({ title: t("ServiceBook.Offer.New"), color: "red" });
        break;
      case CraftsmanTaskListStatus.Accepted:
        setStatusText({
          title: t("ServiceBook.Tasks.Accepted"),
          color: "orange",
        });
        break;
      case CraftsmanTaskListStatus.InProgress:
        setStatusText({ title: t("ServiceBook.Tasks.OnIt"), color: "blue" });
        break;
      case CraftsmanTaskListStatus.Finished:
        setStatusText({
          title: t("ServiceBook.Tasks.TaskIsDone"),
          color: "green",
        });
        break;
      default:
        setStatusText({ title: "", color: "" });
        break;
    }
    return () => {
      setStatusText({ title: "", color: "" });
    };
  }, [props.taskList.Status]);

  return (
    <>
      <ListItem onClick={() => setOpen(!open)}>
        <ListItemText>
          <Grid item container justifyContent="space-between">
            <Typography variant="h6">{getTaskListTitle(taskList)}</Typography>
            <Typography variant="body2" style={{ color: statusText.color }}>
              {statusText.title}
            </Typography>
          </Grid>
          <Typography variant="body2">
            Email: {taskList.CraftsmanEmail}
          </Typography>
          <Typography variant="body2">
            {t("ServiceBook.Tel")}: {taskList.CraftsmanPhone}
          </Typography>
          <Typography variant="body1" style={{ textTransform: "capitalize" }}>
            {format(new Date(taskList.Created), "eeee dd.MM.yyyy hh:mm", {
              locale: dalocale,
            })}
          </Typography>
        </ListItemText>
        <ListItemIcon>
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </ListItemIcon>
      </ListItem>
      <Collapse in={open} unmountOnExit>
        <Grid item xs={12} container direction="column">
          {props.showProgress && (
            <Grid item>
              <TaskListProgressChart tasks={taskList.Tasks} />
            </Grid>
          )}
          {taskList.Status === CraftsmanTaskListStatus.Shared && (
            <Grid item container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() =>
                    setTaskListStatus(
                      taskList.Id,
                      CraftsmanTaskListStatus.Accepted
                    )
                  }
                >
                  {t("General.Buttons.Approve")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() =>
                    setTaskListStatus(
                      taskList.Id,
                      CraftsmanTaskListStatus.Cancelled
                    )
                  }
                >
                  {t("General.Buttons.Reject")}
                </Button>
              </Grid>
            </Grid>
          )}
          {taskList.Tasks.map((x, i) => (
            <Task
              key={x.Id}
              task={x}
              number={i + 1}
              showTaskStatusCheckbox={
                taskList.Status === CraftsmanTaskListStatus.InProgress ||
                taskList.Status === CraftsmanTaskListStatus.Finished
              }
              setTaskStatus={(taskId: string, status: CraftsmanTaskStatus) =>
                setTaskStatus(taskId, taskList.Id, status)
              }
            />
          ))}
        </Grid>
        {taskList.Status !== CraftsmanTaskListStatus.New && (
          <>
            <Grid item container justifyContent="space-between">
              <Typography>{t("ServiceBook.Tasks.EstimatedPrice")}</Typography>
              <Typography>
                {(taskList.TotalPrice > taskList.Discount
                  ? taskList.TotalPrice + taskList.Discount
                  : 0
                ).toLocaleString("da-dk", {
                  currency: "DKK",
                  style: "currency",
                })}
              </Typography>
            </Grid>
            <Grid item container justifyContent="space-between">
              <Typography>{t("ServiceBook.Tasks.Discount")}</Typography>
              <Typography>
                {taskList.Discount.toLocaleString("da-dk", {
                  currency: "DKK",
                  style: "currency",
                })}
              </Typography>
            </Grid>
            <Grid item container justifyContent="space-between">
              <Typography>{t("ServiceBook.Tasks.Total")}</Typography>
              <Typography>
                {taskList.TotalPrice.toLocaleString("da-dk", {
                  currency: "DKK",
                  style: "currency",
                })}
              </Typography>
            </Grid>
          </>
        )}
      </Collapse>
      <Divider />
    </>
  );
};

const getTaskListTitle = (taskList: CraftsmanTaskList): string => {
  const { t } = useTranslation("translation");

  switch (taskList.Status) {
    case CraftsmanTaskListStatus.New:
      return `${t("ServiceBook.Tasks.RequestToCraftsman")} ${
        taskList.CraftsmanName
      }`;
    case CraftsmanTaskListStatus.Shared:
      return `${t("ServiceBook.Tasks.OfferFromCraftsman")} ${
        taskList.CraftsmanName
      }`;
    default:
      return `${t("ServiceBook.Tasks.TasksForCraftsman")} ${
        taskList.CraftsmanName
      }`;
  }
};

export default TaskList;
