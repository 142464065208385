import {
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Rating,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import SwiperCore from "swiper";
import { Autoplay } from "swiper";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { useTranslation } from "react-i18next";
import { CompanyTestimonialModel } from "../../../definitions/Company";
import { useLandingActions } from "../../../actions";


const StyledCard = styled(Card,{
  shouldForwardProp: (propName: string) => propName !== "backgroundColor",
})<{ backgroundColor: string | undefined }>(({ theme, backgroundColor }) => ({
  backgroundColor: backgroundColor,
  color: theme.palette.getContrastText(backgroundColor || "") ,
  maxHeight: "430px",
  minHeight: "430px",
  display: "flex",
  maxWidth: "380px",
  minWidth: "100%",
  [theme.breakpoints.only("sm")]: {
    minHeight: "320px",
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  paddingBottom: 0,
  maxHeight: "400px",
  [theme.breakpoints.only("sm")]: {
    maxHeight: "300px",
  },
}));
const ClientLogo = styled("img")(({ theme }) => ({
  width: "auto",
  maxWidth: 100,
  maxHeight: 40,
  background: theme.palette.background.default,
  padding: 4,
  boxSizing: "content-box",
  borderRadius: 8,
}));

const TestimonialsCarousel = (props: {backgroundColor: string | undefined}) => {
  const theme = useTheme();
  const { t } = useTranslation("translation");
  const sliderRef = useRef<SwiperRef>(null);
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [testimonials, setTestimonials] = useState<CompanyTestimonialModel[]>([]);
  const { getCompanyTestimonials } = useLandingActions();

  useEffect(() => {
    if (testimonials.length === 0) getCompanyTestimonials().then(setTestimonials);
  }, []);

  const handleNext = () => {
    sliderRef.current?.swiper.slideNext();
  };
  SwiperCore.use([Autoplay]);
  return (
    <>
      <Grid container sx={{ height: isSm ? "350px " : "450px", overflow: "hidden" }}>
        <Swiper
          style={{ width: "100%" }}
          speed={1500}
          ref={sliderRef}
          loop={Boolean(testimonials.length > 1)}
          height={isSm ? 350 : 450}
          autoplay={{ delay: 4000 }}
          direction={"vertical"}
          slidesPerView={1}
          className="mySwiper"
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <StyledCard key={index} backgroundColor={props.backgroundColor}>
                <Grid
                  container
                  direction="column"
                  justifyContent={"space-between"}
                  sx={{ flex: 1, flexWrap: "nowrap" }}
                >
                  <StyledCardContent>
                    <Rating
                      size="small"
                      readOnly
                      defaultValue={testimonial.Rating}
                      precision={0.5}
                      sx={{ marginBottom: 1 }}
                    />
                    <Typography color="inherit" variant="subtitle1" pb={1} sx={{ lineHeight: 1.2 }}>
                      {t(testimonial.Title)}
                    </Typography>
                    <Typography color="inherit" variant="body1" sx={{ lineHeight: 1.4 }}>
                      {t(testimonial.Text, { hostname: window.location.hostname })}
                    </Typography>
                  </StyledCardContent>
                  <CardActions>
                    <Grid container justifyContent="flex-end" alignItems={"flex-end"} mb={1}>
                      <ClientLogo alt="" src={testimonial.CompanyLogoUrl} />
                    </Grid>
                  </CardActions>
                </Grid>
              </StyledCard>
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
      <Grid container justifyContent="center">
        <IconButton sx={{ color: theme.palette.getContrastText(theme.palette.grey[900]) }} onClick={handleNext}>
          <KeyboardDoubleArrowDownIcon />
        </IconButton>
      </Grid>
    </>
  );
};

export default TestimonialsCarousel;
