import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import { TbReportSearch } from "react-icons/tb";
import { FaHandPointDown } from "react-icons/fa";
import HandymanIcon from "@mui/icons-material/Handyman";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Grid, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const StyledConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 30,
    [theme.breakpoints.down("sm")]: {
      top: 25,
    },
    "& .MuiStepConnector-line": {
      // border: "1px solid",
    },
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.grey[300],
    borderRadius: 1,
  },
}));

const StyledStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  zIndex: 1,
  color: theme.palette.primary.contrastText,
  width: 60,
  height: 60,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  backgroundColor: theme.palette.grey[300],
  [theme.breakpoints.down("sm")]: {
    width: 55,
    height: 55,
  },
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.main,
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
  }),
}));

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  "& .MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel": {
    maxWidth: 150,
  },
}));

function StyledStepIcon(props: StepIconProps) {
  const { active, completed } = props;
  const icons: { [index: string]: React.ReactElement } = {
    1: <FaHandPointDown fontSize="30px" />,
    2: <TbReportSearch fontSize="30px" />,
    3: <HandymanIcon fontSize="large" />,
    4: <ShoppingCartIcon fontSize="large" />,
  };

  return (
    <StyledStepIconRoot ownerState={{ completed, active }}>
      {icons[String(props.icon)]}
    </StyledStepIconRoot>
  );
}

type ServicebookStepperProps = {
  activeStep: number;
  withStyles?: string;
  setActiveStep?: (newActiveStep: number) => void;
};

const ServicebookStepper = ({
  activeStep,
  setActiveStep,
}: ServicebookStepperProps): JSX.Element => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation("translation");
  const steps = [
    `${t("ServiceBook.ChooseReport")}`,
    isXs ?`${t("ServiceBook.SeeReportDetailsMobile")}` : `${t("ServiceBook.SeeReportDetails")}`,
    `${t("ServiceBook.ChooseCraftsmen")}`,
    `${t("ServiceBook.OrderOffer")}`,
  ];
  return (
    <Grid item container marginBottom={isXs ? 0 : 2}>
      <Stepper
        sx={{ width: "100%" }}
        alternativeLabel
        activeStep={activeStep}
        connector={<StyledConnector />}
      >
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          return (
            <Step
              {...stepProps}
              onClick={() => setActiveStep && setActiveStep(index)}
              key={label}
            >
              <StyledStepLabel StepIconComponent={StyledStepIcon}>
                {label}
              </StyledStepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Grid>
  );
};

export default ServicebookStepper;
