import { Reducer } from "react";
import { AdminPricePredictionActionTypes } from "./admin.price-prediction.actiontypes";
import { AppAction } from "../../../../definitions/Action";
import { PricePredictionModel, PredictionTestUnitModel } from "./defenitions";

export interface PricePredictionsStoreState {
  predictions: PricePredictionModel[];
  testUnits: PredictionTestUnitModel[];
  loading: boolean;
  error: string;
}

export const initialState: PricePredictionsStoreState = {
  predictions: [],
  testUnits: [],
  loading: false,
  error: "",
};

const priePredictionsReducer: Reducer<PricePredictionsStoreState, AppAction> = (
  state: PricePredictionsStoreState = initialState,
  action: AppAction
): PricePredictionsStoreState => {
  switch (action.type) {
    case AdminPricePredictionActionTypes.GET_PRICE_PREDICTIONS:
    case AdminPricePredictionActionTypes.GET_TEST_UNITS:
    case AdminPricePredictionActionTypes.ADD_UNIT_FOR_PREDICTION:
    case AdminPricePredictionActionTypes.DELETE_UNIT_FOR_PREDICTION:
    case AdminPricePredictionActionTypes.RENEW_PREDTICTIONS:
    case AdminPricePredictionActionTypes.RENEW_ONE_PREDTICTION:
    case AdminPricePredictionActionTypes.DELETE_PRICE_PREDICTION:
    case AdminPricePredictionActionTypes.EDIT_PRICE_PREDICTION:
      return {
        ...state,
        loading: true,
      };
    case AdminPricePredictionActionTypes.GET_PRICE_PREDICTIONS_SUCCEEDED:
      return {
        ...state,
        predictions: action.payload,
        loading: false,
      };
    case AdminPricePredictionActionTypes.EDIT_PRICE_PREDICTION_SUCCEEDED:
      return {
        ...state,
        predictions: state.predictions.map((x) =>
          x.Id === action.payload.Id ? action.payload : x
        ),
        loading: false,
      };
    case AdminPricePredictionActionTypes.DELETE_PRICE_PREDICTION_SUCCEEDED:
      return {
        ...state,
        predictions: state.predictions.filter((x) => x.Id !== action.payload),
        loading: false,
      };
    case AdminPricePredictionActionTypes.GET_TEST_UNITS_SUCCEEDEDS:
    case AdminPricePredictionActionTypes.RENEW_PREDTICTIONS_SUCCEEDEDS:
      return {
        ...state,
        testUnits: action.payload,
        loading: false,
      };
    case AdminPricePredictionActionTypes.RENEW_ONE_PREDTICTION_SUCCEEDEDS:
      return {
        ...state,
        testUnits: state.testUnits.map((x) =>
          x.Id === action.payload.Id ? action.payload : x
        ),
        loading: false,
      };
    case AdminPricePredictionActionTypes.ADD_UNIT_FOR_PREDICTION_SUCCEEDED:
      return {
        ...state,
        testUnits: [action.payload, ...state.testUnits],
        loading: false,
      };
    case AdminPricePredictionActionTypes.DELETE_UNIT_FOR_PREDICTION_SUCCEEDED:
      return {
        ...state,
        testUnits: state.testUnits.filter((x) => x.Id !== action.payload),
        loading: false,
      };
    case AdminPricePredictionActionTypes.PRICE_PREDICTIONS_OPERATION_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default priePredictionsReducer;
