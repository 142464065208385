/* eslint-disable react/prop-types */
import { CircularProgress, Grid, Typography } from "@mui/material";
import React, { Children } from "react";

type LoadWrapperProps = {
  isLoading: boolean;
  result: unknown;
  loadingComponent?: JSX.Element;
  emptyResultComponent?: JSX.Element | string;
  children: React.ReactNode;
};

const LoadWrapper = ({
  isLoading,
  result,
  loadingComponent,
  emptyResultComponent,
  children,
}: LoadWrapperProps): JSX.Element => {
  const hasResults =
    (result && !Array.isArray(result)) ||
    (Array.isArray(result) && result.length > 0);

  if (hasResults) {
    return <>{Children.map(children, (children) => children)}</>;
  }

  if (isLoading) {
    return loadingComponent ? (
      loadingComponent
    ) : (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      {React.isValidElement(emptyResultComponent) ? (
        emptyResultComponent
      ) : (
        <Typography>{emptyResultComponent}</Typography>
      )}
    </>
  );
};

export default LoadWrapper;
