import { Reducer } from "redux";

import {
  MapHousesData,
  Neighbors,
  Road,
  MarkerType,
  MobileTowerInfo,
  GymnasiumInfo,
  Crime,
  Ground,
  Radon,
  Noise,
  Flood,
  LocalPlan,
  EcoBusinessInfo,
  EcoBusinessAd,
} from "../definitions/Map";
import { AppAction } from "../definitions/Action";
import { MapActionTypes } from "../constants/map.actiontypes";

export interface MapStoreState {
  loading: boolean;
  error: string | null;
  localPlans: any[];
  crimeInfo: Crime | null;
  floodInfo: Flood | null;
  planInfo: LocalPlan | null;
  noiseInfo: Noise | null;
  groundInfo: Ground | null;
  radonInfo: Radon | null;
  soldUnits: MapHousesData[];
  estimationUnits: MapHousesData[];
  neighbors: Neighbors[];
  roads: Road[];
  busStations: MarkerType[];
  mobileTowers: MarkerType[];
  gymnasiums: MarkerType[];
  schools: MarkerType[];
  ecoBusinesses: MarkerType[];
  ecoBusinessAds: EcoBusinessAd[];
  mobileTowerInfo: MobileTowerInfo | null;
  gymnasiumInfo: GymnasiumInfo | null;
  ecoBusinessInfo: EcoBusinessInfo | null;
}

export const initialState: MapStoreState = {
  localPlans: [],
  loading: false,
  error: null,
  crimeInfo: null,
  floodInfo: null,
  planInfo: null,
  noiseInfo: null,
  groundInfo: null,
  radonInfo: null,
  soldUnits: [],
  estimationUnits: [],
  neighbors: [],
  roads: [],
  busStations: [],
  mobileTowers: [],
  gymnasiums: [],
  schools: [],
  ecoBusinesses: [],
  ecoBusinessAds: [],
  mobileTowerInfo: null,
  gymnasiumInfo: null,
  ecoBusinessInfo: null,
};

const mapReducer: Reducer<MapStoreState, AppAction> = (
  state: MapStoreState = initialState,
  action: AppAction
): MapStoreState => {
  switch (action.type) {
    case MapActionTypes.GET_PLANS:
    case MapActionTypes.GET_CRIME_INFO:
    case MapActionTypes.GET_FLOOD_INFO:
    case MapActionTypes.GET_PLAN_INFO:
    case MapActionTypes.GET_NOISE_INFO:
    case MapActionTypes.GET_GROUND_INFO:
    case MapActionTypes.GET_RADON_INFO:
    case MapActionTypes.GET_SOLD_UNITS:
    case MapActionTypes.GET_ESTIMATION_UNITS:
    case MapActionTypes.GET_NEIGHBORS:
    case MapActionTypes.GET_ROAD_PLANS:
    case MapActionTypes.GET_BUS_STATIONS:
    case MapActionTypes.GET_MOBILE_TOWERS:
    case MapActionTypes.GET_GYMNASIUMS:
    case MapActionTypes.GET_SCHOOLS:
    case MapActionTypes.GET_ECOBUSINESSES:
    case MapActionTypes.GET_MOBILE_TOWER_INFO:
    case MapActionTypes.GET_GYMNASIUM_INFO:
      return { ...state, loading: true };
    case MapActionTypes.GET_ECOBUSINESS_INFO:
      return {
        ...state,
        loading: true,
        ecoBusinessInfo: null,
      };
    case MapActionTypes.GET_PLANS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        localPlans: action.payload,
      };
    case MapActionTypes.GET_CRIME_INFO_SUCCEEDED:
      return {
        ...state,
        loading: false,
        crimeInfo: action.payload,
      };
    case MapActionTypes.GET_FLOOD_INFO_SUCCEEDED:
      return {
        ...state,
        loading: false,
        floodInfo: action.payload,
      };
    case MapActionTypes.GET_PLAN_INFO_SUCCEEDED:
      return {
        ...state,
        loading: false,
        planInfo: action.payload,
      };
    case MapActionTypes.GET_NOISE_INFO_SUCCEEDED:
      return {
        ...state,
        loading: false,
        noiseInfo: action.payload,
      };
    case MapActionTypes.GET_GROUND_INFO_SUCCEEDED:
      return {
        ...state,
        loading: false,
        groundInfo: action.payload,
      };
    case MapActionTypes.GET_RADON_INFO_SUCCEEDED:
      return {
        ...state,
        loading: false,
        radonInfo: action.payload,
      };
    case MapActionTypes.GET_SOLD_UNITS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        soldUnits: action.payload,
      };
    case MapActionTypes.GET_ESTIMATION_UNITS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        estimationUnits: action.payload,
      };
    case MapActionTypes.GET_NEIGHBORS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        neighbors: action.payload,
      };
    case MapActionTypes.GET_ROAD_PLANS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        roads: action.payload,
      };
    case MapActionTypes.GET_BUS_STATIONS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        busStations: action.payload,
      };
    case MapActionTypes.GET_MOBILE_TOWERS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        mobileTowers: action.payload,
      };
    case MapActionTypes.GET_GYMNASIUMS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        gymnasiums: action.payload,
      };
    case MapActionTypes.GET_SCHOOLS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        schools: action.payload,
      };
    case MapActionTypes.GET_ECOBUSINESSES_SUCCEEDED:
      return {
        ...state,
        loading: false,
        ecoBusinesses: action.payload.MarkersData.Markers,
        ecoBusinessAds: action.payload.Ads,
      };
    case MapActionTypes.HIDE_ECOBUSINESS_ADS:
      return {
        ...state,
        ecoBusinessAds: [],
      };
    case MapActionTypes.GET_MOBILE_TOWER_INFO_SUCCEEDED:
      return {
        ...state,
        loading: false,
        mobileTowerInfo: action.payload,
      };
    case MapActionTypes.GET_GYMNASIUM_INFO_SUCCEEDED:
      return {
        ...state,
        loading: false,
        gymnasiumInfo: action.payload,
      };
    case MapActionTypes.GET_ECOBUSINESS_INFO_SUCCEEDED:
      return {
        ...state,
        loading: false,
        ecoBusinessInfo: action.payload,
      };
    case MapActionTypes.MAP_OPERATION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default mapReducer;
