import { GroupMembersDto } from "../definitions/model/Group";
import { Reducer } from "react";
import { AppAction } from "../definitions/Action";
import { FamilyConstatns } from "../constants";
import { useSelector } from "react-redux";
import { ApplicationState } from "./store";

export interface FamilyStoreState {
  loading: boolean;
  error: string | null;
  families: GroupMembersDto[];
  mainFamily: GroupMembersDto | null;
}

export const initialState: FamilyStoreState = {
  loading: false,
  error: null,
  families: [],
  mainFamily: null,
};

export const useFamilyState = () => useSelector((state: ApplicationState) => state.family);

const familyReducer: Reducer<FamilyStoreState, AppAction> = (
  state: FamilyStoreState = initialState,
  action: AppAction
) => {
  switch (action.type) {
    case FamilyConstatns.ACCEPT_FAMILY_JOIN_REQUEST:
    case FamilyConstatns.DECLINE_FAMILY_JOIN_REQUEST:
    case FamilyConstatns.GET_USER_FAMILIES:
    case FamilyConstatns.GET_USER_FAMILY:
    case FamilyConstatns.REMOVE_FAMILY_MEMBER:
      return { ...state, loading: true };
    case FamilyConstatns.ACCEPT_FAMILY_JOIN_REQUEST_SUCCEEDED:
    case FamilyConstatns.DECLINE_FAMILY_JOIN_REQUEST_SUCCEEDED:
    case FamilyConstatns.REMOVE_FAMILY_MEMBER_SUCCEEDED:
      return {
        ...state,
        families: state.families.map((family) => (family.UnitId === action.payload.UnitId ? action.payload : family)),
        loading: false,
      };
    case FamilyConstatns.GET_USER_FAMILIES_SUCCEEDED:
      return { ...state, families: action.payload, loading: false };
    case FamilyConstatns.GET_USER_FAMILY_SUCCEEDED:
      return { ...state, mainFamily: action.payload, loading: false };
    case FamilyConstatns.FAMILY_OPERATION_FAILED:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

export default familyReducer;
