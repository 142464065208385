import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useFamily } from "../../actions";
import FamilyMembersList from "./components/FamilyMembersList";
import { LoadWrapper, MCard } from "../../components/Base";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UnitHelper from "../../helpers/unit-helper";

const FamilyPage = () => {
  const { t } = useTranslation("translation");
  const { id } = useParams<{ id: string }>();
  const [{ families, loading }, familyActions] = useFamily();
  const [error, setError] = useState("");
  useEffect(() => {
    if (id) {
      familyActions.getUserFamily(id).catch(setError);
    }
  }, [id]);

  return (
        <Grid container>
          <Grid item container direction="column">
            {!error ? (
              families && (
                <LoadWrapper isLoading={loading} result={families}>
                  {families.map((family) => (
                    <MCard key={family.UnitId} type="main" title={UnitHelper.GetFullAddress(family.Address)}>
                      <FamilyMembersList
                        members={family.Members}
                        unitId={family.UnitId}
                        onAddAction={familyActions.acceptFamilyJoinRequest}
                        onBlockAction={familyActions.declineFamilyJoinRequest}
                        onRemoveAction={familyActions.removeFamilyMember}
                      />
                    </MCard>
                  ))}
                </LoadWrapper>
              )
            ) : (
              <Grid container justifyContent="center">
                <Typography align="center" variant="h5">
                  {error}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
  );
};

export default FamilyPage;
