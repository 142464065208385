import React, { useEffect, useState, useRef, useMemo } from "react";
import { Grid, useTheme, useMediaQuery, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  CO2EmissionSourceGroup,
  CO2EmissionSurvey,
  CO2Plan,
  CO2PlanCustomQuestionsTarget,
  CO2PlanPerson,
  CustomQuestions,
  MergePersons,
  partsToShow,
} from "../CO2Plan";
import ReadonlySurveyContextWrapper from "./ReadonlySurveyContextWrapper";
import CO2ActionsTimelineComponent from "./ActionsTimeline/CO2ActionsTimelineComponent";
import CO2InitialSurveyComponent from "./InitialSurvey/CO2InitialSurveyComponent";
import CO2InitialSurveyResultComponent from "./InitialSurvey/CO2InitialSurveyResultComponent";
import CO2TargetSurveyComponent from "./TargetSurvey/CO2TargetSurveyComponent";
import CO2TargetSurveyResultComponent from "./TargetSurvey/CO2TargetSurveyResultComponent";
import CO2WidgetsComponent from "./CO2WidgetsComponent";
import BaseDialog from "../../../../components/Dialogs/BaseDialog";
import { useDispatch } from "react-redux";
import { CO2PlanActionTypes } from "../co2plan.actiontypes";
import SideMenu from "./ClimatePlanContainer/SideMenu";
import { GroupToShow } from "./CO2EmissionChartComponent";
import { getCurrenUserEmission, getSurveyPersonsEmission } from "../co2-helpers";
import EditPersons from "./EditPersons";
import { ContextProps } from "../ClimatePlanPage";
import CO2InstallAppComponent from "./CO2InstallAppComponent";
import CO2ContextWrapper from "./CO2ContextWrapper";
import CustomQuestionsComponent from "./InitialSurvey/CustomQuestionsComponent";
import { integrateCustomQuestionsIntoGroups, useCO2 } from "../co2plan.actions";
import { BsQuestionSquare } from "react-icons/bs";
import { menuItems } from "./ClimatePlanContainer/sideMenuItems";

type CO2PlanProps = {
  co2Plan: CO2Plan;
  lastStep: partsToShow | null;
  readOnly?: boolean;
  contextData: ContextProps;
  ignorePlanPersons?: boolean;
};

const CO2PlanComponent = (props: CO2PlanProps): JSX.Element => {
  const [initialPersons, setInitialPersons] = useState<GroupToShow[]>([]);
  const [initialUsersEmission, setInitialUsersEmission] = useState(0);
  const [targetPersons, setTargetPersons] = useState<GroupToShow[]>([]);
  const [targetUsersEmission, setTargetUsersEmission] = useState(0);
  const headerRef = useRef<HTMLDivElement>(null);
  // const widgetRef = useRef<HTMLDivElement>(null);
  const [personsDialogOpen, setPersonsDialogOpen] = useState(false);
  const { t } = useTranslation("translation");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const [enabledParts, setEnabledParts] = useState<partsToShow[]>([]);
  // part that we want to show, but might be not showing because it is disabled
  const [nextPartToShow, setNextPartToShow] = useState<partsToShow>(partsToShow.Unknown);
  // part that is shown to user
  const [partToShow, setPartToShow] = useState<partsToShow>(partsToShow.Unknown);
  const [newUserToPlan, setNewUserToPlan] = useState(false);
  const [customQuestions, setCustomQuestions] = useState<CO2EmissionSourceGroup | null>(null);
  const { unit, user, showEconomyItems, isMultiSelect } = props.contextData;
  const dispatch = useDispatch();
  const [state, actions] = useCO2();

  const withCustomQuestions = useMemo(() => {
    let result = false;
    state.co2Plan?.CustomQuestions?.map((question: CustomQuestions) => {
      if (question.Target === CO2PlanCustomQuestionsTarget.MainToolBar) {
        result = true;
      }
    });
    return result;
  }, [state.co2Plan?.CustomQuestions]);

  useEffect(() => {
    if (withCustomQuestions && !menuItems.find((m) => m.key === "ClimatePlan.General.Questions")) {
      menuItems.splice(1, 0, {
        key: "ClimatePlan.General.Questions",
        mobileName: "ClimatePlan.General.Questions",
        root: "/co2calculator/questions",
        partsToShow: partsToShow.ToolBarQuestions,
        icon: <BsQuestionSquare fontSize={30} color="inherit" />,
      });
    }
  }, [withCustomQuestions]);

  useEffect(() => {
    if (partToShow === partsToShow.ToolBarQuestions) {
      const group = integrateCustomQuestionsIntoGroups(
        props.co2Plan.CustomQuestions,
        [],
        CO2PlanCustomQuestionsTarget.MainToolBar
      );

      setCustomQuestions(group[0]);
    }
  }, [props.co2Plan.CustomQuestions, partToShow]);

  const closeMemberDialog = async (
    personNamesToAdd: CO2PlanPerson[],
    personIdsToDelete: string[],
    personsToRename: CO2PlanPerson[],
    isSinglePersonPlan: boolean,
    planPersonsToIgnore: string[],
    personsToMerge: MergePersons[]
  ) => {
    await actions.updatePlanPersons(
      personNamesToAdd,
      personIdsToDelete,
      personsToRename,
      isSinglePersonPlan,
      planPersonsToIgnore,
      personsToMerge,
      !!isMultiSelect,
      unit?.Id
    );

    setNewUserToPlan(false);
    setPersonsDialogOpen(false);
  };

  useEffect(() => {
    const newPersons = getSurveyPersonsEmission(props.co2Plan.InitialSurvey, props.co2Plan.UnchangeableBasicEmission);
    const currentPersonEmission = getCurrenUserEmission(newPersons, user);
    setInitialPersons(newPersons);
    setInitialUsersEmission(currentPersonEmission);
  }, [props.co2Plan.InitialSurvey]);

  useEffect(() => {
    const newPersons = getSurveyPersonsEmission(props.co2Plan.TargetSurvey, props.co2Plan.UnchangeableBasicEmission);
    const currentPersonEmission = getCurrenUserEmission(newPersons, user);
    setTargetPersons(newPersons);
    setTargetUsersEmission(currentPersonEmission);
  }, [props.co2Plan.TargetSurvey]);

  useEffect(() => {
    const newEnabledParts: partsToShow[] = [partsToShow.InitialSurvey];
    let defaultPartToShow = partsToShow.InitialSurvey;

    const initialCustom = props.co2Plan.CustomQuestions?.find(
      //CO2PlanCustomQuestionsTarget.InitialSurvey value is 0, but it could be simplified by server serialization
      (question) => question.Target === CO2PlanCustomQuestionsTarget.InitialSurvey || question.Target === undefined
    );

    const isFilledInInitialCustom = initialCustom ? initialCustom.IsFilledIn : true;
    if (props.co2Plan.InitialSurvey.IsFilledIn && isFilledInInitialCustom) {
      newEnabledParts.push(partsToShow.InitialResults);
      newEnabledParts.push(partsToShow.InitialResultsFinish);
      newEnabledParts.push(partsToShow.TargetSurvey);
      newEnabledParts.push(partsToShow.ExtraStep);

      if (partToShow === partsToShow.Unknown) {
        defaultPartToShow = partsToShow.InitialResults;
      }
    }

    const targetCustom = props.co2Plan.CustomQuestions?.find(
      (question) => question.Target === CO2PlanCustomQuestionsTarget.TargetSurvey
    );

    const isFilledIntargetCustom = targetCustom ? targetCustom.IsFilledIn : true;
    if (props.co2Plan.TargetSurvey.IsFilledIn && isFilledInInitialCustom && isFilledIntargetCustom) {
      newEnabledParts.push(partsToShow.ExtraStep);
      newEnabledParts.push(partsToShow.TargetResults);
      newEnabledParts.push(partsToShow.ActionsTimeline);

      if (partToShow === partsToShow.Unknown) {
        defaultPartToShow = partsToShow.ActionsTimeline;
      }
    }

    if (props.co2Plan.IsInitialSurveyOutdated) {
      defaultPartToShow = partsToShow.InitialSurvey;
    }

    newEnabledParts.push(partsToShow.ToolBarQuestions);
    if (props.lastStep !== null) defaultPartToShow = props.lastStep;

    if (partToShow === partsToShow.Unknown) setNextPartToShow(defaultPartToShow);

    if (newEnabledParts.indexOf(partToShow) === -1) {
      // something has changed and we cannot show active part anymore
      setPartToShow(partsToShow.InitialSurvey);
    }

    setEnabledParts(newEnabledParts);
  }, [props.co2Plan, user]);

  useEffect(() => {
    if (props.lastStep) setNextPartToShow(props.lastStep);
  }, [props.lastStep]);

  useEffect(() => {
    const isUserInPlan = props.co2Plan.PlanPersons.filter((p) => p.Id === user?.Id).length > 0;
    if (user && !isUserInPlan && !props.readOnly && !props.ignorePlanPersons) {
      setNewUserToPlan(true);
      setPersonsDialogOpen(true);
    }
  }, [user]);

  useEffect(() => {
    const hasPersonsWithoutYear = props.co2Plan.PlanPersons.filter((p) => !p.BirthYear).length > 0;
    if (hasPersonsWithoutYear && !props.ignorePlanPersons) {
      setPersonsDialogOpen(true);
    }
  }, []);

  useEffect(() => {
    if (
      nextPartToShow !== partToShow &&
      nextPartToShow !== partsToShow.Unknown &&
      enabledParts.indexOf(nextPartToShow) !== -1
    ) {
      dispatch({
        type: CO2PlanActionTypes.SET_CO2_PLAN_LAST_STEP,
        payload: { lastStep: nextPartToShow },
      });
      setPartToShow(nextPartToShow);
      // scroll top if user is in the bottom of the page
      if (headerRef.current && window.scrollY > headerRef.current.offsetTop) {
        headerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [enabledParts, nextPartToShow]);

  const handlePartToShowChange = (partToShow: partsToShow | null) => {
    if (partToShow) {
      if (nextPartToShow === partsToShow.TargetSurvey && !props.readOnly) {
        // make user that user's changes are not lost by saving survey when he switches
        // from the target survey tab
        actions.saveEmissionSurvey(
          props.co2Plan.InitialSurvey,
          props.co2Plan.TargetSurvey,
          props.co2Plan.CustomQuestions,
          undefined,
          props.co2Plan.Id,
          unit?.Id,
          isMultiSelect
        );
      }

      setNextPartToShow(partToShow);
    }
  };
  return (
    <SideMenu
      showPrint={
        partToShow === partsToShow.InitialResults ||
        partToShow === partsToShow.TargetResults ||
        partToShow === partsToShow.ActionsTimeline
      }
      showMembersButton={partToShow === partsToShow.InitialSurvey}
      currentPage={nextPartToShow}
      handlePartToShowChange={handlePartToShowChange}
      enabledParts={enabledParts}
      openFamilyDialog={() => setPersonsDialogOpen(true)}
      renderTopBar={props.contextData.renderTopBar}
      historyNavigate={props.contextData.historyNavigate}
      ignorePlanPersons={props.ignorePlanPersons}
    >
      <Grid ref={headerRef} container justifyContent="center">
        <Grid
          item
          container
          justifyContent="center"
          xs={12}
          md={9}
          style={{
            flexWrap: !isSm && partToShow ? "nowrap" : "wrap",
            marginTop: fullScreen ? 20 : 0,
          }}
        >
          <Grid id="the" item container direction="column" minHeight="100vh">
            <ContextWrapper
              initialSurvey={props.co2Plan.InitialSurvey}
              targetSurvey={props.co2Plan.TargetSurvey}
              isTargetSurvey={false}
              isSinglePersonPlan={props.co2Plan.IsSinglePersonPlan}
              isReadOnly={!!props.readOnly}
              planId={props.co2Plan.Id}
              defaultCO2Emission={props.co2Plan.DefaultCO2Emission}
              governmentGoalCO2Emission={props.co2Plan.GovernmentGoalCO2Emission}
              unchangeableBasicEmission={props.co2Plan.UnchangeableBasicEmission}
              contextData={props.contextData}
            >
              {partToShow === partsToShow.InitialSurvey && (
                <CO2InitialSurveyComponent
                  openFamilyDialog={() => setPersonsDialogOpen(true)}
                  ignorePlanPersons={props.ignorePlanPersons}
                  survey={props.co2Plan.InitialSurvey}
                  isReadonly={props.readOnly}
                  planPersons={props.co2Plan.PlanPersons}
                  finishSurvey={async () => {
                    if (props.lastStep) {
                      const lastItemIndex = menuItems.findIndex((m) => m.partsToShow === props.lastStep);
                      if (menuItems.length > lastItemIndex) {
                        const nextItem = menuItems[lastItemIndex + 1];
                        setNextPartToShow(nextItem.partsToShow);
                        return;
                      }
                    }

                    setNextPartToShow(
                      props.co2Plan.TargetSurvey?.IsFilledIn ? partsToShow.TargetResults : partsToShow.InitialResults
                    );
                  }}
                  scrollToTop={() =>
                    // sometimes scroll doesn't work, setTimeout fixes it
                    setTimeout(() => {
                      if (headerRef.current) {
                        headerRef.current.scrollIntoView({
                          behavior: "smooth",
                          block: "nearest",
                        });
                      }
                    }, 0)
                  }
                ></CO2InitialSurveyComponent>
              )}
              {partToShow === partsToShow.ToolBarQuestions && customQuestions && (
                <CustomQuestionsComponent group={customQuestions} widthSaveButton setPartHandler={setNextPartToShow} />
              )}
            </ContextWrapper>

            <ContextWrapper
              initialSurvey={props.co2Plan.InitialSurvey}
              targetSurvey={props.co2Plan.TargetSurvey}
              isTargetSurvey={true}
              isSinglePersonPlan={props.co2Plan.IsSinglePersonPlan}
              isReadOnly={!!props.readOnly}
              planId={props.co2Plan.Id}
              defaultCO2Emission={props.co2Plan.DefaultCO2Emission}
              governmentGoalCO2Emission={props.co2Plan.GovernmentGoalCO2Emission}
              unchangeableBasicEmission={props.co2Plan.UnchangeableBasicEmission}
              contextData={props.contextData}
            >
              {partToShow === partsToShow.TargetSurvey && (
                <CO2TargetSurveyComponent
                  targetSurvey={props.co2Plan.TargetSurvey}
                  initialSurvey={props.co2Plan.InitialSurvey}
                  finishSurvey={async () => setNextPartToShow(partsToShow.TargetResults)}
                  scrollToTop={() =>
                    // sometimes scroll doesn't work, setTimeout fixes it
                    setTimeout(() => {
                      if (headerRef.current) {
                        headerRef.current.scrollIntoView({
                          behavior: "smooth",
                          block: "nearest",
                        });
                      }
                    }, 0)
                  }
                />
              )}
            </ContextWrapper>
            <ContextWrapper
              initialSurvey={props.co2Plan.InitialSurvey}
              targetSurvey={props.co2Plan.TargetSurvey}
              isTargetSurvey={false}
              isSinglePersonPlan={props.co2Plan.IsSinglePersonPlan}
              isReadOnly={!!props.readOnly}
              planId={props.co2Plan.Id}
              defaultCO2Emission={props.co2Plan.DefaultCO2Emission}
              governmentGoalCO2Emission={props.co2Plan.GovernmentGoalCO2Emission}
              unchangeableBasicEmission={props.co2Plan.UnchangeableBasicEmission}
              contextData={props.contextData}
            >
              {partToShow === partsToShow.InitialResults && (
                <CO2InitialSurveyResultComponent
                  initialUserEmission={initialUsersEmission}
                  persons={initialPersons}
                  user={props.contextData.user}
                  survey={props.co2Plan.InitialSurvey}
                  showInitialSurvey={() => setNextPartToShow(partsToShow.InitialSurvey)}
                  startTargetSurvey={() => setNextPartToShow(partsToShow.TargetSurvey)}
                  showPersonalChart={props.co2Plan.PlanPersons.length > 1}
                />
              )}
              {partToShow === partsToShow.TargetResults && (
                <CO2TargetSurveyResultComponent
                  targetHouseholdEmission={props.co2Plan.DefaultCO2Emission}
                  initialHouseholdEmission={props.co2Plan.DefaultCO2Emission}
                  persons={targetPersons}
                  targetUserEmission={targetUsersEmission}
                  initialUserEmission={initialUsersEmission}
                  user={props.contextData.user}
                  co2Plan={props.co2Plan}
                  showEconomyItems={showEconomyItems}
                  showTargetSurvey={() => setNextPartToShow(partsToShow.TargetSurvey)}
                  showActionsTimeLine={() => setNextPartToShow(partsToShow.ActionsTimeline)}
                  showPersonalChart={props.co2Plan.PlanPersons.length > 1}
                />
              )}
              {partToShow === partsToShow.ActionsTimeline && (
                <CO2ActionsTimelineComponent
                  timeline={props.co2Plan.ActionsTimeLine}
                  categories={props.co2Plan.InitialSurvey.Categories}
                  backToSurvey={() => setNextPartToShow(partsToShow.TargetSurvey)}
                  showTargetResults={() => setNextPartToShow(partsToShow.TargetResults)}
                  showSendCredentialsMessage={() => {
                    setNextPartToShow(partsToShow.ExtraStep);
                  }}
                ></CO2ActionsTimelineComponent>
              )}
              {partToShow === partsToShow.ExtraStep && (
                <CO2InstallAppComponent
                  user={user}
                  showActionsTimeLine={() => setNextPartToShow(partsToShow.ActionsTimeline)}
                />
              )}
            </ContextWrapper>
            <Grid marginTop={5}>
              <CO2WidgetsComponent tabNumber={partToShow}></CO2WidgetsComponent>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <BaseDialog
        open={personsDialogOpen}
        hideClose={
          props.contextData.forceUserToMember && props.co2Plan.PlanPersons.filter((p) => p.Id === user?.Id).length == 0
        }
        closeDialog={() => {
          const isInPlan = props.co2Plan.PlanPersons.filter((p) => p.Id === user?.Id).length > 0;
          if (isInPlan || !props.contextData.forceUserToMember) setPersonsDialogOpen(false);
        }}
        titleComponent={t("ClimatePlan.MembersDialogHeader") ?? ""}
      >
        <EditPersons
          existingPersons={props.co2Plan.PlanPersons}
          isSinglePersonPlan={props.co2Plan.IsSinglePersonPlan}
          user={user}
          unit={null}
          family={props.contextData.family || null}
          planPersonsToIgnore={props.co2Plan?.PlanPersonsToIgnore || []}
          handleSave={closeMemberDialog}
          forceUserToMember={props.contextData.forceUserToMember}
        >
          {newUserToPlan && (
            <Typography align="center" variant="body1">
              {t("ClimatePlan.CreateClimatePlanAndCorrectHouseholdData") ?? ""}
            </Typography>
          )}
        </EditPersons>
      </BaseDialog>
    </SideMenu>
  );
};

export default CO2PlanComponent;

const ContextWrapper = (props: {
  initialSurvey: CO2EmissionSurvey;
  targetSurvey: CO2EmissionSurvey;
  isTargetSurvey: boolean;
  children: React.ReactNode;
  isSinglePersonPlan: boolean;
  isReadOnly: boolean;
  planId: string;
  defaultCO2Emission: number;
  governmentGoalCO2Emission: number;
  unchangeableBasicEmission: number;
  contextData: ContextProps;
}) => {
  return (
    <>
      {props.isReadOnly && (
        <ReadonlySurveyContextWrapper
          survey={props.isTargetSurvey ? props.targetSurvey : props.initialSurvey}
          isTargetSurvey={props.isTargetSurvey}
          IsSinglePersonPlan={props.isSinglePersonPlan}
          planId={props.planId}
          defaultCO2Emission={props.defaultCO2Emission}
          governmentGoalCO2Emission={props.governmentGoalCO2Emission}
          unchangeableBasicEmission={props.unchangeableBasicEmission}
          contextData={props.contextData}
        >
          {props.children}
        </ReadonlySurveyContextWrapper>
      )}
      {!props.isReadOnly && (
        <CO2ContextWrapper
          initialsurvey={props.initialSurvey}
          targetSurvey={props.targetSurvey}
          isTargetSurvey={props.isTargetSurvey}
          IsSinglePersonPlan={props.isSinglePersonPlan}
          planId={props.planId}
          defaultCO2Emission={props.defaultCO2Emission}
          governmentGoalCO2Emission={props.governmentGoalCO2Emission}
          unchangeableBasicEmission={props.unchangeableBasicEmission}
          {...props.contextData}
        >
          {props.children}
        </CO2ContextWrapper>
      )}
    </>
  );
};
