import { Reducer } from "react";
import { BehaviourRule } from "../definitions/BehaviourRule";
import { AppAction } from "../definitions/Action";
import { AdminBehaviourRulesActionTypes, BehaviourRulesActionTypes } from "../constants/behaviourRules.actiontypes";

export interface BehaviourRulesStoreState {
  rules: BehaviourRule[];
  loading: boolean;
  error: string;
}

export const initialState: BehaviourRulesStoreState = {
  rules: [],
  loading: false,
  error: "",
};

const behaviourRulesReducer: Reducer<BehaviourRulesStoreState, AppAction> = (
  state: BehaviourRulesStoreState = initialState,
  action: AppAction
): BehaviourRulesStoreState => {
  switch (action.type) {
    case AdminBehaviourRulesActionTypes.UPDATE_BEHAVIOUR_RULE:
    case AdminBehaviourRulesActionTypes.CREATE_BEHAVIOUR_RULE:
    case AdminBehaviourRulesActionTypes.REMOVE_BEHAVIOUR_RULE:
    case AdminBehaviourRulesActionTypes.GET_BEHAVIOUR_RULES:
    case BehaviourRulesActionTypes.GET_ACTIVE_BEHAVIOUR_RULES:
      return {
        ...state,
        loading: true,
      };
    case BehaviourRulesActionTypes.GET_ACTIVE_BEHAVIOUR_RULES_SUCCEEDED:
    case AdminBehaviourRulesActionTypes.GET_BEHAVIOUR_RULES_SUCCEEDED:
      return {
        ...state,
        rules: action.payload,
        loading: false,
      };
    case AdminBehaviourRulesActionTypes.CREATE_BEHAVIOUR_RULE_SUCCEEDED:
      return {
        ...state,
        rules: [...state.rules, action.payload],
        loading: false,
      };
    case AdminBehaviourRulesActionTypes.UPDATE_BEHAVIOUR_RULE_SUCCEEDED:
      return {
        ...state,
        rules: state.rules.map((x) => (x.Id === action.payload.Id ? action.payload : x)),
        loading: false,
      };
    case AdminBehaviourRulesActionTypes.REMOVE_BEHAVIOUR_RULE_SUCCEEDED:
      return {
        ...state,
        rules: state.rules.filter((x) => x.Id !== action.payload),
        loading: false,
      };
    case AdminBehaviourRulesActionTypes.ADMIN_BEHAVIOUR_RULE_OPERATION_FAILED:
    case BehaviourRulesActionTypes.BEHAVIOUR_RULE_OPERATION_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default behaviourRulesReducer;
