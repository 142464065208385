import React, { useState, useCallback } from "react";
import {
  Grid,
  Typography,
  ListItem,
  ImageList,
  ImageListItem,
  FormControlLabel,
  Checkbox,
  useTheme,
  useMediaQuery,
  styled,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { CraftsmanTask, CraftsmanTaskStatus, TaskActorRole } from "../../../../definitions/model/ServiceBook";
import { CraftsmanType } from "../../../../definitions/model/Worker";
import { format } from "date-fns";
import daLocale from "date-fns/locale/da";
import { PollCarousel } from "../../../DesignPoll/components/PollCarousel";
import TaskDescription from "./TaskDescription";

const GridStyled = styled(Grid)(() => ({
  paddingTop: 10,
}));

type TaskProps = {
  task: CraftsmanTask;
  number: number;
  showTaskStatusCheckbox: boolean;
  setTaskStatus: (taskId: string, status: CraftsmanTaskStatus) => void;
};

const Task = (props: TaskProps) => {
  const { task, setTaskStatus } = props;
  const { t } = useTranslation("translation");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [activeImage, setActiveImage] = useState(0);

  const handleOpen = useCallback(
    (index: number) => () => {
      setActiveImage(index);
      setOpen(true);
    },
    []
  );
  const handleClose = useCallback(() => setOpen(false), []);

  const changeTaskStatusHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    const newStatus =
      task.Status === CraftsmanTaskStatus.Done ? CraftsmanTaskStatus.InProgress : CraftsmanTaskStatus.Done;
    setTaskStatus(task.Id, newStatus);
  };

  const getActorRoleName = (role: TaskActorRole) => {
    switch (role) {
      case TaskActorRole.Craftsman:
        return t("ServiceBook.Tasks.ACraftsman");
      case TaskActorRole.Customer:
        return t("ServiceBook.Tasks.You");
    }
  };

  const getCraftsmanStatusText = (status: CraftsmanTaskStatus) => {
    switch (status) {
      case CraftsmanTaskStatus.New:
        return t("ServiceBook.Offer.New");
      case CraftsmanTaskStatus.InProgress:
        return t("ServiceBook.Tasks.OnIt");
      case CraftsmanTaskStatus.Done:
        return t("ServiceBook.Tasks.TaskIsDone");
      case CraftsmanTaskStatus.Cancelled:
        return t("ServiceBook.Tasks.Rejected");
      case CraftsmanTaskStatus.Shared:
        return t("ServiceBook.Tasks.Shared");
      default:
        return t("ServiceBook.Offer.New");
    }
  };

  return (
    <ListItem>
      <Grid container item xs={12} sx={{ padding: "20px", boxSizing: "border-box" }}>
        <Typography variant="h6">
          {t("ServiceBook.Tasks.Task")} {props.number}
        </Typography>
        <GridStyled item xs={12} container justifyContent="flex-start">
          <Typography variant="body1" color="secondary">
            {t("ServiceBook.Tasks.Heading")}:&nbsp;
          </Typography>
          <Typography variant="body1" gutterBottom>
            {" "}
            {task.Header}
          </Typography>
        </GridStyled>
        {task.Descriptions.length && (
          <GridStyled item xs={12}>
            <Typography variant="body1" color="primary">
              {t("ServiceBook.Tasks.Description")}:
            </Typography>
            <TaskDescription descriptions={task.Descriptions} />
          </GridStyled>
        )}
        <GridStyled item xs={12}>
          <Typography variant="body1" color="primary">
            {t("ServiceBook.Craftsman", { count: task.Craftsmen.length })}:
          </Typography>
          <Typography variant="body1" gutterBottom>
            {!!task.Craftsmen?.length &&
              task.Craftsmen.map((c) =>
                CraftsmanType.find((x) => x.key === c)?.value
                  ? t(CraftsmanType.find((x) => x.key === c)?.value as string)
                  : ""
              ).join(", ")}
          </Typography>
        </GridStyled>
        {task.Status !== CraftsmanTaskStatus.New && (
          <>
            <GridStyled item xs={12}>
              <Grid item>
                <Typography variant="body1" color="primary">
                  {t("ServiceBook.Tasks.EstimatedPrice")}:
                </Typography>
              </Grid>
              <Typography variant="body1" gutterBottom>
                {(task.EstimatedPrice + task.EstimatedPrice * 0.25).toLocaleString("da-dk", {
                  currency: "DKK",
                  style: "currency",
                })}
              </Typography>
            </GridStyled>
            <GridStyled item xs={12}>
              <Typography variant="body1" color="primary">
                {t("ServiceBook.Tasks.EstimatedMaterialPrice")}:
              </Typography>
              <Typography variant="body1" gutterBottom>
                {(task.EstimatedMaterialPrice + task.EstimatedMaterialPrice * 0.25).toLocaleString("da-dk", {
                  currency: "DKK",
                  style: "currency",
                })}
              </Typography>
            </GridStyled>
            <GridStyled item xs={12}>
              <Typography variant="body1" color="primary">
                {t("ServiceBook.Tasks.EstimatedTime")}:
              </Typography>
              <Typography variant="body1" gutterBottom>
                {task.EstimatedTime}
              </Typography>
            </GridStyled>
            {task.CraftsmanNote && (
              <GridStyled item xs={12}>
                <Typography variant="body1" color="primary">
                  {t("ServiceBook.Tasks.CraftsmanNote")}:
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {task.CraftsmanNote}
                </Typography>
              </GridStyled>
            )}
          </>
        )}
        {!!task.Pictures?.length && (
          <GridStyled item xs={12}>
            <Typography variant="body1" color="primary">
              {t("ServiceBook.Tasks.Pictures")}:
            </Typography>
            <ImageList variant="standard" cols={isMobile ? 2 : 3} gap={8}>
              {task.Pictures.map((x, i) => (
                <ImageListItem key={i} onClick={handleOpen(i)}>
                  <img src={x.Url} alt="" loading="lazy" />
                </ImageListItem>
              ))}
            </ImageList>
          </GridStyled>
        )}
        <Grid sx={{ paddingTop: "10px", display: "flex", flexWrap: "wrap" }}>
          {props.showTaskStatusCheckbox && (
            <FormControlLabel
              control={
                <Checkbox
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  checked={task.Status === CraftsmanTaskStatus.Done}
                  onClick={changeTaskStatusHandler}
                />
              }
              label={t("ServiceBook.Tasks.TaskIsDone")}
            />
          )}
          {new Date(task.StatusChanged).getFullYear() > 1900 && (
            <Typography>
              {t("ServiceBook.Tasks.ChangedTo")} "{getCraftsmanStatusText(props.task.Status)}"{" "}
              {t("ServiceBook.Tasks.Of")} {getActorRoleName(props.task.ActorRole)} {t("ServiceBook.Tasks.On")}{" "}
              {format(new Date(props.task.StatusChanged), `dd MMM yyyy '${t("ServiceBook.Tasks.At")}' HH:mm`, {
                locale: daLocale,
              })}
            </Typography>
          )}
        </Grid>
      </Grid>
      <PollCarousel
        open={open}
        handleClose={handleClose}
        activeStep={activeImage}
        setActiveStep={setActiveImage}
        images={
          task.Pictures?.length
            ? task.Pictures.map((p) => p.Url)
            : task.Descriptions.length
            ? task.Descriptions.reduce<{ Url: string }[]>((a, i) => (i.Pictures ? a.concat(i.Pictures) : a), []).map(
                (p) => p.Url
              )
            : []
        }
      />
    </ListItem>
  );
};

export default Task;
