import ApiService from "./api.service";
import { convertToQueryString } from './converter.service';
import { 
    MapHousesData, 
    MobileTowerInfo, 
    GymnasiumInfo, 
    MarkerType, 
    Neighbors, 
    LocalPlanData,
    Road,
    MarkerParams,
    EcoBusinessInfo,
    EcoBusinessesOnMap
} from "../definitions/Map"
import { RiskModel } from "../definitions/model/unit";

const MapService = {
    getSoldUnits: async (unitId: string): Promise<MapHousesData[]> => {
        const url = `/api/map/${unitId}/neighbors-prices`
        const result = await ApiService.get<MapHousesData[]>(url, {});

        return result;
    },
    getEstimationUnits: async (unitId: string): Promise<MapHousesData[]> => {
        const url = `/api/map/${unitId}/estimations`
        const result = await ApiService.get<any>(url);

        return (
            result.map((houses: any) => {
                const data = new MapHousesData();

                data.House = houses.Estimations.map((house: any) => {
                    return ({
                        Address: house.Address,
                        PostalCode: house.PostalCode,
                        City: house.City,
                        PropertyType: house.PropertyType,
                        PictureUrl: house.SmallPictureUrl,
                        AddressUrl: house.AddressURLPart,
                        Area: house.Area,
                        GroundArea: house.GroundArea,
                        Rooms: house.Rooms,
                        BuildYear: house.BuildYear,
                        Price: house.EstimatedPrice
                    })
                })
                data.Latitude = houses.Latitude
                data.Longitude = houses.Longtitude

                return data
            })
        )
    },
    getRoadPlans: async (unitId: string): Promise<Road[]> => {
        const url = `/api/map/${unitId}/roadplans`
        const result = await ApiService.get<Road[]>(url);
        return result;
    },
    getPlans: async (unitId: string): Promise<LocalPlanData[]> => {
        const url = `/api/map/${unitId}/plans`
        const result = await ApiService.get<LocalPlanData[]>(url);
        return result;
    },
    getNeighbors: async (unitId: string): Promise<Neighbors[]> => {
        const url = `/api/map/${unitId}/neighbors`
        const result = await ApiService.get<any>(url, {});
        const parsedResult = result?.neighboursData;
        return parsedResult;
    },
    getBusStations: async (params: MarkerParams):Promise<MarkerType[]> => {
        const queryString = convertToQueryString(params)
        const url = `/api/map/GetBusStationMarkers?${queryString}`
        const result = await ApiService.get<{
            Count: number,
            Markers: MarkerType[]
        }>(url, {});
        return result.Markers;
    },
    getMobileTowers: async (params: MarkerParams):Promise<MarkerType[]> => {
        const queryString = convertToQueryString(params)
        const url = `/api/map/GetAntennaMarkers?${queryString}`
        const result = await ApiService.get<{
            Count: number,
            Markers: MarkerType[]
        }>(url, {});
        return result.Markers;
    },
    getGymnasiums: async (params: MarkerParams): Promise<MarkerType[]> => {
        const queryString = convertToQueryString(params)
        const url = `/api/map/GetInstitutionMarkers?${queryString}`
        const result = await ApiService.get<{
            Count: number,
            Markers: MarkerType[]
        }>(url, {});
        return result.Markers;
    },
    getEcoBusinesses: async (params: MarkerParams): Promise<EcoBusinessesOnMap> => {
        const queryString = convertToQueryString(params)
        const url = `/api/map/GetEcoBusinessMarkers?${queryString}`
        const result = await ApiService.get<EcoBusinessesOnMap>(url, {});
        return result;
    },
    getMobileTowerInfo: async (id: string): Promise<MobileTowerInfo> => {
        const url = `/api/map/GetAntennaModel?Id=${id}`
        const result = await ApiService.get<any>(url, {})
        const parsedResult = JSON.parse(result?.Antenna)
        return parsedResult;
    },
    getGymnasiumInfo: async (id: string, unitId: string): Promise<GymnasiumInfo> => {
        const url = `/api/map/getinstitutionmodel?id=${id}&unitId=${unitId}`
        const result = await ApiService.get<any>(url, {})
        const parsedResult = JSON.parse(result?.School)
        return parsedResult;
    },
    getLayerInfo: async (lat: number, lng: number, type: string): Promise<any> => {
        const url = `/api/map/GetLayerInfo`
        const result = await ApiService.post<any>(url, {lat, lng, type}, {})
        const parsedResult = JSON.parse(result?.LayerInfo)
        return parsedResult;
    },
    getMovedIn: async (): Promise<any> => {
        const url = '/api/admin/users/movedin'
        const result = await ApiService.get<any>(url)
        return result
    },
    getUnitRisks: async (unitId: string): Promise<RiskModel> => {
        const url = `/api/map/${unitId}/risk-flags`;
        const result = await ApiService.get<RiskModel>(url);
        return result;
    },
    getEcoBusinessInfo: async (id: string, longitude: number, latitude: number): Promise<EcoBusinessInfo> => {
        const url = `/api/map/getecobusinessinfo?id=${id}&longitude=${longitude}&latitude=${latitude}`
        const result = await ApiService.get<any>(url, {})
        return result.EcoBusiness;
    },
}

export default MapService
