import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "./assets/translations.json";
import climatplanTranslations from "./pages/ClimatePlan/shared/ClimatplanTranslations.json";

const finalTr = {
  da: { translation: { ...translations.da.translation, ...climatplanTranslations.da.translation } },
  en: { translation: { ...translations.en.translation, ...climatplanTranslations.en.translation } },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: finalTr,
    lng: "da",

    //keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
