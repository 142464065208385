export const NeighborGroupConstants = {
    GET_NEIGHBORGROUPS: "GET_NEIGHBORGROUPS",
    GET_NEIGHBORGROUPS_SUCCEEDED: "GET_NEIGHBORGROUPS_SUCCEEDED",
    GET_NEIGHBORGROUP: "GET_NEIGHBORGROUP",
    GET_NEIGHBORGROUP_SUCCEEDED: "GET_NEIGHBORGROUP_SUCCEEDED",
    CREATE_NEIGHBORGROUP: "CREATE_NEIGHBORGROUP",
    CREATE_NEIGHBORGROUP_SUCCEEDED: "CREATE_NEIGHBORGROUP_SUCCEEDED",
    UPDATE_NEIGHBORGROUP: "UPDATE_NEIGHBORGROUP",
    UPDATE_NEIGHBORGROUP_SUCCEEDED: "UPDATE_NEIGHBORGROUP_SUCCEEDED",
    DELETE_NEIGHBORGROUP: "DELETE_NEIGHBORGROUP",
    DELETE_NEIGHBORGROUP_SUCCEEDED: "DELETE_NEIGHBORGROUP_SUCCEEDED",
    JOIN_NEIGHBORGROUP: "JOIN_NEIGHBORGROUP",
    JOIN_NEIGHBORGROUP_SUCCEEDED: "JOIN_NEIGHBORGROUP_SUCCEEDED",
    LEAVE_NEIGHBORGROUP: "LEAVE_NEIGHBORGROUP",
    LEAVE_NEIGHBORGROUP_SUCCEEDED: "LEAVE_NEIGHBORGROUP_SUCCEEDED",
    ADD_ANNOUNCEMENT : "ADD_ANNOUNCEMENT",
    ADD_ANNOUNCEMENT_SUCCEEDED : "ADD_ANNOUNCEMENT_SUCCEEDED",
    REMOVE_ANNOUNCEMENT : "REMOVE_ANNOUNCEMENT",
    REMOVE_ANNOUNCEMENT_SUCCEEDED : "REMOVE_ANNOUNCEMENT_SUCCEEDED",
    EDIT_ANNOUNCEMENT : "EDIT_ANNOUNCEMENT",
    EDIT_ANNOUNCEMENT_SUCCEEDED : "EDIT_ANNOUNCEMENT_SUCCEEDED",
    GET_NEIGHBORGROUP_MEMBERS: "GET_NEIGHBORGROUP_MEMBERS",
    GET_NEIGHBORGROUP_MEMBERS_SUCCEEDED: "GET_NEIGHBORGROUP_MEMBERS_SUCCEEDED",
    NEW_ANNOUNCEMENT_RECEIVED: "NEW_ANNOUNCEMENT_RECEIVED",
    ANNOUNCEMENT_UPDATED: "ANNOUNCEMENT_UPDATED",
    ANNOUNCEMENT_REMOVED: "ANNOUNCEMENT_REMOVED",
    CLEAR_ANNOUNCEMENT_NOTIFICATION: "CLEAR_ANNOUNCEMENT_NOTIFICATION",
    ADD_SUBSCRIBER: "ADD_SUBSCRIBER",
    ADD_SUBSCRIBER_SUCCEEDED: "ADD_SUBSCRIBER_SUCCEEDED",
    REMOVE_SUBSCRIBER: "REMOVE_SUBSCRIBER",
    REMOVE_SUBSCRIBER_SUCCEEDED: "REMOVE_SUBSCRIBER_SUCCEEDED",
    SET_NEW_ANNOUNCEMENTS: "SET_NEW_ANNOUNCEMENTS",
    UPDATE_SUBSCRIBERS: "UPDATE_SUBSCRIBERS",
    UPDATE_SUBSCRIBERS_SUCCEEDED: "UPDATE_SUBSCRIBERS_SUCCEEDED",
    ADD_GROUP_ADMIN: "ADD_GROUP_ADMIN",
    ADD_GROUP_ADMIN_SUCCEEDED: "ADD_GROUP_ADMIN_SUCCEEDED",
    INVITE_TO_GROUP: "INVITE_TO_GROUP",
    INVITE_TO_GROUP_SUCCEEDED: "INVITE_TO_GROUP_SUCCEEDED",
    REMOVE_FROM_GROUP: "REMOVE_FROM_GROUP",
    REMOVE_FROM_GROUP_SUCCEEDED: "REMOVE_FROM_GROUP_SUCCEEDED",
    UPDATE_GROUP_PICTURE: "UPDATE_GROUP_PICTURE",
    UPDATE_GROUP_PICTURE_SUCCEEDED: "UPDATE_GROUP_PICTURE_SUCCEEDED",
    UPDATE_GROUP_DOCUMENTS: "UPDATE_GROUP_DOCUMENTS",
    UPDATE_GROUP_DOCUMENTS_SUCCEEDED: "UPDATE_GROUP_DOCUMENTS_SUCCEEDED",
    UPDATE_MEMBER_SETTINGS: "UPDATE_MEMBER_SETTINGS",
    UPDATE_MEMBER_SETTINGS_SUCCEEDED: "UPDATE_MEMBER_SETTINGS_SUCCEEDED",
    SET_EDITOR_GROUP: "SET_EDITOR_GROUP",
    SET_EDITOR_GROUP_SUCCEEDED: "SET_EDITOR_GROUP_SUCCEEDED",
    
    UPDATE_MEMBER_GROUP_VISITED_DATE_SUCCEEDED: "UPDATE_MEMBER_GROUP_VISITED_DATE_SUCCEEDED",
    UPDATE_GROUP_NEW_MESSAGES: "UPDATE_GROUP_NEW_MESSAGES",
}