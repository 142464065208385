import React from "react";
import ReactDOM from "react-dom/client";
import { GrapesEditor, GrapesTypes, LoadComponentOptions } from "../../defenitions";

export default function (editor: GrapesEditor, options: LoadComponentOptions): void {
  const domComponents = editor.DomComponents;
  const blockManager = editor.BlockManager;
  const defaultType = domComponents.getType("default");
  const { model, view } = defaultType;

  blockManager.add(GrapesTypes.UnsubscribeLinkComponent, {
    label: "Unsubscribe",
    category: options.categoryLabel,
    attributes: {
      class: "fa fa-link",
      "data-gjs-type": `${GrapesTypes.UnsubscribeLinkComponent}`,
    },
    content: `<span data-gjs-type="${GrapesTypes.UnsubscribeLinkComponent}">Unsubscribe</span>`,
  });

  domComponents.addType(GrapesTypes.UnsubscribeLinkComponent, {
    isComponent(el: HTMLElement) {
      if (
        (el.getAttribute && el.getAttribute("data-gjs-type") === GrapesTypes.UnsubscribeLinkComponent) ||
        (el.attributes && (el.attributes as any)["data-gjs-type"] === GrapesTypes.UnsubscribeLinkComponent)
      ) {
        return {
          type: GrapesTypes.UnsubscribeLinkComponent,
          content: el.innerHTML,
          components: [],
        };
      }
    },
    model: {
      defaults: {
        ...model.prototype.defaults,
        // tagName: "unsubscribe",
        droppable: true,
        draggable: "*",
        // type: "text",
      },
    },
    view: view.extend({
      // init() {
      //   this.listenTo(this.model, "change:label", this.handleChanges);
      // },

      // handleChanges() {
      //   ReactDOM.unmountComponentAtNode(this.el);
      //   this.render();
      // },

      onRender({ el }: { el: HTMLElement }) {
        const comps = this.model.get("components");
        comps.reset();
        const compString = `<unsubscribe data-gjs-type="${GrapesTypes.UnsubscribeLinkComponent}">Configure link</unsubscribe>`;
        comps.add(compString);
        const root = ReactDOM.createRoot(el);

        root.render(<span data-gjs-type={`${GrapesTypes.UnsubscribeLinkComponent}`}>Configure link</span>);
      },
    }),
  });
}
