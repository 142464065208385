import ApiService from "./api.service";
import {
  ProductModel,
  UpdateProductContract,
  PaymentChargeContract,
  SubscriptionModel,
  CreateProductRequest,
  CreditCard,
  CreatePriceRequest,
  ProductPriceDTO
} from "../definitions/model/Subscriptions";
import { SearchResult } from "../definitions/model/SearchResult";


const SubscriptionsService = {
  processSucceededPayment: async (
    data: PaymentChargeContract
  ): Promise<unknown> => {
    const url = `/api/subscriptions`;
    const result = await ApiService.post(url, data);
    return result;
  },
  getProduct: async (id: string): Promise<ProductModel> => {
    const url = `/api/subscriptions/product/${id}`;
    const result = await ApiService.get<ProductModel>(url);

    return result;
  },
  getProducts: async (name: string, skip: number, take: number, isDescending: boolean, sortingColumn: string): Promise<SearchResult<ProductModel[]>> => {
    const url = `/api/subscriptions/product?name=${name}&sortingColumn=${sortingColumn}&isDescending=${isDescending}&skip=${skip}&take=${take}`;
    const result = await ApiService.get<SearchResult<ProductModel[]>>(url);
    return result;
  },
  createProduct: async (data: CreateProductRequest): Promise<ProductModel> => {
    const url = `/api/subscriptions/product`;
    const result = await ApiService.post<ProductModel>(url, data);
    return result;
  },
  updateProduct: async (data: UpdateProductContract): Promise<ProductModel> => {
    const url = `/api/subscriptions/product/${data.Id}`;
    const result = await ApiService.put<ProductModel>(url, data);
    return result;
  },
  deleteProduct: async (productId: string): Promise<ProductModel> => {
    const url = `/api/subscriptions/product/${productId}`;
    const result = await ApiService.delete<ProductModel>(url);
    return result;
  },
  getProductPrices: async (productId: string): Promise<ProductPriceDTO[]> => {
    const url = `/api/subscriptions/product/${productId}/prices`;
    const result = await ApiService.get<ProductPriceDTO[]>(url);
    return result;
  },
  createProductPrice: async (data: CreatePriceRequest): Promise<ProductPriceDTO> => {
    const url = `/api/subscriptions/product/${data.ProductId}/prices`;
    const result = await ApiService.post<ProductPriceDTO>(url, data);
    return result;
  },
  getUserSubscriptions: async (): Promise<SubscriptionModel[]> => {
    const url = `/api/subscriptions`;
    const result = await ApiService.get<SubscriptionModel[]>(url);
    return result;    
  },
  getProductSubscriptions: async (
    productId: string,
    skip: number,
    take: number,
    isDescending: boolean,
    sortingColumn: string,
    searchTerm: string
  ): Promise<SearchResult<SubscriptionModel>> => {
    const url = 
      `/api/admin/subscriptions/by-product/${productId}?skip=${skip}&take=${take}&isDescending=${isDescending}&sortingColumn=${sortingColumn}&searchTerm=${searchTerm}`;
    const result = await ApiService.get<SearchResult<SubscriptionModel>>(url);
    return result;
  },
  cancelUserSubscription: async (subscriptionId: string): Promise<SubscriptionModel> => {
    const url = `/api/subscriptions/${subscriptionId}`;
    const result = await ApiService.delete<SubscriptionModel>(url);
    return result;
  },
  getUserCards: async (): Promise<CreditCard[]> => {
    const url = `/api/subscriptions/customer/cards`;
    const result = await ApiService.get<CreditCard[]>(url);
    return result;
  },
  addUserCard: async (token: string, email: string): Promise<CreditCard> => {
    const url = `/api/subscriptions/customer/cards/${token}?email=${email}`;
    const result = await ApiService.put<CreditCard>(url,{});
    return result;
  },
  changeDefaultCard: async (token: string) : Promise<CreditCard[]> => {
    const url = `/api/subscriptions/customer/cards/${token}`;
    const result = await ApiService.post<CreditCard[]>(url,{});
    return result;
  },
  removeUserCard: async (token: string): Promise<CreditCard> => {
    const url = `/api/subscriptions/customer/cards/${token}`
    const result = await ApiService.delete<CreditCard>(url);
    return result;
  },
  createTestSubscription: async (email: string, productId: string, priceId: string): Promise<SubscriptionModel> => {
    const url = `/api/admin/subscriptions/test`;
    const result = await ApiService.put<SubscriptionModel>(url,{
      Email: email,
      ProductId: productId,
      PriceId: priceId
    });
    return result;
  }
};

export default SubscriptionsService;
