import React, { useState } from "react";
import { TextField, TextFieldProps, InputAdornment, IconButton, styled } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const TextFieldStyled = styled(TextField, {
  shouldForwardProp: (propName: string) => propName !== "multiline" && propName !== "isSmall",
})<{ multiline: boolean | undefined; isSmall: boolean }>(({ theme, multiline, isSmall }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: multiline ? 30 : theme.shape.borderRadius,
  },
  "& .MuiOutlinedInput-input": {
    padding: isSmall ? "12px 20px" : '',
    "&[type=password]": {
      fontSize: 20,
      padding: "10px 20px",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "10px 5px",
    },
  },

  "&. MuiOutlinedInput-inputMultiline": {
    padding: isSmall ? "7px 0" : "2px 0",
    [theme.breakpoints.only("xs")]: {
      "& textarea::placeholder": {
        fontSize: 14,
      },
    },
  },
}));

export default function MyhouseTextField({ variant, type, classes, InputProps, size, ...other }: TextFieldProps) {
  const [visibility, setVisibility] = useState(type !== "password");
  const toggleVisibility = () => setVisibility(!visibility);

  return (
    <TextFieldStyled
      variant="outlined"
      classes={classes}
      type={visibility ? "text" : "password"}
      multiline={other.multiline}
      isSmall={size === "medium"}
      placeholder={other.placeholder}
      label={other.label}
      InputProps={{
        endAdornment:
          type && type === "password" ? (
            <InputAdornment position="end">
              <IconButton arial-label="toggle password visibility" onClick={toggleVisibility} edge="end" size="large">
                {visibility ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ) : null,
        ...InputProps,
        classes: {
          ...InputProps?.classes,
        },
      }}
      {...other}
    ></TextFieldStyled>
  );
}
