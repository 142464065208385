import { Reducer } from "redux";

import { AppAction } from "../../definitions/Action";
import { WorkersActionTypes } from "./workers.actiontypes";
import { WorkerLinkRequest, WorkerModel, WorkerRatingDetails } from "../../definitions/model/Worker";
import { UserConstants } from "../../constants";

export interface WorkersStoreState {
  loading: boolean;
  error: string | null;
  workers: WorkerModel[];
  unitWorkers: WorkerModel[];
  buildingWorkers: WorkerModel[];
  unitWorkersLoaded: boolean;
  buildingWorkersLoaded: boolean;
  workerRequestsLoaded: boolean;
  userWorkers: WorkerModel[];
  workerRequests: WorkerLinkRequest[];
}

export const initialState: WorkersStoreState = {
  loading: false,
  error: null,
  workers: [],
  unitWorkers: [],
  buildingWorkers: [],
  unitWorkersLoaded: false,
  buildingWorkersLoaded: false,
  workerRequestsLoaded: false,
  userWorkers: [],
  workerRequests: [],
};

const workersReducer: Reducer<WorkersStoreState, AppAction> = (
  state: WorkersStoreState = initialState,
  action: AppAction
): WorkersStoreState => {
  switch (action.type) {
    case WorkersActionTypes.GET_WORKERS:
      return { ...state, loading: true };
    case WorkersActionTypes.GET_UNIT_WORKERS:
      return { ...state, unitWorkersLoaded: false, loading: true };
    case WorkersActionTypes.GET_BUILDING_WORKERS:
      return { ...state, buildingWorkersLoaded: false, loading: true };
    case WorkersActionTypes.LINK_WORKER_TO_UNIT:
    case WorkersActionTypes.UNLINK_WORKER_FROM_UNIT:
    case WorkersActionTypes.LINK_WORKER_TO_BUILDING:
    case WorkersActionTypes.UNLINK_WORKER_FROM_BUILDING:
    case WorkersActionTypes.CREATE_WORKER:
      return { ...state, loading: false, error: null };
    case WorkersActionTypes.GET_WORKERS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        workers: action.payload.Results,
      };
    case WorkersActionTypes.GET_WORKERS_ADDITIONAL_SUCCEEDED:
      return {
        ...state,
        loading: false,
        workers: [...state.workers, ...action.payload.Results],
      };
    case WorkersActionTypes.GET_UNIT_WORKERS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        unitWorkersLoaded: true,
        unitWorkers: action.payload,
      };
    case WorkersActionTypes.GET_BUILDING_WORKERS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        buildingWorkersLoaded: true,
        buildingWorkers: action.payload,
      };
    case WorkersActionTypes.GET_UNIT_WORKERS_RATINGS_SUCCEEDED:
      return {
        ...state,
        unitWorkers: state.unitWorkers.map((worker) => {
          const rating = action.payload.filter((r: WorkerRatingDetails) => r.WorkerId === worker.Id);
          if (rating.length > 0) {
            return {
              ...worker,
              Rating: rating[0].Rating,
              RatingFromCurrentUser: rating[0].RatingFromCurrentUser,
              CommentFromCurrentUser: rating[0].CommentFromCurrentUser,
            };
          } else {
            return worker;
          }
        }),
      };
    case WorkersActionTypes.LINK_WORKER_TO_UNIT_SUCCEEDED:
    case WorkersActionTypes.CREATE_WORKER_SUCCEEDED:
      return {
        ...state,
        loading: false,
        unitWorkers: [...state.unitWorkers, action.payload],
      };
    case WorkersActionTypes.UNLINK_WORKER_FROM_UNIT_SUCCEEDED:
      return {
        ...state,
        loading: false,
        unitWorkers: state.unitWorkers.filter((x) => x.Id !== action.payload.Id),
      };
    case WorkersActionTypes.LINK_WORKER_TO_BUILDING_SUCCEEDED:
      return {
        ...state,
        loading: false,
        buildingWorkers: [...state.buildingWorkers, action.payload],
      };
    case WorkersActionTypes.UNLINK_WORKER_FROM_BUILDING_SUCCEEDED:
      return {
        ...state,
        loading: false,
        buildingWorkers: state.buildingWorkers.filter((x) => x.Id !== action.payload.Id),
      };
    case WorkersActionTypes.RATE_UNIT_WORKER_SUCCEEDED:
      return {
        ...state,
        unitWorkers: state.unitWorkers.map((worker) =>
          worker.Id === action.payload.workerId
            ? {
                ...worker,
                RatingFromCurrentUser: action.payload.rating,
                CommentFromCurrentUser: action.payload.comment,
                Rating: action.payload.rating,
              }
            : worker
        ),
        workers: state.workers.map((worker) =>
          worker.Id === action.payload.workerId
            ? {
                ...worker,
                RatingFromCurrentUser: action.payload.rating,
                CommentFromCurrentUser: action.payload.comment,
                Rating: action.payload.rating,
              }
            : worker
        ),
      };
    case WorkersActionTypes.RATE_BUILDING_WORKER_SUCCEEDED:
      return {
        ...state,
        buildingWorkers: state.buildingWorkers.map((worker) =>
          worker.Id === action.payload.workerId
            ? {
                ...worker,
                RatingFromCurrentUser: action.payload.rating,
                CommentFromCurrentUser: action.payload.comment,
                Rating: action.payload.rating,
              }
            : worker
        ),
        workers: state.workers.map((worker) =>
          worker.Id === action.payload.workerId
            ? {
                ...worker,
                RatingFromCurrentUser: action.payload.rating,
                CommentFromCurrentUser: action.payload.comment,
                Rating: action.payload.rating,
              }
            : worker
        ),
      };
    case WorkersActionTypes.GET_WORKER_LINK_REQUEST_SUCCEEDED:
      return {
        ...state,
        workerRequestsLoaded: true,
        workerRequests: action.payload,
      };

    case UserConstants.CHANGE_USER_CURRENT_HOUSE_SUCCEEDED:
      return {
        ...initialState,
        workers: state.workers,
        userWorkers: state.userWorkers,
      };

    case WorkersActionTypes.WORKERS_OPERATION_FAILED:
      return {
        ...state,
        loading: false,
        unitWorkersLoaded: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default workersReducer;
