import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {styled} from "@mui/material";

const LoadingSpinnerContainer = styled("div")(()=>({
  position: "fixed",
  zIndex: 1300,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(0,0,0,0.5)"
}))

type LoadingOverlayProps = {
    loading: boolean;
    value?: number;
}

const LoadingOverlay = ({ loading, value } :LoadingOverlayProps) => {
  return loading ? (
    <LoadingSpinnerContainer>
       {value ? (
        <CircularProgress variant="determinate" value={value} />
      ) : (
        <CircularProgress />
      )}
    </LoadingSpinnerContainer>
  ) : null;
};

export default LoadingOverlay;
