import { NewsStreamDTO } from "../../definitions/NewsStream";
import { Reducer } from "react";
import { AppAction } from "../../definitions/Action";
import { NewsStreamConstants } from "../../constants";
import { CommentConstants } from '../../constants/comment.constants';

export interface NewsStreamStoreState {
  news: NewsStreamDTO[];
  oneNews: NewsStreamDTO | null;
  loading: boolean;
  newsLoaded: boolean;
  error: string | null;
}

export const initialState: NewsStreamStoreState = {
  news: [],
  oneNews: null,
  loading: false,
  newsLoaded: false,
  error: "",
};

const NewsStreamReducer: Reducer<NewsStreamStoreState, AppAction> = (
  state: NewsStreamStoreState = initialState,
  action: AppAction
) => {
  switch (action.type) {
    case NewsStreamConstants.GET_NEWS:
    case NewsStreamConstants.GET_NEWS_DETAILS:
    case CommentConstants.UPDATE_COMMENT:
    case CommentConstants.GET_COMMENTS:
    case CommentConstants.POST_COMMENT:
    case CommentConstants.DELETE_COMMENT:
      return { ...state, loading: true };
    case NewsStreamConstants.GET_NEWS_SUCCEEDED:
      return {
        ...state,
        news: [...state.news, ...action.payload.news],
        newsLoaded: action.payload.newsLoaded,
        loading: false,
      };
    case NewsStreamConstants.GET_NEWS_DETAILS_SUCCEEDED:
      return {
        ...state,
        oneNews: action.payload,
        loading: false,
      };
    case NewsStreamConstants.UPDATE_NEWSITEM_SUCCEEDED:
      return {
        ...state,
        news: state.news.map((x) => (x.ActionModel.ActionId === action.payload.Id ? action.payload : x)),
        loading: false,
      };
    case NewsStreamConstants.CLEAR_NEWS: 
      return {
        ...state,
        news: []
      };
    case NewsStreamConstants.NEWSSTREAM_OPERATION_FAILED:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

export default NewsStreamReducer;
