import { AppThunkDispatch } from "../definitions/Action";
import { FamilyConstatns } from "../constants/family.actiontypes";
import FamilyService from "../services/family.service";
import { GroupMemberDto } from "../definitions/model/Group";
import { GroupMemberRole } from "../constants/enums";
import { operationFailedActionGeneral, useAppDispatch } from ".";
import { useSelector } from "react-redux";
import { ApplicationState } from "../reducers/store";

const operationFailedAction = (payload: unknown) =>
  operationFailedActionGeneral(payload, FamilyConstatns.FAMILY_OPERATION_FAILED);

export const getUserFamilies = (unitId: string | null) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({ type: FamilyConstatns.GET_USER_FAMILIES });
    const result = await FamilyService.getUserFamily(unitId);
    dispatch({
      type: FamilyConstatns.GET_USER_FAMILIES_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

const getUserOneFamily = (unitId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({ type: FamilyConstatns.GET_USER_FAMILY });
    const result = await FamilyService.getUserOneFamily(unitId);
    dispatch({
      type: FamilyConstatns.GET_USER_FAMILY_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

export const assignNewFamilyOwner =
  (newOwner: GroupMemberDto, unitId: string) => async (dispatch: AppThunkDispatch) => {
    try {
      if (newOwner.Role === GroupMemberRole.NotValidMember) {
        await dispatch(acceptFamilyJoinRequest(newOwner.UserId, unitId));
      }

      dispatch({ type: FamilyConstatns.ASSIGN_NEW_FAMILYOWNER });

      const result = await FamilyService.assingNewFamilyOwner(unitId, newOwner.UserId);
      dispatch({ type: FamilyConstatns.ASSIGN_NEW_FAMILYOWNER_SUCCEEDED, payload: result });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const sendFamilyJoinRequest = (unitId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: FamilyConstatns.SEND_FAMILY_JOIN_REQUEST,
    });
    const result = await FamilyService.sendFamilyJoinRequest(unitId);
    dispatch({
      type: FamilyConstatns.SEND_FAMILY_JOIN_REQUEST_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const acceptFamilyJoinRequest = (userId: string, unitId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: FamilyConstatns.ACCEPT_FAMILY_JOIN_REQUEST,
    });

    const result = await FamilyService.acceptFamilyJoinRequest(userId, unitId);
    dispatch({
      type: FamilyConstatns.ACCEPT_FAMILY_JOIN_REQUEST_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

export const declineFamilyJoinRequest = (userId: string, unitId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: FamilyConstatns.DECLINE_FAMILY_JOIN_REQUEST,
    });

    const result = await FamilyService.declineFamilyJoinRequest(userId, unitId);

    dispatch({
      type: FamilyConstatns.DECLINE_FAMILY_JOIN_REQUEST_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

export const removeFamilyMember =
  (userId: string, unitId: string, buildingGroupId?: string) => async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({
        type: FamilyConstatns.REMOVE_FAMILY_MEMBER,
      });

      const result = await FamilyService.removeFamilyMember(userId, unitId, buildingGroupId);

      dispatch({
        type: FamilyConstatns.REMOVE_FAMILY_MEMBER_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const acceptInviteMember = (unitId: string, token: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: FamilyConstatns.ACCEPT_INVITE_MEMBER,
    });

    const result = await FamilyService.acceptInviteMember(unitId, token);

    dispatch({
      type: FamilyConstatns.ACCEPT_INVITE_MEMBER_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

const useFamilyState = () => useSelector((state: ApplicationState) => state.family);

const useFamilyActions = () => {
  const dispatch = useAppDispatch();
  return {
    getUserFamily: (id: string) => dispatch(getUserFamilies(id)),
    getUserFamilies: (unitId: string | null) => dispatch(getUserFamilies(unitId)),
    getUserOneFamily: (unitId: string) => dispatch(getUserOneFamily(unitId)),
    acceptFamilyJoinRequest: (userId: string, unitId: string) => dispatch(acceptFamilyJoinRequest(userId, unitId)),
    declineFamilyJoinRequest: (userId: string, unitId: string) => dispatch(declineFamilyJoinRequest(userId, unitId)),
    removeFamilyMember: (userId: string, unitId: string, buildingGroupId?: string) =>
      dispatch(removeFamilyMember(userId, unitId, buildingGroupId)),
    acceptInviteMember: (unitId: string, token: string) => dispatch(acceptInviteMember(unitId, token)),
  };
};

export const useFamily = (): [ReturnType<typeof useFamilyState>, ReturnType<typeof useFamilyActions>] => {
  const state = useFamilyState();
  const actions = useFamilyActions();
  return [state, actions];
};
