import { Box } from "@mui/system";
import React from "react";
import { useAlertContext } from "./MyhouseAlert";
import { useTranslation } from "react-i18next";

type FileDroppableProps = {
  children: React.ReactNode;
  dropAction: (files: File[]) => Promise<void>;
  handleDragOver: (dragOver: boolean) => void;
};

const FileDroppable = (props: FileDroppableProps) => {
  const alert = useAlertContext();
  const { t } = useTranslation();

  const preventDefaultHandler = (e: React.DragEvent<HTMLElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Box sx={{ width: "100%"}}
      onDragOver={(e) => {
        preventDefaultHandler(e);
        props.handleDragOver(true);
      }}
      onDragEnter={(e) => {
        preventDefaultHandler(e);
        props.handleDragOver(true);
      }}
      onDragLeave={(e) => {
        preventDefaultHandler(e);
        props.handleDragOver(false);
      }}
      onDrop={async (e) => {
        preventDefaultHandler(e);
        const files = Array.from(e.dataTransfer.files);
        if (files.length === 0) {
          alert.showAlert({ severity: "error", text: t("Documents.Messages.OperationNotSupported") });
          props.handleDragOver(false);
          return;
        }
        await props.dropAction(files);
        props.handleDragOver(false);
      }}
    >
      {props.children}
    </Box>
  );
};

export default FileDroppable;
