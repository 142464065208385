import React from "react";
import {
  Delete,
  Edit,
  StarHalf,
  FileCopy,
  NotInterested,
  Public,
  Link,
  Verified,
  Pageview,
} from "@mui/icons-material";
import { IconButton, TableCell, Tooltip } from "@mui/material";
import { Column, Icon } from "./AdminTable";
import ConfirmDialog from "../../../../components/Dialogs/ConfirmDialog";

type Props<T> = {
  column: Column<T>;
  data: T;
  isIndividualCell?: boolean;
};

export const AdminTableCellIcon = <T,>({
  column,
  data,
  isIndividualCell,
}: Props<T>) => {
  const renderIcon = (icon: Icon) => {
    switch (icon) {
      case Icon.Edit:
        return (
          <IconButton onClick={() => column.callback?.(data)} size="large">
            <Edit />
          </IconButton>
        );
      case Icon.Rating:
        return (
          <IconButton onClick={() => column.callback?.(data)} size="large">
            <StarHalf />
          </IconButton>
        );
      case Icon.Delete:
        return (
          <IconButton size="large" sx={{ fontSize: "0px" }}>
            <ConfirmDialog
              text="Are you sure want to delete this item?"
              OkClickHandler={() =>  {
                column.callback?.(data)
              }}
            >
              <Delete />
            </ConfirmDialog>
          </IconButton>
        );
      case Icon.Copy:
        return (
          <IconButton onClick={() => column.callback?.(data)} size="large">
            <FileCopy />
          </IconButton>
        );
      case Icon.Disable:
        return (
          <IconButton onClick={() => column.callback?.(data)} size="large">
            <NotInterested />
          </IconButton>
        );
      case Icon.Public:
        return (
          <IconButton onClick={() => column.callback?.(data)} size="large">
            <Public />
          </IconButton>
        );
      case Icon.Link:
        return (
          <IconButton onClick={() => column.callback?.(data)} size="large">
            <Link />
          </IconButton>
        );
      case Icon.Verified:
        return (
          <IconButton onClick={() => column.callback?.(data)} size="large">
            <Verified />
          </IconButton>
        );
      case Icon.Pageview:
        return (
          <IconButton onClick={() => column.callback?.(data)} size="large">
            <Pageview />
          </IconButton>
        );

      case Icon.Invisible:
        return (
          <IconButton
            size="large"
            sx={{
              visibility: "hidden",
              width: "0px",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            <NotInterested />
          </IconButton>
        );

      default:
        return <></>;
    }
  };

  if (isIndividualCell) {
    return (
      <TableCell align="center" style={{ width: 115 }}>
        {renderIcon(column.icon!)}
      </TableCell>
    );
  } else {
    return (
      <Tooltip title={column.name || ""}>{renderIcon(column.icon!)}</Tooltip>
    );
  }
};
