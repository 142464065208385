import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Link,
  useMediaQuery,
  useTheme,
  styled,
  Card,
  CardContent,
  List,
  ListItem,
  Skeleton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { NewsStreamDTO } from "../../../definitions/NewsStream";
import { useNewsstreamActions } from "../../NewsStream/newsstream.actions";
import { repeatSkeleton } from "../../../components/Base/DetailsSkeleton";

const TAKE = 5;

const StyledNewsCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900] + 70,
  color: theme.palette.background.default,
  width: "100%",
  height: "420px",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    maxHeight: "420px",
  },
}));

const StyledCardContent = styled(CardContent)(() => ({
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  justifyContent: "space-between",
}));

const StyledListItem = styled(ListItem)(() => ({
  "& .MuiListItemSecondaryAction-root": {
    height: "100%",
    paddingTop: 8,
  },
}));
const StyledLink = styled(Link)(() => ({
  display: "-webkit-box",
  WebkitLineClamp: "2",
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));


const NewsComponent = (): JSX.Element => {
  const { t } = useTranslation("translation");
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const newsActions = useNewsstreamActions();
  const [news, setNews] = useState<NewsStreamDTO[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAsync = async () => {
      try {
        const result = await newsActions.getNews(0, 0, 0, TAKE, null, new Date());
        setNews(result);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    getAsync();

    return () => {};
  }, []);




  return (
   
            <Grid item sx={{ maxWidth: isSm ? "100%" : "380px", width: "100%" }}>
              <StyledNewsCard>
                <StyledCardContent>
                  <Typography color="inherit" variant="h5">
                    {t("NewsStream.News")}
                  </Typography>
                  {!loading ? (
                    <List disablePadding>
                      {news.length
                        ? news.map((item) => (
                            <StyledListItem
                              disableGutters
                              key={item.ActionModel?.Id || item.CustomNews?.Id}
                              sx={{ alignItems: "center" }}
                              secondaryAction={
                                <Typography color="inherit" variant="caption" align="right">
                                  {new Date(item.ActionModel?.Submitted || item.CustomNews?.Created).toLocaleDateString(
                                    "da-dk",
                                    {
                                      day: "numeric",
                                      month: "numeric",
                                      year: "2-digit",
                                    }
                                  )}
                                </Typography>
                              }
                            >
                              <Typography color="inherit" variant="body1" sx={{ overflow: "hidden" }}>
                                <StyledLink
                                  target="_blank"
                                  href={item.ActionModel?.Action.Link || item.CustomNews?.NavigateTo}
                                  color="inherit"
                                >
                                  {item.ActionModel?.Action.Header || item.CustomNews?.Title}
                                </StyledLink>
                              </Typography>
                            </StyledListItem>
                          ))
                        : null}
                    </List>
                  ) : (
                    <Grid item container direction="column" sx={{ flex: 1 }}>
                      {repeatSkeleton({ height: 20,sx: {marginBottom: 4} }, 5)}
                    </Grid>
                  )}
                </StyledCardContent>
              </StyledNewsCard>
            </Grid>
  );
};

export default NewsComponent;
