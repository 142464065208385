import React from "react";
import { Typography, Grid, Button } from "@mui/material";
import BaseDialog from "../../../components/Dialogs/BaseDialog";
import { AppThunkDispatch } from "../../../definitions/Action";
import { connect, ConnectedProps } from "react-redux";
import { sendFamilyJoinRequest } from "../../../actions";
import Unit from "../../../definitions/model/unit/Unit";
import { useTranslation } from "react-i18next";

type JoinFamilyDialogProps = {
  open: boolean;
  presetUnit?: Unit | null;
  onRequestSended?: () => void | Promise<void>;
  closeDialog: () => void;
};

const JoinFamilyDialog = ({
  open,
  presetUnit,
  closeDialog,
  sendFamilyJoinRequest,
  onRequestSended,
}: JoinFamilyDialogProps & JoinFamilyDialogConnectedProps) => {
  const sendJoinRequest = () => {
    if (presetUnit) {
      sendFamilyJoinRequest(presetUnit.Id).then(onRequestSended);
    }
    closeDialog();
  };
  const { t } = useTranslation("translation");

  return (
    <BaseDialog
      titleComponent={t("Authorization.Forms.AlertText1") ?? ""}
      actionsComponent={
        <Button variant="contained" color="secondary" onClick={sendJoinRequest}>
          {t("General.Buttons.Send")}
        </Button>
      }
      closeDialog={closeDialog}
      open={open}
    >
      <Grid container justifyContent="center">
        <Grid item lg={8} md={9} sm={10} xs={12}>
          <Typography>{t("Pages.Family.SendRequestForMoveIn")}</Typography>
        </Grid>
      </Grid>
    </BaseDialog>
  );
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  sendFamilyJoinRequest: (unitId: string) =>
    dispatch(sendFamilyJoinRequest(unitId)),
});

const connector = connect(null, mapDispatchToProps);

type JoinFamilyDialogConnectedProps = ConnectedProps<typeof connector>;

export default connector(JoinFamilyDialog);
