import React, { useRef, useState } from "react";
import { Card, Grid, Typography, useTheme } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { FileType } from "../../definitions/Document";
import { v4 as uuid } from "uuid";
import { PermissionTarget } from "../../constants/enums";

type FileDropZoneProps = {
  teaserText?: string | null;
  addFiles: (files: FileType[]) => void;
  removeFile: (fileId: string) => void;
  chooseFileText: string;
  loading: boolean;
  fileListTitle?: string | null;
  loadingText?: string | null;
};

const FileDropZone = (props: FileDropZoneProps) => {
  const [shouldHighlight, setShouldHighlight] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();

  const showFileUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const onFilesAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event && event.currentTarget && event.currentTarget.files && event.currentTarget.files.length) {
      addFiles(Array.from(event.currentTarget.files));
    }
  };

  const addFiles = (files: File[]) => {
    const fileList: FileType[] = files.map((file) => {
      return {
        Id: uuid(),
        Name: file.name,
        FileExtension: file.name.split(".").pop() || "",
        Size: file.size,
        Type: file.type,
        File: file,
        Permission: PermissionTarget.Member,
      };
    });
    props.addFiles(fileList);
  };

  const preventDefaultHandler = (e: React.DragEvent<HTMLElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Card variant="outlined">
      <Grid
        container
        justifyContent={"center"}
        sx={{
          background: shouldHighlight ? theme.palette.background.grayPaper : "none",
        }}
      >
        {!props.loading ? (
          <Grid
            sx={{ padding: 4 }}
            container
            justifyContent={"center"}
            direction={"column"}
            alignItems={"center"}
            item
            onDragOver={(e) => {
              preventDefaultHandler(e);
              setShouldHighlight(true);
            }}
            onDragEnter={(e) => {
              preventDefaultHandler(e);
              setShouldHighlight(true);
            }}
            onDragLeave={(e) => {
              preventDefaultHandler(e);
              setShouldHighlight(false);
            }}
            onDrop={(e) => {
              preventDefaultHandler(e);
              addFiles(Array.from(e.dataTransfer.files));
              setShouldHighlight(false);
            }}
            onClick={showFileUpload}
          >
            <UploadFileIcon color="primary" />
            <Typography variant="body1">{props.chooseFileText || "Choose a file or drop it here"}</Typography>
          </Grid>
        ) : (
          <Grid sx={{ padding: 4 }} container justifyContent={"center"} direction={"column"} alignItems={"center"} item>
            <HourglassBottomIcon color="primary" />
            <Typography variant="body1">{props.loadingText || "Please wait..."}</Typography>
          </Grid>
        )}
        <input ref={inputRef} style={{ display: "none" }} type="file" multiple={true} onChange={onFilesAdded} />
      </Grid>
    </Card>
  );
};

export default FileDropZone;
