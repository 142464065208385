export const FamilyConstatns = {
  ACCEPT_FAMILY_JOIN_REQUEST: "ACCEPT_FAMILY_JOIN_REQUEST",
  ACCEPT_FAMILY_JOIN_REQUEST_SUCCEEDED: "ACCEPT_FAMILY_JOIN_REQUEST_SUCCEEDED",
  ASSIGN_NEW_FAMILYOWNER: "ASSIGN_NEW_FAMILYOWNER",
  ASSIGN_NEW_FAMILYOWNER_SUCCEEDED: "ASSIGN_NEW_FAMILYOWNER_SUCCEEDED",
  DECLINE_FAMILY_JOIN_REQUEST: "DECLINE_FAMILY_JOIN_REQUEST",
  DECLINE_FAMILY_JOIN_REQUEST_SUCCEEDED: "DECLINE_FAMILY_JOIN_REQUEST_SUCCEEDED",
  GET_USER_FAMILY: "GET_USER_FAMILY",
  GET_USER_FAMILY_SUCCEEDED: "GET_USER_FAMILY_SUCCEEDED",
  GET_USER_FAMILIES: "GET_USER_FAMILIES",
  GET_USER_FAMILIES_SUCCEEDED: "GET_USER_FAMILIES_SUCCEEDED",
  SEND_FAMILY_JOIN_REQUEST: "SEND_FAMILY_JOIN_REQUEST",
  SEND_FAMILY_JOIN_REQUEST_SUCCEEDED: "SEND_FAMILY_JOIN_REQUEST_SUCCEEDED",
  REMOVE_FAMILY_MEMBER: "REMOVE_FAMILY_MEMBER",
  REMOVE_FAMILY_MEMBER_SUCCEEDED: "REMOVE_FAMILY_MEMBER_SUCCEEDED",
  FAMILY_OPERATION_FAILED: "FAMILY_OPERATION_FAILED",
  ACCEPT_INVITE_MEMBER: "ACCEPT_INVITE_MEMBER",
  ACCEPT_INVITE_MEMBER_SUCCEEDED: "ACCEPT_INVITE_MEMBER_SUCCEEDED",
};
