import {
  ClickAwayListener,
  MenuItem,
  Paper,
  Popper,
  styled,
  MenuList,
  Grid,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import React from "react";
import English from "../../assets/flags/en.svg";
import Danish from "../../assets/flags/dk.svg";

import { LanguageType } from "../../definitions/Menu";

const FlagAvatar = styled(Avatar)({
  width: 28,
  height: 28,
  overflow: "hidden",
  "& img": {
    position: "absolute",
    height: "100%",
  },
});

type Language = {
  name: LanguageType;
  iconSrc: string;
};

const languages: Language[] = [
  {
    name: "en",
    iconSrc: English,
  },
  {
    name: "da",
    iconSrc: Danish,
  },
];

type LanguageChangeMenuProps = {
  loading: boolean;
  languageId: LanguageType;
  setLanguageId: (languageId: LanguageType) => void;
};

const LanguageChangeMenu = ({
  loading,
  languageId = "da",
  setLanguageId,
}: LanguageChangeMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleLanguageChange = async (languageId: LanguageType) => {
    setLanguageId(languageId);
    setAnchorEl(null);
  };

  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      style={{ width: "unset" }}
    >
      <FlagAvatar
        onClick={(e) => {
          if (!loading) setAnchorEl(e.currentTarget);
        }}
        sx={{ cursor: "pointer", opacity: loading ? 0.6 : 1 }}
        variant="rounded"
      >
        <img
          src={languages.find((l) => l.name === languageId)!.iconSrc}
          alt=""
        />
      </FlagAvatar>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        disablePortal
        placement="bottom-end"
      >
        <Paper style={{ zIndex: 2 }}>
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <MenuList autoFocusItem={Boolean(anchorEl)}>
              {languages.map((l) => (
                <MenuItem
                  key={l.name}
                  onClick={() => handleLanguageChange(l.name)}
                  selected={languageId == l.name}
                >
                  <img
                    src={l.iconSrc}
                    alt=""
                    style={{ width: 20, marginRight: 10 }}
                  />
                  {l.name}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </Grid>
  );
};

export default LanguageChangeMenu;
