import React from "react";
import { Container } from "@mui/material";
import BaseDialog from "../BaseDialog";
import { AuthDialogChildrenProps } from "../AuthDialog";
import RegisterForm from "./RegisterForm";
import { useTranslation } from "react-i18next";

const RegisterDialog = ({
  state,
  open,
  ignoreNotifications,
  showPhoneField,
  showNameField,
  showCO2MailField,
  closeDialog,
  setStep,
  onRegistrationComplete,
  employerId,
  communeNumber,
}: AuthDialogChildrenProps): JSX.Element => {
  const { t } = useTranslation("translation");

  return (
    <BaseDialog titleComponent={t("Base.Dialog.RegisterAtMyhouse") ?? ""} open={open} closeDialog={closeDialog}>
      <Container maxWidth="xs">
        <RegisterForm
          state={state}
          ignoreNotifications={ignoreNotifications}
          setStep={setStep}
          showPhoneField={showPhoneField}
          showNameField={showNameField}
          showCO2MailField={showCO2MailField}
          onRegistrationComplete={onRegistrationComplete}
          closeDialog={closeDialog}
          employerId={employerId}
          communeNumber={communeNumber} 
           />
      </Container>
    </BaseDialog>
  );
};

export default RegisterDialog;
