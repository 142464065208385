import React from "react";
import { Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { CraftsmanTaskList } from "../../../../../definitions/model/ServiceBook";
import TaskList from "../../../Tasks/components/TaskList";
import { useUnit } from "../../../../../actions/unit.actions";
import { useServicebook } from "../../../../../actions/servicebook.actions";

type OffersComponentProps = {
  taskLists: CraftsmanTaskList[];
  disabled: boolean;
};

const TaskListStep = (props: OffersComponentProps) => {
  const { taskLists } = props;
  const { t } = useTranslation("translation");

  const [{ unit }] = useUnit();
  const [, actions] = useServicebook();

  return (
    <>
      <Grid item xs={12}>
        {!taskLists.length && (
          <Typography variant="body1" gutterBottom>
            {t("ServiceBook.Offer.NoOffers")}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" gutterBottom>
          {t("ServiceBook.Offer.Description")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {taskLists.map((x) => (
          <TaskList
            key={x.Id}
            taskList={x}
            setTaskStatus={(taskId, craftsmanId, status) =>
              actions
                .setTaskStatus(taskId, craftsmanId, unit?.Id ?? "", status)
                .then(() => unit && actions.getTasks(unit?.Id))
            }
            setTaskListStatus={(tasklistId, status) =>
              actions
                .setTaskListStatus(tasklistId, status)
                .then(() => unit && actions.getTasks(unit?.Id))
            }
          />
        ))}
      </Grid>
    </>
  );
};

export default TaskListStep;
