export const AdminPricePredictionActionTypes = {
  PRICE_PREDICTIONS_OPERATION_FAILED:
    "ADMIN_PRICE_PREDICTIONS_OPERATION_FAILED",
  GET_PRICE_PREDICTIONS: "GET_ADMIN_PRICE_PREDICTIONS",
  GET_PRICE_PREDICTIONS_SUCCEEDED: "GET_ADMIN_PRICE_PREDICTIONS_SUCCEEDED",
  DELETE_PRICE_PREDICTION: "DELETE_ADMIN_PRICE_PREDICTION",
  DELETE_PRICE_PREDICTION_SUCCEEDED: "DELETE_ADMIN_PRICE_PREDICTION_SUCCEEDED",
  EDIT_PRICE_PREDICTION: "EDIT_ADMIN_PRICE_PREDICTION",
  EDIT_PRICE_PREDICTION_SUCCEEDED: "EDIT_ADMIN_PRICE_PREDICTION_SUCCEEDED",
  SET_ACTIVE_PRICE_PREDICTIONS: "SET_ACTIVE_ADMIN_PRICE_PREDICTIONS",
  SET_ACTIVE_PRICE_PREDICTIONS_SUCCEEDED: "SET_ACTIVE_ADMIN_PRICE_PREDICTIONS_SUCCEEDED",
  ADD_UNIT_FOR_PREDICTION: "ADD_ADMIN_UNIT_FOR_PREDICTION",
  ADD_UNIT_FOR_PREDICTION_SUCCEEDED: "ADD_ADMIN_UNIT_FOR_PREDICTION_SUCCEEDED",
  DELETE_UNIT_FOR_PREDICTION: "DELETE_ADMIN_UNIT_FOR_PREDICTION",
  DELETE_UNIT_FOR_PREDICTION_SUCCEEDED: "DELETE_ADMIN_UNIT_FOR_PREDICTION_SUCCEEDED",
  GET_TEST_UNITS: "GET_ADMIN_TEST_UNITS",
  GET_TEST_UNITS_SUCCEEDEDS: "GET_ADMIN_TEST_UNITS_SUCCEEDEDS",
  RENEW_PREDTICTIONS: "ADMIN_RENEW_PREDTICTIONS",
  RENEW_PREDTICTIONS_SUCCEEDEDS: "ADMIN_RENEW_PREDTICTIONS_SUCCEEDEDS",
  RENEW_ONE_PREDTICTION: "ADMIN_RENEW_ONE_PREDTICTION",
  RENEW_ONE_PREDTICTION_SUCCEEDEDS: "ADMIN_RENEW_ONE_PREDTICTION_SUCCEEDEDS",
  REINIT_PREDTICTIONS: "ADMIN_REINIT_PREDTICTIONS",
  REINIT_PREDTICTIONS_SUCCEEDEDS: "ADMIN_REINIT_PREDTICTIONS_SUCCEEDEDS",
};
