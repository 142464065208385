import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";


const CreatedTasksDIalog = (props: { open: boolean; handleClose: () => void}) => {
  const { t } = useTranslation("translation");

  return (
    <Dialog open={props.open}>
      <DialogTitle>{t("ServiceBook.Tasks.CreatedTitle")}</DialogTitle>
          <DialogContent>
            <Grid container>
              <Typography variant="body1">
                {t("ServiceBook.Tasks.CreatedText")}
              </Typography>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => props.handleClose()}
              variant="contained"
              size="small"
              color="secondary"
            >
              {t("General.Buttons.Ok")}
            </Button>
          </DialogActions>
    </Dialog>
  );
};

export default CreatedTasksDIalog