import React, { useEffect, useState } from "react";
import { Typography, Grid, Button, TextField, InputAdornment, styled, Alert } from "@mui/material";
import { Search } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  ServiceBookItemCraftsmenSelection,
  ServiceBookItemToFix,
  ServiceBookOfferRequestContract,
  ServiceTargetType,
} from "../../../../../definitions/model/ServiceBook";
import { useServicebookWorker } from "../../../../Workers/workers.actions";
import ChooseSectionWorkersComponent from "../ChooseSectionWorkers";
import { CraftsmanType } from "../../../../../definitions/model/Worker";
import { MCard } from "../../../../../components/Base";
import Address from "../../../../../definitions/model/unit/Address";

type ChooseWorkerComponentProps = {
  items: ServiceBookItemToFix[];
  serviceTargetId: string;
  serviceTargetTyle: ServiceTargetType;
  address: Address;
  reportId: string;
  sendOfferRequest: (requestData: ServiceBookOfferRequestContract) => void;
  goBack: () => void;
};

const ChooseWorkerStep = (props: ChooseWorkerComponentProps) => {
  const { t } = useTranslation("translation");

  const [workerState, workerActions] = useServicebookWorker();

  const [activeItem, setActiveItem] = useState<string | null>(null);
  const [groupedItems, setGroupedItems] = useState<[string, ServiceBookItemToFix[]][]>([]);
  const [resultDraft, setResultDraft] = useState<ServiceBookItemCraftsmenSelection[]>([]);

  const [hasSelectedWorkers, setHasSelectedWorkers] = useState(false);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (props.serviceTargetTyle === "unit") workerActions.getUnitWorkers(props.serviceTargetId);
    if (props.serviceTargetTyle === "building") workerActions.getBuildingWorkers(props.serviceTargetId);
  }, [props.serviceTargetId]);

  useEffect(() => {
    setGroupedItems(groupByCraftsmanType(props.items));
  }, [props.items]);

  const toggleItemOpen = (id: string) => {
    if (activeItem === id) {
      setActiveItem(null);
    } else {
      setActiveItem(id);
    }
  };

  const handleSelectWorkers = (selection: ServiceBookItemCraftsmenSelection) => {
    const currentItem = resultDraft.find((x) => x.item.id === selection.item.id);
    const reduceSelection = currentItem ? currentItem.craftsmenIds.length > selection.craftsmenIds.length : false;
    let craftsmenToAdd: string[] = [];
    if (!reduceSelection) {
      craftsmenToAdd = currentItem
        ? selection.craftsmenIds.filter((x) => !currentItem.craftsmenIds.includes(x))
        : selection.craftsmenIds;
    }

    const newResultDraft = [
      //remove existing selection
      ...resultDraft.filter((record) => !(record.item.id === selection.item.id)),
      //and insert new one
      selection,
    ];

    //fill all the rest items to fix with the selected workers with corresponding competencies
    //if item exists in more than one object it will also be included

    const workers = workerState.workers.filter((w) => craftsmenToAdd.some((x) => x === w.Id));

    if (!reduceSelection && workers.length) {
      workers.forEach((w) => {
        //find items with the same competencies that workers are
        const items = props.items.filter((i) => w.Competencies.some((c) => i.missingCraftsmen.includes(c)));
        items.forEach((i) => {
          //if there's a selection, try to add workers
          const existing = newResultDraft.find((a) => a.item.id === i.id);
          if (existing) {
            if (!existing.craftsmenIds.includes(w.Id)) {
              existing.craftsmenIds = [...existing.craftsmenIds, w.Id];
            }
          } else {
            newResultDraft.push({
              craftsmenIds: [w.Id],
              item: i,
              ownerNote: "",
            });
          }
        });
      });
    }

    setResultDraft(newResultDraft);

    // allow to click on the Next button only if at least one worker is selected
    setHasSelectedWorkers(newResultDraft.filter((record) => record.craftsmenIds.length > 0).length > 0);
  };

  const handleSubmit = () => {
    const request = {
      reportId: props.reportId,
      itemsToFix: resultDraft
        .filter((record) => record.craftsmenIds.length > 0)
        .map((record) => ({
          id: record.item.id,
          ownerNote: record.ownerNote,
          craftsmanIds: record.craftsmenIds,
        })),
    };

    if (request.itemsToFix.length > 0) {
      props.sendOfferRequest(request);
    }
  };

  const NameFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.currentTarget.value);
  };

  const groupByCraftsmanType = (items: ServiceBookItemToFix[]) => {
    const groups: { [key: number]: ServiceBookItemToFix[] } = {};

    items.forEach((i) => {
      i.missingCraftsmen.forEach((c) => {
        groups[c] = groups[c] || [];
        const toAdd = items
          .filter((x) => x.missingCraftsmen.some((m) => m === c))
          .filter((x) => !groups[c].some((e) => e.id === x.id));
        groups[c] = groups[c].concat(toAdd);
      });
    });

    return Object.entries(groups).sort((a, b) => {
      if (a[0] >= "100" /* CraftsmanType:Other */) return 1;

      return t(CraftsmanType.find((x) => x.key === parseInt(a[0]))?.value || "") >
        t(CraftsmanType.find((x) => x.key === parseInt(b[0]))?.value || "")
        ? 1
        : -1;
    });
  };

  return props.items.length ? (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography paragraph variant="body1">
          {t("ServiceBook.ChooseWorkers.Intro")}
        </Typography>
        <Typography>{t("ServiceBook.ChooseWorkers.ChooseWorkersForItem")}</Typography>
      </Grid>

      <StickyContainer item mt={2}>
        <StyledTextField
          label={t("ServiceBook.Workers.FindWorker")}
          variant="outlined"
          fullWidth
          value={filter}
          onChange={NameFilterChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="primary" />
              </InputAdornment>
            ),
          }}
        />
        <Button variant="contained" color="secondary" disabled={!hasSelectedWorkers} onClick={handleSubmit}>
          {t("General.Buttons.Next")}
        </Button>
      </StickyContainer>

      {groupedItems.map((group) => (
        <Grid item key={group[0]}>
          <MCard type="main" title={t(CraftsmanType.find((x) => x.key === parseInt(group[0]))?.value || "")}>
            {group[1].map((item, index) => (
              <Grid item key={index}>
                <ChooseSectionWorkersComponent
                  item={item}
                  craftsmenToFilter={[parseInt(group[0])]}
                  postCode={props.address.PostalCode}
                  communeCode={[props.address.Commune.CommuneNumber]}
                  workers={workerState.workers}
                  resultDraft={resultDraft}
                  unitWorkerIds={workerState.unitWorkerIds}
                  open={activeItem === "group___" + group[0] + "___" + item.id}
                  loading={workerState.loading}
                  getWorkers={workerActions.getWorkers}
                  selectWorkers={handleSelectWorkers}
                  requestToggle={() => toggleItemOpen("group___" + group[0] + "___" + item.id)}
                  filter={filter}
                />
              </Grid>
            ))}
          </MCard>
        </Grid>
      ))}
    </Grid>
  ) : (
    <Grid container direction="column" alignItems={"center"}>
      <Grid item>
        <Alert severity="warning" sx={{ marginBottom: 2 }}>
          {t("ServiceBook.ChooseWorkers.NoItems")}
        </Alert>
      </Grid>
      <Grid item>
        <Button variant="contained" color="secondary" onClick={props.goBack}>
          {t("General.Buttons.Back")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ChooseWorkerStep;

const StickyContainer = styled(Grid)({
  display: "flex",
  position: "sticky",
  top: 0,
  zIndex: 1,
  background: "white",
});

const StyledTextField = styled(TextField)({
  marginRight: 40,
  "& input": {
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
  },
});
