import React from "react";
import {
  Typography,
  Grid,
  Card,
  Box,
  useMediaQuery,
  useTheme,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ServiceBookReportPreview } from "../../../../../definitions/model/ServiceBook";
import { AddCircleOutline } from "@mui/icons-material";
import ReportCard from "../ReportCard";
import { da } from "date-fns/locale";
import { format, add } from "date-fns";

const AddCircleOutlineStyled = styled(AddCircleOutline)(({ theme }) => ({
  fontSize: "6rem",
  width: "6rem",
  height: "6rem",
  transition: "color 0.3s",
  color: theme.palette.grey[500],
}));

const CardStyled = styled(Card)(({ theme }) => ({
  transition: "border-color 0.3s",
  cursor: "pointer",
  height: "100%",
  "&:hover": {
    borderColor: theme.palette.grey[600],
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
}));

type ReportsListProps = {
  reports: ServiceBookReportPreview[];
  showReport: (reportId: string) => void;
  requestInspection: () => void;
};

const ReportsListStep = (props: ReportsListProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Grid container>
          <Typography variant="h3">
            {t("ServiceBook.ReportList.NextInspection")} :{" "}
            {format(
              add(new Date(props.reports[0].Created), { years: 3 }),
              "d MMM yyyy",
              { locale: da }
            )}
          </Typography>
      </Grid>
      <Box mb={2}>
        <Grid
          container
          spacing={2}
          alignContent="stretch"
          justifyContent={isSmDown ? "center" : "flex-start"}
        >
          <Grid item md={4} sm={6} xs={12}>
            <Box height="100%">
              <CardStyled variant="outlined" onClick={props.requestInspection}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  direction="column"
                  sx={{ height: "100%" }}
                >
                  <AddCircleOutlineStyled />
                  <Typography variant="h5">
                    {t("ServiceBook.ReportList.RequestInspection")}
                  </Typography>
                </Grid>
              </CardStyled>
            </Box>
          </Grid>
          {props.reports.map((report) => (
            <Grid item md={4} sm={6} xs={12} key={report.Id}>
              <ReportCard report={report} onSelect={props.showReport} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default ReportsListStep;
