import { TranslationText } from "../definitions/Menu";
import i18n from "../i18n";

export const GetTranslatedText = (
  translationText: TranslationText[] | null
): string => {
  if (translationText && translationText.length) {
    return (
      translationText.find((a) => a.Language === i18n.language)?.Text ?? ""
    );
  } else return "";
};
