export const BehaviourRulesActionTypes = {
  GET_ACTIVE_BEHAVIOUR_RULES: "GET_ACTIVE_BEHAVIOUR_RULES",
  GET_ACTIVE_BEHAVIOUR_RULES_SUCCEEDED: "GET_ACTIVE_BEHAVIOUR_RULES_SUCCEEDED",
  BEHAVIOUR_RULE_OPERATION_FAILED: "BEHAVIOUR_RULE_OPERATION_FAILED",
};

export const AdminBehaviourRulesActionTypes = {
  GET_BEHAVIOUR_RULE: "GET_BEHAVIOUR_RULE",
  GET_BEHAVIOUR_RULE_SUCCEEDED: "GET_BEHAVIOUR_RULE_SUCCEEDED",
  GET_BEHAVIOUR_RULES: "GET_BEHAVIOUR_RULES",
  GET_BEHAVIOUR_RULES_SUCCEEDED: "GET_BEHAVIOUR_RULES_SUCCEEDED",
  CREATE_BEHAVIOUR_RULE: "CREATE_BEHAVIOUR_RULE",
  CREATE_BEHAVIOUR_RULE_SUCCEEDED: "CREATE_BEHAVIOUR_RULE_SUCCEEDED",
  UPDATE_BEHAVIOUR_RULE: "UPDATE_BEHAVIOUR_RULE",
  UPDATE_BEHAVIOUR_RULE_SUCCEEDED: "UPDATE_BEHAVIOUR_RULE_SUCCEEDED",
  REMOVE_BEHAVIOUR_RULE: "REMOVE_BEHAVIOUR_RULE",
  REMOVE_BEHAVIOUR_RULE_SUCCEEDED: "REMOVE_BEHAVIOUR_RULE_SUCCEEDED",
  ADMIN_BEHAVIOUR_RULE_OPERATION_FAILED:
    "ADMIN_BEHAVIOUR_RULE_OPERATION_FAILED",
};
