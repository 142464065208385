import { LoadingButton } from "@mui/lab";
import React from "react";

type FindEventsProps = {
  id: string;
  onClick: (id: string, type: string) => void;
  name: string;
  loading?: boolean;
};

const FindEventsBlock = (props: FindEventsProps) => {
  const clickHandler = () => {
    props.onClick(props.id, props.name);
  };
  return (
    <LoadingButton
      variant="contained"
      onClick={clickHandler}
      loading={props.loading}
      size='small'
    >
      Events
    </LoadingButton>
  );
};

export default FindEventsBlock;
