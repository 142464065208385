import { Reducer } from "react";
import { SubscriptionsActions } from "../constants";
import { AppAction } from "../definitions/Action";
import { SubscriptionModel, ProductModel, CreditCard } from "../definitions/model/Subscriptions";

export interface SubscriptionsStoreState {
  subscriptions: SubscriptionModel[] | null;
  products: ProductModel[];
  cards: CreditCard[];
  count: number;
  loading: boolean;
  error: string;
}

export const initialState: SubscriptionsStoreState = {
  subscriptions: null,
  products: [],
  cards: [],
  count: 0,
  loading: false,
  error: "",
};

const subscriptionsReucer: Reducer<SubscriptionsStoreState, AppAction> = (
  state: SubscriptionsStoreState = initialState,
  action: AppAction
): SubscriptionsStoreState => {
  switch (action.type) {
    case SubscriptionsActions.GET_USER_SUBSCRIPTIONS:
    case SubscriptionsActions.PROCESS_PAYMENT:
    case SubscriptionsActions.CANCEL_SUBSCRIPTION:
    case SubscriptionsActions.GET_USER_CARDS:
    case SubscriptionsActions.ADD_CREDIT_CARD:
    case SubscriptionsActions.CHANGE_DEFAULT_CARD:
    case SubscriptionsActions.REMOVE_CREDIT_CARD:
    case SubscriptionsActions.GET_PRODUCT:
    case SubscriptionsActions.GET_PRODUCTS:
    case SubscriptionsActions.CREATE_PRODUCT:
    case SubscriptionsActions.DELETE_PRODUCT:
      return {
        ...state,
        loading: true,
      };
    case SubscriptionsActions.GET_USER_SUBSCRIPTIONS_SUCCEEDED:
      return {
        ...state,
        subscriptions: action.payload,
        loading: false,
      };
    case SubscriptionsActions.PROCESS_PAYMENT_SUCCEEDED:
      return {
        ...state,
        subscriptions: [action.payload, ...(state.subscriptions || [])],
        loading: false,
      };
    case SubscriptionsActions.CANCEL_SUBSCRIPTION_SUCCEEDED:
      return {
        ...state,
        subscriptions: (state.subscriptions || []).map((s) => (s.Id === action.payload.Id ? action.payload : s)),
        loading: false,
      };
    case SubscriptionsActions.GET_PRODUCT_SUCCEEDED:
      return {
        ...state,
        products: [...state.products.filter((p) => p.Id !== action.payload.Id), action.payload],
      };
    case SubscriptionsActions.ADD_CREDIT_CARD_SUCCEEDED: {
      return {
        ...state,
        cards: [...state.cards, action.payload],
        loading: false,
      };
    }
    case SubscriptionsActions.REMOVE_CREDIT_CARD_SUCCEEDED:
    case SubscriptionsActions.CHANGE_DEFAULT_CARD_SUCCEEDED:
    case SubscriptionsActions.GET_USER_CARDS_SUCCEEDED:
      return {
        ...state,
        cards: action.payload,
        loading: false,
      };
    case SubscriptionsActions.GET_PRODUCTS_SUCCEEDED:
      return {
        ...state,
        products: action.payload.Results,
        count: action.payload.Count,
        loading: false,
      };
    case SubscriptionsActions.CREATE_PRODUCT_SUCCEEDED:
      return {
        ...state,
        products: [action.payload, ...state.products],
        count: ++state.count,
        loading: false,
      };
    case SubscriptionsActions.DELETE_PRODUCT_SUCCEEDED:
      return {
        ...state,
        products: state.products.filter((product) => product.Id !== action.payload.Id),
        count: --state.count,
        loading: false,
      };
    case SubscriptionsActions.CLEAR_PRODUCTS:
      return { ...state, products: [], count: 0 };
    case SubscriptionsActions.SUBSCRIPTIONS_OPERATION_FAILED:
      return {
        ...state,
        error: action.payload && action.payload.statusText !== undefined ? action.payload.statusText : action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default subscriptionsReucer;
