import { NavigationItem } from "../../definitions/Menu";
import ApiService from "../../services/api.service";

const NavigationService = {
  getNavigationItems: async (): Promise<NavigationItem[]> => {
    const url = `/api/navigation-items`;
    const result = await ApiService.get<NavigationItem[]>(url);

    return result;
  },
  getPartnersItems: async (): Promise<NavigationItem[]> => {
    const url = `/api/navigation-items/partner`;
    const result = await ApiService.get<NavigationItem[]>(url);

    return result;
  },
};

export default NavigationService;
