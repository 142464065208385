export const SubscriptionsActions = {
    SUBSCRIPTIONS_OPERATION_FAILED: "SUBSCRIPTIONS_OPERATION_FAILED",
    PROCESS_PAYMENT: "PROCESS_PAYMENT",
    PROCESS_PAYMENT_SUCCEEDED: "PROCESS_PAYMENT_SUCCEEDED",
    GET_PRODUCT: "GET_PRODUCT",
    GET_PRODUCT_SUCCEEDED: "GET_PRODUCT_SUCCEEDED",
    GET_PRODUCTS: "GET_PRODUCTS",
    GET_PRODUCTS_SUCCEEDED: "GET_PRODUCTS_SUCCEEDED",
    CLEAR_PRODUCTS: "CLEAR_PRODUCTS",
    CREATE_PRODUCT: "CREATE_PRODUCT",
    CREATE_PRODUCT_SUCCEEDED: "CREATE_PRODUCT_SUCCEEDED",
    UPDATE_PRODUCT: "UPDATE_PRODUCT",
    UPDATE_PRODUCT_SUCCEEDED: "UPDATE_PRODUCT_SUCCEEDED",
    DELETE_PRODUCT: "DELETE_PRODUCT",
    DELETE_PRODUCT_SUCCEEDED: "DELETE_PRODUCT_SUCCEEDED",
    GET_PRODUCT_PRICES: "GET_PRODUCT_PRICES",
    GET_PRODUCT_PRICES_SUCCEEDED: "GET_PRODUCT_PRICES_SUCCEEDED",
    CREATE_PRODUCT_PRICE: "CREATE_PRODUCT_PRICE",
    CREATE_PRODUCT_PRICE_SUCCEEDED: "CREATE_PRODUCT_PRICE_SUCCEEDED",
    GET_USER_SUBSCRIPTIONS :"GET_USER_SUBSCRIPTIONS",
    GET_USER_SUBSCRIPTIONS_SUCCEEDED : "GET_USER_SUBSCRIPTIONS_SUCCEEDED",
    GET_USER_CARDS: "GET_USER_CARDS",
    GET_USER_CARDS_SUCCEEDED: "GET_USER_CARDS_SUCCEEDED",
    CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
    CANCEL_SUBSCRIPTION_SUCCEEDED: "CANCEL_SUBSCRIPTION_SUCCEEDED",
    ADD_CREDIT_CARD: "ADD_CREDIT_CARD",
    ADD_CREDIT_CARD_SUCCEEDED: "ADD_CREDIT_CARD_SUCCEEDED",
    CHANGE_DEFAULT_CARD:"CHANGE_DEFAULT_CARD",
    CHANGE_DEFAULT_CARD_SUCCEEDED:"CHANGE_DEFAULT_CARD_SUCCEEDED",
    REMOVE_CREDIT_CARD: "REMOVE_CREDIT_CARD",
    REMOVE_CREDIT_CARD_SUCCEEDED: "REMOVE_CREDIT_CARD_SUCCEEDED"
}