import React from "react";
import BaseDialog, { BaseDialogProps } from "./BaseDialog";
import TermsPage from "../../pages/User/TermsPage";
import { useTranslation } from "react-i18next";

const DataPoliticDialog = ({
  closeDialog,
  open,
  ...other
}: BaseDialogProps): JSX.Element => {
  const { t } = useTranslation("translation");

  return (
    <BaseDialog
      open={open}
      closeDialog={closeDialog}
      titleComponent={t("Pages.Contact.MainContainerTitle")!}
      {...other}
    >
      <TermsPage/>
    </BaseDialog>
  );
};

export default DataPoliticDialog;
