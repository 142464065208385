import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { ReportWishListItem } from "../../../../definitions/model/ServiceBook";

type ReportSummaryProps = {
  wishList: ReportWishListItem[];
};

const ReportWishList = (props: ReportSummaryProps) => {
  const { t } = useTranslation("translation");

  return (
    <Grid item container direction="column">
      <Card variant="outlined">
        <CardContent>
          <Grid item xs={12}>
            <Typography variant="h3">
              {t("ServiceBook.WishList.Title")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container item justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">
                  {t("ServiceBook.WishList.Item", { count: 5 })}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Typography variant="h6">
                  {t("ServiceBook.WishList.Price")}
                </Typography>
              </Grid>
            </Grid>
            <Divider variant="middle" sx={{ marginBottom: 1 }} />

            {props.wishList.map((x) => (
              <Grid container item key={x.Id} justifyContent="space-between">
                <Grid item>
                  <Typography variant={"body1"} paddingTop={"2px"}>
                    {x.Label}
                  </Typography>
                </Grid>
                <Grid item sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Typography variant="body1" gutterBottom>
                    {x.PriceEstimate.toLocaleString("da-DK", {
                      currency: "DKK",
                      style: "currency",
                    })}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ReportWishList;
