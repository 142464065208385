import ApiService from "../api.service";
import { CommuneLocation } from "../../definitions/model/unit/Commune";

const CommuneService = {
  getCommunes: async (): Promise<CommuneLocation[]> => {
    const url = "/api/admin/communes";
    const result = await ApiService.get<CommuneLocation[]>(url);
    return result;
  },
};

export default CommuneService;
