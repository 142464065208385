import Address from "../definitions/model/unit/Address";
import Unit from "../definitions/model/unit/Unit";

const UnitHelper = {
  GetPropertyTypeDisplayString: (propertyType: string) => {
    switch (propertyType.toLowerCase()) {
      case "cooperative":
        return "Andelsbolig";
      case "villaapartment":
      case "apartment":
        return "Lejlighed";
      case "countryhouse":
        return "Landejendom";
      case "fullyearground":
        return "Helårsgrund";
      case "holidayground":
      case "holidayhouse":
        return "Fritidshus";
      case "townhouse":
        return "Rækkehus";
      case "villa":
        return "Villa";
      case "summerhouse":
        return "Sommerhus";
      case "rented":
        return "Lejet";
      case "holidayapartment":
        return "Ferielejlighed";
      case "commercialwholesale":
        return "Engroshandel";
      case "commercialwarehouse":
        return "Enhed til lager";
      case "commercialoffice":
        return "Enhed til kontor";
      case "commercialshoppingcenter":
        return "Butikscenter";
      case "commercialgasstation":
        return "Tankstation";
      case "commercialservice":
        return "Detailhandel";
      default:
        return "Anden boligtype";
    }
  },
  GetFullAddress: (address: Address, includeCity = true, includePostalCode = false): string => {
    let result = "";
    if (!address) return result;

    if (address.Street) result += address.Street;
    if (address.HouseNo) result += ` ${address.HouseNo}`;

    if (address.Floor) {
      result += `, ${address.Floor}`;
      if (address.DoorNumber) result += `-${address.DoorNumber}`;
    } else if (address.DoorNumber) result += address.DoorNumber;
    if (address.PostalCode && includePostalCode) result += `, ${address.PostalCode}`;
    if (address.City && includeCity) result += `, ${address.City}`;

    return result;
  },
};

export default UnitHelper;
