import { Dispatch, AnyAction } from "redux";
import { ServiceBookService } from "../services/servicebook.service";
import { ServiceBookActions } from "../constants/servicebook.actiontypes";
import {
  CraftsmanTaskListStatus,
  CraftsmanTaskStatus,
  CustomerReportState,
  ServiceBookOfferRequestContract,
  ServiceReportType,
} from "../definitions/model/ServiceBook";
import { AppAction, AppActionThunk, AppThunkDispatch } from "../definitions/Action";
import { ServiceBookItemToFix } from "../definitions/model/ServiceBook";
import { useSelector } from "react-redux";
import { ApplicationState } from "../reducers/store";
import { RegisterLeadContract } from "../definitions/LiteCapaign";
import { ThunkDispatch } from "redux-thunk";
import { operationFailedActionGeneral, useAppDispatch } from ".";

const useServicebookState = () => useSelector((state: ApplicationState) => state.servicebook);

const useServicebookActions = () => {
  const dispatch = useAppDispatch();
  return {
    getUnitReports: (houseId: string) => dispatch(getUnitReports(houseId)),
    getBuildingReports: (buildingId: string, reportType: ServiceReportType) => dispatch(getBuildingReports(buildingId, reportType)),
    getReport: (reportId: string) => dispatch(getReport(reportId)),
    sendOfferRequest: (requestData: ServiceBookOfferRequestContract) => dispatch(sendOfferRequest(requestData)),
    acceptOffer: (offerId: string) => dispatch(acceptOffer(offerId)),
    rejectOffer: (offerId: string, unitId: string) => dispatch(rejectOffer(offerId, unitId)),
    setItemsToFix: (items: ServiceBookItemToFix[]) => dispatch(setItemsToFix(items)),

    getTasks: (unitId: string) => dispatch(getTasks(unitId)),
    setTaskStatus: (taskId: string, craftsmanId: string, unitId: string, status: CraftsmanTaskStatus) =>
      dispatch(setTaskStatus(taskId, craftsmanId, unitId, status)),
    clearError: () => dispatch(clearError()),
    setTaskListStatus: (taskListId: string, status: CraftsmanTaskListStatus) =>
      dispatch(setTaskListStatus(taskListId, status)),
    getOffers: (unitId: string, reportId?: string) => dispatch(getOffers(unitId, reportId)),
    setCustomerReportState: (state: CustomerReportState) =>
      dispatch(setCustomerReportState(state)),
    getCustomerState: (reportId: string) => dispatch(getCustomerState(reportId)),
    getLatestReport: (unitId: string) => dispatch(getLatestReport(unitId)),
    requestConsent: (reportId: string) => dispatch(requestConsent(reportId)),
    manageConsent: (reportId: string, managerId: string, given: boolean) => dispatch(manageConsent(reportId, managerId, given)),
  };
};

export const useServicebook = (): [
  ReturnType<typeof useServicebookState>,
  ReturnType<typeof useServicebookActions>
] => {
  const state = useServicebookState();
  const actions = useServicebookActions();
  return [state, actions];
};

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(payload, ServiceBookActions.SERVICEBOOK_ACTION_FAILED);
};

const getUnitReports = (houseId: string) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch({ type: ServiceBookActions.GET_REPORTS });
  try {
    const reports = await ServiceBookService.getUnitReports(houseId);

    dispatch({
      type: ServiceBookActions.GET_REPORTS_SUCCEEDED,
      payload: reports,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const getBuildingReports = (buildingId: string, reportType: ServiceReportType) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch({ type: ServiceBookActions.GET_REPORTS });
  try {
    const reports = await ServiceBookService.getBuildingReports(buildingId, reportType);

    dispatch({
      type: ServiceBookActions.GET_REPORTS_SUCCEEDED,
      payload: reports,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const getReport = (reportId: string) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch({ type: ServiceBookActions.GET_REPORT });
  try {
    const report = await ServiceBookService.getReport(reportId);

    dispatch({
      type: ServiceBookActions.GET_REPORT_SUCCEEDED,
      payload: report,
    });
    return report;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const sendOfferRequest = (requestData: ServiceBookOfferRequestContract) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch({ type: ServiceBookActions.SEND_OFFER_REQUEST });
  try {
    const response = await ServiceBookService.sendOfferRequest(requestData);

    dispatch({
      type: ServiceBookActions.SEND_OFFER_REQUEST_SUCCEEDED,
    });
    return response;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const acceptOffer = (offerId: string) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch({ type: ServiceBookActions.ACCEPT_OFFER });
  try {
    await ServiceBookService.acceptOffer(offerId);

    dispatch({
      type: ServiceBookActions.ACCEPT_OFFER_SUCCEEDED,
      payload: offerId,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const rejectOffer = (offerId: string, unitId: string) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch({ type: ServiceBookActions.REJECT_OFFER });
  try {
    await ServiceBookService.rejectOffer(offerId, unitId);

    dispatch({
      type: ServiceBookActions.REJECT_OFFER_SUCCEEDED,
      payload: offerId,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const setItemsToFix = (items: ServiceBookItemToFix[]) => (dispatch: Dispatch<AnyAction>) => {
  dispatch({ type: ServiceBookActions.SET_ITEMS_TO_FIX, payload: items });
};

const getTasks =
  (unitId: string): AppActionThunk<Promise<any>> =>
  async (dispatch: AppThunkDispatch) => {
    dispatch({ type: ServiceBookActions.GET_UNIT_TASKS });
    try {
      const result = await ServiceBookService.getTasks(unitId);
      dispatch({
        type: ServiceBookActions.GET_UNIT_TASKS_SUCCEEDED,
        payload: result.Results,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const setTaskStatus =
  (taskId: string, craftsmanId: string, unitId: string, status: CraftsmanTaskStatus) =>
  async (dispatch: AppThunkDispatch) => {
    dispatch({ type: ServiceBookActions.SET_TASK_STATUS });
    try {
      const result = await ServiceBookService.setTaskStatus(taskId, unitId, status);

      dispatch({
        type: ServiceBookActions.SET_TASK_STATUS_SUCCEEDED,
        payload: { taskId, craftsmanId, status },
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const clearError = () => (dispatch: AppThunkDispatch) => {
  dispatch({ type: ServiceBookActions.CLEAR_ERROR });
};

const setTaskListStatus =
  (taskListId: string, status: CraftsmanTaskListStatus) => async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({ type: ServiceBookActions.SET_TASKLIST_STATUS });
      await ServiceBookService.setTaskListStatus(taskListId, status);
      dispatch({
        type: ServiceBookActions.SET_TASKLIST_STATUS_SUCCEEDED,
        payload: { taskListId, status },
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const sendReportRequest = (data: RegisterLeadContract) => async (dispatch: AppThunkDispatch) => {
  try {
    await ServiceBookService.sendReportRequest(data);
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const getOffers =
  (unitId: string, reportId?: string): AppActionThunk<Promise<any>> =>
  async (dispatch: AppThunkDispatch) => {
    dispatch({ type: ServiceBookActions.GET_UNIT_OFFERS });
    try {
      const result = await ServiceBookService.getTasks(unitId, reportId);
      dispatch({
        type: ServiceBookActions.GET_UNIT_OFFERS_SUCCEEDED,
        payload: result.Results,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const setCustomerReportState =
  (state: CustomerReportState) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    dispatch({
      type: ServiceBookActions.SET_CUSTOMER_REPORT_STATE,
      payload: state,
    });
    try {
      const result = await ServiceBookService.setCustomerReportState(state);
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const getCustomerState = (reportId: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
  try {
    const result = await ServiceBookService.getCustomerReportState(reportId);
    if (result && Object.keys(result).length) {
      dispatch({
        type: ServiceBookActions.SET_CUSTOMER_REPORT_STATE,
        payload: result,
      });
    }
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};


const getLatestReport = (unitId: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
  try {
    dispatch({ type: ServiceBookActions.GET_LATEST_REPORT });
    const result = await ServiceBookService.getLatestReport(unitId);
    if (result && Object.keys(result).length) {
      dispatch({
        type: ServiceBookActions.GET_LATEST_REPORT_SUCCEEDED,
        payload: result,
      });
      return result;
    }
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};


const requestConsent = (reportId: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
  try {
    dispatch({ type: ServiceBookActions.REQUEST_CONSENT });
    const result = await ServiceBookService.requestConsent(reportId);
    if (result && Object.keys(result).length) {
      dispatch({
        type: ServiceBookActions.REQUEST_CONSENT_SUCCEEDED,
        payload: result,
      });
    }
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};


const manageConsent = (reportId: string, managerId: string, given: boolean) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
  try {
    dispatch({ type: ServiceBookActions.GIVE_CONSENT });
    const result = await ServiceBookService.manageConsent(reportId, managerId, given);
    if (result && Object.keys(result).length) {
      dispatch({
        type: ServiceBookActions.GIVE_CONSENT_SUCCEEDED,
        payload: result,
      });
    }
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};
