import React from "react";
import { NumericFormat, PatternFormat } from "react-number-format";
import { TextField, TextFieldProps } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";

//this section is to integrate NumberFormat with MaterialUI
//handleChange is possibly redundant

export const CurrencyField = (props: TextFieldProps) => {
  const handleChange = (e: any) => {
    props?.onChange?.(e);
  };
  return (
    <NumericFormat
      {...props}
      value={props.value as string | number | null | undefined}
      defaultValue={props.defaultValue as string | number | null | undefined}
      type="text"
      thousandSeparator="."
      decimalSeparator=","
      valueIsNumericString
      decimalScale={2}
      suffix=" Kr"
      allowNegative={false}
      customInput={TextField}
      onChange={handleChange}
    />
  );
};

export const PhoneField = (props: TextFieldProps) => {
  const handleChange = (e: any) => {
    props?.onChange?.(e);
  };

  return (
    <MuiTelInput
      {...props}
      value={props.value as string}
      onChange={handleChange}
      variant={props.variant}
      inputRef={props.inputRef}
    />
  );
};

export const PostCodeField = (props: TextFieldProps) => {
  const handleChange = (e: any) => {
    props?.onChange?.(e);
  };

  return (
    <PatternFormat
      format="####"
      {...props}
      value={props.value as string | number | null | undefined}
      defaultValue={props.defaultValue as string | number | null | undefined}
      type="text"
      onChange={handleChange}
      customInput={TextField}
    />
  );
};
