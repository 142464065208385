import React from "react"
import { CircularProgress, TableCell, TableRow } from "@mui/material"

export const AdminTableLoading = () => {
    return (
        <TableRow
            sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                top: 0
            }}
        >
            <TableCell align={'center'} sx={{ width: "100%" }}>
                <CircularProgress />
            </TableCell>
        </TableRow>
    )
}