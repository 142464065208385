export const AdminNavigationActionTypes = {
    NAVIGATION_OPERATION_FAILED: "NAVIGATION_OPERATION_FAILED",
    GET_NAVIGATION_ITEMS: "GET_NAVIGATION_ITEMS",
    GET_NAVIGATION_ITEMS_SUCCEEDED: "GET_ADMIN_NAVIGATION_ITEMS_SUCCEEDED",
    GET_NAVIGATION_ITEM: "GET_NAVIGATION_ITEM",
    GET_NAVIGATION_ITEM_SUCCEEDED: "GET_NAVIGATION_ITEM_SUCCEEDED",
    CREATE_NAVIGATION_ITEM: "CREATE_NAVIGATION_ITEM",
    CREATE_NAVIGATION_ITEM_SUCCEEDED: "CREATE_NAVIGATION_ITEM_SUCCEEDED",
    UPDATE_NAVIGATION_ITEM: "UPDATE_NAVIGATION_ITEM",
    UPDATE_NAVIGATION_ITEM_SUCCEEDED: "UPDATE_NAVIGATION_ITEM_SUCCEEDED",
    UPDLOAD_NAVIGATION_ITEM_BACKGROUND: "UPDLOAD_NAVIGATION_ITEM_BACKGROUND",
    UPDLOAD_NAVIGATION_ITEM_BACKGROUND_SUCCEEDED: "UPDLOAD_NAVIGATION_ITEM_BACKGROUND_SUCCEEDED",
    DELETE_NAVIGATION_ITEM: "DELETE_NAVIGATION_ITEM",
    DELETE_NAVIGATION_ITEM_SUCCEEDED: "DELETE_NAVIGATION_ITEM_SUCCEEDED",
    REORDER_NAVIGATION_ITEMS: "REORDER_NAVIGATION_ITEMS",
    REORDER_NAVIGATION_ITEMS_SUCCEEDED: "REORDER_NAVIGATION_ITEMS_SUCCEEDED",
    SET_NAVIGATION_ITEM_PUBLISH: "SET_NAVIGATION_ITEM_PUBLISH",
    SET_NAVIGATION_ITEM_PUBLISH_SUCCEEDED: "SET_NAVIGATION_ITEM_PUBLISH_SUCCEEDED",
}