import React, { Suspense, useEffect, useLayoutEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import LoadingOverlay from "../components/LoadingOverlay";
import { useUser } from "../actions";
import OfflinePage from "../pages/OfflinePage";
import { Dialog } from "@mui/material";
import ContactPage from "../pages/Contact/ContactPage";
import { UserConstants } from "../constants/user.constants";
import CommuneClimatePlanLayout, { klimaplanRoutes } from "../pages/MyClimatePlanPage";
import NotFoundPage from "../pages/NotFound/NotFoundPage";
import WidgetsPage from "../pages/AdminPanel/pages/Widgets/Widgets";
import WidgetEditor from "../pages/AdminPanel/pages/Widgets/WidgetEditor";
import AdministratorsPage from "../pages/AdminPanel/pages/Administrators/Administrators";
import ClubsPage from "../pages/AdminPanel/pages/Clubs/ClubsPage";
import ClubPageEditor from "../pages/AdminPanel/pages/Clubs/ClubPageEditor";
import ArticleEditorPage from "../pages/AdminPanel/pages/Clubs/ArticleEditorPage";
import MapPage from "../pages/AdminPanel/pages/Map/MapPage";
import NewsPage from "../pages/AdminPanel/pages/News/NewsPage";
import EditNewsPage from "../pages/AdminPanel/pages/News/EditNewsPage";
import DashboardPage from "../pages/AdminPanel/pages/Dashboard/DashboardPage";
import LeadAlarmsPage from "../pages/AdminPanel/pages/Leads/LeadsPage";
import LandingsPage from "../pages/AdminPanel/pages/Landing/LandingsPage";
import EditLandingPage from "../pages/AdminPanel/pages/Landing/EditLandingPage";
import TemplatesPage from "../pages/AdminPanel/pages/Templates/TemplatesPage";
import ProductsPage from "../pages/AdminPanel/pages/Products/ProductsPage";
import ProductEditorPage from "../pages/AdminPanel/pages/Products/ProductEditor";
import EditTemplatePage from "../pages/AdminPanel/pages/Templates/EditTemplatePage";
import BenefitClubsPage from "../pages/AdminPanel/pages/BenefitClubs/BenefitClubsPage";
import EditBenefitClubPage from "../pages/AdminPanel/pages/BenefitClubs/EditBenefitClubPage";
import CommentsModerationPage from "../pages/AdminPanel/pages/CommentsModeration/CommentsModerationPage";
import CO2PlansPage from "../pages/AdminPanel/pages/CO2Plans/CO2PlansPage";
import CompaniesPage from "../pages/AdminPanel/pages/Companies/CompaniesPage";
import OverviewNavigationPage from "../pages/AdminPanel/pages/OverviewNavigation/OverviewNavigationPage";
import BehaviourRulesPage from "../pages/AdminPanel/pages/BehaviourRules/BehaviourRulesPage";
import PricePredictionsPage from "../pages/AdminPanel/pages/PricePredictions/PricePredictionsPage";
import SystemMessages from "../pages/AdminPanel/pages/SystemMessage/SystemMessages";
import SystemMessageEditor from "../pages/AdminPanel/pages/SystemMessage/SystemMessageEditor";
import AggregateViewPage from "../pages/AdminPanel/pages/AggregateViewPage/AggregateViewPage";
import CraftsmenPage from "../pages/AdminPanel/pages/Craftsmen/CraftsmenPage";
import UsersPage from "../pages/AdminPanel/pages/Users/UsersPage";
import SystemSettingsPage from "../pages/AdminPanel/pages/SystemSettings";
import TermsPage from "../pages/AdminPanel/pages/Terms/Terms";
import EditTermsPage from "../pages/AdminPanel/pages/Terms/EditTermsPage";
import AdminLayout from "../pages/AdminPanel";

import { UserType } from "../constants/enums";
import BuildingLayout, { buildingRoutes, OldInviteBuildingRedirect } from "../pages/Building/BuildingLayout";
import PropertyLayout, { propertyRoutes } from "../components/Layout/PropertyLayout";
import BaseLayout, { pagesRoutes } from "../components/Layout/BaseLayout";
import MainContainer from "../components/Layout/MainContainer";

export type RouteType = {
  title?: string;
  titleOptions?: any;
  path: string;
  element: React.LazyExoticComponent<() => React.JSX.Element> | (() => React.JSX.Element) | any;
  hideMenu?: boolean;
  fullScreen?: boolean;
  allowAnonymous?: boolean;
  hideElements?: boolean;
};

const RoutesMap = (): JSX.Element => {
  const [{ user, isMaintenance, needToAcceptTerms }, userActions] = useUser();
  const isAdmin = !!user?.Role && user?.Role >= UserType.ExternalManager;
  const isAuthorized = user?.Role !== undefined;

  const { pathname } = useLocation();
  useEffect(() => {
    userActions._getBasicData();

    const beforeInstallPromptHandler = (e: Event) => {
      e.preventDefault();
      userActions.setPwaOptions(e);
    };

    window.addEventListener("beforeinstallprompt", beforeInstallPromptHandler);

    return () => {
      window.removeEventListener("beforeinstallprompt", beforeInstallPromptHandler);
    };
  }, []);

  useEffect(() => {
    const sendAnalyticData = (e: BeforeUnloadEvent) => {
      userActions.dispatchMessage(UserConstants.USER_REGISTERED_AND_STOP);
    };
    if (user) {
      const currentDate = new Date();
      const isNewUser = currentDate.getTime() / 3600000 - new Date(user.Created).getTime() / 3600000 <= 24;

      if (isNewUser && pathname === "/overview") {
        window.addEventListener("beforeunload", sendAnalyticData);
      } else {
        window.removeEventListener("beforeunload", sendAnalyticData);
      }
    }
  });

  useLayoutEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      left: 0,
      top: 0,
    });
  }, [pathname]);

  return (
    <Suspense fallback={<LoadingOverlay loading />}>
      <Dialog open={isMaintenance || false} fullScreen fullWidth>
        <OfflinePage />
      </Dialog>
      <Dialog
        key={pathname}
        open={(pathname != "/profile" && needToAcceptTerms && user !== null) || false}
        fullScreen
        fullWidth
      >
        <MainContainer title="Pages.Contact.TermsUpdated">
          <ContactPage />
        </MainContainer>
      </Dialog>
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="klimaplan" element={<CommuneClimatePlanLayout />}>
          {klimaplanRoutes?.map((route, i) => (
            <Route key={"klimaplan_" + i} path={route.path} element={<route.element />} />
          ))}
        </Route>
        <Route path="/admin" element={<AdminLayout />}>
          {isAdmin && (
            <>
              <Route path="administrators" element={<AdministratorsPage />} />
              <Route path="users" element={<UsersPage />} />
              <Route path="widgets" element={<WidgetsPage />} />
              <Route path="widgets/:id" element={<WidgetEditor />} />
              <Route path="clubs/:id" element={<ClubPageEditor />} />
              <Route path="clubs/:id/article/:articleId" element={<ArticleEditorPage />} />
              <Route path="products/:id" element={<ProductEditorPage />} />
              <Route path="landings/:id/:langId?" element={<EditLandingPage />} />
              <Route path="templates/:id" element={<EditTemplatePage />} />
              <Route path="news/:id" element={<EditNewsPage />} />
              <Route path="benefitclubs/:id" element={<EditBenefitClubPage />} />
              <Route path="terms-editor/:id" element={<EditTermsPage />} />
              {/* <Route path="neighbor-groups/:id"} element={<EditNeighborGroupPage/>} />
              <Route path="neighbor-groups"} element={<NeighborGroupsPage/>} /> */}
              <Route path="articleGroups" element={<ClubsPage />} />
              <Route path="companies" element={<CompaniesPage />} />
              <Route path="lead-alarms" element={<LeadAlarmsPage />} />
              <Route path="landings" element={<LandingsPage />} />
              <Route path="map" element={<MapPage />} />
              <Route path="news" element={<NewsPage />} />
              <Route path="benefitclubs" element={<BenefitClubsPage />} />
              <Route path="templates" element={<TemplatesPage />} />
              <Route path="products" element={<ProductsPage />} />
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="comments" element={<CommentsModerationPage />} />
              <Route path="co2" element={<CO2PlansPage />} />
              <Route path="navigation" element={<OverviewNavigationPage />} />
              <Route path="behaviourrules" element={<BehaviourRulesPage />} />
              <Route path="aggregate-view" element={<AggregateViewPage />} />
              <Route path="green-craftsmen" element={<CraftsmenPage />} />
              <Route path="terms-editor" element={<TermsPage />} />
              <Route path="price-predictions" element={<PricePredictionsPage />} />
              <Route path="system-messages/:id" element={<SystemMessageEditor />} />
              <Route path="system-settings" element={<SystemSettingsPage />} />
              <Route path="system-messages" element={<SystemMessages />} />
              <Route path="" element={<Navigate to="dashboard" replace />} />
            </>
          )}
          {!isAdmin && <Route path="*" element={<Navigate to="/" replace />} />}
        </Route>
        <Route element={<BaseLayout />}>
          <React.Fragment>
            <Route path="*" element={<Navigate to="/" replace />} />
            {pagesRoutes.map((route: RouteType, i: number) => (
              <Route
                key={"base_" + i}
                path={route.path}
                element={isAuthorized || route.allowAnonymous ? <route.element /> : <Navigate to="/" replace />}
              />
            ))}
          </React.Fragment>
        </Route>
        <Route path="/buildings" element={<BuildingLayout />}>
          <React.Fragment>
            {buildingRoutes?.map((route, i) => (
              <Route
                key={"building_" + i}
                path={route.path}
                element={isAuthorized || route.allowAnonymous ? <route.element /> : <Navigate to="/" replace />}
              />
            ))}
          </React.Fragment>
        </Route>
        <Route path="/" element={<PropertyLayout />}>
          <React.Fragment>
            {propertyRoutes?.map((route: RouteType, i: number) => (
              <Route
                key={"property_" + i}
                path={route.path}
                element={isAuthorized || route.allowAnonymous ? <route.element /> : <Navigate to="/" replace />}
              />
            ))}
          </React.Fragment>
        </Route>
        {/* TODO: remove this route when make changes to the system message */}
        <Route path="/:addressURL/building/:token/join" element={<OldInviteBuildingRedirect />} />
      </Routes>
    </Suspense>
  );
};

export default RoutesMap;
