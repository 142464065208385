import { useSelector } from "react-redux";
import { operationFailedActionGeneral } from ".";
import { AdminBehaviourRulesActionTypes, BehaviourRulesActionTypes } from "../constants/behaviourRules.actiontypes";
import { AppAction, AppActionThunk } from "../definitions/Action";
import { BehaviourRule, CreateBehaviourRuleContract, UpdateBehaviourRuleContract } from "../definitions/BehaviourRule";
import { BehaviourRulesService } from "../services/behaviour-rules.service";
import { ApplicationState } from "../reducers/store";

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(payload, BehaviourRulesActionTypes.BEHAVIOUR_RULE_OPERATION_FAILED);
};

const adminOperationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(payload, AdminBehaviourRulesActionTypes.ADMIN_BEHAVIOUR_RULE_OPERATION_FAILED);
};

export const getActiveBehaviourRules = (): AppActionThunk<Promise<BehaviourRule[]>> => async (dispatch) => {
  try {
    dispatch({
      type: BehaviourRulesActionTypes.GET_ACTIVE_BEHAVIOUR_RULES,
    });
    const result = await BehaviourRulesService.getActiveBehaviourRules();
    dispatch({
      type: BehaviourRulesActionTypes.GET_ACTIVE_BEHAVIOUR_RULES_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

export const createBehaviourRule =
  (contract: CreateBehaviourRuleContract): AppActionThunk<Promise<BehaviourRule>> =>
  async (dispatch) => {
    try {
      dispatch({
        type: AdminBehaviourRulesActionTypes.CREATE_BEHAVIOUR_RULE,
      });
      const result = await BehaviourRulesService.createBehaviourRule(contract);
      dispatch({
        type: AdminBehaviourRulesActionTypes.CREATE_BEHAVIOUR_RULE_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(adminOperationFailedAction(error));
      throw error;
    }
  };

export const updateBehaviourRule =
  (contract: UpdateBehaviourRuleContract): AppActionThunk<Promise<BehaviourRule>> =>
  async (dispatch) => {
    try {
      dispatch({
        type: AdminBehaviourRulesActionTypes.UPDATE_BEHAVIOUR_RULE,
      });
      const result = await BehaviourRulesService.updateBehaviourRule(contract);
      dispatch({
        type: AdminBehaviourRulesActionTypes.UPDATE_BEHAVIOUR_RULE_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(adminOperationFailedAction(error));
      throw error;
    }
  };

export const removeBehaviourRule =
  (id: string): AppActionThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      dispatch({
        type: AdminBehaviourRulesActionTypes.REMOVE_BEHAVIOUR_RULE,
      });
      await BehaviourRulesService.removeBehaviourRule(id);
      dispatch({
        type: AdminBehaviourRulesActionTypes.REMOVE_BEHAVIOUR_RULE_SUCCEEDED,
        payload: id,
      });
    } catch (error) {
      dispatch(adminOperationFailedAction(error));
      throw error;
    }
  };

export const getBehaviourRules = (): AppActionThunk<Promise<BehaviourRule[]>> => async (dispatch) => {
  try {
    dispatch({
      type: AdminBehaviourRulesActionTypes.GET_BEHAVIOUR_RULES,
    });
    const result = await BehaviourRulesService.getBehaviourRules();
    dispatch({
      type: AdminBehaviourRulesActionTypes.GET_BEHAVIOUR_RULES_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

export const useBehaviourRuleState = () => useSelector((state: ApplicationState) => state.behaviourRules);
