import ApiService from "./api.service";
import {
  ClubModel,
  ClubGroupModel,
  ArticleModel,
} from "../definitions/model/Club";
import { LandingArticleDto } from "../definitions/Landing";
import { ArticleCategory } from "../constants/enums";
import { SearchResult } from "../definitions/model/SearchResult";

const ClubService = {
  getClubGroups: async (): Promise<ClubGroupModel[]> => {
    const url = `/api/admin/club/group?filter=&skip=${0}&take=${0}`;
    const result = await ApiService.get<ClubGroupModel[]>(url);
    return result;
  },

  getClubGroup: async (groupId: string): Promise<ClubGroupModel[]> => {
    const url = `/api/club/group/${groupId}`;
    const result = await ApiService.get<ClubGroupModel[]>(url);
    return result;
  },

  getClubs: async (skip: number, take: number): Promise<ClubModel[]> => {
    const url = `/api/admin/club?filter=&skip=${skip}&take=${take}`;
    const result = await ApiService.get<ClubModel[]>(url);
    return result;
  },

  getClub: async (clubId: string): Promise<ClubModel> => {
    const url = `/api/admin/club/${clubId}`;
    const result = await ApiService.get<ClubModel>(url);
    return result;
  },

  getClubByUniqueName: async (uniqueName: string): Promise<ClubModel> => {
    const url = `/api/club/${uniqueName}`;
    const result = await ApiService.get<ClubModel>(url);
    return result;
  },
  addClubGroup: async (group: ClubGroupModel): Promise<ClubGroupModel> => {
    const url = `/api/admin/club/group`;
    const result = await ApiService.post<ClubGroupModel>(url, group);
    return result;
  },

  editClubGroup: async (group: ClubGroupModel): Promise<ClubGroupModel> => {
    const url = `/api/admin/club/group`;
    const result = await ApiService.put<ClubGroupModel>(url, group);
    return result;
  },

  deleteClubGroup: async (groupId: string): Promise<boolean> => {
    const url = `/api/admin/club/group/${groupId}`;
    const result = await ApiService.delete<boolean>(url);
    return result;
  },

  addClub: async (title: string, description: string): Promise<ClubModel> => {
    const url = `/api/admin/club`;
    const result = await ApiService.post<ClubModel>(url, {
      Name: title,
      Description: description,
    });
    return result;
  },

  editClub: async (club: ClubModel): Promise<ClubModel> => {
    const url = `/api/admin/club/`;
    const result = await ApiService.put<ClubModel>(url, club);
    return result;
  },

  deleteClub: async (clubId: string): Promise<boolean> => {
    const url = `/api/admin/club/${clubId}`;
    const result = await ApiService.delete<boolean>(url);
    return result;
  },
  createArticle: async (
    clubId: string,
    title: string,
    uniqueName: string,
    templateId: string,
    categories: ArticleCategory[],
    tag: string
  ): Promise<ArticleModel> => {
    const url = `/api/admin/club/article`;
    const result = await ApiService.post<ArticleModel>(url, {
      ClubId: clubId,
      Title: title,
      UniqueName: uniqueName,
      TemplateId: templateId,
      Categories: categories,
      Tag: tag,
    });
    return result;
  },

  getArticle: async (articleId: string): Promise<ArticleModel> => {
    const url = `/api/admin/club/article/${articleId}`;
    const result = await ApiService.get<ArticleModel>(url);
    return result;
  },
  getArticlePage: async (uniqueName: string): Promise<LandingArticleDto> => {
    const url = `/api/club/article/${uniqueName}`;
    const result = await ApiService.get<LandingArticleDto>(url);
    return result;
  },
  getArticlePages: async (clubId?: string): Promise<SearchResult<LandingArticleDto>> => {
    const url = clubId ? `/api/club/${clubId}/articles` : `/api/club/articles`;
    const result = await ApiService.get<SearchResult<LandingArticleDto>>(url);
    return result;
  },
  editArticle: async (article: ArticleModel): Promise<ArticleModel> => {
    const url = `/api/admin/club/article`;
    const result = await ApiService.put<ArticleModel>(url, article);
    return result;
  },
  getRecentArticles: async (
    articleId: string,
    take: number
  ): Promise<SearchResult<LandingArticleDto>> => {
    const url = `/api/club/article/${articleId}/recent/${take}`;
    const result = await ApiService.get<SearchResult<LandingArticleDto>>(url);
    return result;
  },
  getArticlesByTags: async (tags: string[]): Promise<SearchResult<LandingArticleDto>> => {
    const urlParam = tags
      .map((tag, index) => `${index === 0 ? "?" : "&"}tags=${tag}`)
      .join("");
    const url = `/api/club/articleByTags${urlParam}`;
    const result = await ApiService.get<SearchResult<LandingArticleDto>>(url);
    return result;
  },
  deleteArticlePage: async (articleId: string): Promise<boolean> => {
    const url = `/api/admin/club/article/${articleId}`;
    const result = await ApiService.delete<boolean>(url);
    return result;
  },
  getBenefitClubs: async (
    skip: number,
    take: number
  ): Promise<LandingArticleDto[]> => {
    const url = `/api/landing/benefit/${skip}/${take}`;
    const result = await ApiService.get<LandingArticleDto[]>(url);
    return result;
  },
};

export default ClubService;
