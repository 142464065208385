import {Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Typography} from "@mui/material";
import React from "react";
import { isId } from "../AggregateViewPage";
import FindEventsBlock from "./FindEventsBlock";

type AdminInfoGroup = {
  Id: string;
  Title: string;
  GroupType: number;
};

type GroupListProps = {
  groups: AdminInfoGroup[];
  findEventHandler: (id: string, type: string) => void;
  loading: boolean;
};

const groupTypes = ["Privat", "Offentlig", "Familie", "Home", "Landeniveau"];

const GroupsList = (props: GroupListProps) => {
  return (
      <List disablePadding dense>
        {props.groups.map((group) => (
          <ListItem
            key={group.Id}
            secondaryAction={isId(group.Id) && (
              <FindEventsBlock
                id={group.Id}
                name={groupTypes[group.GroupType]}
                onClick={props.findEventHandler}
              />
              )}
          >
            <ListItemText primary={`Name: ${groupTypes[group.GroupType]}`} secondary={`Id: ${group.Id}`}/>
          </ListItem>
        ))}
      </List>
  );
};

export default GroupsList;
