import React, { useState } from "react";
// this lib has bad typescript support, so this component writed in jsx
import JsxParser from "react-jsx-parser";
import { components } from ".";
import PropTypes from "prop-types";
import {styled} from "@mui/material";



const FullWidthJsxParser= styled(JsxParser)(()=>({
  width: "100%"
}))

const GrapesJsxRenderer = (props) => {
  const [isParsingErrorOccured, setParsingErrorOccured] = useState(false);

  return (
    <>
      {!isParsingErrorOccured ? (
        <FullWidthJsxParser
          autoCloseVoidElements
          components={components}
          onError={(e) => {
            props.onError(e);
            setParsingErrorOccured(true);
          }}
          jsx={props.jsx}
        />
      ) : (
        <div
          style={{width: "100%"}}
          dangerouslySetInnerHTML={{ __html: props.jsx }}
        ></div>
      )}
    </>
  );
};

GrapesJsxRenderer.propTypes = {
  jsx: PropTypes.string,
  onError : PropTypes.func
};
// Memo is used for stop re rendering whole tree of components
// when state is changed, becaouse it is unmounting components and performs memory leaks
export default React.memo(GrapesJsxRenderer);
