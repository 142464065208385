export const MapActionTypes = {
    MAP_OPERATION_FAILED: 'MAP_OPERATION_FAILED',
    GET_SOLD_UNITS: 'GET_SOLD_UNITS',
    GET_SOLD_UNITS_SUCCEEDED: 'GET_SOLD_UNITS_SUCCEEDED',
    GET_ESTIMATION_UNITS: 'GET_ESTIMATION_UNITS',
    GET_ESTIMATION_UNITS_SUCCEEDED: 'GET_ESTIMATION_UNITS_SUCCEEDED',
    GET_NEIGHBORS: 'GET_NEIGHBORS',
    GET_NEIGHBORS_SUCCEEDED: 'GET_NEIGHBORS_SUCCEEDED',
    GET_ROAD_PLANS: 'GET_ROAD_PLANS',
    GET_ROAD_PLANS_SUCCEEDED: 'GET_ROAD_PLANS_SUCCEEDED',
    GET_BUS_STATIONS: 'GET_BUS_STATIONS',
    GET_BUS_STATIONS_SUCCEEDED: 'GET_BUS_STATIONS_SUCCEEDED',
    GET_MOBILE_TOWERS: 'GET_MOBILE_TOWERS',
    GET_MOBILE_TOWERS_SUCCEEDED: 'GET_MOBILE_TOWERS_SUCCEEDED',
    GET_GYMNASIUMS: 'GET_GYMNASIUMS',
    GET_GYMNASIUMS_SUCCEEDED: 'GET_GYMNASIUMS_SUCCEEDED',
    GET_SCHOOLS: 'GET_SCHOOLS',
    GET_SCHOOLS_SUCCEEDED: 'GET_SCHOOLS_SUCCEEDED',
    GET_MOBILE_TOWER_INFO: 'GET_MOBILE_TOWER_INFO',
    GET_MOBILE_TOWER_INFO_SUCCEEDED: 'GET_MOBILE_TOWER_INFO_SUCCEEDED',
    GET_ECOBUSINESSES: 'GET_ECOBUSINESSES',
    GET_ECOBUSINESSES_SUCCEEDED: 'GET_ECOBUSINESSES_SUCCEEDED',
    HIDE_ECOBUSINESS_ADS: 'HIDE_ECOBUSINESS_ADS',
    GET_GYMNASIUM_INFO: 'GET_GYMNASIUM_INFO',
    GET_GYMNASIUM_INFO_SUCCEEDED: 'GET_GYMNASIUM_INFO_SUCCEEDED',
    GET_PLANS: 'GET_PLANS',
    GET_PLANS_SUCCEEDED: 'GET_PLANS_SUCCEEDED',
    GET_CRIME_INFO: 'GET_CRIME_INFO',
    GET_CRIME_INFO_SUCCEEDED: 'GET_CRIME_INFO_SUCCEEDED',
    GET_NOISE_INFO: 'GET_NOISE_INFO',
    GET_NOISE_INFO_SUCCEEDED: 'GET_NOISE_INFO_SUCCEEDED',
    GET_FLOOD_INFO: 'GET_FLOOD_INFO',
    GET_FLOOD_INFO_SUCCEEDED: 'GET_FLOOD_INFO_SUCCEEDED',
    GET_PLAN_INFO: 'GET_PLAN_INFO',
    GET_PLAN_INFO_SUCCEEDED: 'GET_PLAN_INFO_SUCCEEDED',
    GET_GROUND_INFO: 'GET_GROUND_INFO',
    GET_GROUND_INFO_SUCCEEDED: 'GET_GROUND_INFO_SUCCEEDED',
    GET_RADON_INFO: 'GET_RADON_INFO',
    GET_RADON_INFO_SUCCEEDED: 'GET_RADON_INFO_SUCCEEDED',
    GET_UNIT_RISK_FLAGS: 'GET_UNIT_RISK_FLAGS',
    GET_UNIT_RISK_FLAGS_SUCCEEDE: 'GET_UNIT_RISK_FLAGS_SUCCEEDE',
    GET_ECOBUSINESS_INFO: 'GET_ECOBUSINESS_INFO',
    GET_ECOBUSINESS_INFO_SUCCEEDED: 'GET_ECOBUSINESS_INFO_SUCCEEDED',
};