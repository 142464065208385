import React, { useState, TouchEvent } from "react";
import {MobileStepper, Button, Dialog, Fab, Grid, styled} from "@mui/material";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Room,
  Close,
} from "@mui/icons-material";

const Wrapper = styled(Grid)(()=>({
  position: "relative",
  height: 300,
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}))

const StyledMobileStepper = styled(MobileStepper)(({theme})=>({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "transparent",
  padding: 0,
  "& .MuiMobileStepper-dots": {
    display: "none",
  },
  width: "100%",
  "& svg": {
    color: theme.palette.primary.contrastText,
    fontSize: 40,
  },
}))

const StyledDialog = styled(Dialog)(({theme})=>({
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  "& .MuiDialog-paperFullWidth": {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
    },
  },
}))

const ButtonsMobileStepper = styled(MobileStepper)(({theme})=>({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "transparent",
  padding: 0,
  "& .MuiMobileStepper-dots": {
    display: "none",
  },
  width: "98%",
  "& button": {
    [theme.breakpoints.down("sm")]: {
      width: 35,
      height: 35,
    },
    "& svg": {
      fontSize: 40,
      [theme.breakpoints.down("sm")]: {
        fontSize: 25,
      },
    },
  },
}))

const Image = styled("img")(()=>({
  height: "auto",
  width: "100%",
  overflow: "hidden",
  display: "block",
}))

const OffIcon = styled(Close)(({theme}) => ({
  position: "fixed",
  top: 7,
  right: 7,
  backgroundColor: "transparent",
  color: theme.palette.background.default,
  width: 40,
  height: 40,
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const Pointer = styled(Room)(({theme}) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -33px)",
  color: "red",
  width: 40,
  height: 40,
  [theme.breakpoints.down("sm")]: {
    width: 35,
    height: 35,
  },
}));

const ArrowButton = styled(Button)({
  minWidth: "auto",
  padding: 0,
});

type CarouselProps = {
  qualityPhotos: string[];
};

const Carousel = ({ qualityPhotos }: CarouselProps): JSX.Element => {
  const [step, setStep] = useState(0);
  const [open, setOpen] = useState(false);
  let xDown: number | null = null;

  const toggleOpen = () => setOpen(!open);

  const handleDirection = (direction: string) => {
    if (direction === "right") {
      if (step === qualityPhotos.length - 1) {
        setStep(0);
      } else {
        setStep(step + 1);
      }
    } else {
      if (step <= 0) {
        setStep(qualityPhotos.length - 1);
      } else {
        setStep(step - 1);
      }
    }
  };

  const handleTouchStart = (evt: TouchEvent) => {
    xDown = evt.touches[0].clientX;
  };

  const handleTouchMove = (evt: TouchEvent) => {
    if (!xDown) {
      return;
    }
    const xUp = evt.touches[0].clientX;
    const xDiff = xDown - xUp;
    if (xDiff > 0) {
      handleDirection("right");
    } else {
      handleDirection("left");
    }
    xDown = null;
  };

  return (
    <Wrapper container item xs={12}>
      <img src={qualityPhotos[step]} alt="house" onClick={toggleOpen} />
      {qualityPhotos.length !== 1 && (
        <StyledMobileStepper
          steps={qualityPhotos.length}
          position="static"
          onClick={(event: any) => {
            if (event.target.classList.contains("MuiMobileStepper-root"))
              toggleOpen();
          }}
          activeStep={step}
          nextButton={
            <ArrowButton onClick={() => handleDirection("right")}>
              <KeyboardArrowRight />
            </ArrowButton>
          }
          backButton={
            <ArrowButton onClick={() => handleDirection("left")}>
              <KeyboardArrowLeft />
            </ArrowButton>
          }
        />
      )}

      <StyledDialog
        open={open}
        onClose={toggleOpen}
        maxWidth="md"
      >
        <OffIcon fontSize="large" spacing={2} onClick={toggleOpen} />
        <Grid item sm={12}>
          <Image
            src={qualityPhotos[step]}
            alt="quality house"
            onTouchStart={(e) => handleTouchStart(e)}
            onTouchMove={(e) => handleTouchMove(e)}
          />
          {qualityPhotos.length !== 1 && (
            <>
              <Pointer />
              <ButtonsMobileStepper
                steps={qualityPhotos.length}
                position="static"
                activeStep={step}
                nextButton={
                  <Fab onClick={() => handleDirection("right")}>
                    <KeyboardArrowRight />
                  </Fab>
                }
                backButton={
                  <Fab onClick={() => handleDirection("left")}>
                    <KeyboardArrowLeft />
                  </Fab>
                }
              />
            </>
          )}
        </Grid>
      </StyledDialog>
    </Wrapper>
  );
};

export default Carousel;
