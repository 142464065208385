import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Accordion, AccordionDetails,
  AccordionSummary, 
  Divider,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled
} from "@mui/material";
import React, {useCallback, useMemo, useState} from "react";
import { AdminInfoAggregate } from "../AggregateViewPage";
import EventInfo from "./EventInfo";
import {TableView, List, ArrowUpward, ArrowDownward, ExpandMore} from "@mui/icons-material";
import {useDefaultReducer} from "../../../../../helpers/hooks";

type EventsListProps = {
  events: { [key: string]: AdminInfoAggregate[] };
  Title: string
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

type State = {
  sorting: "asc" | "desc",
  grouping: "list" | "group"
  
}

const defaultState : State = {
  sorting: "desc",
  grouping: "list"
}



const EventsList = (props: EventsListProps) => {
  const [activeTab, setActiveTab] = useState("By group");
  const keys = Object.keys(props.events);

  const [state, dispatch] = useDefaultReducer(defaultState)
  
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };
  
  const handleSorting = (event: React.SyntheticEvent, newValue: string) => {
    dispatch({type: "sorting", payload: newValue})
  }

  const handleGrouping = (event: React.SyntheticEvent, newValue: string) => {
    dispatch({type: "grouping", payload: newValue})
  }

  const sortCallback = useCallback((a: AdminInfoAggregate, b: AdminInfoAggregate) => {

    if(state.sorting == "asc"){
      return new Date(a.TimeStamp).getTime() - new Date(b.TimeStamp).getTime();
    }else{
      return new Date(b.TimeStamp).getTime() - new Date(a.TimeStamp).getTime();
    }
  }, [state.sorting]);
  
  const eventsArray = useMemo(() => {
    const eventsList = keys.reduce((acc, key) => {
      return [
        ...acc,
        ...props.events[key].map((event) => ({
          ...event,
          EventName: key,
        })),
      ];
    }, [] as AdminInfoAggregate[]);

    return eventsList.sort(sortCallback);
  }, [props.events, sortCallback]);
  
  const eventGroup = useMemo(()=>{
    const events : {[key: string]: AdminInfoAggregate[]} = {};
    
    keys.forEach(x=> {events[x] = props.events[x].sort(sortCallback) })
    return events;
  }, [props.events, sortCallback])
  
  
  return (
    <Grid container spacing={1}>
      <Grid item container justifyContent="center">
        <Typography variant="h3">Events</Typography>
      </Grid>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">{props.Title}</Typography>
        </Grid>
        <Grid item sx={{
          display: 'flex',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          flexWrap: 'wrap',
        }}>
             <StyledToggleButtonGroup
                 size="small"
                 color="secondary"
                 value={state.grouping}
                 exclusive
                 onChange={handleGrouping}
             >
               <ToggleButton value="list"><List/></ToggleButton>
               <ToggleButton value="group"><TableView/></ToggleButton>
             </StyledToggleButtonGroup>
          <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
          <StyledToggleButtonGroup
              size="small"
              color="secondary"
              value={state.sorting}
              exclusive
              onChange={handleSorting}
          >
            <ToggleButton value="asc"><ArrowUpward/></ToggleButton>
            <ToggleButton value="desc"><ArrowDownward/></ToggleButton>
          </StyledToggleButtonGroup>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12}>
          {state.grouping == "list" && eventsArray.map((event) => (
              <EventInfo key={event.CommandId} event={event} elevation={1} />
          ))}
          {state.grouping == "group" && keys.map((key) => (
              <Accordion key={key}>
                <AccordionSummary expandIcon={<ExpandMore/>}>
                  <Typography>
                    {key}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {props.events[key].map((event) => (
                      <EventInfo event={event} key={event.Id} elevation={0} />
                  ))}
                </AccordionDetails>
              </Accordion>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
};

export default EventsList;
