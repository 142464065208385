export const WorkersActionTypes = {
    WORKERS_OPERATION_FAILED: "WORKERS_OPERATION_FAILED",
    GET_WORKERS: "GET_WORKERS",
    GET_WORKERS_SUCCEEDED: "GET_WORKERS_SUCCEEDED",
    GET_WORKER: "GET_WORKER",
    GET_WORKER_SUCCEEDED: "GET_WORKER_SUCCEEDED",
    GET_WORKERS_ADDITIONAL_SUCCEEDED: "GET_WORKERS_ADDITIONAL_SUCCEEDED",
    GET_UNIT_WORKERS: "GET_UNIT_WORKERS",
    GET_UNIT_WORKERS_SUCCEEDED: "GET_UNIT_WORKERS_SUCCEEDED",
    GET_UNIT_WORKERS_RATINGS_SUCCEEDED: "GET_UNIT_WORKERS_RATINGS_SUCCEEDED",
    LINK_WORKER_TO_UNIT: "LINK_WORKER_TO_UNIT",
    LINK_WORKER_TO_UNIT_SUCCEEDED: "LINK_WORKER_TO_UNIT_SUCCEEDED",
    UNLINK_WORKER_FROM_UNIT: "UNLINK_WORKER_FROM_UNIT",
    UNLINK_WORKER_FROM_UNIT_SUCCEEDED: "UNLINK_WORKER_FROM_UNIT_SUCCEEDED",
    RATE_UNIT_WORKER_SUCCEEDED: "RATE_UNIT_WORKER_SUCCEEDED",
    RATE_BUILDING_WORKER_SUCCEEDED: "RATE_BUILDING_WORKER_SUCCEEDED",
    GET_WORKER_LINK_REQUEST_SUCCEEDED: "GET_WORKER_LINK_REQUEST_SUCCEEDED",
    PROCESS_WORKER_LINK_REQUEST_SUCCEEDED: "PROCESS_WORKER_LINK_REQUEST_SUCCEEDED",
    CREATE_WORKER: "CREATE_WORKER",
    CREATE_WORKER_SUCCEEDED: "CREATE_WORKER_SUCCEEDED",
    GET_COMPANIES: "GET_COMPANIES", 
    GET_COMPANIES_SUCCEEDED: "GET_COMPANIES_SUCCEDED",
    CREATE_COMPANY: "CREATE_COMPANY",
    CREATE_COMPANY_SUCCEEDED: "CREATE_COMPANY_SUCCEEDED",
    GET_BUILDING_WORKERS: "GET_BUILDING_WORKERS",
    GET_BUILDING_WORKERS_SUCCEEDED: "GET_BUILDING_WORKERS_SUCCEEDED",
    LINK_WORKER_TO_BUILDING: "LINK_WORKER_TO_BUILDING",
    LINK_WORKER_TO_BUILDING_SUCCEEDED: "LINK_WORKER_TO_BUILDING_SUCCEEDED",
    UNLINK_WORKER_FROM_BUILDING: "UNLINK_WORKER_FROM_BUILDING",
    UNLINK_WORKER_FROM_BUILDING_SUCCEEDED: "UNLINK_WORKER_FROM_BUILDING_SUCCEEDED",
}