import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Skeleton, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import HouseInfo from "./components/HouseInfo";
import { useFamily, useUnit, useUser } from "../../../actions";
import Unit from "../../../definitions/model/unit/Unit";
import { useAlertContext } from "../../../components/Base";
import AddressHelper from "../../../helpers/address-helper";
import { GroupMembersDto } from "../../../definitions/model/Group";

const PropertyJoinPage = (): JSX.Element => {
  const { addressURL, token } = useParams<{ addressURL: string; token: string }>();
  const { t } = useTranslation("translation");
  const alert = useAlertContext();
  const navigate = useNavigate();
  const [{ loading, error }, unitActions] = useUnit();
  const [, { getUserOneFamily, acceptInviteMember }] = useFamily();
  const [{ user }, userActions] = useUser();
  const [acceptLoading, setAcceptLoading] = React.useState(false);
  const [unit, setUnit] = React.useState<Unit | null>(null);
  const [mainFamily, setFamily] = React.useState<GroupMembersDto | null>(null);

  useEffect(() => {
    if (addressURL) {
      const addressParts = addressURL.split("_");
      const id = addressParts[addressParts.length - 1];
      if (id) {
        unitActions.getUnit(id).then((u) => setUnit(u));
        if (user) getUserOneFamily(id).then((f) => setFamily(f));
      }
    }
  }, [addressURL]);

  useEffect(() => {
    if (error) {
      alert.showAlert({ severity: "error", text: error });
    }
  }, [error]);

  useEffect(() => {
    if (user && mainFamily && unit) {
      mainFamily.Members?.find((m) => m.UserId === user.Id) && navigateToUnit(unit);
    }
  }, [user, mainFamily, unit]);

  const navigateToUnit = (u: Unit) => {
    const unitUrl = `${AddressHelper.GetUnitAddressUrl(u)}/profile`;
    navigate(unitUrl);
  };

  const acceptClick = (u: Unit) => {
    if (!user) {
      const setDestinationAsync = async () => {
        await userActions.setAuthDestination(false, window.location.pathname, 1);
      };
      setDestinationAsync();
      userActions.openRegisterDialog();
      return;
    }

    setAcceptLoading(true);
    const acceptAsync = async () => {
      try {
        if (!token) return;
        await acceptInviteMember(u.Id, token);
        await userActions._getBasicData();
        navigateToUnit(u);
      } catch (e) {
        console.error(e);
      } finally {
        setAcceptLoading(false);
      }
    };

    acceptAsync();
  };

  return (
   <>
        <Stack sx={{ width: "100%" }} mb={2} spacing={2}>
          {unit && (
            <Alert
              severity="success"
              action={
                <LoadingButton
                  loading={acceptLoading}
                  color="secondary"
                  size="small"
                  variant="outlined"
                  onClick={() => acceptClick(unit)}
                >
                  {t("General.Buttons.Accept")}
                </LoadingButton>
              }
            >
              {t("Pages.PropertyFacts.JoinPropertyMessage", {
                address: AddressHelper.GetAutocompleteInput(unit.Address),
              })}
            </Alert>
          )}
          {loading && <Skeleton animation="wave" variant="text" width="100%" height={70} />}
        </Stack>
        <HouseInfo
          unit={unit}
          user={user}
          trackMoveInClick={userActions.trackInitiateMoveIn}
          isSubscribedToHousePhotoUpdate={userActions.isUserSubscribedToHousePhotoUpdate}
          subscribeOnHousePhotoUpdate={userActions.subscribeOnPhotoUpdate}
        />
        </>
  );
};

export default PropertyJoinPage;
