import ApiService from "../api.service";
import {
  BenefitClub,
  FormBuilderDTO,
  LandingArticleDto,
  LandingModel,
  RegisterBenefitClubContract,
  RegisterLandingContract,
  UpdateBenefitClubContract,
} from "../../definitions/Landing";
import {
  DashboardActionRequest,
  DashboardActionResponse,
  DashboardEntryModel,
} from "../../definitions/model/Dashboard";
import { ClubModel } from "../../definitions/model/Club";
import { DashboardUpdatingFrequency } from "../../constants/enums";
import User, { RemovedUserLogItemModel } from "../../definitions/model/User";
import { SearchResult } from "../../definitions/model/SearchResult";
import { ReplacementModel, SystemMetaModel } from "../../definitions/model/SystemMetaModel";
import { StopWatcherModel } from "../../definitions/model/StopWatcher";
import { SystemStringModel, SystemStringType } from "../../definitions/model/SystemString";

const AdminService = {
  addNews: async (data: RegisterLandingContract): Promise<LandingModel> => {
    const url = `/api/admin/landing/addnews`;
    const result = await ApiService.post<LandingModel>(url, data);
    return result;
  },
  getNews: async (filter: string, skip: number): Promise<Array<LandingArticleDto>> => {
    if (!filter) filter = " ";
    const url = `/api/admin/landing/news/${skip}/50/${filter}/`;
    const result = await ApiService.get<Array<LandingArticleDto>>(url);
    return result;
  },
  getNewsEditor: async (id: string): Promise<FormBuilderDTO> => {
    const url = `/api/admin/landing/${id}/editor`;
    const result = await ApiService.get<FormBuilderDTO>(url);
    return result;
  },
  deleteNews: async (id: string): Promise<LandingArticleDto> => {
    const url = `/api/admin/landing/news/${id}`;
    const result = await ApiService.delete<LandingArticleDto>(url);
    return result;
  },
  setNewsPublish: async (id: string, publish: boolean): Promise<LandingArticleDto> => {
    const url = `/api/admin/landing/news/${id}/publish/${publish}`;
    const result = await ApiService.put<LandingArticleDto>(url, null);
    return result;
  },
  getDashboardEntries: async (): Promise<DashboardEntryModel[]> => {
    const url = "/api/admin/dashboard/DashboardEntries";
    const result = await ApiService.get<DashboardEntryModel[]>(url);
    return result;
  },
  changeDashboardSubscription: async (request: DashboardActionRequest): Promise<DashboardActionResponse> => {
    const url = "/api/admin/dashboard/ChangeSubscription";
    const result = await ApiService.post<DashboardActionResponse>(url, request);
    return result;
  },
  getBenefitClub: async (id: string): Promise<BenefitClub> => {
    const url = `/api/admin/club/benefit/${id}`;
    const result = await ApiService.get<BenefitClub>(url);
    return result;
  },
  getBenefitClubs: async (skip: number, take: number): Promise<Array<BenefitClub>> => {
    const url = `/api/admin/club/benefit/${skip}/${take}`;
    const result = await ApiService.get<Array<BenefitClub>>(url);
    return result;
  },
  addBenefitClub: async (data: RegisterBenefitClubContract): Promise<BenefitClub> => {
    const url = "/api/admin/club/benefit";
    const result = await ApiService.post<BenefitClub>(url, data);
    return result;
  },
  setBenefitClubPublish: async (id: string, publish: boolean): Promise<BenefitClub> => {
    const url = `/api/admin/club/benefit/${id}/publish/${publish}`;
    const result = await ApiService.put<BenefitClub>(url, null);
    return result;
  },
  updateBenefitClub: async (data: UpdateBenefitClubContract): Promise<BenefitClub> => {
    const url = `/api/admin/club/benefit/${data.Id}`;
    const result = await ApiService.post<BenefitClub>(url, data);
    return result;
  },
  deleteBenefitClub: async (id: string): Promise<boolean> => {
    const url = `/api/admin/club/benefit/${id}`;
    const result = await ApiService.delete<boolean>(url);
    return result;
  },
  getClubs: async (skip: number): Promise<Array<ClubModel>> => {
    const url = `/api/admin/club?filter=&skip=${skip}&take=50`;
    const result = await ApiService.get<Array<ClubModel>>(url);
    return result;
  },
  reorederBenefitClubs: async (clubs: BenefitClub[]): Promise<BenefitClub[]> => {
    const url = "/api/admin/club/benefit/reorder";
    const result = await ApiService.put<BenefitClub[]>(url, {
      BenefitClubs: clubs,
    });
    return result;
  },
  updateDashboardEntry: async (
    id: string,
    name: string,
    frequency: DashboardUpdatingFrequency,
    environment: string
  ): Promise<DashboardEntryModel> => {
    const url = "/api/admin/dashboard/updateentry";
    const result = await ApiService.post<DashboardEntryModel>(url, {
      Id: id,
      Name: name,
      UpdateFrequency: frequency,
      Environment: environment,
    });

    return result;
  },
  createDashboardEntry: async (
    name: string,
    frequency: DashboardUpdatingFrequency,
    environment: string
  ): Promise<DashboardEntryModel> => {
    const url = "/api/admin/dashboard/createentry";
    const result = await ApiService.put<DashboardEntryModel>(url, {
      Name: name,
      UpdateFrequency: frequency,
      Environment: environment,
    });

    return result;
  },
  deleteDashboardEntry: async (id: string): Promise<void> => {
    const url = "/api/admin/dashboard/deleteentry/" + id;
    await ApiService.delete(url);
  },
  getAllUsers: async (filter: string, companyId: string | null, skip: number, take: number) => {
    let url = `/api/admin/users/?filter=${filter}&skip=${skip}&take=${take}`;
    if (companyId) url += `&companyId=${companyId}`;

    return await ApiService.get<SearchResult<User>>(url);
  },
  getRemovedUsersLog: async (filter: string, skip: number, take: number) => {
    return await ApiService.get<SearchResult<RemovedUserLogItemModel>>(
      `/api/admin/users/removedlog?filter=${filter}&skip=${skip}&take=${take}`
    );
  },
  removeUser: async (id: string) => {
    return await ApiService.delete(`/api/admin/users/${id}`);
  },
  changeUserRating: async (userId: string, rating: number) => {
    const data = {
      UserId: userId,
      Rating: rating,
    };
    return await ApiService.put(`/api/admin/users/${userId}/rating`, data);
  },
  joinUserToCompany: async (userId: string, companyId: string) => {
    return await ApiService.post(`/api/admin/users/${userId}/companies/${companyId}`, null);
  },
  removeUserFromCompany: async (userId: string, companyId: string) => {
    return await ApiService.delete(`/api/admin/users/${userId}/companies/${companyId}`, null);
  },
  getSystemMetaList: async () => {
    return await ApiService.get(`/api/admin/system-meta`);
  },
  getSystemMeta: async (id: string) => {
    return await ApiService.get(`/api/admin/system-meta/${id}`);
  },
  createSystemMeta: async (hostname: string, path: string, replacements: ReplacementModel) => {
    return await ApiService.post<SystemMetaModel>("/api/admin/system-meta", {
      hostname,
      path,
      replacements,
    });
  },
  updateSystemMeta: async (id: string, hostname: string, path: string, replacements: ReplacementModel) => {
    return await ApiService.put<SystemMetaModel>("/api/admin/system-meta", {
      aggregateRootId: id,
      hostname,
      path,
      replacements,
    });
  },
  deleteSystemMeta: async (id: string) => {
    return await ApiService.delete(`/api/admin/system-meta/${id}`);
  },
  getStopWatcherData: async () => {
    return await ApiService.get<StopWatcherModel>("/api/admin/stopwatch");
  },
  switchStopWatcher: async () => {
    return await ApiService.post<StopWatcherModel>("/api/admin/stopwatch/switch", null);
  },
  getSystemStrings: async (type?: SystemStringType) => {
    const typeParam = type ? `?type=${type}` : "";
    return await ApiService.get<SystemStringModel[]>(`/api/admin/landing/system-strings${typeParam}`);
  },
  addSystemString: async (value: string, type: SystemStringType) => {
    const data = {
      Value: value,
      Type: type,
    };
    return await ApiService.post<SystemStringModel>("/api/admin/landing/system-strings", data);
  },
};

export default AdminService;
