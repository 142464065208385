import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Grid,
  Button,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  List,
  Divider,
  TextField,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { ServiceBookItemCraftsmenSelection, ServiceBookItemToFix } from "../../../../definitions/model/ServiceBook";
import { CustomWorkerRequest, WorkerModel } from "../../../../definitions/model/Worker";
import WorkerComponent from "../../../Workers/components/worker-component";
import WorkerHelper from "../../../../helpers/worker-helper";

type ChooseSectionWorkersProps = {
  item: ServiceBookItemToFix;
  craftsmenToFilter: number[];
  postCode: number;
  communeCode: number[];
  open: boolean;
  workers: WorkerModel[];
  unitWorkerIds: string[];
  requestToggle: () => void;
  loading: boolean;
  getWorkers: (customWorkerRequest: CustomWorkerRequest, skip: number, take: number) => void;
  selectWorkers: (selection: ServiceBookItemCraftsmenSelection) => void;
  filter: string;
  resultDraft: ServiceBookItemCraftsmenSelection[];
};

type WorkerSelection = {
  worker: WorkerModel;
  checked: boolean;
};

const ChooseSectionWorkersComponent = (props: ChooseSectionWorkersProps) => {
  const { t } = useTranslation("translation");
  const loadingPageSize = 5;
  const headerRef = useRef<HTMLDivElement>(null);
  const [mappedWorkers, setMappedWorkers] = useState<WorkerSelection[]>([]);
  const [comment, setComment] = useState("");
  const [postCodeFilter, setPostCodeFilter] = useState(0);
  const [communeFilter, setCommuneFilter] = useState<number[]>([]);
  const [skip, setSkip] = useState(0);
  const [isListRendered, setIsListRendered] = useState(false);

  useEffect(() => {
    if (props.open) {
      props.getWorkers(
        {
          SearchString: props.filter,
          FilterByType: (props.craftsmenToFilter && props.craftsmenToFilter.length>0),
          PostCode: postCodeFilter,
          CommuneCode: communeFilter,
          Types:(props.craftsmenToFilter && props.craftsmenToFilter.length>0)? props.craftsmenToFilter : WorkerHelper.GetWorkerTypes(props.filter),
        },
        0,
        loadingPageSize
      );
      setSkip(0);
    }
  }, [props.open, props.filter, communeFilter]);

  const handleLoadMore = () => {
    const newSkip = skip + loadingPageSize;
    props.getWorkers(
      {
        SearchString: props.filter,
        FilterByType: (props.craftsmenToFilter && props.craftsmenToFilter.length>0),
        PostCode: postCodeFilter,
        CommuneCode: communeFilter,
        Types:(props.craftsmenToFilter && props.craftsmenToFilter.length>0)? props.craftsmenToFilter : WorkerHelper.GetWorkerTypes(props.filter),
      },
      newSkip,
      loadingPageSize
    );
    setSkip(newSkip);
  };

  useEffect(() => {
    setPostCodeFilter(props.postCode);
  }, [props.postCode]);

  useEffect(() => {
    if (!communeFilter.length) setCommuneFilter(props.communeCode);
  }, [props.communeCode]);

  useEffect(() => {
    handleChoose(mappedWorkers, comment);
  }, [comment]);

  useEffect(() => {
    // unit workers should be on top
    // green workers should be next to the unit workers
    // others are at the end

    const unitWorkers = props.workers
      .filter((x) => props.unitWorkerIds.some((id) => x.Id === id))
      .sort((a, b) => (a.Organization < b.Organization ? -1 : 1));
    const workers = unitWorkers
      .concat(
        props.workers
          .filter((x) => x.GreenTag && !props.unitWorkerIds.some((id) => x.Id === id))
          .sort((a, b) => (a.Organization < b.Organization ? -1 : 1))
      )
      .concat(props.workers.filter((x) => !props.unitWorkerIds.some((id) => x.Id === id) && !x.GreenTag))
      .map((worker) => ({
        worker,
        checked: !!props.resultDraft.find((x) => x.item.id === props.item.id && x.craftsmenIds.includes(worker.Id)),
      }));

    setMappedWorkers(workers);

    if (!isListRendered && props.open && !props.loading) {
      setIsListRendered(true);
      scrollToView();
    }
  }, [props.workers, props.unitWorkerIds, props.resultDraft]);

  useEffect(
    () => () => {
      props.open && setIsListRendered(false);
    },
    [props.open]
  );

  const handleCheckboxCheck = (checked: boolean, workerId: string) => {
    const newRecords = mappedWorkers.map((record) => (record.worker.Id === workerId ? { ...record, checked } : record));
    setMappedWorkers(newRecords);

    handleChoose(newRecords, comment);
  };

  const handleChoose = (records: WorkerSelection[], currentComment: string) => {
    const selection = {
      item: props.item,
      craftsmenIds: records.filter((record) => record.checked).map((record) => record.worker.Id),
      ownerNote: currentComment,
    };
    props.selectWorkers(selection);
  };

  const scrollToView = () => {
    setTimeout(
      () =>
        headerRef.current!.scrollIntoView({
          behavior: "smooth",
          block: "start",
        }),
      0
    );
  };

  const resetFilters = () => {
    setCommuneFilter([]);
    setPostCodeFilter(0);
  };

  return (
    <div ref={headerRef}>
      <ListItem style={{ cursor: "pointer" }} onClick={props.requestToggle}>
        <ListItemText>{props.item.name}</ListItemText>
        <ListItemIcon>{props.open ? <ArrowDropUp /> : <ArrowDropDown />}</ListItemIcon>
      </ListItem>
      <Collapse in={props.open} unmountOnExit>
        {props.loading ? (
          <Grid container justifyContent="center" style={{ marginBottom: 10 }}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container direction="column">
            {!props.loading && !mappedWorkers.length && (!!communeFilter.length || postCodeFilter !== 0) && (
              <ListItem>
                <Typography>{t("ServiceBook.ChooseWorkers.NoWorkersInCommune")}</Typography>
                <Button onClick={resetFilters} variant="contained" color="secondary" style={{ margin: 10 }}>
                  {t("ServiceBook.ChooseWorkers.ShowAll")}
                </Button>
              </ListItem>
            )}

            {!props.loading && !mappedWorkers.length && (!communeFilter.length || postCodeFilter === 0) && (
              <ListItem>
                <Typography>{t("ServiceBook.ChooseWorkers.NoWorkers")}</Typography>
              </ListItem>
            )}

            {!!mappedWorkers.length && (
              <>
                <List disablePadding sx={{ width: "100%", marginBottom: 4 }}>
                  {mappedWorkers.map((record, i) => (
                    <Grid container direction="row" key={i} alignItems="center">
                      <Grid item>
                        <Checkbox
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                          checked={record.checked}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            handleCheckboxCheck(event.target.checked, record.worker.Id)
                          }
                        />
                      </Grid>
                      <Grid item style={{ flex: 1 }}>
                        <WorkerComponent
                          worker={record.worker}
                          linked={props.unitWorkerIds.some((x) => x === record.worker.Id)}
                          showControls={false}
                          error={null}
                          linkWorkerToUnit={() => Promise.resolve(undefined)}
                          unlinkWorkerFromUnit={() => Promise.resolve()}
                          rateWorker={() => Promise.resolve()}
                        />
                      </Grid>
                    </Grid>
                  ))}
                  {!props.loading &&
                    mappedWorkers.length > 0 &&
                    props.filter === "" &&
                    mappedWorkers.length % loadingPageSize === 0 && (
                      <Grid container justifyContent="center" mt={2}>
                        <Button variant="contained" color="secondary" onClick={handleLoadMore}>
                          {t("General.Buttons.ShowMore")}
                        </Button>
                      </Grid>
                    )}
                </List>
                <TextField
                  label={t("Base.Dialog.Comment")}
                  multiline={true}
                  value={comment}
                  onChange={(event) => setComment(event.target.value)}
                  InputProps={{
                    sx: {
                      backgroundColor: "transparent",
                    },
                  }}
                ></TextField>
              </>
            )}
          </Grid>
        )}
      </Collapse>
      <Divider />
    </div>
  );
};

export default ChooseSectionWorkersComponent;
