import { Reducer } from "react";

import { AppAction } from "../definitions/Action";
import { UnitConstants, UserConstants } from "../constants";
import Unit, { UnitNeighbourHouse, Broker } from "../definitions/model/unit/Unit";

import { HouseEconomyModel } from "../definitions/model/unit";
import { MapActionTypes } from "../constants/map.actiontypes";
import { ChartData } from "../definitions/Chart";

export interface UnitStoreState {
  loading: boolean;
  unit: Unit | null;
  aboutData: any;
  economy: HouseEconomyModel | null;
  neighbourHouses: UnitNeighbourHouse[];
  brokers: Broker[];
  error: string | null;
  bankChartData?: ChartData[];
}

export const initialState: UnitStoreState = {
  unit: null,
  aboutData: null,
  economy: null,
  neighbourHouses: [],
  brokers: [],
  loading: false,
  error: null,
};

const unitReducer: Reducer<UnitStoreState | undefined, AppAction> = (
  state: UnitStoreState = initialState,
  action: AppAction
): UnitStoreState | undefined => {
  switch (action.type) {
    case UnitConstants.GET_ABOUT_DATA:
    case UnitConstants.GET_HOUSE_ECONOMY:
    case UnitConstants.GET_HOUSE_MARKET_PRICE:
    case UnitConstants.GET_UNIT:
    case UnitConstants.GET_MOVED_IN_PROPERTY:
    case UserConstants.MOVE_IN_UNIT:
    case UnitConstants.GET_PENDING_PROPERTY:
    case UnitConstants.GET_HOUSE_NEIGHBOUR_HOUSES:
    case MapActionTypes.GET_UNIT_RISK_FLAGS:
      return { ...state, loading: true };
    case UnitConstants.GET_PENDING_PROPERTY_SUCCEEDED:
    case UnitConstants.GET_UNIT_SUCCEEDED:
      return {
        ...state,
        loading: false,
        unit: action.payload,
      };
    case UnitConstants.GET_ABOUT_DATA_SUCCEEDED:
      return {
        ...state,
        aboutData: action.payload,
        loading: false,
      };
    case UnitConstants.GET_MOVED_IN_PROPERTY_SUCCEEDED:
      return {
        ...state,
        unit: action.payload,
        loading: false,
      };
    case UserConstants.CHANGE_USER_CURRENT_HOUSE_SUCCEEDED:
    case UserConstants.TRY_USER_SUCCEEDED:
      return {
        ...state,
        unit: action.payload.Unit,
      };
    case UnitConstants.GET_HOUSE_MARKET_PRICE_SUCCEEDED:
      return {
        ...state,
        neighbourHouses: action.payload.neighbourHouses,
        brokers: action.payload.brokers,
        economy: action.payload.economy,
        loading: false,
      };
    case UnitConstants.GET_HOUSE_NEIGHBOUR_HOUSES_SUCCEEDED:
      return { ...state, neighbourHouses: action.payload, loading: false };
    case UnitConstants.GET_HOUSE_ECONOMY_SUCCEEDED:
      return {
        ...state,
        economy: action.payload.economy,
        loading: false,
      };
    case UserConstants.MOVE_OUT_UNIT_SUCCESS:
      return {
        ...state,
        unit: action.payload?.unit,
      };
    case UserConstants.REMOVE_USER_SUCCEEDED:
      return {
        ...state,
        unit: null,
        loading: false,
      };
    case UnitConstants.UNIT_OPERATION_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case MapActionTypes.GET_UNIT_RISK_FLAGS_SUCCEEDE:
      return {
        ...state,
        unit: !state.unit
          ? state.unit
          : {
              ...state.unit,
              UnitRisks: action.payload,
            },
      };
    case UnitConstants.SET_BANK_CHART_DATA:
      return {
        ...state,
        bankChartData: action.payload,
      };
    case UserConstants.LOGOUT: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default unitReducer;
