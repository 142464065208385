import {List, ListItem, ListItemText} from "@mui/material";
import React, { useEffect, useState } from "react";
import { isId } from "../AggregateViewPage";
import FindEventsBlock from "./FindEventsBlock";

type Claim = {
  Type: string;
  Value: string;
};

type ClaimsListProps = {
  claims: Claim[];
  findEventHandler: (id: string, type: string) => void;
  loading: boolean;
};
const ClaimsList = (props: ClaimsListProps) => {
  const [isListOpen, setIsListOpen] = useState(false);

  useEffect(() => {
    if (!props.loading && isListOpen) {
      setIsListOpen(false);
    }
  }, [props.loading]);

  return (
      <List disablePadding dense>
        {props.claims.map((claim) => (
            <ListItem
                key={claim.Type+claim.Value}
                secondaryAction={isId(claim.Value) && (
                    <FindEventsBlock
                        id={claim.Value}
                        name={claim.Type.split("/").pop() || ""}
                        onClick={props.findEventHandler}
                    />
                )}
            >
              <ListItemText primary={`Name: ${claim.Type.split("/").pop() || ""}`} secondary={`Value: ${claim.Value}`}/>
            </ListItem>))}
      </List>
  );
};

export default ClaimsList;
