import { SearchResult } from "../definitions/model/SearchResult";
import { CommentModel, NewsStreamDTO } from "../definitions/NewsStream";
import ApiService from "./api.service";

const commentEndpoint = "/api/message";
const commentAdminEndpoint = "/api/admin/CommentModeration"

const CommentService = {
  getComment: async (commentId: string) => {
    const url = `${commentEndpoint}/comment/${commentId}`;
    const result = await ApiService.get<NewsStreamDTO>(url);
    return result;
  },

  getComments: async (
    parentId: string,
    skip: number,
    take: number
  ): Promise<CommentModel[]> => {
    const url = `${commentEndpoint}/comments/${parentId}?skip=${skip}&take=${take}`;
    const result = await ApiService.get<CommentModel[]>(url);
    return result;
  },
  postComment: async (comment: Partial<CommentModel>) => {
    const url = `${commentEndpoint}/comment`;
    const result = await ApiService.post<CommentModel>(url, comment);
    return result;
  },
  putComment: async (comment: CommentModel) => {
    const url = `${commentEndpoint}/comment`;
    const result = await ApiService.put<CommentModel>(url, comment);
    return result;
  },
  deleteComment: async (id: string) => {
    const url = `${commentEndpoint}/comment/${id}`;
    const result = await ApiService.delete(url);
    return result;
  },
  getNotApprovedComments: async (skip: number, take: number
  ): Promise<SearchResult<CommentModel>> => {
    const url = `${commentAdminEndpoint}/notApprovedComments?skip=${skip}&take=${take}`;
    const result = await ApiService.get<SearchResult<CommentModel>>(url);
    return result;
  },
  approveComment: async (id: string) => {
    const url = `${commentAdminEndpoint}/approveComment/${id}`;
    await ApiService.post(url, {});
  },
};

export default CommentService;
