import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { styled } from "@mui/material";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
   zIndex: 5,
   position: "fixed",
   bottom: 40,
   right: 40,
   backgroundColor: theme.palette.background.grayPaper,
   border: "1px solid" + " " + theme.palette.divider         
  }));

const ScrollToTopButton = (): JSX.Element => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 200) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return <>{showTopBtn && <StyledIconButton onClick={goToTop}><ArrowUpwardIcon /></StyledIconButton>}</>;
};

export default ScrollToTopButton;
