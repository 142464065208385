import React, { useEffect, useReducer } from "react";
import {
  CircularProgress,
  Typography,
} from "@mui/material";
import HousePrices from "../House/HousePrices/HousePrices";
import { ApplicationState } from "../../reducers/store";
import { AppThunkDispatch } from "../../definitions/Action";
import { connect, ConnectedProps } from "react-redux";
import {
  getNeighbourHouses,
  updateExtraPriceUserInput,
  subscribeOnHousePriceChanges,
} from "../../actions";
import {
  ExtraPriceUserInput,
  PriceEstimation,
} from "../../definitions/model/unit";
import HouseNeighbourhood from "../House/HousePrices/HouseNeighbourhood";
import ChangesAlert from "./components/ChangesAlert";
import HouseExtraPriceUserInput from "../House/HousePrices/HouseExtraPriceUserInput";
import { useTranslation } from "react-i18next";

type PriceSubscriptionState = {
  lastEstimation: PriceEstimation | null;
  alertShown: boolean;
};

const initialState: PriceSubscriptionState = {
  lastEstimation: null,
  alertShown: true,
};

const reducer = (
  state: PriceSubscriptionState,
  action: { type: string; payload: unknown }
) => {
  return { ...state, [action.type]: action.payload };
};

const PriceSubscriptionPage = (
  props: PriceSubscriptionConnectedProps
): JSX.Element => {
  const {
    unit,
    user,
    loading,
    neighborHouses,
    getNeigbourHouses,
    updateExtraPriceUserInput,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { t } = useTranslation("translation");

  const setAlertShown = (value: boolean) =>
    dispatch({ type: "alertShown", payload: value });

  useEffect(() => {
    if (unit) {
      const lastEstimation = unit.EstimationData.sort(
        (a, b) =>
          new Date(b.CalculationDateTime).getTime() -
          new Date(a.CalculationDateTime).getTime()
      )[0];
      getNeigbourHouses(unit.Id);
      dispatch({ type: "lastEstimation", payload: lastEstimation });
    }
  }, [unit]);

  return (
    <>
        {user && state.alertShown && (
          <ChangesAlert user={user} hideAlert={() => setAlertShown(false)}>
            <Typography paragraph mt={4}>
              {t("Subscriptions.NotificationBecauseOfExperiencingChange")}
            </Typography>
            <Typography paragraph>
              {t("Subscriptions.RecalculationPriceOfYourHome")}
            </Typography>
            <Typography paragraph>
              {t("Subscriptions.IfHaveQuestions")}
            </Typography>
            <Typography>{t("Subscriptions.KindRegardsMyhouseTeam", { hostname: window.location.hostname })}</Typography>
          </ChangesAlert>
        )}
        <Typography variant="h3">{t("Subscriptions.ValueOfHome")}</Typography>
        <Typography>
          {t("Subscriptions.BelowYouWillFindWhatCalculated")}
        </Typography>
        {unit && user && state.lastEstimation ? (
          <>
            <HousePrices
              // lastEstimation={state.lastEstimation}
              isLoading={loading}
              unit={unit}
              subscribeOnHousePriceChanges={props.subscribeOnHousePriceChanges}
            />
            <HouseExtraPriceUserInput
              unit={unit}
              user={user}
              estimatedPrice={state.lastEstimation.EstimatedSalePrice}
              updateExtraPriceUserInput={updateExtraPriceUserInput}
            />
          </>
        ) : (
          <CircularProgress />
        )}
        <Typography variant="h3">{t("Subscriptions.Reference")}</Typography>
        <Typography paragraph>
          {t("Subscriptions.WeCalculatedHomeValue")}
        </Typography>
        <HouseNeighbourhood
          neighbourHouses={neighborHouses ? neighborHouses : []}
          isLoading={loading}
        />
      </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.user,
  unit: state.unit.unit,
  neighborHouses: state.unit.neighbourHouses,
  loading: state.unit.loading,
});
const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  updateExtraPriceUserInput: (unitId: string, data: ExtraPriceUserInput) =>
    dispatch(updateExtraPriceUserInput(unitId, data)),
  getNeigbourHouses: (unitId: string) =>
    dispatch(getNeighbourHouses(unitId, 20)),
  subscribeOnHousePriceChanges: (unitId: string) =>
    dispatch(subscribeOnHousePriceChanges(unitId)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PriceSubscriptionConnectedProps = ConnectedProps<typeof connector>;
export default connector(PriceSubscriptionPage);
