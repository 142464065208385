import React from "react";
import { ButtonProps, Button, styled } from "@mui/material";
import FacebookIcon from "../Icons/FacebookIcon";
import { colorType, getColorByType } from "../../constants/theme";

const ButtonRoot = styled(Button, {
  shouldForwardProp: (propName: string) =>
    propName !== "buttonType" && propName !== "noXPaddings",
})<{ buttonType: colorType; noXPaddings: true | undefined }>(
  ({ theme, buttonType, noXPaddings }) => ({
    "& .MuiButton-label": {
      textTransform: "none",
    },
    "&.MuiButton-sizeSmall": {
      width: "180px",
      padding: "12px 0",
      [theme.breakpoints.only("xs")]: {
        padding: "6px 0",
      },
    },
    "&.MuiButton-sizeLarge": {
      padding: "11px 70px",
    },

    backgroundColor: getColorByType(buttonType).main,
    color: getColorByType(buttonType).contrastText,
    "&:hover": {
      backgroundColor: getColorByType(buttonType).dark,
    },
    "&:disabled": {
      backgroundColor: getColorByType(buttonType).light,
      color: getColorByType(buttonType).contrastText,
    },
    paddingLeft: noXPaddings ? "0 !important" : "",
    paddingRight: noXPaddings ? "0 !important" : "",
  })
);

interface StyledButtonProps extends ButtonProps {
  buttonType?: colorType;
  noXPaddings?: true;
}

export default function MyhouseButton({
  buttonType,
  noXPaddings,
  ...other
}: StyledButtonProps) {
  return (
    <ButtonRoot
      variant="contained"
      startIcon={buttonType === "facebook" ? <FacebookIcon /> : undefined}
      {...other}
      buttonType={buttonType}
      noXPaddings={other.fullWidth || noXPaddings}
    ></ButtonRoot>
  );
}
