export const CO2PlanActionTypes = {
    GET_CO2_PLAN: "GET_CO2_PLAN",
    SET_CO2_PLAN: "SET_CO2_PLAN",
    SET_CO2_PLAN_LAST_STEP: "SET_CO2_PLAN_LAST_STEP",
    REGISTER_CO2_PLAN: "REGISTER_CO2_PLAN",
    CO2_PLAN_OPERATION_FAILED: "CO2_PLAN_OPERATION_FAILED",
    SET_CO2_SURVEY: "SET_CO2_SURVEY",
    MARK_CO2_TIMELINE_FULFILLED: "MARK_CO2_TIMELINE_FULFILLED",
    SAVE_CO2_SURVEY_ERROR: "SAVE_CO2_SURVEY_ERROR",
    GET_TOTAL_CO2_PLANS_COUNT: "GET_TOTAL_CO2_PLANS_COUNT",
    GET_TOTAL_CO2_PLANS_COUNT_SUCCEEDED: "GET_TOTAL_CO2_PLANS_COUNT_SUCCEEDED",
    TRACK_SPONSOR_SOLUTIONS_OPEN: "TRACK_SPONSOR_SOLUTIONS_OPEN",
    TRACK_CO2_SURVEY_FILLED_IN: "TRACK_CO2_SURVEY_FILLED_IN",
    SET_CO2_ARTICLES: "SET_CO2_ARTICLES",
    SET_TEMP_CO2_PLANID: "SET_TEMP_CO2_PLANID",
    MAP_TEMP_CO2_PLAN_TO_UNIT: "MAP_TEMP_CO2_PLAN_TO_UNIT",
    MAP_TEMP_CO2_PLAN_TO_UNIT_SUCCEEDED: "MAP_TEMP_CO2_PLAN_TO_UNIT_SUCCEEDED",
    REGISTER_CO2_ECONOMY_ITEM_CLICK_SUCCEEDED: "REGISTER_CO2_ECONOMY_ITEM_CLICK_SUCCEEDED",
    SET_MULTISELECT: "SET_MULTISELECT",
    SET_UPDATED_QUESTIONS: "SET_UPDATED_QUESTIONS",
    GET_SPONSOR_SOLUTIONS: "GET_SPONSOR_SOLUTIONS",
};