export const UnitConstants = {
    GET_UNIT: 'GET_UNIT',
    GET_UNIT_SUCCEEDED: 'GET_UNIT_SUCCEEDED',
    GET_ABOUT_DATA: 'GET_ABOUT_UNIT_DATA',
    GET_ABOUT_DATA_SUCCEEDED: 'GET_ABOUT_DATA_SUCCEEDED',
    UNIT_OPERATION_FAILED: 'UNIT_OPERATION_FAILED',
    GET_MOVED_IN_PROPERTY: 'GET_MOVED_IN_PROPERTY',
    GET_MOVED_IN_PROPERTY_SUCCEEDED: 'GET_MOVED_IN_PROPERTY_SUCCEEDED',
    GET_PENDING_PROPERTY: 'GET_PENDING_PROPERTY',
    GET_PENDING_PROPERTY_SUCCEEDED: 'GET_PENDING_PROPERTY_SUCCEEDED',
    GET_HOUSE_MARKET_PRICE:'GET_HOUSE_MARKET_PRICE',
    GET_HOUSE_MARKET_PRICE_SUCCEEDED: 'GET_HOUSE_MARKET_PRICE_SUCCEEDED',
    GET_HOUSE_ECONOMY: "GET_HOUSE_ECONOMY",
    GET_HOUSE_ECONOMY_SUCCEEDED: "GET_HOUSE_ECONOMY_SUCCEEDED",
    GET_HOUSE_NEIGHBOUR_HOUSES: "GET_HOUSE_NEIGHBOUR_HOUSES",
    GET_HOUSE_NEIGHBOUR_HOUSES_SUCCEEDED: "GET_HOUSE_NEIGHBOUR_HOUSES_SUCCEEDED",
    UPDATE_PRICE_INPUT: "UPDATE_PRICE_INPUT",
    UPDATE_PRICE_INPUT_SUCCEEDED: "UPDATE_PRICE_INPUT_SUCCEEDED",
    GET_LOAN_CALCULATION: "GET_LOAN_CALCULATION",
    GET_LOAN_CALCULATION_SUCCEEDED: "GET_LOAN_CALCULATION_SUCCEEDED",
    TOGGLE_SHARE_MODEL: "TOGGLE_SHARE_MODEL",
    TOGGLE_SHARE_MODEL_SUCCEEDED: "TOGGLE_SHARE_MODEL_SUCCEEDED",
    GET_OWNED_HOUSES:"GET_OWNED_HOUSES",
    GET_OWNED_HOUSES_SUCCEDED:"GET_OWNED_HOUSES_SUCCEEDED",
    GET_OWNED_HOUSES_COUNT:"GET_OWNED_HOUSES_COUNT",
    GET_OWNED_HOUSES_COUNT_SUCCEDED:"GET_OWNED_HOUSES_COUNT_SUCCEEDED",
    TRACK_SEARCH_AUTOCOMPLETE_FILLED_IN: "TRACK_SEARCH_AUTOCOMPLETE_FILLED_IN",
    SET_BANK_CHART_DATA: "SET_BANK_CHART_DATA",
};