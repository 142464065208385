import { NIL } from "uuid";
import { AdminActionTypes } from "../../constants/admin/admin.actiontypes";
import { AdminNeighborGroupActionTypes } from "../../constants/admin/admin.neighborgroup.actiontypes";
import { NeighborGroup } from "../../definitions/model/NeighborGroup";
import { SearchResult } from "../../definitions/model/SearchResult";

export interface NeighborGroupAdminStoreState {
  groups: SearchResult<NeighborGroup>;
  currentGroup?: NeighborGroup;
  loading: boolean;
}

const emptyGroup: NeighborGroup = {
  Id: NIL,
  Created: new Date(),
  Updated: new Date(),
  Name: "",
  Icon: "",
  Description: "",
  Announcements: [],
  OwnerId: "",
  Linked: false,
  NewMessages: false,
  newAnnouncements: false,
  MembersCount: 0,
  Range: 0,
  IsPrivate: false,
  Administrators: [],
  Settings: {
    ConfirmedMember: false,
    ReceiveEmails: false,
    ReceiveSms: false,
  },
  Documents: [],
  Picture: {
    Id: NIL,
    Name: "",
    FileExtension: "",
    Url: undefined,
    AbsoluteUrl: undefined,
    File: undefined,
  },
  IsLocal: false,
  ExternalInvitedMembers: []
};

export const initialState: NeighborGroupAdminStoreState = {
  groups: {
    Count: 0,
    Results: [],
  },
  currentGroup: emptyGroup,
  loading: false,
};

const reducer = (
  state: NeighborGroupAdminStoreState = initialState,
  action: { type: keyof typeof AdminNeighborGroupActionTypes; payload: any }
) => {
  switch (action.type) {
    case AdminNeighborGroupActionTypes.GET_NEIGHBORGROUPS:
    case AdminNeighborGroupActionTypes.GET_NEIGHBORGROUP:
    case AdminNeighborGroupActionTypes.CREATE_NEIGHBORGROUP:
    case AdminNeighborGroupActionTypes.UPDATE_NEIGHBORGROUP:
    case AdminNeighborGroupActionTypes.DELETE_NEIGHBORGROUP:
      return { ...state, loading: true };
    case AdminActionTypes.ADMIN_OPERATION_FAILED:
      return { ...state, loading: false };
    case AdminNeighborGroupActionTypes.GET_NEIGHBORGROUPS_SUCCEEDED:
      return { ...state, groups: action.payload, loading: false };
    case AdminNeighborGroupActionTypes.CREATE_NEIGHBORGROUP_SUCCEEDED:
      return {
        ...state,
        groups: { Results: [...state.groups.Results, action.payload], Count: state.groups.Count++ },
        loading: false,
      };
    case AdminNeighborGroupActionTypes.DELETE_NEIGHBORGROUP_SUCCEEDED:
      return {
        ...state,
        groups: { Results: state.groups.Results.filter((x) => x.Id != action.payload), Count: state.groups.Count-- },
        loading: false,
      };
    case AdminNeighborGroupActionTypes.GET_NEIGHBORGROUP_SUCCEEDED:
    case AdminNeighborGroupActionTypes.UPDATE_NEIGHBORGROUP_SUCCEEDED:
      return { ...state, currentGroup: action.payload };
    case AdminNeighborGroupActionTypes.CLEAR_CURRENT:
      return { ...state, currentGroup: emptyGroup };
    default:
      return state;
  }
};

export default reducer;
