import * as signalR from "@microsoft/signalr";
import { AnyAction, Middleware, MiddlewareAPI } from "redux";
import ReactGA from "react-ga4";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import ReactPixel from "react-facebook-pixel";

import { PostBoxActions } from "../constants/postbox.actiontypes";
import { UserConstants, UnitConstants, ChatConstants } from "../constants";
import UserService from "../services/user.service";
import { CO2PlanActionTypes } from "../pages/ClimatePlan/shared/co2plan.actiontypes";
import CO2PlanService from "../pages/ClimatePlan/shared/co2plan.service";
// import UnitHelper from "../helpers/unit-helper";
// import { PriceEstimationUnitTypes } from "../definitions/model/unit";
import { ApplicationState } from "./store";
import { ServiceBookActions } from "../constants/servicebook.actiontypes";
import { ServiceBookService } from "../services/servicebook.service";
import { ThunkDispatch } from "redux-thunk";
import { ChatDto, ChatMessage } from "../definitions/model/Chat";
import { NeighborGroupAnnouncement } from "../definitions/model/NeighborGroup";
import { NeighborGroupConstants } from "../constants/neighborgroup.constants";
import { CommentConstants } from "../constants/comment.constants";

const startSignalR = (store: MiddlewareAPI<ThunkDispatch<ApplicationState, any, AnyAction>, ApplicationState>) => {
  const connection = store?.getState().user?.hubConnection;
  if (connection?.state === signalR.HubConnectionState.Disconnected) {
    connection
      .start()
      .then(() => {
        store.dispatch({
          type: UserConstants.START_SIGNALR_SUCCEEDED,
          payload: connection,
        });
      })
      .catch((error) => {
        console.log(error);
        const regexp = /.*[Status code].*[503].*/;
        if (error.errroType === "FailedToNegotiateWithServerError" && regexp.test(error.message)) {
          store.dispatch({
            type: "SET_IS_MAINTENANCE",
          });
        }
      });
  }
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const signalrConnector: Middleware<{}, ApplicationState> = (store) => (next) => async (action) => {
  const userState = store.getState().user;
  const user = userState.user;
  switch (action.type) {
    case UserConstants.START_SIGNALR:
      if (user) {
        setTimeout(() => {
          // const transport = signalR.HttpTransportType.WebSockets;
          const options: signalR.IHttpConnectionOptions = {
            // transport,
            logger: signalR.LogLevel.Debug,
            logMessageContent: true,
            skipNegotiation: false,
            accessTokenFactory: () => user.Token,
          };
          const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl("/apphub", options)
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Debug)
            .build();

          hubConnection.on("NewMail", (data: any) => {
            store.dispatch({
              type: PostBoxActions.POSTBOX_NEW_MAIL_RECEIVED,
              payload: data,
            });
          });
          hubConnection.on("GroupJoinRequestAccepted", async (data: any) => {
            store.dispatch({
              type: UserConstants.MOVE_IN_UNIT_SUCCESS,
              payload: data.User,
            });
            store.dispatch({
              type: UnitConstants.GET_MOVED_IN_PROPERTY_SUCCEEDED,
              payload: data.Unit,
            });

            UserService.updateLocalStorageUser(data.User);
          });

          hubConnection.on("UpdateTasklist", async () => {
            const unit = store.getState().unit;
            if (unit.unit) {
              store.dispatch({ type: ServiceBookActions.GET_UNIT_TASKS });
              try {
                const result = await ServiceBookService.getTasks(unit.unit.Id);
                store.dispatch({
                  type: ServiceBookActions.GET_UNIT_TASKS_SUCCEEDED,
                  payload: result.Results,
                });
              } catch {
                //do nothing
              }
            }
          });

          hubConnection.on("setChats", (data: ChatDto[]) => {
            store.dispatch({
              type: ChatConstants.SET_CHATS,
              payload: data,
            });
          });

          hubConnection.on("receiveChatMessage", (data: ChatMessage) => {
            store.dispatch({
              type: ChatConstants.RECEIVE_CHAT_MESSAGE,
              payload: data,
            });
          });

          hubConnection.on("connectToChat", (data: ChatDto) => {
            store.dispatch({
              type: ChatConstants.CONNECT_TO_CHAT,
              payload: data,
            });
          });
          hubConnection.on("newChat", (data: ChatDto) => {
            store.dispatch({
              type: ChatConstants.NEW_CHAT,
              payload: data,
            });
          });
          hubConnection.on("newAnnouncement", (data: NeighborGroupAnnouncement) => {
            store.dispatch({
              type: NeighborGroupConstants.NEW_ANNOUNCEMENT_RECEIVED,
              payload: data,
            });
          });

          hubConnection.on("announcementUpdated", (data: NeighborGroupAnnouncement) => {
            store.dispatch({
              type: NeighborGroupConstants.ANNOUNCEMENT_UPDATED,
              payload: data,
            });
          });

          hubConnection.on("announcementRemoved", (data: { GroupId: string; AnnouncementId: string }) => {
            store.dispatch({
              type: NeighborGroupConstants.ANNOUNCEMENT_REMOVED,
              payload: data,
            });
          });

          hubConnection.on("commentAdded", (data: Comment) => {
            store.dispatch({
              type: CommentConstants.ADD_COMMENT,
              payload: data,
            });
          });

          hubConnection.on("commentRemoved", (data: string) => {
            store.dispatch({
              type: CommentConstants.REMOVE_COMMENT,
              payload: data,
            });
          });

          // hubConnection.onclose(() =>
          //   setTimeout(() => {
          //     if (user) startSignalR(hubConnection, store);
          //   })
          // );

          hubConnection.on("ReceiveChatMessageConfirmation", (data: ChatMessage) => {
            store.dispatch({
              type: ChatConstants.RECEIVE_CHAT_MESSAGE_CONFIRMATION,
              payload: data,
            });
          });

          store.dispatch({
            type: UserConstants.INIT_SIGNALR_SUCCEEDED,
            payload: hubConnection,
          });

          setInterval(() => startSignalR(store), 2000);
        }, parseInt(process.env.REACT_APP_SIGNALR_START_DELAY || "0"));
      }
      break;
    case UserConstants.STOP_SIGNALR:
      if (userState.hubConnection) {
        userState.hubConnection.stop();
        store.dispatch({ type: UserConstants.STOP_SIGNALR_SUCCEEDED });
      }
      break;
    default:
      break;
  }

  return next(action);
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const actionLogger: Middleware<{}, ApplicationState> = (store) => (next) => async (action) => {
  console.info(new Date().toLocaleString(), "action", action.type);
  console.log("prev state", store.getState());
  console.log("action", action);
  const result = next(action);
  console.log("next state", store.getState());
  return result;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const googleAnalyticsTracker: Middleware<{}, ApplicationState> = (store) => (next) => async (action) => {
  let event = null;
  const user = UserService.getCurrentUser();
  switch (action.type) {
    case UserConstants.REGISTER_SUCCESS:
      event = {
        category: "User",
        action: "Registered",
      };
      break;
    case UserConstants.INITIATE_MOVE_IN:
      event = {
        category: "User",
        action: "InitiateMovedIn",
      };
      break;
    case UserConstants.MOVE_IN_UNIT_SUCCESS:
      event = {
        category: "User",
        action: "MovedIn",
      };
      break;
    case UserConstants.REMOVE_USER:
      event = {
        category: "User",
        action: "Delete",
        label: `${user?.Email}`,
      };
      break;
    case CO2PlanActionTypes.REGISTER_CO2_PLAN:
      event = {
        category: "CO2",
        action: "CO2PlanRegistered",
        label: user ? "by user" : " by anon",
      };
      break;
    case CO2PlanActionTypes.TRACK_SPONSOR_SOLUTIONS_OPEN:
      event = {
        category: "CO2",
        action: "CO2SponsorSolutionsOpen",
        label: action.payload,
      };
      break;
    case CO2PlanActionTypes.TRACK_CO2_SURVEY_FILLED_IN:
      event = {
        category: "CO2",
        action: action.payload.isTargetSurvey ? "TargetSurveyFilledIn" : "InitialSurveyFilledIn",
      };
      break;
    case CO2PlanActionTypes.MARK_CO2_TIMELINE_FULFILLED:
      event = {
        category: "CO2",
        action: "TimleLineActionMarkedAsFulfilled",
      };
      break;
    case UnitConstants.TRACK_SEARCH_AUTOCOMPLETE_FILLED_IN:
      event = {
        category: "Unit",
        action: "SearchAutocompleateFilledIn",
      };
      break;
    case UserConstants.USER_FAILED_ENTERING_ADRESS:
      event = {
        category: "User",
        action: "UserFailedEnteringAdress",
      };
      break;
    case UserConstants.USER_TYPPED_ADDRESS_FAILED_TO_REGISTER:
      event = {
        category: "User",
        action: "UserFailedToRegister",
      };
      break;
    case UserConstants.USER_REGISTERED_AND_STOP:
      event = {
        category: "User",
        action: "UserRegisteredAndStop",
      };
      break;
    case UserConstants.USER_SEEN_CO2_REGISTER_AND_STOP:
      event = {
        category: "CO2",
        action: "UserSeenCO2RegisterDialogAndStop",
      };
      break;
    case UserConstants.USER_CREATED_PLAN_AND_STOP_WITHOUT_FILLING:
      event = {
        category: "CO2",
        action: "UserCreatedPlanAndStopWithoutFilling",
      };
      break;
    case UserConstants.USER_RETURN_AND_FINISH_PLAN:
      event = {
        category: "CO2",
        action: "UserReturnAndFinishPlan",
      };
      break;
    case UserConstants.USER_START_STYLES_SURVEY:
      event = {
        category: "StylesSurvey",
        action: "UserStartStylesSurvey",
      };
      break;
    case UserConstants.USER_FINISH_STYLES_SURVEY:
      event = {
        category: "StylesSurvey",
        action: "UserFinishStylesSurvey",
      };
      break;
      case UserConstants.USER_MAIL_CLICK_STYLES_SURVEY:
      event = {
        category: "StylesSurvey",
        action: "UserMailClickStylesSurvey",
      };
      break;
  }

  if (event) {
    ReactGA.event(event);
  }

  const result = next(action);
  return result;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const facebookPixelTracker: Middleware<{}, ApplicationState> =
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (store) => (next) => async (action) => {
    switch (action.type) {
      case UserConstants.REGISTER_SUCCESS:
        ReactPixel.track("CompleteRegistration");
        break;
      case UserConstants.INITIATE_MOVE_IN:
        ReactPixel.trackCustom("InitiateMovedIn");
        break;
      case UserConstants.MOVE_IN_UNIT_SUCCESS:
        ReactPixel.trackCustom("MoveIn");
        break;
      case UserConstants.REMOVE_USER:
        ReactPixel.trackCustom("UserRemoved");
        break;
      case CO2PlanActionTypes.REGISTER_CO2_PLAN:
        ReactPixel.trackCustom("CO2PlanRegistered");
        break;
      case UnitConstants.TRACK_SEARCH_AUTOCOMPLETE_FILLED_IN:
        ReactPixel.trackCustom("SearchAutocompleateFilledIn");
        break;
    }

    const result = next(action);
    return result;
  };

// // eslint-disable-next-line @typescript-eslint/ban-types
// export const intercomTracker: Middleware<{},  ApplicationState> =
//   (store) => (next) => async (action) => {
//     switch (action.type) {
//       case UserConstants.LOGIN_SUCCESS:
//       case UserConstants.REGISTER_SUCCESS:
//         (window as any).Intercom("update", {
//           userId: action.payload.Id,
//           email: action.payload.Email,
//           name: action.payload.Name,
//         });
//         break;
//       case UserConstants.LOGOUT:
//         (window as any).Intercom("shutdown");
//         break;
//       case CO2PlanActionTypes.REGISTER_CO2_PLAN:
//         (window as any).Intercom("trackEvent", "CO2PlanRegistered");
//         // ping Intercom to check if event has triggered any banner or message
//         setTimeout(() => {
//           (window as any).Intercom("update", {
//             last_request_at: new Date().getTime() / 1000,
//           });
//         }, 5000);
//         break;
//       case CO2PlanActionTypes.TRACK_CO2_SURVEY_FILLED_IN:
//         (window as any).Intercom(
//           "trackEvent",
//           action.payload.isTargetSurvey
//             ? "TargetSurveyFilledIn"
//             : "InitialSurveyFilledIn"
//         );
//         // ping Intercom to check if event has triggered any banner or message
//         setTimeout(() => {
//           (window as any).Intercom("update", {
//             last_request_at: new Date().getTime() / 1000,
//           });
//         }, 5000);
//         break;
//       case UnitConstants.GET_UNIT_SUCCEEDED:
//         (window as any).Intercom("trackEvent", "LoadedUnit", {
//           Id: action.payload.Id,
//           Address: UnitHelper.GetFullAddress(
//             action.payload.Address,
//             true,
//             true
//           ),
//         });
//         // ping Intercom to check if event has triggered any banner or message
//         setTimeout(() => {
//           (window as any).Intercom("update", {
//             lastOpenedUnitId: action.payload.Id,
//             lastOpenedUnitEstimationAvailable:
//               PriceEstimationUnitTypes.indexOf(action.payload.PropertyType) >=
//               0,
//             last_request_at: new Date().getTime() / 1000,
//           });
//         }, 5000);
//     }

//     const result = next(action);
//     return result;
//   };

// // eslint-disable-next-line @typescript-eslint/ban-types
// export const co2TempPlanChecker: Middleware<{}, ApplicationState> = (store) => (next) => async (action) => {
//   try {
//     const tempPlanId = CO2PlanService.getTempCO2PlanId();
//     switch (action.type) {
//       // if user has a temp CO2 plan id in localstorage, then it was probably created when user didn't have an account
//       // but now he has account and is moved in. We can trasfter his co2 plan to his house
//       case UnitConstants.GET_MOVED_IN_PROPERTY_SUCCEEDED:
//         if (tempPlanId) {
//           // use then instead of await to get action to reducer faster
//           CO2PlanService.mapTempPlanToUnit(tempPlanId, action.payload.Id)
//             .then(() => {
//               // we no longer need to store temp plan id if transfer was successfull
//               CO2PlanService.clearTempCO2PlanId();

//               store.dispatch({
//                 type: CO2PlanActionTypes.MAP_TEMP_CO2_PLAN_TO_UNIT_SUCCEEDED,
//               });
//             })
//             .catch(() => {
//               //something went wrong, remove temp plan locally
//               CO2PlanService.clearTempCO2PlanId();
//             });
//         }
//         break;
//     }
//   } catch (error) {
//     console.error(error);
//   }
//   const result = next(action);
//   return result;
// };
