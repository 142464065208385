import Address from "./model/unit/Address";

export class AutocompleteItem {
  Type: AutocompleteResultType;
  Text: string;
  CursorPosition: number;
  UnitId: string;
  BuildingId: string;
  Address: Address;
}

export enum AutocompleteResultType {
  Street = 0,
  Building = 1,
  House = 2,
}
