import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//@ts-expect-error No type definition
import WinChan from "winchan";

const FacebookAuthCallback = (): JSX.Element => {
    const { facebookKey } = useParams<{ facebookKey: string }>();
  const [log, setLog] = useState("");

  useEffect(() => {
    setLog(log + `WinChan: ${WinChan}` + "\r\n");

    WinChan.onOpen(function (origin: unknown, r: unknown, cb: unknown) {
      setLog(log + "onOpen fiered!!!\r\n");
      setLog(log + `WinChan cb: ${cb}` + "\r\n");
      //@ts-expect-error No type definition
      cb(facebookKey);
      setLog(log + "WinChan cb called \r\n");
      //window.close();
    });
  }, []);

  return (
    <>
      You successfully authorized. Please close this window.
      <div id="facebookId" style={{display: "none"}}>
        {facebookKey}
      </div>
      <div>{log}</div>
    </>
  );
};

export default FacebookAuthCallback;
