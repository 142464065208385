import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ContactMeBox from "../../../../components/Base/ContactMeBox";
import { RegisterLeadContract } from "../../../../definitions/LiteCapaign";
import BaseDialog from "../../../../components/Dialogs/BaseDialog";

type RequestInspectionDialogProps = {
  handleClose: () => void;
  userName: string;
  userPhone: string;
  loading: boolean;
  open: boolean;
  addLead: (data: RegisterLeadContract) => Promise<any>;
};

const RequestInspectionDialog = ({
  handleClose,
  userName,
  userPhone,
  loading,
  open,
  addLead,
}: RequestInspectionDialogProps) => {
  const { t } = useTranslation("translation");

  const requestInspection = async (data: RegisterLeadContract) => {
    try {
      await addLead(data);
      handleClose();
    } catch (e) {
      //
    }
  };

  return (
    <BaseDialog titleComponent={t("ServiceBook.RequestInspectionTitle")} closeDialog={() => handleClose()} open={open}>
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="body1">{t("ServiceBook.RequestInspection1")}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" paragraph>
            {t("ServiceBook.RequestInspection2")}
          </Typography>
        </Grid>
      </Grid>
      <ContactMeBox
        utmSource="myhouse.servicebook"
        initialValues={{ name: userName, phone: userPhone }}
        loading={loading}
        addLead={requestInspection}
        keepFormData
      />
      <Grid mt={2} container direction="column" alignItems="center">
        <Grid item>
          <Typography variant="body1" paragraph>
            {t("ServiceBook.RequestInspection3", { hostname: window.location.hostname })}
          </Typography>
        </Grid>
        <Grid item>
          <Button onClick={() => handleClose()} variant="contained" color="secondary">
            {t("General.Buttons.Close")}
          </Button>
        </Grid>
      </Grid>
    </BaseDialog>
  );
};

export default RequestInspectionDialog;
