export const AdminCompanyTestimonialActionTypes = {
  GET_COMPANY_TESTIMONIALS: "GET_COMPANY_TESTIMONIALS",
  GET_COMPANY_TESTIMONIALS_SUCCEEDED: "GET_COMPANY_TESTIMONIALS_SUCCEEDED",
  ADD_COMPANY_TESTIMONIAL: "ADD_COMPANY_TESTIMONIAL",
  ADD_COMPANY_TESTIMONIAL_SUCCEEDED: "ADD_COMPANY_TESTIMONIAL_SUCCEEDED",
  EDIT_COMPANY_TESTIMONIAL: "EDIT_COMPANY_TESTIMONIAL",
  EDIT_COMPANY_TESTIMONIAL_SUCCEEDED: "EDIT_COMPANY_TESTIMONIAL_SUCCEEDED",
  DELETE_COMPANY_TESTIMONIAL: "DELETE_COMPANY_TESTIMONIAL",
  DELETE_COMPANY_TESTIMONIAL_SUCCEEDED: "DELETE_COMPANY_TESTIMONIAL_SUCCEEDED",
};
