export const NewsStreamConstants = {
  GET_NEWS: "GET_NEWS",
  GET_NEWS_SUCCEEDED: "GET_NEWS_SUCCEEDED",
  GET_NEWS_DETAILS: "GET_NEWS_DETAILS",
  GET_NEWS_DETAILS_SUCCEEDED: "GET_NEWS_DETAILS_SUCCEEDED",
  UPDATE_NEWSITEM: "UPDATE_NEWSITEM",
  UPDATE_NEWSITEM_SUCCEEDED: "UPDATE_NEWSITEM_SUCCEEDED",
  CLEAR_NEWS: "CLEAR_NEWS",
  NEWSSTREAM_OPERATION_FAILED: "NEWSSTREAM_OPERATION_FAILED",
};
