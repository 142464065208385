import { Container, Grid, styled, Typography } from "@mui/material";
import React from "react";

const DreamHuntTermsPage = (): JSX.Element => {
  return (
    <Container maxWidth="md">
      <GridStyled container item isScrollable={false} offsetTop={0}>
        <Typography variant="h6">Purpose</Typography>
        <Typography paragraph>
          In our climate plan, we focus on sustainability and initiatives that can support it, especially with a view to
          reducing CO2e. We are working to offer a vibrant and exciting digital platform with new initiatives that can
          support citizens in their daily lives.
        </Typography>
        <Typography paragraph>
          Our climate plan is a service built on top of the digital platform myhouse.dk.
        </Typography>
        <Typography paragraph>
          We collaborate with organizations that are specialists in specific areas such as the Danish Energy Agency,
          FDM, Madkulturen, Byggesagkyndig.nu, municipalities, etc., with the aim of offering users solutions that can
          reduce their CO2e footprint while saving money.
        </Typography>
        <Typography variant="h6">Data Collection and Use</Typography>
        <Typography paragraph>
          If a solution requires us to share data with one of our partners, we will request user consent in that case.
          This consent can be managed under the user profile, where one can also delete their account.
        </Typography>
        <Typography paragraph>
          You give us permission to use your information to calculate your and your household's CO2e footprint.
        </Typography>
        <Typography variant="h6">Contact Information</Typography>
        <Typography paragraph>
          All questions regarding this policy, the processing of your personal data, or suspicions of non-compliance
          should be directed to myhouse A/S, which is the data processor on behalf of the municipality. Please send your
          email with the subject "Personal Data" to{" "}
          <a href="mailto:info@myhouse.dk?subject=Personal%20Data">info@myhouse.dk</a>.
        </Typography>
        <Typography variant="h6">Data Responsibility</Typography>
        <Typography paragraph>
          All Danish addresses can be found in myhouse along with other relevant information we retrieve from public
          databases. myhouse assumes no responsibility for the accuracy of the information where it is stated that data
          comes from other sources, nor for information obtained via links.
        </Typography>
        <Typography variant="h6">Definitions</Typography>
        <Typography paragraph>
          "Our climate plan" refers to the service built on top of the digital platform myhouse.dk. www.myhouse.dk is
          the digital platform on which the service "our climate plan" is based. myhouse ApS is the company that owns
          the digital platform www.myhouse.dk and has the primary purpose of supporting homeowners. CDM A/S is a
          software house that has been developing software and services to support processes in marketing, customer
          service, and procurement since 1984. CDM A/S owns myhouse ApS.
        </Typography>
        <Typography variant="h6">Contact Information for myhouse ApS</Typography>
        <Grid xs={12} item>
          <Typography paragraph>Fredensborg Kongevej 56</Typography>
          <Typography paragraph>2980 Kokkedal</Typography>
          <Typography paragraph>CVR no. 35056823</Typography>
          <Typography paragraph>
            Email: <a href="mailto:info@myhouse.dk">info@myhouse.dk</a>
          </Typography>
          <Typography paragraph>Phone: 70271927</Typography>
          <Typography paragraph>Monday - Friday 9-16</Typography>
        </Grid>
        <Typography variant="h6">Privacy Policy for myhouse.dk</Typography>
        <Grid xs={12} item>
          <Typography paragraph>
            The personal data policy applies to all personal data you provide to myhouse.
          </Typography>
        </Grid>
        <Typography variant="h6">Data Responsibility</Typography>
        <Typography paragraph>
          We take your data protection seriously. We process personal data and have therefore adopted this personal data
          policy, which tells you how we process your personal data.
        </Typography>
        <Typography paragraph>
          According to the law on the processing of personal data, a company must inform its users about how the company
          processes the user's personal data. Below you can read how myhouse processes information about you.
        </Typography>
        <Typography paragraph>
          The overall legal framework for our processing of personal data is the EU General Data Protection Regulation
          2016/679 of 27.04.2016 and the Data Protection Act L 502 Act on supplementary provisions to the regulation on
          the protection of natural persons in connection with the processing of personal data and on the free exchange
          of such data.
        </Typography>
        <Typography variant="h6">Personal Data</Typography>
        <Typography paragraph>
          myhouse processes personal data collected directly from you. In some cases, we collect personal data about you
          from others, e.g., where you live. myhouse does not process personal data that users post on social media,
          such as comments and posts about myhouse, and myhouse profiles on Facebook, Twitter, Instagram, YouTube
          channel. myhouse is not responsible for information and posts published by others. We use personal data about
          you to improve our service and ensure high quality. The personal data we use includes, in particular: General
          personal data in the form of contact information (email, name, phone number) and information about your home
          as well as information included in inquiries to us, complaints, feedback, etc.
        </Typography>
        <Typography variant="h6">We Protect Your Personal Data</Typography>
        <Typography paragraph>
          We take care of your personal data to prevent it from being accidentally lost or altered, unauthorized
          disclosure, and unauthorized access or knowledge. We use data processors inside and outside the EU who must
          all store and process personal data on behalf of myhouse in accordance with this data policy and applicable EU
          legislation. In the event of a security breach that results in a high risk for you of discrimination, identity
          theft, financial loss, loss of reputation, or other significant disadvantage, we will notify you of the
          security breach as soon as possible.
        </Typography>
        <Typography variant="h6">Your Rights</Typography>
        <Grid xs={12} item>
          <Typography paragraph>You have the right to have inaccurate personal data corrected or deleted.</Typography>
        </Grid>
        <Typography paragraph>
          You have the right to access the information we process about you. If you believe it is inaccurate, you have
          the right to have it corrected or deleted. You must contact us and inform us of the inaccuracies, and we will
          instruct you on how they can be corrected. In some cases, we will be obliged to delete your personal data.
          This applies, for example, if you withdraw your consent.
        </Typography>
        <Typography paragraph>
          If you believe that your personal data is no longer necessary for the purpose for which we collected it, you
          can delete it yourself under your user profile. You can also contact us if you believe that your personal data
          is being processed in violation of the law or other legal obligations.
        </Typography>
        <Typography paragraph>
          When you contact us with a request to have your personal data corrected or deleted, we will investigate
          whether the conditions are met and, if so, make changes or deletions as soon as possible.
        </Typography>
        <Typography variant="h6">Data Storage</Typography>
        <Typography paragraph>
          We store your data as long as we consider the data to be sufficiently valid to advise you on how to reduce
          your climate footprint.
        </Typography>
        <Typography variant="h6">Responsibility</Typography>
        <Typography paragraph>
          myhouse is an information site for users consisting of a number of publicly available sources. We are not
          responsible for these sources. This means that if you find errors in publicly available data, myhouse cannot
          be held responsible for this. In such cases, you should contact the source directly. If you have questions
          about this, you can always contact myhouse and inquire about who you should contact.
        </Typography>
        <Typography paragraph>
          If myhouse considers the error to be urgent, myhouse will contact the source directly after your inquiry.
        </Typography>
        <Typography paragraph>
          In addition to information from public registers and news media, you as a user have the opportunity to upload
          documents and images yourself. As a user, you can upload a new profile picture of yourself.
        </Typography>
        <Typography paragraph>
          myhouse is not responsible for the accuracy of such documents and images and disclaims responsibility for loss
          or damage to your data, including uploaded material.
        </Typography>
        <Typography variant="h6">Errors in Registered Information</Typography>
        <Typography paragraph>
          If myhouse becomes aware that there are errors in the information we have registered about you, or that the
          information is misleading, we will correct the information if possible.
        </Typography>
        <Typography paragraph>
          You have the right to file a complaint with the Data Protection Agency if you are dissatisfied with the way we
          process your information.
        </Typography>
        <Typography variant="h6">Newsletter and Push Notifications</Typography>
        <Typography paragraph>
          You can separately sign up for our campaigns and push notifications using www.myhouse.dk under the profile.
          You will always be able to unsubscribe from this service. You will not receive newsletters or other
          unsolicited commercial inquiries from our partners.
        </Typography>
        <Typography variant="h6">We Use Google Analytics</Typography>
        <Typography paragraph>
          myhouse uses cookies from Google Analytics to measure website traffic such as the number of visits, the number
          of page views, and the number of users on the site. This information can be used in anonymized form to
          document the value and effect of marketing material. Google Analytics does not register personal data.
        </Typography>
        <Typography paragraph>You can always unsubscribe from our service under your user profile.</Typography>
        <Typography paragraph>
          If you have questions or suggestions for changes, you are welcome to write to{" "}
          <a href="mailto:info@myhouse.dk">info@myhouse.dk</a>.
        </Typography>
        <Typography variant="h6">Cookies</Typography>
        <Typography paragraph>
          On our website myhouse.dk, "cookies" are used to improve your user experience on our websites, to collect web
          statistics, and to manage logins. Read more in our cookie policy below:
        </Typography>
        <Typography variant="h6">What are cookies</Typography>
        <Typography paragraph>
          Cookies are used by almost all websites. In some cases, cookies are the only way to get a website to function
          as intended.
        </Typography>
        <Typography paragraph>
          A cookie is a small data file that collects digital footprints about users as they navigate online. Cookies
          are stored on the user's computer, tablet, or smartphone when they visit your website. Cookies can collect
          information about which pages the user visits, which articles the user reads, and what the user buys. There
          are also other similar technologies that similarly collect information about users.
        </Typography>
        <Typography paragraph>
          Websites can use cookies and similar technologies for many different purposes. For example, to ensure that the
          features of websites work and to optimize the design and quality of a website, to provide targeted and
          relevant content on a website, or to target ads for commercial purposes.
        </Typography>
        <Typography paragraph>
          The first time you land on this website, you will see a banner with information about cookies for statistics.
          If you click "Reject," no cookies will be set for statistics. However, we use a cookie to remember your no
          thanks to statistics. If you click "Accept," cookies will be set to collect statistics, and the banner will
          disappear. We use the statistics to improve user-friendliness. The information in the statistics is anonymous
          and is not linked to individual users.
        </Typography>
        <Typography paragraph>
          How to avoid cookies: You can always reject cookies on your computer by changing the settings in your browser.
          Where you find the settings depends on which browser you use. However, you should be aware that if you do,
          there are many features and services on the internet that you cannot use. All browsers allow you to delete
          your cookies collectively or individually. How you do it depends on which browser you use. Remember, if you
          use multiple browsers, you need to delete cookies in all of them.
        </Typography>
        <Typography variant="h6">General validity of data</Typography>
        <Typography paragraph>
          Less than 5% of Danes know what their climate footprint is. As it is difficult to reduce something you do not
          know, we have created this model that, in a pragmatic way, can quickly give individual citizens an indication
          of where they stand in relation to the national target and an idea of how much it takes to reach the goal. If
          you want a completely accurate measurement, we refer to other tools, including those from the Energy Agency
          and Concito. Fill out our climate plan and see how you can reduce your climate footprint. See if you are in
          the red, yellow, or green zone. The goal is to move away from red and into green.
        </Typography>
        <Typography paragraph>
          A scheme shows you and your household where you can make the most effective changes. In our climate plan, you
          can subsequently simulate the effect of what you can change. You can follow up on your plan and see how close
          you are to realizing it.
        </Typography>
        <Typography paragraph>It is super easy and only takes a few minutes.</Typography>
      </GridStyled>
    </Container>
  );
};
export default DreamHuntTermsPage;

const GridStyled = styled(Grid, {
  shouldForwardProp: (propName: string) => propName !== "isScrollable" && propName !== "offsetTop",
})<{ isScrollable: boolean; offsetTop: number }>(({ theme, isScrollable, offsetTop }) => ({
  flex: 1,
  justifyContent: "left",
  overflowY: isScrollable ? "scroll" : "initial",
  height: isScrollable ? `calc(100vh - ${offsetTop}px)` : "auto",
  color: "white",
  "& div:first-child": {
    "& div > p > a": {
      color: theme.palette.secondary.main,
      textDecoration: "none",
    },
  },
}));
