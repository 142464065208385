import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useBuilding } from "../../actions/building.actions";
import BuildingConditionPage from "../House/ServiceBook/BuildingConditionPage";

const BuildingTender = (): JSX.Element => {
  const { addressURL } = useParams<{ addressURL: string }>();

  const [{ building, loading }, buidingActions] = useBuilding();

  useEffect(() => {
    if (addressURL) {
      const addressParts = addressURL.split("_");
      const id = addressParts[addressParts.length - 1];
      if (!loading && building?.Id !== id) {
        buidingActions.getBuilding(id);
      }
    }
  }, [addressURL]);

  return <>{building && <BuildingConditionPage />}</>;
};

export default BuildingTender;
