
import {   CompanyModel,
  CompanyGeneralInfo,
  CompanySustainabilityInfo, } from "../definitions/Company";
import ApiService from "./api.service";
import { headers } from "./http.service";

const CompanyService = {
  getCompanies: async (skip: number, take: number, isClient?: boolean): Promise<CompanyModel[]> => {
    const url = `/api/admin/company?filter=&skip=${skip}&take=${take}&isClient=${isClient ?? false}`;
    const result = await ApiService.get<CompanyModel[]>(url);
    return result;
  },
  addCompany: async (
    generalInfo: CompanyGeneralInfo,
    sustainabilityInfo: CompanySustainabilityInfo
  ): Promise<CompanyModel> => {
    const url = `/api/admin/company`;
    const result = await ApiService.post<CompanyModel>(url, {
      generalInfo,
      sustainabilityInfo,
    });
    return result;
  },

  editCompany: async (company: CompanyModel): Promise<CompanyModel> => {
    const url = `/api/admin/company/`;
    const result = await ApiService.put<CompanyModel>(url, {
      Id: company.Id,
      GeneralInfo: {
        Name: company.Name,
        WebSite: company.WebSite,
        OfficialName: company.OfficialName,
        Phone: company.Phone,
        Addresses: company.Addresses,
        CvrNumber: company.CvrNumber,
        Country: company.Country,
        EmployeesNumber: company.EmployeesNumber,
        IsOurClient: company.IsOurClient,
        IsActiveClient: company.IsActiveClient,
      },
      SustainabilityInfo: company.SustainabilityInfo,
    });
    return result;
  },

  deleteCompany: async (companyId: string): Promise<boolean> => {
    const url = `/api/admin/company/${companyId}`;
    const result = await ApiService.delete<boolean>(url);
    return result;
  },

  assignRating: async (companyId: string, rating: number): Promise<number> => {
    const url = `/api/admin/company/assignRating`;
    const result = await ApiService.post<number>(url, {
      Id: companyId,
      Rating: rating,
    });
    return result;
  },
  updateCompanyLogo: async (id: string, picture: File): Promise<string> => {
    const formData = new FormData();
    const blob = new Blob([picture], { type: picture.type });
    formData.append("File", blob);
    
    const headersNoContentType = await headers();
    delete headersNoContentType["Content-Type"];

    const response = await fetch(`/api/admin/company/${id}/upload-logo`, {
      method: "POST",
      body: formData,
      headers: headersNoContentType,
    });
    const result = await response.text();
    if (response.status === 200) {
      return result;
    } else {
      throw result;
    }
  },
};

export default CompanyService;
