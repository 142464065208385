import React from "react";

type DomainStringProps = {
  type: "string" | "link" | "email";
  email?: string;
  route?: string;
  linkname?: string;
};

export const domainstring = (props: DomainStringProps) => {
  const location = window.location;

  switch (props.type) {
    case "string":
      return <span>{location.host}</span>;
    case "email":
      if (props.email) {
        return <a href={`${props.email}@${location.host}`}>{`${props.email}@${location.host}`}</a>;
      }
      return <a href={`no-reply@${location.host}`}>{`no-reply@${location.host}`}</a>;
    case "link":
      if (props.route) {
        return <a href={location.origin + '/' + props.route}>{props.linkname ? props.linkname : location.host}</a>;
      }
      return <a href={location.origin}>{location.host}</a>;
  }
};
