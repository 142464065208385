import { NewsStreamConstants } from "../../constants";
import NewsService from "./news.service";
import { Dispatch } from "redux";
import { AppAction } from "../../definitions/Action";
import { NewsCategoriesFilter } from "../../constants/enums";
import { NewsStreamDTO } from "../../definitions/NewsStream";
import { operationFailedActionGeneral, useAppDispatch } from "../../actions";

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(payload, NewsStreamConstants.NEWSSTREAM_OPERATION_FAILED);
};

export const getNews =
  (
    longitude: number,
    latitude: number,
    skip: number,
    take: number,
    filter: NewsCategoriesFilter | null,
    requestTime: Date
  ) =>
  async (dispatch: Dispatch<AppAction>): Promise<NewsStreamDTO[]> => {
    try {
      dispatch({ type: NewsStreamConstants.GET_NEWS });
      const result = await NewsService.getNewsStreams(longitude, latitude, skip, take, filter, requestTime);
      dispatch({
        type: NewsStreamConstants.GET_NEWS_SUCCEEDED,
        payload: {
          news: result,
          newsLoaded: result.length !== take,
        },
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const getOneNews =
  (newsId: string) =>
  async (dispatch: Dispatch<AppAction>): Promise<NewsStreamDTO> => {
    try {
      dispatch({ type: NewsStreamConstants.GET_NEWS_DETAILS });
      const result = await NewsService.getCustomNews(newsId);
      dispatch({
        type: NewsStreamConstants.GET_NEWS_DETAILS_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const clearNews =
  () =>
  (dispatch: Dispatch<AppAction>): void => {
    dispatch({ type: NewsStreamConstants.CLEAR_NEWS });
  };

export const useNewsstreamActions = () => {
  const dispatch = useAppDispatch();
  return {
    getNews: (
      longitude: number,
      latitude: number,
      skip: number,
      take: number,
      filter: NewsCategoriesFilter | null,
      requestTime: Date
    ) => dispatch(getNews(longitude, latitude, skip, take, filter, requestTime)),
    getOneNews: (url: string) => dispatch(getOneNews(url)),
    clearNews: () => dispatch(clearNews()),
  };
};
