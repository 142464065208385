import { NeighborGroup, UpdateNeighborGroupContract } from "../../definitions/model/NeighborGroup";
import ApiService from "../api.service";

const NeighborGroupAdminService = {
  getGroups: async (): Promise<NeighborGroup[]> => {
    const url = `/api/neighborgroup/global`;
    const result = await ApiService.get<NeighborGroup[]>(url);
    return result;
  },
  getGroup: async (groupId: string): Promise<NeighborGroup> => {
    const url = `/api/neighborgroup/${groupId}`;
    const result = await ApiService.get<NeighborGroup>(url);
    return result;
  },
  createGroup: async (request: UpdateNeighborGroupContract): Promise<NeighborGroup> => {
    const url = `/api/neighborgroup/global`;
    const result = await ApiService.post<NeighborGroup>(url, request);
    return result;
  },
  updateGroup: async (request: UpdateNeighborGroupContract): Promise<NeighborGroup> => {
    const url = `/api/neighborgroup`;
    const result = await ApiService.put<NeighborGroup>(url, request);
    return result;
  },
  deleteGroup: async (groupId: string): Promise<void> => {
    const url = `/api/neighborgroup/${groupId}`;
    await ApiService.delete(url);
  },
};

export default NeighborGroupAdminService;
