import React from "react";
import {
  Container,
  Typography,
  Grid,
  styled,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import { HiOutlineCheck } from "react-icons/hi";
import { RxDotFilled } from "react-icons/rx";
import { useTranslation } from "react-i18next";
import Superstamp from "../../../../assets/img/superstamp.png";

const StyledListIcon = styled(ListItemIcon)(({ theme }) => ({
  "&.MuiListItemIcon-root": {
    color: theme.palette.secondary.main,
  },
}));

const NewToServiceBookComponent = (): JSX.Element => {
  const { t } = useTranslation("translation");
  return (
    <Container maxWidth="md">
      <Grid container direction="column">
        <Grid
          item
          container
          justifyContent="flex-end"
          xs={3}
          style={{ position: "relative" }}
        >
          <img style={{ position: "absolute", bottom: -50 }} src={Superstamp} />
        </Grid>
        <Typography variant="subtitle1">
          {t("ServiceBook.NewToSb.Title1")}
        </Typography>
        <Typography variant="body1">
          {t("ServiceBook.NewToSb.ReportByInspectorText")}
        </Typography>
        <Typography variant="body1" paragraph>
          {t("ServiceBook.NewToSb.PlanCraftsmenTasks")}
        </Typography>
        <Typography variant="subtitle1">
          {t("ServiceBook.NewToSb.WhyGreen")}
        </Typography>
        <ListItem disablePadding>
          <StyledListIcon>
            <HiOutlineCheck fontSize={30} />
          </StyledListIcon>
          <ListItemText>
            {t("ServiceBook.NewToSb.FirstGreenPoint")}
          </ListItemText>
        </ListItem>
        <ListItem disablePadding>
          <StyledListIcon>
            <HiOutlineCheck fontSize={30} />
          </StyledListIcon>
          <ListItemText>
            {t("ServiceBook.NewToSb.SecondGreenPoint")}
          </ListItemText>
        </ListItem>
        <ListItem disablePadding style={{ marginBottom: 20 }}>
          <StyledListIcon>
            <HiOutlineCheck fontSize={30} />
          </StyledListIcon>
          <ListItemText>
            {t("ServiceBook.NewToSb.ThirdGreenPoint")}
          </ListItemText>
        </ListItem>
        <Typography variant="subtitle1">
          {t("ServiceBook.NewToSb.GetStarted")}
        </Typography>
        <ListItem disablePadding>
          <ListItemIcon>
            <RxDotFilled fontSize={30} />
          </ListItemIcon>
          <ListItemText>
            {t("ServiceBook.NewToSb.FillOutContactForm")}
          </ListItemText>
        </ListItem>
        <ListItem disablePadding>
          <ListItemIcon>
            <RxDotFilled fontSize={30} />
          </ListItemIcon>
          <ListItemText>{t("ServiceBook.NewToSb.AdviserCall")}</ListItemText>
        </ListItem>       
        <Button
          sx={{ marginBottom: 2 }}
          component="a"
          href="https://api.servicebook.myhouse.dk/api/report/pdf/5727cc5d-f292-439c-8daa-1bd77f2ec395"
          variant="contained"
          color="secondary"
        >
          {" "}
          {t("ServiceBook.NewToSb.SeeExample")}
        </Button>
      </Grid>
    </Container>
  );
};

export default NewToServiceBookComponent;
