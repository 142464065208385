import React from "react";
import { TableCell, TableHead, TableRow } from "@mui/material";
import { Column } from "./AdminTable";

type Props<T> = {
  columns: Column<T>[];
  additionColumn?: string | JSX.Element;
};

export const AdminTableHead = <T,>({ columns, additionColumn }: Props<T>) => {
  return (
    <TableHead>
      <TableRow>
        {columns
          .filter((x) => x.isIndividualCell || !x.callback)
          .map((column, index) => (
            <TableCell
              key={index}
              align={column.icon !== undefined ? "center" : "left"}
            >
              {column.name}
            </TableCell>
          ))}
        {additionColumn && (
          <TableCell
            sx={{
              width: "10%",
              textAlign: "-webkit-center",
              paddingRight: "30px",
            }}
          >
            {additionColumn}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
