import React from "react";
import { Alert, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useServiceWorker } from "./useServiceWorker";

const SystemUpdateAlert = () => {
  const { t } = useTranslation("translation");
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  return (
    <>
      {showReload && waitingWorker && (
        <Alert
          severity="info"
          sx={{
            position: "fixed",
            bottom: 10,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
            width: { xs: "95%", sm: 400 },
          }}
          action={
            <Button
              color="inherit"
              size="small"
              variant={"outlined"}
              onClick={reloadPage}
            >
              {t("General.Buttons.Update")}
            </Button>
          }
        >
          {t("General.PwaAlert.Message")}
        </Alert>
      )}
    </>
  );
};

export default SystemUpdateAlert;
