import React, { useEffect, useState } from "react";
import { DocRenderer, DocRendererProps } from "@cyntler/react-doc-viewer";
import PostalMime, { Email, Attachment } from "postal-mime";
import { Box, Grid, Link, Typography } from "@mui/material";
import { ModelContainer } from "../HouseModel/HouseModelPage";

export const TextRenderer: DocRenderer = ({ mainState: { currentDocument } }: DocRendererProps) => {
  const [content, setContent] = useState<string | undefined>();
  const [url, setUrl] = useState<boolean>();
  const [error, setShowError] = useState("");

  function isValidUrl(urlString: string) {
    try {
      new URL(urlString);
      return true;
    } catch (e) {
      return false;
    }
  }

  useEffect(() => {
    if (!currentDocument) return;
    try {
      const raw = currentDocument.fileData?.toString().replace("data:text/plain;base64,", "");
      if (raw) {
        console.log(raw);
        const content = atob(raw);
        setContent(content);
        console.log(content);
        if (isValidUrl(content)) {
          setUrl(true);
        }
      }
    } catch (e) {
      setShowError("The text format is not supported");
    }

    return () => {
      setUrl(undefined);
    };
  }, [currentDocument]);

  return (
    <>
      <Box id="text-renderer" sx={{ width: "100%" }}>
        {url ? (
          <>
            <ModelContainer modelUrl={content || ""} />
            <Link variant="subtitle1">URL: {content}</Link>
          </>
        ) : (
          <div id="text">{content || ""}</div>
        )}
      </Box>
      {error ? (
        <Box sx={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center" }}>{error}</Box>
      ) : null}
    </>
  );
};
TextRenderer.fileTypes = ["text/plain", "txt"];
TextRenderer.weight = 1;

export const EmailRenderer: DocRenderer = ({ mainState: { currentDocument } }: DocRendererProps) => {
  const [content, setContent] = useState<Email | undefined>();
  const [error, setShowError] = useState("");

  useEffect(() => {
    if (!currentDocument) return;
    const raw = currentDocument.fileData?.toString().replace("data:message/rfc822;base64,", "");

    let blob: Blob;
    try {
      blob = base64ToBlob(raw!);
    } catch (e) {
      setShowError("The message format is not supported");
    }

    const parseAsync = async () => {
      if (blob) {
        const email = await PostalMime.parse(blob);
        email.attachments?.forEach((attachment) => {
          if (attachment.mimeType.startsWith("image")) {
            email.html = email.html?.replace(
              `cid:${attachment.contentId?.replace("<", "").replace(">", "")}`,
              `${getAttachmentLink(attachment)}`
            );
          }
        });
        setContent(email);
      }
    };
    parseAsync();
    return () => {
      setContent(undefined);
    };
  }, [currentDocument]);

  return (
    <div id="email-renderer">
      {content ? (
        <Grid container direction="column" sx={{ p: 2 }}>
          <Typography variant="h6">{content.subject}</Typography>
          <Typography variant="subtitle1">
            Fra:
            {content.from?.name}
            {`<${content.from?.address}>`}
          </Typography>
          <Typography variant="subtitle1">
            Til: {content.to?.map((to) => to.name + `<${to.address}>`).join(", ")}
          </Typography>
          {content.date && (
            <Typography variant="subtitle1">
              Dato: {new Date(content.date).toLocaleString("da-dk", { dateStyle: "full", timeStyle: "short" })}
            </Typography>
          )}

          <Box dangerouslySetInnerHTML={{ __html: content.html ? content.html : content.text ? content.text : "" }} />
          <Grid item>
            <Typography variant="subtitle1">Attachments:</Typography>
            {content.attachments?.map((attachment) => (
              <Link key={attachment.contentId} href={getAttachmentLink(attachment)} variant="subtitle1" target="_blank">
                {attachment.filename}
              </Link>
            ))}
          </Grid>
        </Grid>
      ) : null}
      {error ? (
        <Box sx={{ display: "flex", flex: 1, alignItems: "center", justifyContent: "center" }}>{error}</Box>
      ) : null}
    </div>
  );
};

EmailRenderer.fileTypes = ["message/rfc822", "eml"];
EmailRenderer.weight = 1;

function base64ToBlob(base64: string, contentType = "", sliceSize = 512) {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

const getAttachmentLink = (attachment: Attachment) => {
  const blob = new Blob([attachment.content], { type: attachment.mimeType });
  return URL.createObjectURL(blob);
};
