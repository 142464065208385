export const CommentConstants = {
  GET_COMMENT: "GET_COMMENT",
  GET_COMMENT_SUCCEEDED: "GET_COMMENT_SUCCEEDED",
  GET_COMMENTS: "GET_COMMENTS",
  GET_COMMENTS_SUCCEEDED: "GET_COMMENTS_SUCCEEDED",
  POST_COMMENT: "POST_COMMENT",
  POST_COMMENT_SUCCEEDED: "POST_COMMENT_SUCCEEDED",
  UPDATE_COMMENT: "UPDATE_COMMENT",
  UPDATE_COMMENT_SUCCEEDED: "UPDATE_COMMENT_SUCCEEDED",
  DELETE_COMMENT: "DELETE_COMMENT",
  DELETE_COMMENT_SUCCEEDED: "DELETE_COMMENT_SUCCEEDED",
  COMMENT_OPERATION_FAILED: 'COMMENT_OPERATION_FAILED',
  ADD_COMMENT: 'ADD_COMMENT',
  REMOVE_COMMENT: 'REMOVE_COMMENT',
};
