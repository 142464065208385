import { Dispatch } from "redux";
import { AppAction } from "../../definitions/Action";
import { CO2PlanActionTypes } from "./shared/co2plan.actiontypes";
import ClubService from "../../services/club.service";
import { CO2Plan } from "./shared/CO2Plan";

export const loadRelatedArticles = async (
  plan: CO2Plan,
  dispatch: Dispatch<AppAction>
): Promise<void> => {
  if (plan.TargetSurvey && plan.TargetSurvey.Categories) {
    const groups = plan.TargetSurvey.Categories.flatMap(
      (cat) => cat.EmissionSourceGroups
    );
    const groupIds = groups.map((gr) => gr.Id);
    const relatedArticles = await ClubService.getArticlesByTags(groupIds);
    dispatch({
      type: CO2PlanActionTypes.SET_CO2_ARTICLES,
      payload: relatedArticles.Results.map((article) => ({
        emissionSourceGroupId: article.Tag,
        article: article,
      })),
    });
  }
};
