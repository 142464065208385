export const ChatConstants = {
    START_CHAT: "START_CHAT",
    SEND_CHAT_MESSAGE: "SEND_CHAT_MESSAGE",
    RECEIVE_CHAT_MESSAGE: "RECEIVE_CHAT_MESSAGE",
    CONNECT_TO_CHAT: "CONNECT_TO_CHAT",
    CLEAR_CHAT: "CLEAR_CHAT",
    SET_CHATS : "SET_CHATS",
    CHAT_OPERATION_FAILED: "CHAT_OPERATION_FAILED",
    SET_CHAT_WAIT_INIT_TIMER: "SET_CHAT_WAIT_INIT_TIMER",
    LOAD_CHATS: "LOAD_CHATS",
    NEW_CHAT: "NEW_CHAT",
    CLEAR_NEW_MESSAGES: "CLEAR_NEW_MESSAGES",
    RECEIVE_CHAT_MESSAGE_CONFIRMATION: "RECEIVE_CHAT_MESSAGE_CONFIRMATION",
}