import React, { useState, useEffect } from "react";
import { ServiceBookReportSection } from "../../../../../definitions/model/ServiceBook";
import {
  ListItem,
  ListItemText,
  Collapse,
  Divider,
  ListItemIcon,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ReportParameter from "./ReportParameter";

interface ReportSectionProps extends ServiceBookReportSection {
  open: boolean;
}

const ReportSection = (props: ReportSectionProps) => {
  const [open, setOpen] = useState(props.open);
  
  useEffect(() => {

            setOpen(props.open);

      return () => {
      }
  }, [props.open])


  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem  onClick={toggleOpen}>
        <ListItemText>{props.Name}</ListItemText>
        <ListItemIcon>
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </ListItemIcon>
      </ListItem>
       <Divider variant="middle" />
      <Collapse in={open} unmountOnExit>
        
        {props.Parameters.sort((a, b) => parseInt(a.Label.split(".")[1]) > parseInt(b.Label.split(".")[1]) ? 1 : -1).map((x) => (
          <ReportParameter key={x.Id} {...x} />
        ))}
      </Collapse>
    </>
  );
};

export default ReportSection;
