import React, { lazy } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useUser } from "../../actions/user.actions";
import NavigationHelper from "../../helpers/navigation-helper";
import CommuneClimatePlan from "../../pages/MyClimatePlanPage";
import MyHouseStartPage from "../../pages/MyHouseStartPage/MyHouseStartPage";
import CommercialClimatePlan from "../../pages/MyClimatePlanPage/pages/ClimatePlanCommercialStartPage";
import FacebookAuthCallback from "../../pages/User/FacebookAuthCallback";
import CompleteUserRegistration from "../../pages/CompleteRegistration";
import CommercialCompleteUserRegistration from "../../pages/CommercialCompleteRegistration";
import { RouteType } from "../../routes";
import ClubsStartPage from "../../pages/Clubs/ClubsStartPage";
import ClubsChristmasBox from "../../pages/Clubs/ClubsChristmasBox";
import ClubsSubscribeIntroPage from "../../pages/Clubs/ClubsSubscribeIntroPage";
import ClubsPaymantPage from "../../pages/Clubs/ClubsPaymentPage";
import NewsDetailsPage from "../../pages/NewsStream/NewsDetailsPage";
import ArticlePage from "../../pages/Clubs/ArticlePage";

const CO2CalculatorPageLazy = React.lazy(() => import("../../pages/ClimatePlan/MyHouseClimatePlan"));
const CustomLandingPageLazy = lazy(() => import("../../pages/LandingPage/CustomLandingPage"));
const LandingPageLazy = lazy(() => import("../../pages/LandingPage/LandingPage"));
const LandingLoaderLazy = lazy(() => import("../../pages/LandingPage/LandingLoader"));
const SustainabilityCheckPageLazy = lazy(() => import("../../pages/SustainabilityCheck/SustainabilityCheckPage"));

export const pagesRoutes: RouteType[] = (() => {
  return [
    { path: "landing/:name/:languageId", element: CustomLandingPageLazy, allowAnonymous: true },
    { path: "landing/:name", element: CustomLandingPageLazy, allowAnonymous: true },
    { path: "landing", element: LandingPageLazy, allowAnonymous: true },
    { path: "landing-loader", element: LandingLoaderLazy, allowAnonymous: true },
    {
      path: "",
      element: NavigationHelper.IsClimateSite() ? CommercialClimatePlan : MyHouseStartPage,
      allowAnonymous: true,
    },
    { path: "klimaplan", element: CommuneClimatePlan, allowAnonymous: true },
    { path: "sustainabilitycheck", element: SustainabilityCheckPageLazy, allowAnonymous: true },
    {
      path: "facebook-authenticated-callback/:facebookKey",
      element: FacebookAuthCallback,
      allowAnonymous: true,
    },
    { path: "completeregistration", element: CompleteUserRegistration },
    {
      path: "commercial-completeregistration",
      element: CommercialCompleteUserRegistration,
    },
    { path: "/co2calculator", element: CO2CalculatorPageLazy },
    { path: "/articles", element: ClubsStartPage, allowAnonymous: true },
    {
      path: "/clubs-subscribe",
      element: ClubsSubscribeIntroPage,
      allowAnonymous: true,
    },
    { path: "/christmas-box", element: ClubsChristmasBox, allowAnonymous: true },
    { path: "/clubs-payment", element: ClubsPaymantPage, allowAnonymous: true },
    { path: "/style", element: ClubsStartPage, allowAnonymous: true },
    { path: "/community", element: ClubsStartPage, allowAnonymous: true },
    { path: "/news/:newsId", element: NewsDetailsPage, allowAnonymous: true },
    { path: "/articles/:articleName", element: ArticlePage, allowAnonymous: true },
  ];
})();

const BaseLayout = (): JSX.Element => {
  const { pathname } = useLocation();
  const [{ uiMode }, userActions] = useUser();

  React.useEffect(() => {
    if (uiMode !== "unit") {
      userActions.setUiMode("unit");
    }
  }, [pathname]);

  return <Outlet />;
};

export default BaseLayout;
