export const AdminNeighborGroupActionTypes = {
    GET_NEIGHBORGROUPS: "ADMIN_GET_NEIGHBORGROUPS",
    GET_NEIGHBORGROUPS_SUCCEEDED: "ADMIN_GET_NEIGHBORGROUPS_SUCCEEDED",
    GET_NEIGHBORGROUP: "ADMIN_GET_NEIGHBORGROUP",
    GET_NEIGHBORGROUP_SUCCEEDED: "ADMIN_GET_NEIGHBORGROUP_SUCCEEDED",
    CREATE_NEIGHBORGROUP: "ADMIN_CREATE_NEIGHBORGROUP",
    CREATE_NEIGHBORGROUP_SUCCEEDED: "ADMIN_CREATE_NEIGHBORGROUP_SUCCEEDED",
    UPDATE_NEIGHBORGROUP: "ADMIN_UPDATE_NEIGHBORGROUP",
    UPDATE_NEIGHBORGROUP_SUCCEEDED: "ADMIN_UPDATE_NEIGHBORGROUP_SUCCEEDED",
    DELETE_NEIGHBORGROUP: "ADMIN_DELETE_NEIGHBORGROUP",
    DELETE_NEIGHBORGROUP_SUCCEEDED: "ADMIN_DELETE_NEIGHBORGROUP_SUCCEEDED",
    CLEAR_CURRENT: "ADMIN_NEIGHBORGROUP_CLEAR_CURRENT"
}
