import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Grid, TextField, Typography, styled } from "@mui/material";
import AuthModel from "../../../definitions/model/Auth";
import UserService from "../../../services/user.service";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TextFieldConnector } from "../../Base/FormConnector";
import CircularProgressButton from "../../Base/CircularProgressButton";

const StyledCircularProgressButton = styled(CircularProgressButton)(() => ({
  width: 400,
  "& .MuiButton-label": {
    justifyContent: "center",
  },
}));
const StyledAlert = styled(Alert)(() => ({
  marginTop: "7px",
  marginBottom: "-20px",
}));

type ForgotPasswordFormData = {
  Email?: string;
  TermsOfUse: boolean;
  Notifications: boolean;
};
type ForgotPasswordFormProps = {
  state: AuthModel;
};

const ForgotPasswordForm = ({
  state,
}: ForgotPasswordFormProps): JSX.Element => {
  const methods = useForm<ForgotPasswordFormData>({
    defaultValues: {
      Email: state.Email,
    },
    mode: "onChange",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  const [errorText, setErrorText] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation("translation");
  const submit = (data: ForgotPasswordFormData) => {
    setErrorText("");
    setIsChanged(false);
    setLoading(true);
    // submit code here
    if (data && data.Email)
      UserService.resetPassword(data.Email)
        .catch((error) => {
          setErrorText("statusText" in error ? error.statusText : error);
        })
        .then((result: any) => {
          if (result) setIsChanged(result.success);
        })
        .finally(() => {
          setLoading(false);
        });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box mb={1.5}>
        <Typography>{t("Base.Dialog.ForgotPasswordTypography", { hostname: window.location.hostname })}</Typography>
      </Box>
      <Box mb={1.5}>
        <TextFieldConnector register={register("Email", { required: true })}>
          <TextField
            variant="outlined"
            fullWidth
            type="email"
            size="small"
            label={t("Base.Dialog.LoginEmailLabelPlaceholder")}
            style={{ maxWidth: 400 }}
            error={!!errors.Email}
          />
        </TextFieldConnector>
      </Box>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        spacing={2}
        sx={{
          width: "100%",
          marginLeft: "0px",
        }}
      >
        <Grid
          item
          container
          justifyContent="center"
          sx={{
            paddingLeft: "0px !important",
          }}
        >
          <StyledCircularProgressButton
            variant="contained"
            color="secondary"
            inProgress={loading}
            type="submit"
          >
            Send
          </StyledCircularProgressButton>
        </Grid>
        {errorText && <StyledAlert severity="error">{errorText}</StyledAlert>}
        {isChanged && (
          <StyledAlert severity="success">
            {t("Base.Dialog.AlertText")}
          </StyledAlert>
        )}
      </Grid>
    </form>
  );
};

export default ForgotPasswordForm;
