import {
  MoveInAutocomplete,
  LeadForm,
  UsersCounter,
  SearchAutocompleteForm,
  UnitAddressForm,
  BackButton,
  EntityField,
  MyhouseLink,
  HouseDataButton,
  CO2Companies,
  domainstring,
} from "./ReactComponentsPlugin";

export const components = {
  MoveInAutocomplete,
  LeadForm,
  UsersCounter,
  SearchAutocompleteForm,
  BackButton,
  EntityField,
  UnitAddressForm,
  MyhouseLink,
  HouseDataButton,
  CO2Companies,
  domainstring,
};
