import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { PhoneField } from "../../../../components/Base/FormattedFields";
import { FormProvider, useForm } from "react-hook-form";
import { ControllerConnector } from "../../../../components/Base/FormConnector";
import { PhoneRegexp } from "../../../../services/validation.service";
import { LoadingButton } from "@mui/lab";
import { useUser } from "../../../../actions/user.actions";
import { matchIsValidTel } from "mui-tel-input";

type NoPhoneDialogProps = {
  open: boolean;
  handleCancel: () => void;
  handleOk: (result: boolean) => void;
};

const NoPhoneDialog = (props: NoPhoneDialogProps) => {
  const { t } = useTranslation("translation");

  const methods = useForm<{ phone: string }>({
    defaultValues: {
      phone: "",
    },
  });

  const [{ user }, { updateUserProfile }] = useUser();

  const [updating, setUpdating] = useState(false);

  const { formState, handleSubmit } = methods;

  const submit = async (data: { phone: string }) => {
    if (!user) return;
    setUpdating(true);
    try {
      await updateUserProfile({
        Name: user.Name,
        Email: user.Email,
        Phone: data.phone,
        Language: user.Language,
      });

      props.handleOk(!!data.phone);
    } catch {
      //
    }
    setUpdating(false);
  };

  return (
    <Dialog open={props.open}>
      <DialogTitle>{t("ServiceBook.NoPhoneTitle")}</DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submit)}>
          <DialogContent>
            <Grid container>
              <Typography variant="body1">
                {t("ServiceBook.NoPhone")}
              </Typography>
            </Grid>

            <ControllerConnector
              name="phone"
              rules={{
                pattern: PhoneRegexp,
                required: true,
                validate: (e: any) => matchIsValidTel(e),
              }}
            >
              <PhoneField
                sx={{ marginTop: { md: 2, sm: 1, xs: 1 } }}
                variant="outlined"
                fullWidth
                size="small"
                label={t("ServiceBook.Tel")}
                defaultValue={""}
              />
            </ControllerConnector>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => props.handleCancel()}
              variant="contained"
              size="small"
              color="secondary"
            >
              {t("General.Buttons.Undo")}
            </Button>
            <LoadingButton
              variant="contained"
              size="small"
              color="secondary"
              disabled={formState.isSubmitting}
              type="submit"
              loading={updating}
            >
              {t("General.Buttons.Ok")}
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default NoPhoneDialog;
