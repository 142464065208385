import React, { useState } from "react";
import BaseDialog from "../../../components/Dialogs/BaseDialog";
import {
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tab,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useNeighborGroups } from "../../../actions/neighborgroups.actions";
import { EventSubscriberDto, NeighborGroupAnnouncement } from "../../../definitions/model/NeighborGroup";
import UserAvatar from "../../../components/Layout/UserAvatar";
import FileDropZone from "../../../components/Base/FileDropZone";
import { FileType } from "../../../definitions/Document";
import { useDocument } from "../../../actions/document.actions";
import { Dictionary } from "@reduxjs/toolkit";

type ImportSubscribersDialogProps = {
  open: boolean;
  closeDialog: () => void;
  announcement: NeighborGroupAnnouncement;
  updateSubscribers: (subscribers: EventSubscriberDto[]) => Promise<void>;
};
interface FileWithMetadata extends File {
  Metadata: Dictionary<string>;
}

const ImportSubscribersDialog = (props: ImportSubscribersDialogProps) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("group");
  const [{ currentGroupMembers, currentGroup }, actions] = useNeighborGroups();
  const [selected, setSelected] = useState<EventSubscriberDto[]>([]);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [, { uploadFile }] = useDocument();

  const handleChange = (event: React.SyntheticEvent, newValue: "group" | "file") => {
    setActiveTab(newValue);
  };

  const updateSubscribers = () => {
    props.updateSubscribers(selected);
  };

  const addFile = async (files: FileType[]) => {
    const file = files[0];
    if (!file) return;
    try {
      if (file.File) {
        setLoading(true);
        const fileType = await uploadFile(file.File, {
          GroupId: currentGroup.Id,
          AnnouncementId: props.announcement.Id,
        });
        if (!fileType) return;
        await actions.uploadSubscribersFromFile(fileType.Id);
        setShowSuccess(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BaseDialog open={props.open} closeDialog={props.closeDialog}>
        <Grid container direction="column">
          <TabContext value={activeTab}>
            <TabList onChange={handleChange}>
              <Tab value="group" label="Import from group members" />
              <Tab value="file" label="Import from file" />
            </TabList>

            <TabPanel value={"group"}>
              <Grid item container>
                <List dense>
                  {currentGroupMembers
                    .flatMap((x) => x.Members)
                    .filter((s) => !props.announcement?.Subscribers?.some((x) => x.Id === s.UserId))
                    .filter((s) => s.UserId !== props.announcement?.OwnerId)
                    .map((s) => ({
                      Id: s.UserId,
                      Email: s.Email,
                      Name: s.Name,
                      AvatarUrl: s.AvatarUrl,
                      Address: s.Address,
                      Confirmed: false,
                    }))
                    .map((s) => (
                      <ListItem key={s.Id}>
                        <ListItemButton
                          onClick={() => {
                            if (selected.find((x) => x.Id === s.Id)) {
                              setSelected(selected.filter((x) => x.Id !== s.Id));
                              return;
                            }
                            setSelected([...selected, s]);
                          }}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={!!selected.find((x) => x.Id === s.Id)}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": s.Id }}
                            />
                          </ListItemIcon>
                          <ListItemAvatar>
                            <UserAvatar
                              user={{
                                Name: s.Name,
                                AvatarUrl: s.AvatarUrl,
                              }}
                              variant="circular"
                            />
                          </ListItemAvatar>
                          <ListItemText primary={s.Name} secondary={s.Address} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                </List>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={updateSubscribers}>
                  {t("NeighborGroups.AddSubscribers")}
                </Button>
              </Grid>
            </TabPanel>
            <TabPanel value={"file"}>
              <Grid item>
                <Grid item>
                  <FileDropZone
                    addFiles={addFile}
                    removeFile={() => {}}
                    chooseFileText={t("NeighborGroups.ChooseFileText")}
                    loading={loading}
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </TabContext>
        </Grid>
      </BaseDialog>
      <BaseDialog open={showSuccess} closeDialog={() => setShowSuccess(false)}>
        <Typography>
          {t("NeighborGroups.SuccessfullyUploaded")}
        </Typography>
      </BaseDialog>
    </>
  );
};

export default ImportSubscribersDialog;
