export function authHeader() {
  // return authorization header with jwt token
  const user = JSON.parse(localStorage.getItem("user") as string);

  if (user && user.Token) {
    return { Authorization: "Bearer " + user.Token };
  } else {
    return {};
  }
}

export const isInAppBrowser = ((): boolean => {
  const ua = navigator.userAgent || navigator.vendor;
  return ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1 || ua.indexOf("Instagram") > -1;
})();
