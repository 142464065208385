export const AdminPartnersActionTypes = {
    PARTNERS_OPERATION_FAILED: "PARTNERS_OPERATION_FAILED",
    GET_PARTNERS_ITEMS: "GET_PARTNERS_ITEMS",
    GET_PARTNERS_ITEMS_SUCCEEDED: "GET_ADMIN_PARTNERS_ITEMS_SUCCEEDED",
    GET_PARTNERS_ITEM: "GET_PARTNERS_ITEM",
    GET_PARTNERS_ITEM_SUCCEEDED: "GET_PARTNERS_ITEM_SUCCEEDED",
    CREATE_PARTNERS_ITEM: "CREATE_PARTNERS_ITEM",
    CREATE_PARTNERS_ITEM_SUCCEEDED: "CREATE_PARTNERS_ITEM_SUCCEEDED",
    UPDATE_PARTNERS_ITEM: "UPDATE_PARTNERS_ITEM",
    UPDATE_PARTNERS_ITEM_SUCCEEDED: "UPDATE_PARTNERS_ITEM_SUCCEEDED",
    UPDLOAD_PARTNERS_ITEM_BACKGROUND: "UPDLOAD_PARTNERS_ITEM_BACKGROUND",
    UPDLOAD_PARTNERS_ITEM_BACKGROUND_SUCCEEDED: "UPDLOAD_PARTNERS_ITEM_BACKGROUND_SUCCEEDED",
    DELETE_PARTNERS_ITEM: "DELETE_PARTNERS_ITEM",
    DELETE_PARTNERS_ITEM_SUCCEEDED: "DELETE_PARTNERS_ITEM_SUCCEEDED",
    REORDER_PARTNERS_ITEMS: "REORDER_PARTNERS_ITEMS",
    REORDER_PARTNERS_ITEMS_SUCCEEDED: "REORDER_PARTNERS_ITEMS_SUCCEEDED",
    SET_PARTNERS_ITEM_PUBLISH: "SET_PARTNERS_ITEM_PUBLISH",
    SET_PARTNERS_ITEM_PUBLISH_SUCCEEDED: "SET_PARTNERS_ITEM_PUBLISH_SUCCEEDED",
}