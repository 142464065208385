import ApiService from "./api.service";
import {
  BehaviourRule,
  CreateBehaviourRuleContract,
  UpdateBehaviourRuleContract,
} from "../definitions/BehaviourRule";

export const BehaviourRulesService = {
  async getActiveBehaviourRules(): Promise<BehaviourRule[]>{
    const url = "/api/behaviourrule";
    const result = await ApiService.get<BehaviourRule[]>(url);
    return result;
  },
  async getBehaviourRules(): Promise<BehaviourRule[]> {
    const url = "/api/admin/behaviourrule";
    const result = await ApiService.get<BehaviourRule[]>(url);
    return result;
  },
  async createBehaviourRule(
    contract: CreateBehaviourRuleContract
  ): Promise<BehaviourRule> {
    const url = "/api/admin/behaviourrule";
    const result = await ApiService.put<BehaviourRule>(url, contract);

    return result;
  },
  async updateBehaviourRule(
    contract: UpdateBehaviourRuleContract
  ): Promise<BehaviourRule> {
    const url = "/api/admin/behaviourrule";
    const result = await ApiService.post<BehaviourRule>(url, contract);
    return result;
  },
  async removeBehaviourRule(id: string): Promise<string> {
    const url = `/api/admin/behaviourrule/${id}`;
    await ApiService.delete<BehaviourRule>(url);
    return id;
  },
};
