import { Reducer } from "redux";
import { AppAction } from "../definitions/Action";
import { UserMailModel, RecipientModel } from "../definitions/model/UserMail";
import { PostBoxActions } from "../constants/postbox.actiontypes";

export interface PostBoxStoreState {
  // extends UserMailContract
  loading: boolean;
  updating: boolean;
  loadingRecipients: boolean;
  error: string | null;
  messages: UserMailModel[];
  count: number;
  recipients: RecipientModel[];
  newMail: boolean;
  unreadMessages: string[];
}

export const initialState: PostBoxStoreState = {
  messages: [],
  loading: false,
  updating: false,
  loadingRecipients: false,
  error: null,
  recipients: [],
  count: 0,
  newMail: false,
  unreadMessages: [],
};

const postBoxReducer: Reducer<PostBoxStoreState, AppAction> = (
  state: PostBoxStoreState = initialState,
  action: AppAction
): PostBoxStoreState => {
  switch (action.type) {
    case PostBoxActions.GET_MESSAGES:
      return { ...state, loading: true };
    case PostBoxActions.GET_MESSAGE:
    case PostBoxActions.CREATE_MESSAGE:
    case PostBoxActions.REPLY_TO_MESSAGE:
    case PostBoxActions.MOVE_MESSAGE_TO_TRASH:
    case PostBoxActions.REMOVE_MESSAGE:
      return {
        ...state,
        updating: true,
      };
    case PostBoxActions.GET_MESSAGES_SUCCEEDED:
      return {
        ...state,
        messages: action.payload.Results as UserMailModel[],
        count: action.payload.Results.length,
        loading: false,
        newMail: false,
        unreadMessages: [],
      };
    case PostBoxActions.CREATE_MESSAGE_SUCCEEDED:
    case PostBoxActions.REPLY_TO_MESSAGE_SUCCEEDED:
    case PostBoxActions.GET_THREAD_SUCCEEDED:
      return {
        ...state,
        messages: [action.payload as UserMailModel, ...state.messages],
        count: ++state.count,
        updating: false,
      };
    case PostBoxActions.GET_MESSAGE_SUCCEEDED:
      return {
        ...state,
        messages: [action.payload, ...state.messages],
        unreadMessages: state.unreadMessages.filter((x) => x !== action.payload.Id),
      };
    case PostBoxActions.MOVE_MESSAGE_TO_TRASH_SUCCEEDED: //TODO: while we don't have folders move and remove actions are the same
    case PostBoxActions.REMOVE_MESSAGE_SUCCEEDED:
      return {
        ...state,
        messages: state.messages.filter((x) => x.Id !== action.payload),
        count: --state.count,
        updating: false,
      };
    case PostBoxActions.GET_RECIPIENTS:
      return {
        ...state,
        recipients: [],
        loadingRecipients: true,
      };
    case PostBoxActions.GET_RECIPIENTS_SUCCEEDED:
      return {
        ...state,
        recipients: action.payload,
        loadingRecipients: false,
      };
    case PostBoxActions.POSTBOX_OPERATION_FAILED:
      return {
        ...state,
        error: action.payload,
        loadingRecipients: false,
        loading: false,
        updating: false,
      };
    case PostBoxActions.POSTBOX_NEW_MAIL_RECEIVED:
      return {
        ...state,
        newMail: true,
        unreadMessages: [...state.unreadMessages, action.payload],
      };
    default:
      return state;
  }
};

export default postBoxReducer;
