import { Button, Grid, Typography, styled } from "@mui/material";
import React from "react";
import { useServiceWorker } from "../components/ServiceWorker/useServiceWorker";
import { useTranslation } from "react-i18next";

const OfflinePage = () => {
  const { reloadPage } = useServiceWorker();
  const { t } = useTranslation("translation");

  return (
    <StyledGrid>
      <StyledHeader>{t("General.Errors.Maintenance")}</StyledHeader>
      <StyledText variant="h6">
        {t("General.Errors.OfflinePageText")}
      </StyledText>
      <Button variant='contained' onClick={reloadPage}>
        {t("General.Buttons.TryAgain")}
      </Button>
    </StyledGrid>
  );
};

const StyledHeader = styled(Typography)(({ theme }) => ({
  fontSize: "90px",
}));

const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  borderBottom: "1px solid #cccccc",
  padding: "18px 0",
  marginBottom: "18px",
  fontWeight: "400",
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  color: theme.typography.h1.color,
  margin: "50px auto 0",
  textAlign: "center",
  width: "600px",
}));

export default OfflinePage;
