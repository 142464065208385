import { NIL } from "uuid";
import { UserConstants } from "../constants";
import { NeighborGroup, NeighborGroupMembersDto } from "../definitions/model/NeighborGroup";
import { NeighborGroupConstants } from "../constants/neighborgroup.constants";

export interface NeighborGroupStoreState {
  groups: NeighborGroup[];
  currentGroup: NeighborGroup;
  loading: boolean;
  currentGroupMembers: NeighborGroupMembersDto[];
  editorGroup: NeighborGroup;
}

const emptyGroup: NeighborGroup = {
  Id: NIL,
  Created: new Date(),
  Updated: new Date(),
  Name: "",
  Icon: "",
  Description: "",
  Announcements: [],
  OwnerId: "",
  Linked: false,
  NewMessages: false,
  newAnnouncements: false,
  MembersCount: 0,
  Range: 0,
  IsPrivate: false,
  Administrators: [],
  Settings: {
    ConfirmedMember: false,
    ReceiveEmails: false,
    ReceiveSms: false,
  },
  Documents: [],
  Picture: {
    Id: NIL,
    Name: "",
    FileExtension: "",
    Url: undefined,
    AbsoluteUrl: undefined,
    File: undefined,
  },
  IsLocal: false,
  ExternalInvitedMembers: []
};

export const initialState: NeighborGroupStoreState = {
  groups: [],
  currentGroup: emptyGroup,
  loading: false,
  currentGroupMembers: [],
  editorGroup: emptyGroup,
};

const reducer = (
  state: NeighborGroupStoreState = initialState,
  action: { type: keyof typeof UserConstants; payload: any }
) => {
  switch (action.type) {
    case NeighborGroupConstants.GET_NEIGHBORGROUPS:
    case NeighborGroupConstants.GET_NEIGHBORGROUP:
    case NeighborGroupConstants.CREATE_NEIGHBORGROUP:
    case NeighborGroupConstants.UPDATE_NEIGHBORGROUP:
    case NeighborGroupConstants.DELETE_NEIGHBORGROUP:
    case NeighborGroupConstants.JOIN_NEIGHBORGROUP:
    case NeighborGroupConstants.LEAVE_NEIGHBORGROUP:
    case NeighborGroupConstants.ADD_ANNOUNCEMENT:
    case NeighborGroupConstants.REMOVE_ANNOUNCEMENT:
    case NeighborGroupConstants.EDIT_ANNOUNCEMENT:
    case NeighborGroupConstants.ADD_SUBSCRIBER:
    case NeighborGroupConstants.REMOVE_SUBSCRIBER:
    case NeighborGroupConstants.ADD_GROUP_ADMIN:
    case NeighborGroupConstants.INVITE_TO_GROUP:
    case NeighborGroupConstants.REMOVE_FROM_GROUP:
    case NeighborGroupConstants.UPDATE_MEMBER_SETTINGS:
    case NeighborGroupConstants.SET_EDITOR_GROUP:
      return {
        ...state,
        loading: true,
      };
    case NeighborGroupConstants.GET_NEIGHBORGROUPS_SUCCEEDED:
      return {
        ...state,
        groups: action.payload,
        loading: false,
      };
    case NeighborGroupConstants.GET_NEIGHBORGROUP_SUCCEEDED:
      return {
        ...state,
        currentGroup: action.payload,
        currentGroupMembers: [],
        chats: [],
        loading: false,
      };
    case NeighborGroupConstants.CREATE_NEIGHBORGROUP_SUCCEEDED:
      return {
        ...state,
        groups: [...state.groups, action.payload],
        currentGroup: action.payload,
        loading: false,
      };
    //case NeighborGroupConstants.ADD_ANNOUNCEMENT_SUCCEEDED:

    case NeighborGroupConstants.EDIT_ANNOUNCEMENT_SUCCEEDED:
    case NeighborGroupConstants.REMOVE_ANNOUNCEMENT_SUCCEEDED:
    case NeighborGroupConstants.UPDATE_NEIGHBORGROUP_SUCCEEDED: {
      const groups = [...state.groups.filter((x) => x.Id !== action.payload.Id), action.payload];
      if (state.currentGroup?.Id === action.payload.Id) {
        return {
          ...state,
          groups,
          currentGroup: action.payload,
          editorGroup: emptyGroup,
          loading: false,
        };
      }
      return {
        ...state,
        groups,
        loading: false,
      };
    }
    case NeighborGroupConstants.DELETE_NEIGHBORGROUP_SUCCEEDED: {
      const groups = state.groups.filter((x) => x.Id !== action.payload);
      if (state.currentGroup?.Id === action.payload) {
        return {
          ...state,
          groups,
          currentGroup: emptyGroup,
          editorGroup: emptyGroup,
          currentGroupMembers: [],
          loading: false,
        };
      }
      return {
        ...state,
        groups,
        loading: false,
      };
    }
    case NeighborGroupConstants.JOIN_NEIGHBORGROUP_SUCCEEDED:
      return {
        ...state,
        groups: [...state.groups.filter((x) => x.Id !== action.payload.Id), action.payload],
        currentGroup: action.payload,
        loading: false,
        newAnnouncements: true,
      };
    case UserConstants.USER_OPERATION_FAILED:
      return {
        ...state,
        loading: false,
      };
    case NeighborGroupConstants.LEAVE_NEIGHBORGROUP_SUCCEEDED:
      return {
        ...state,
        currentGroup: emptyGroup,
        currentGroupMembers: [],
        loading: false,
      };
    case NeighborGroupConstants.GET_NEIGHBORGROUP_MEMBERS_SUCCEEDED:
      return {
        ...state,
        currentGroupMembers: action.payload,
        loading: false,
      };
    case NeighborGroupConstants.NEW_ANNOUNCEMENT_RECEIVED: {
      const groups = state.groups.map((x) => {
        if (x.Id === action.payload.ParentId) {
          return { ...x, newAnnouncements: true };
        }
        return x;
      });

      const current = { ...state.currentGroup, Announcements: [...state.currentGroup.Announcements] };
      if (state.currentGroup?.Id === action.payload.ParentId) {
        current.Announcements = [
          ...state.currentGroup.Announcements.filter((x) => x.Id !== action.payload.Id),
          action.payload,
        ];
      }
      return {
        ...state,
        groups: groups,
        currentGroup: current,
      };
    }
    case NeighborGroupConstants.ANNOUNCEMENT_UPDATED:
      {
        if (state.currentGroup?.Id === action.payload.ParentId) {
          const current = { ...state.currentGroup, Announcements: [...state.currentGroup.Announcements] };
          current.Announcements = [
            ...state.currentGroup.Announcements.filter((x) => x.Id !== action.payload.Id),
            action.payload,
          ];
          return {
            ...state,
            currentGroup: current,
          };
        }
      }
      return state;

    case NeighborGroupConstants.ANNOUNCEMENT_REMOVED:
      {
        if (state.currentGroup?.Id === action.payload.GroupId) {
          const current = {
            ...state.currentGroup,
            Announcements: [...state.currentGroup.Announcements.filter((x) => x.Id !== action.payload.AnnouncementId)],
          };
          return {
            ...state,
            currentGroup: current,
          };
        }
      }
      return state;
    case NeighborGroupConstants.CLEAR_ANNOUNCEMENT_NOTIFICATION:
      return {
        ...state,
        groups: state.groups.map((x) => {
          if (x.Id === action.payload) {
            return { ...x, newAnnouncements: false };
          }
          return x;
        }),
      };
    case NeighborGroupConstants.ADD_SUBSCRIBER_SUCCEEDED:
    case NeighborGroupConstants.REMOVE_SUBSCRIBER_SUCCEEDED:
      return {
        ...state,
        groups: [...state.groups.filter((x) => x.Id != action.payload.Id), action.payload],
        currentGroup: action.payload,
        loading: false,
      };
    case NeighborGroupConstants.SET_NEW_ANNOUNCEMENTS:
      return {
        ...state,
        groups: state.groups.map((x) => {
          if (x.Id === action.payload) {
            return { ...x, newAnnouncements: true };
          }
          return x;
        }),
      };
    case NeighborGroupConstants.INVITE_TO_GROUP_SUCCEEDED:
    case NeighborGroupConstants.ADD_GROUP_ADMIN_SUCCEEDED:
    case NeighborGroupConstants.UPDATE_MEMBER_SETTINGS_SUCCEEDED:
      return {
        ...state,
        currentGroup: action.payload,
        loading: false,
      };
    case NeighborGroupConstants.UPDATE_GROUP_PICTURE_SUCCEEDED:
    case NeighborGroupConstants.UPDATE_GROUP_DOCUMENTS_SUCCEEDED:
      if (state.currentGroup?.Id === action.payload.Id) {
        return {
          ...state,
          groups: state.groups.map((group) => {
            if (group.Id === action.payload.Id) {
              return action.payload;
            }

            return group;
          }),
          currentGroup: action.payload,
          editorGroup: action.payload,
          loading: false,
        };
      }
      return { ...state, loading: false };
    case NeighborGroupConstants.REMOVE_FROM_GROUP_SUCCEEDED:
      return {
        ...state,
        currentGroup: action.payload,
        groups: state.groups.map((x) => {
          if (x.Id === action.payload.Id) {
            return { ...x, ...action.payload };
          }
          return x;
        }),
        loading: false,
      };
    case NeighborGroupConstants.SET_EDITOR_GROUP_SUCCEEDED:
      if (!action.payload) {
        return {
          ...state,
          editorGroup: emptyGroup,
          loading: false,
        };
      }
      return {
        ...state,
        editorGroup: action.payload,
        loading: false,
      };

    case NeighborGroupConstants.UPDATE_MEMBER_GROUP_VISITED_DATE_SUCCEEDED:
      return {
        ...state,
        currentGroupMembers: state.currentGroupMembers.map((group) => ({
          ...group,
          Members: group.Members.map((member) =>
            member.UserId === action.payload.UserId ? { ...member, LastVisited: action.payload.LastVisited } : member
          ),
        })),
        loading: false,
      };

    case NeighborGroupConstants.UPDATE_GROUP_NEW_MESSAGES:
      return {
        ...state,
        groups: state.groups.map((x) =>
          x.Id === action.payload.groupId ? { ...x, NewMessages: action.payload.newMessage } : x
        ),
        currentGroup:
          state.currentGroup?.Id === action.payload.groupId
            ? { ...state.currentGroup, NewMessages: action.payload.newMessage }
            : state.currentGroup,
      };

    default:
      return state;
  }
};

export default reducer;
