import React, { SetStateAction, Dispatch, useState, useEffect, useCallback } from "react";
import LoginDialog from "./LoginDialog/LoginDialog";
import RegisterDialog from "./RegisterDialog/RegisterDialog";
import ForgotPasswordDialog from "./ForgotPasswordDialog/ForgotPasswordDialog";
import AuthModel from "../../definitions/model/Auth";
import { useLocation } from "react-router-dom";
import { usePreviousRoute } from "../../routes/PreviouseRouteContext";
import { BehaviourRule } from "../../definitions/BehaviourRule";
import { useUser } from "../../actions";
import { useBehaviourRuleState } from "../../actions/behaviourRules.actions";

export const StepArray = ["register", "registerPhone", "login", "forgotPassword", "initial"] as const;
export type Step = (typeof StepArray)[number];

export interface AuthDialogChildrenProps {
  setStep: (step: Step, state?: AuthModel) => void;
  state: AuthModel;
  closeDialog: () => void;
  open: boolean;
  ignoreNotifications: boolean;
  showPhoneField?: boolean;
  showNameField?: boolean;
  showCO2MailField?: boolean;
  onRegistrationComplete?: (unitId: string) => Promise<void>;
  employerId?: string;
  communeNumber?: number;
  loginOnly?: boolean;
  loginButtonText?: string;
}

type AuthDialogProps = {
  open: boolean;
  TermsOfUse: boolean;
  Notifications: boolean;
  PhoneNotifications: boolean | undefined;
  closeDialog: () => void;
  step: Step;
  setStep: Dispatch<SetStateAction<Step>>;
  children?: React.ReactNode;
  propertyid?: string;
  simpleRegistration?: boolean;
  showNameField?: boolean;
  ignoreNotifications?: boolean;
  onRegistrationComplete?: (unitId: string) => Promise<void>;
  showAlertHandler?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  Email?: string;
};

const AuthDialog = ({ step, setStep, closeDialog, ...other }: AuthDialogProps) => {
  const { pathname } = useLocation();
  const { previousLocation, startLocation } = usePreviousRoute();
  const [state, setState] = useState<AuthModel>({
    Email: other.Email || "",
    Password: "",
    FullName: "",
    PropertyId: "",
    TermsOfUse: other.TermsOfUse,
    Notifications: other.Notifications,
    PhoneNotifications: other.PhoneNotifications,
    AddressConfirmation: false,
  });

  const [{ user, error, loginOnly, loginButtonText, communeNumber, employerId, authPropertyId, authSimpleRegistration }, { clearUserError }] = useUser();

  useEffect(() => {
    const newState = {
      Email: other.Email || "",
      Password: "",
      FullName: "",
      PropertyId: "",
      TermsOfUse: other.TermsOfUse,
      Notifications: other.Notifications,
      PhoneNotifications: other.PhoneNotifications,
      AddressConfirmation: false,
    };

    if (JSON.stringify(state) !== JSON.stringify(newState)) setState(newState);
  }, [other.TermsOfUse, other.Notifications, other.PhoneNotifications, other.Email]);

  const behaviourRules = useBehaviourRuleState();
  useEffect(() => {
    if (other.open && user && !authSimpleRegistration) {
      closeDialog();
    }
  }, [user, other.open]);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const extendedSetStep = (step: Step, partialState: object = {}) => {
    setStep(step);
    if (error) clearUserError();

    setState({
      ...state,
      ...partialState,
    });
  };

  const extendedCloseDialog = () => {
    setState({
      TermsOfUse: false,
      Notifications: false,
    });
    if (error) clearUserError();

    closeDialog();
  };

  const additionalFields = useCallback(() => {
    const paths = [pathname, startLocation?.pathname, "all"];
    if (previousLocation?.pathname) {
      paths.unshift(previousLocation.pathname);
    }

    let rules: BehaviourRule[] = [];

    for (let index = 0; index < paths.length; index++) {
      rules = behaviourRules.rules.filter((x) => x.Path.toLocaleLowerCase() === paths[index]?.toLowerCase());
      if (rules.length > 0) break;
    }

    return Object.fromEntries(rules.flatMap((x) => x.Fields.map((y) => [`show${y}Field`, true])));
  }, [previousLocation, pathname, behaviourRules]);

  const childrenProps: AuthDialogChildrenProps = {
    state: {
      ...state,
      PropertyId: authPropertyId,
      SimpleRegistration: authSimpleRegistration,
    },
    setStep: extendedSetStep,
    closeDialog: extendedCloseDialog,
    ...other,
    ignoreNotifications: other.ignoreNotifications || false,
    loginButtonText: loginButtonText,
    employerId: employerId,
    communeNumber: communeNumber,
    ...additionalFields(),
  };
  let componentToRender: null | JSX.Element;
  switch (step) {
    case "login":
      componentToRender = (
        <LoginDialog loginOnly={loginOnly} {...childrenProps} />
      );
      break;

    case "register":
      componentToRender = <RegisterDialog {...childrenProps} />;
      break;
    case "registerPhone":
      componentToRender = <RegisterDialog showPhoneField={true} {...childrenProps} />;
      break;
    case "forgotPassword":
      componentToRender = <ForgotPasswordDialog {...childrenProps} />;
      break;
    default:
      componentToRender = null;
      break;
  }

  return <>{componentToRender}</>;
};

export default AuthDialog;
