import { Draggable } from "@hello-pangea/dnd";
import {
  AdminPanelSettings,
  Delete,
  Diversity3,
  Edit,
  LocalPolice,
  PublicOutlined,
  Visibility,
} from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  TextFieldProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import {
  DocumentSourceType,
  DocumentType,
  FileType,
  FolderType,
  UpdateDocumentRequest,
} from "../../../../definitions/Document";
import { DocumentCildSourceItem } from "../Folders";
import { PermissionTarget } from "../../../../constants/enums";
import { useDocument } from "../../../../actions/document.actions";
import { useTranslation } from "react-i18next";
import { useAlertContext } from "../../../../components/Base/MyhouseAlert";
import FilePreviewDialog from "./FilePreviewDialog";
import { IDocument } from "@cyntler/react-doc-viewer";
import DocumentDialog from "./DocumentDialog";

type DocumentProps = {
  readonly: boolean;
  sourceItemId: string;
  sourceType: DocumentSourceType;
  childSourceItemId?: string;
  childSourceItems?: DocumentCildSourceItem[];
  usePermissions?: boolean;
  documents: DocumentType[];
  folder: FolderType;
  permissionsList: PermissionTarget[];
  setSettingsRef: (value: boolean) => void;
};

const Documents = (props: DocumentProps) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const { showAlert } = useAlertContext();
  const { t } = useTranslation("translation");
  const [{ documentsLoading }, actions] = useDocument();
  const [autocompleteValue, setAutocompleteValue] = useState<DocumentCildSourceItem | null>(null);
  const [showDocument, setShowDocument] = useState<IDocument | undefined>(undefined);
  const [folderId, setFolderId] = useState("");
  const [open, setOpen] = useState(false);
  const [document, setDocument] = useState<DocumentType | null>(null);

  const openDocumentDialog = (folderId: string, document: DocumentType | null = null) => {
    props.setSettingsRef(true);
    setOpen(true);
    setFolderId(folderId);
    setDocument(document);
  };

  const updateDocument = async (document: UpdateDocumentRequest) => {
    try {
      await actions.updateDocument(document);
    } catch (e: any) {
      showAlert({
        severity: "error",
        text: t("Documents.Messages.OperationError"),
      });
    }
  };

  const closeDocumentDialog = () => {
    props.setSettingsRef(false);
    setOpen(false);
    setDocument(null);
  };

  const deleteDocument = async (folderId: string, documentId: string) => {
    try {
      await actions.deleteDocument(
        {
          SourceItemId: props.sourceItemId,
          FolderId: folderId,
          DocumentId: documentId,
        },
        props.sourceType
      );
      showAlert({
        severity: "success",
        text: t("Documents.Messages.DocumentRemoved"),
      });
    } catch (e) {
      showAlert({
        severity: "error",
        text: t("Documents.Messages.InsufficientRights"),
      });
    }
  };

  return (
    <>
      {props.documents.map((document, idx) => (
        <Draggable
          key={document.Id}
          draggableId={document.Id}
          index={idx}
          isDragDisabled={props.readonly || document.isDirty || props.folder.isDirty}
        >
          {(provided, snapshot) => (
            <>
              <Grid
                container
                item
                justifyContent={"space-between"}
                key={document.Id}
                sx={{
                  marginTop: "10px",
                  flexWrap: "nowrap",
                  borderBottom: snapshot.isDragging ? "none" : "1px solid grey",
                  "& :last-child": { borderBottom: "none" },
                  backgroundColor: snapshot.isDragging || document.isDirty ? "lightgrey" : "white",
                  color: theme.palette.primary.main,
                }}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <Grid item xs={7} sm={9} sx={{ flex: 1 }} key={document.Id}>
                  <Typography
                    sx={{
                      width: isXs ? "200px" : "auto",
                      height: "20px",
                      "text-overflow": "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {document?.Name}
                  </Typography>
                  <Typography>
                    {new Date(document?.Submitted).toLocaleString("da", {
                      dateStyle: "short",
                    })}
                  </Typography>
                  {!props.childSourceItemId && props.childSourceItems && (
                    <Grid item container spacing={0.5} pb={1}>
                      {document.RelatedSourceIds?.length > 0 &&
                        document.RelatedSourceIds.map((sId) => (
                          <Grid item key={sId}>
                            <Chip
                              label={props.childSourceItems?.find((c) => c.id === sId)?.name}
                              variant="outlined"
                              color="secondary"
                              sx={{
                                borderBottom: "1px solid",
                                borderColor: theme.palette.secondary.main,
                              }}
                              onDelete={async () => {
                                await updateDocument({
                                  Name: document.Name,
                                  Description: document.Description,
                                  SourceItemId: props.sourceItemId,
                                  SourceType: props.sourceType,
                                  Permission: document.Permission,
                                  FolderId: props.folder.Id,
                                  DocumentId: document.Id,
                                  FileIds: document.Files.map((file: FileType) => file.Id),
                                  RelatedSourceIds: document.RelatedSourceIds.filter((s) => s != sId),
                                });
                              }}
                            />
                          </Grid>
                        ))}
                      <Grid item>
                        <Autocomplete
                          value={autocompleteValue}
                          sx={{
                            width: 200,
                          }}
                          size="small"
                          key={`autocomplete_document_child_source_key_${document.Id}`}
                          color="secondary"
                          onChange={async (event, val) => {
                            setAutocompleteValue(val);
                            if (val) {
                              await updateDocument({
                                Name: document.Name,
                                Description: document.Description,
                                SourceItemId: props.sourceItemId,
                                SourceType: props.sourceType,
                                Permission: document.Permission,
                                FolderId: props.folder.Id,
                                DocumentId: document.Id,
                                FileIds: document.Files.map((file: FileType) => file.Id),
                                RelatedSourceIds: [...document.RelatedSourceIds, val.id],
                              });
                              setAutocompleteValue(null);
                            }
                          }}
                          options={props.childSourceItems.filter((c) => !document.RelatedSourceIds.includes(c.id))}
                          getOptionLabel={(o) => o.name}
                          isOptionEqualToValue={(o, value) => props.childSourceItemId === o.id}
                          renderInput={(params: AutocompleteRenderInputParams) => (
                            <TextField
                              {...(params as TextFieldProps)}
                              label={t("Documents.AttachTo")}
                              variant="outlined"
                              color="secondary"
                              placeholder={t("Documents.AttachTo")}
                            />
                          )}
                        ></Autocomplete>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item container justifyContent="flex-end" alignItems="center" xs={5} sm={3}>
                  {document.isDirty ? (
                    <CircularProgress size={20} sx={{ mr: 1 }} />
                  ) : (
                    <>
                      {!props.readonly && (
                        <>
                          {props.usePermissions && (
                            <IconButton>
                              {document.Permission === PermissionTarget.Public && <PublicOutlined />}
                              {document.Permission === PermissionTarget.Member && <Diversity3 />}
                              {document.Permission === PermissionTarget.Group && <AdminPanelSettings />}
                              {document.Permission === PermissionTarget.Owner && <LocalPolice />}
                            </IconButton>
                          )}
                          <IconButton
                            disabled={props.folder.isDirty}
                            onClick={() => openDocumentDialog(props.folder.Id, document)}
                          >
                            <Edit />
                          </IconButton>
                        </>
                      )}
                      <IconButton
                        disabled={props.folder.isDirty}
                        onClick={() =>
                          setShowDocument({
                            uri: document.Files[0].AbsoluteUrl || "",
                            fileName: document.Files[0].Name || "",
                            fileType: document.Files[0].FileExtension?.substring(1) || "",
                          })
                        }
                      >
                        <Visibility />
                      </IconButton>
                      {!props.readonly && (
                        <IconButton
                          disabled={props.folder.isDirty}
                          onClick={() => deleteDocument(props.folder.Id, document.Id)}
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
              {documentsLoading.includes(document.Id) && <LinearProgress sx={{ width: "100%" }} color="secondary" />}
            </>
          )}
        </Draggable>
      ))}

      <FilePreviewDialog
        open={Boolean(showDocument)}
        document={showDocument}
        onClose={() => setShowDocument(undefined)}
      />
      {!props.readonly && (
        <DocumentDialog
          open={open}
          sourceItemId={props.sourceItemId}
          sourceType={props.sourceType}
          folderId={folderId}
          document={document}
          permissions={props.permissionsList}
          usePermissions={props.usePermissions || false}
          createDocument={actions.createDocument}
          updateDocument={updateDocument}
          uploadFile={actions.uploadFile}
          handleClose={closeDocumentDialog}
        />
      )}
    </>
  );
};

export default Documents;
