import { PaletteColorOptions, PaletteColor, responsiveFontSizes } from "@mui/material/styles";
import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface PaletteOptions {
    facebook?: PaletteColorOptions;
    green?: PaletteColorOptions;
    yellow?: PaletteColorOptions;
  }
  interface Palette {
    facebook: PaletteColor;
    green: PaletteColor;
    yellow: PaletteColor;
    [key: string]: any;
  }
}

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    gray?: string;
    grayPaper?: string;
    lightPaper?: string;
  }
}

export type colorType = "primary" | "secondary" | "info" | "green" | "yellow" | "facebook" | null | undefined;

export const getColorByType = (type: colorType): PaletteColor => (type ? theme.palette[type] : theme.palette.primary);

/**
 * DO NOT IMPORT IT DIRECTLY EXCEPT OF TO THE THEME PROVIDER IN APP.TSX
 */
const greenTheme = createTheme(
  {
    shape: {
      borderRadius: 10,
    },
    palette: {
      primary: {
        main: "#54602c",
        dark: "#353d1c",
      },
      secondary: {
        main: "#32c111",
        light: "#00c1114d",
        contrastText: "#fff",
      },
      success: {
        main: "#32c111",
        contrastText: "#fff",
        light: "#32c111",
      },
      facebook: {
        main: "#3b5a99",
        dark: "#00316a",
        light: "#6d87ca",
        contrastText: "#fff",
      },
      green: {
        main: "#1c4b15",
        light: "#49773e",
        dark: "#002300",
        contrastText: "#fff",
      },
      yellow: {
        main: "#a17f34",
        contrastText: "#fff",
      },
      background: {
        default: "#ffffff",
        gray: "#efefef",
        grayPaper: "#f5f5f5",
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    typography: {
      fontFamily: "'CircularXX-Book', sans-serif",
      allVariants: {
        color: "#000000",
      },
      h1: {
        fontSize: 30,
        padding: "20px 0",
        color: "#000000",
      },
      h2: {
        fontSize: 28,
        lineHeight: 1.5,
        padding: "10px 0",
      },
      h3: {
        padding: "10px 0",
        fontSize: 20,
        lineHeight: 1.5,
        fontFamily: "CircularXX-Bold",
      },
      h4: {
        padding: "10px 0",
        fontSize: 24,
        lineHeight: 1.5,
      },
      h5: {
        padding: "10px 0",
        fontSize: 22,
        lineHeight: 1.5,
      },
      h6: {
        padding: "10px 0",
        fontSize: 16,
        lineHeight: 1.5,
        fontFamily: "CircularXX-Bold",
      },
      body1: {
        fontSize: 16,
        lineHeight: 1.5,
        padding: "0",
      },
      body2: {
        fontSize: 14,
        padding: "3px 0",
      },
      subtitle1: {
        fontSize: 16,
        lineHeight: 1.5,
        padding: "2px 0",
        fontFamily: "CircularXX-Bold",
      },
      subtitle2: {
        fontSize: 13,
        padding: "3px 0",
        fontFamily: "CircularXX-Bold",
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            maxWidth: 210,
            minWidth: 80,
            borderRadius: 10,
            textTransform: "none",
            fontSize: 14,
          },
        },
        defaultProps: {
          disableElevation: true,
          size: "large",
        },
      },
      MuiLink: {
        defaultProps: {
          underline: "none",
        },
        styleOverrides: {
          root: {
            cursor: "pointer",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 10,
            color: "#000",
          },
        },
        defaultProps: {
          elevation: 0,
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: "standard",
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: "standard",
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  },
  { name: "greenTheme" }
);

export const theme = responsiveFontSizes(greenTheme);

const climateTheme = createTheme(
  {
    palette: {
      primary: {
        main: "#9994df",
        contrastText: "#141344",
        light: "#c6c2d2",
      },
      secondary: {
        main: "#cebaff",
        light: "#efe9ff",
        dark: "#9480c7",
        contrastText: "#141344",
      },
      background: {
        default: "#e4e2f0",
        paper: "#ffffff",
        grayPaper: "#e7e8fd",
        lightPaper: "#f8f7ff",
      },
      divider: "rgba(135, 135, 135, 0.38)",
      info: {
        main: "#a0ed6e",
      },
      success: {
        main: "#a0ed6e",
      },
    },
    typography: {
      fontFamily: "'CircularXX-Book', sans-serif",
      allVariants: {
        fontFamily: "CircularXX-Book",
      },

      h1: {
        fontSize: 28,
        padding: "20px 0",
        fontFamily: "CircularXX-Bold",
      },
      h2: {
        fontSize: 26,
        fontFamily: "CircularXX-Bold",
      },
      h3: {
        fontSize: 24,
        fontFamily: "CircularXX-Bold",
      },
      h4: {
        fontSize: 22,
      },
      h5: {
        padding: "10px 0",
        fontFamily: "CircularXX-Bold",
        fontSize: 20,
        lineHeight: 1.5,
      },
      h6: {
        fontSize: 16,
      },
      body1: {
        fontSize: 14,
      },
      subtitle1: {
        fontSize: 14,
        fontFamily: "CircularXX-Bold",
      },
      subtitle2: {
        fontSize: 13,
        lineHeight: 1.3,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 10,
            fontFamily: "CircularXX-Book",
            textTransform: "none",
            "&.Mui-disabled": {
              opacity: 0.4,
            },
          },
          contained: {
            color: "#141344",
          },
        },
        defaultProps: {
          disableElevation: true,
          size: "large",
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: "inherit",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&:before": {
              borderBottom: "unset !important",
            },
          },
          input: {
            borderBottom: "1px solid rgba(135, 135, 135, 0.38)",
            "&.Mui-disabled": {
              WebkitTextFillColor: "rgba(135, 135, 135, 0.38)",
            },
            "&:-webkit-autofill": {
              backgroundColor: "transparent !important",
              webkitBoxShadow: "white  1000px transparent inset",
              transition: "background-color 5000s ease-in-out 0s",
            },
          },
        },
      },

      MuiLink: {
        defaultProps: {
          underline: "none",
          color: "secondary",
        },
        styleOverrides: {
          root: {
            cursor: "pointer",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "1px solid rgba(135, 135, 135, 0.38)",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            borderBottom: "none",
            borderRadius: 10,
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: "standard",
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: "standard",
          disableUnderline: true,
        },
        styleOverrides: {
          select: {
            borderBottom: "1px solid rgba(135, 135, 135, 0.38)",
            "&.Mui-disabled": {
              WebkitTextFillColor: "rgba(135, 135, 135, 0.38)",
            },
          },
        },
      },

      MuiPaper: {
        styleOverrides: {
          outlined: {
            border: "1px solid transparent",
          },
          root: {
            borderRadius: 10,
          },
        },
        defaultProps: {
          variant: "outlined",
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            borderRadius: 10,
            "& .MuiListItemIcon-root": {
              borderRadius: 10,
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            borderRadius: 10,
            ".MuiAlert-icon": {
              color: "inherit",
            },
          },
          standardInfo: {
            backgroundColor: "#a0ed6e",
          },
          standardSuccess: {
            backgroundColor: "#a0ed6e",
          },
          standardError: {
            backgroundColor: "#fa414185",
          },
        },
      },
    },
  },
  { name: "communeTheme" }
);
const commercialThemeDefinition = createTheme(
  {
    palette: {
      primary: {
        main: "#9dbad0",
        contrastText: "#141344",
        light: "#ccdaeb",
      },
      secondary: {
        main: "#4a84ac",
        light: "#e9f0ff",
        dark: "#417fa3",
        contrastText: "#ffffff",
      },
      background: {
        default: "#f2e9e4",
        paper: "#ffffff",
        grayPaper: "#d9edf9",
        lightPaper: "#f4fafd",
      },
      divider: "rgba(135, 135, 135, 0.38)",
      info: {
        main: "#3b7d23",
      },
      success: {
        main: "#3b7d23",
      },
      warning: {
        main: "#c26624",
        contrastText: "#fff",
      },
    },
    typography: {
      fontFamily: "'Aptos', sans-serif",
      allVariants: {
        fontFamily: "Aptos",
      },

      h1: {
        fontSize: 28,
        padding: "20px 0",
        fontFamily: "Aptos-Light",
      },
      h2: {
        fontSize: 26,
        fontFamily: "Aptos-Bold",
      },
      h3: {
        fontSize: 24,
        fontFamily: "Aptos-Bold",
      },
      h4: {
        fontSize: 22,
      },
      h5: {
        padding: "10px 0",
        fontFamily: "Aptos-Bold",
        fontSize: 20,
        lineHeight: 1.5,
      },
      h6: {
        fontSize: 18,
      },
      body1: {
        fontSize: 16,
      },
      body2: {
        fontSize: 14,
      },
      subtitle1: {
        fontSize: 16,
        fontFamily: "Aptos-Bold",
      },
      subtitle2: {
        fontSize: 14,
        lineHeight: 1.3,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 3,
            fontFamily: "Aptos",
            textTransform: "none",
            "&.Mui-disabled": {
              opacity: 0.4,
            },
          },
        },
        defaultProps: {
          disableElevation: true,
          size: "large",
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: "inherit",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&:before": {
              borderBottom: "unset !important",
            },
          },
          input: {
            borderBottom: "1px solid rgba(135, 135, 135, 0.38)",
            "&.Mui-disabled": {
              WebkitTextFillColor: "rgba(135, 135, 135, 0.38)",
            },
            "&:-webkit-autofill": {
              backgroundColor: "transparent !important",
              webkitBoxShadow: "white  1000px transparent inset",
              transition: "background-color 5000s ease-in-out 0s",
            },
          },
        },
      },

      MuiLink: {
        defaultProps: {
          underline: "none",
          color: "secondary",
        },
        styleOverrides: {
          root: {
            cursor: "pointer",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "1px solid rgba(135, 135, 135, 0.38)",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            borderBottom: "none",
            borderRadius: 3,
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: "standard",
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: "standard",
          disableUnderline: true,
        },
        styleOverrides: {
          select: {
            borderBottom: "1px solid rgba(135, 135, 135, 0.38)",
            "&.Mui-disabled": {
              WebkitTextFillColor: "rgba(135, 135, 135, 0.38)",
            },
          },
        },
      },

      MuiPaper: {
        styleOverrides: {
          outlined: {
            border: "1px solid transparent",
          },
          root: {
            borderRadius: 3,
          },
        },
        defaultProps: {
          variant: "outlined",
        },
      },

      MuiAlert: {
        styleOverrides: {
          root: {
            borderRadius: 3,
            ".MuiAlert-icon": {
              color: "inherit",
            },
          },
          standardInfo: {
            backgroundColor: "#a0ed6e",
          },
          standardSuccess: {
            backgroundColor: "#a0ed6e",
          },
          standardError: {
            backgroundColor: "#fa414185",
          },
        },
      },
    },
  },
  { name: "commercialTheme" }
);

export const communeTheme = responsiveFontSizes({ ...greenTheme, ...climateTheme });

export const commercialTheme = responsiveFontSizes({ ...greenTheme, ...commercialThemeDefinition });

export const getOptionalTheme = (themeToPick?: string) => {
  if (themeToPick === "communeTheme") {
    return communeTheme;
  }
  if (themeToPick === "commercialTheme") {
    return commercialTheme;
  }
  return theme;
};

export const markerColor = {
  house: "#ff0000",
  neighbor: "#5b8c12",
  tower: "#65399a",
  bus: "#3d6d69",
  gymnasium: "#0e55b6",
  school: "#15840f",
  ecoBusiness: "#068D00",
};
