import { NewsStreamDTO } from "../../definitions/NewsStream";
import ApiService from "../../services/api.service";
import { NewsCategoriesFilter } from "../../constants/enums";

const baseEndpoint = "/api/newsstream";

const NewsService = {
  getNewsStreams: async (longitude: number, latitude: number, skip: number, take: number, filter: NewsCategoriesFilter | null, requestTime: Date ): Promise<NewsStreamDTO[]> => {
    const url = `${baseEndpoint}?longitude=${longitude}&latitude=${latitude}&skip=${skip}&take=${take}&filter=${filter ?? ""}&requestTime=${requestTime.toJSON()}`;
    const result = await ApiService.get<NewsStreamDTO[]>(url);
    return result;
  },
  getNewsStream: async (actionId: string): Promise<NewsStreamDTO> => {
    const url = `${baseEndpoint}/${actionId}`;
    const result = await ApiService.get<NewsStreamDTO>(url);
    return result;
  },
  getCustomNews: async (newsId: string): Promise<NewsStreamDTO> => {
    const url = `${baseEndpoint}/${newsId}/custom`;
    const result = await ApiService.get<NewsStreamDTO>(url);
    return result;
  },
};

export default NewsService;
