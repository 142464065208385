import { useSelector } from "react-redux";
import { useAppDispatch } from ".";
import { CO2Stat } from "../constants/co2stat.constants";
import { StatisticsArticleType } from "../constants/enums";
import { AppThunkDispatch } from "../definitions/Action";
import { RatingDto, StatisticsArticleDto } from "../definitions/model/CO2Stat";
import { SearchResult } from "../definitions/model/SearchResult";
import ApiService from "../services/api.service";

const operationFailedAction = (error: any) => ({
  type: CO2Stat.OPERATION_FAILED,
  payload: error,
});

const getArticles =
  (filter: string, types: StatisticsArticleType[], skip: number, take: number) =>
  async (dispatch: AppThunkDispatch): Promise<SearchResult<StatisticsArticleDto>> => {
    try {
      dispatch({
        type: CO2Stat.GET_ARTICLES,
      });

      const articleTypes = types.map((t) => t.toString()).join("&types=");

      const result = await ApiService.get<SearchResult<StatisticsArticleDto>>(
        `/api/co2stat/articles?filter=${filter}&types=${articleTypes}&skip=${skip}&take=${take}`
      );
      dispatch({
        type: CO2Stat.GET_ARTICLES_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const getArticle =
  (url: string) =>
  async (dispatch: AppThunkDispatch): Promise<StatisticsArticleDto> => {
    try {
      dispatch({
        type: CO2Stat.GET_ARTICLE,
      });
      const result = await ApiService.get<StatisticsArticleDto>(`/api/co2stat/articles/${url}`);
      dispatch({
        type: CO2Stat.GET_ARTICLE_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const getRatings =
  (sortLabel: string, page: number, pageSize: number, descending: boolean) =>
  async (dispatch: AppThunkDispatch): Promise<SearchResult<RatingDto>> => {
    try {
      dispatch({ type: CO2Stat.GET_RATINGS });
      const result = await ApiService.get<SearchResult<RatingDto>>(
        `/api/co2stat/ratings?sortLabel=${sortLabel}&page=${page}&pageSize=${pageSize}&descending=${descending}`
      );
      dispatch({ type: CO2Stat.GET_RATINGS_SUCCEEDED });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const useCO2StatActions = () => {
  const dispatch = useAppDispatch();
  return {
    getArticles: (filter: string, types: StatisticsArticleType[], skip: number, take: number) =>
      dispatch(getArticles(filter, types, skip, take)),
    getArticle: (url: string) => dispatch(getArticle(url)),
    getRatings: (sortLabel: string, page: number, pageSize: number, descending: boolean) =>
      dispatch(getRatings(sortLabel, page, pageSize, descending)),
  };
};
