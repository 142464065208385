import React, { useRef, ReactElement } from "react";

type PictureInputProps = {
  getPicture: (imageData: File) => void;
  children: ReactElement;
  multiple?: boolean;
};

const FileInput = (props: PictureInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const showFileUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const capture = (imageData: File) => {
    if (imageData) {
      props.getPicture(imageData);
    }
  };

  const onFilesAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event &&
      event.currentTarget &&
      event.currentTarget.files &&
      event.currentTarget.files.length
    ) {
      const file = event.currentTarget.files[0];
      capture(file);
    }
  };
  
  const button = React.cloneElement(props.children, {
    onClick: showFileUpload
  })

  return (
    <>
      {button}
      <input
        ref={inputRef}
        style={{ display: "none" }}
        type="file"
        multiple={props.multiple || true}
        onChange={onFilesAdded}
      />
    </>
  );
};

export default FileInput;
