import React, { useEffect, useState } from "react";
import { ButtonsWrapper, Wrapper } from "./CookiesBlock";
import { Button, Grid, Icon, Typography, styled, useTheme } from "@mui/material";
import { t } from "i18next";
import { useAddToHomescreenPrompt } from "../../../helpers/useAddToHomescreenPrompt";
import AddToHomeScreenDialog from "../../../components/Dialogs/AddToHomeScreenDialog";
import CheckIcon from "../../../assets/check-logo.png";
import BlueCheckIcon from "../../../assets/check-logo-blue.png";
import mhLogo from "../../../assets/icon_x192.png";
import { useUser } from "../../../actions";
import NavigationHelper from "../../../helpers/navigation-helper";

const LogoImage = styled("img")(() => ({
  height: "100%",
}));

const NewLogoIcon = styled(Icon)(() => ({
  display: "flex",
  width: "50px",
  height: "50px",
  "&:hover": {
    cursor: "pointer",
  },
  "& img": {
    height: "inherit",
  },
  "@media print": {
    filter: " drop-shadow(5px 5px 5px rgba(0,0,0,0.75))",
  },
}));

const InstallPwaBlock = () => {
  const theme = useTheme();
  const [hasInstalled, promptToInstall] = useAddToHomescreenPrompt();
  const [visible, setVisible] = useState(true);
  const [openAddHomeScreen, setOpenAddHomeScreen] = useState(false);
  const [userState] = useUser();
  const klimaplan = NavigationHelper.IsClimateSite();
  const [climateTheme, setClimateTheme] = useState(false);

  const InstallPwa = async () => {
    try {
      await promptToInstall();
      setVisible(false);
    } catch (e) {
      console.error(e);
      setOpenAddHomeScreen(true);
    }
  };

  const closeHandler = () => {
    setVisible(false);
  };

  useEffect(() => {
    setClimateTheme((theme as any)["name"] === "communeTheme" || (theme as any)["name"] === "commercialTheme");
  }, [theme]);
  return (
    <>
      {visible && !hasInstalled && userState.wasTheUserAuthorized && (
        <Wrapper container justifyContent="center" style={{ padding: "15px 0" }} zIndex={50}>
          <Grid item sx={{ display: "flex", alignItems: "flex-start", mr: klimaplan ? 0 : 1 }}>
            <NewLogoIcon fontSize="large" style={{ cursor: "default" }}>
              <LogoImage
                src={
                  climateTheme && (theme as any)["name"] === "communeTheme"
                    ? CheckIcon
                    : climateTheme && (theme as any)["name"] === "commercialTheme"
                    ? BlueCheckIcon
                    : mhLogo
                }
                sx={{
                  borderRadius: klimaplan ? "inherit" : 2,
                  border: klimaplan ? "inherit" : theme.palette.grey[500],
                }}
              />
            </NewLogoIcon>
          </Grid>
          <Grid item xs={11} md={9}>
            <Typography variant="h3">
              {t("General.PwaAlert.AlertMessage", { hostname: window.location.hostname })}
            </Typography>
            <ButtonsWrapper item>
              <Button variant="contained" color="secondary" onClick={InstallPwa}>
                {t("General.Buttons.Yes")}
              </Button>
              <Button variant="contained" color="secondary" onClick={closeHandler}>
                {t("General.Buttons.No")}
              </Button>
            </ButtonsWrapper>
          </Grid>
          <AddToHomeScreenDialog open={openAddHomeScreen} handleClose={() => setOpenAddHomeScreen(false)} />
        </Wrapper>
      )}
    </>
  );
};

export default InstallPwaBlock;
