import React, { useEffect, useReducer, useRef } from "react";
import LoadingOverlay from "../../../components/LoadingOverlay";
import NewToServiceBookComponent from "./components/NewToServiceBook";
import ReportsListStep from "./components/Steps/ReportsListStep";
import { Alert, Button, styled, Typography } from "@mui/material";
import { Container, Grid } from "@mui/material";
import { ServiceBookOfferRequestContract, ServiceBookItemToFix, ServiceReportType } from "../../../definitions/model/ServiceBook";
import NoPhoneDialog from "./components/NoPhoneDialog";
import { useAlertContext } from "../../../components/Base";
import { useServicebook } from "../../../actions/servicebook.actions";
import TaskListStep from "./components/Steps/TaskListStep";
import CreatedTasksDIalog from "./components/CreatedTasksDIalog";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../actions/user.actions";
import Craftsmen from "../../../assets/img/craftsmen.jpg";
import ServicebookStepper from "./components/ServicebookStepper";
import { useBuilding } from "../../../actions/building.actions";
import ReportDetailsStep from "./components/Steps/ReportDetailsStep";
import ChooseWorkerStep from "./components/Steps/ChooseWorkerStep";
import RequestInspectionDialog from "./components/RequestInspectionDialog";
import { useLanding } from "../../../actions";

export const ServiceBookBanner = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${Craftsmen})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPositionY: 0,
  width: "100%",
  margin: "0 0 20px",
  minHeight: 200,
  position: "relative",

  [theme.breakpoints.up("sm")]: {
    marginTop: -20,
    minHeight: 220,
  },
  [theme.breakpoints.up("md")]: {
    minHeight: 300,
  },
}));

export enum StepsToShow {
  SelectReport = 1,
  ReportDetails = 2,
  ChooseCrafstmen = 3,
  Tasks = 4,
}

type ServiceBookPageState = {
  showRequestInspection: boolean;
  showNoPhoneDialog: boolean;
  noPhoneDialogResult: boolean;
  loadingOffers: boolean;
  stepToShow: StepsToShow;
  requestData: ServiceBookOfferRequestContract | null;
  createdDialogShow: boolean;
};

const initialState: ServiceBookPageState = {
  showRequestInspection: false,
  showNoPhoneDialog: false,
  loadingOffers: false,
  stepToShow: StepsToShow.SelectReport,
  requestData: null,
  noPhoneDialogResult: false,
  createdDialogShow: false,
};

const reducer = (state: ServiceBookPageState, action: { type: keyof ServiceBookPageState; payload: any }) => {
  return { ...state, [action.type]: action.payload };
};

const BuildingServiceReportsPage = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const headerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation("translation");
  // const [activeStep, setActiveStep] = React.useState(0);
  //const reportContextState = useReportContext();

  const [
    {
      currentReport: report,
      error,
      errorSeverity,
      loading,
      reportPreviewsLoaded,
      updating,
      reportPreviews,
      offers,
      customerState,
      itemsToFix,
    },
    { sendOfferRequest, setItemsToFix, getBuildingReports, getReport, getOffers, clearError, getCustomerState },
  ] = useServicebook();
  const { showAlert } = useAlertContext();
  const [{ user }] = useUser();
  const [{ building }] = useBuilding();
  const [landing, { addLead }] = useLanding();

  useEffect(() => {
    const getAsync = async () => {
      if (building && state.stepToShow === StepsToShow.SelectReport) {
        if (report && report.Data.HouseId !== building.Id) {
          dispatch({ type: "stepToShow", payload: StepsToShow.SelectReport });
        }
        getBuildingReports(building.Id, ServiceReportType.BasicReport);
      }
    };
    getAsync();
    return () => {
      /* */
    };
  }, [building, state.stepToShow]);

  useEffect(() => {
    if (building && report && report.Data.HouseId === building.Id && state.stepToShow === StepsToShow.SelectReport) {
      dispatch({ type: "stepToShow", payload: StepsToShow.ReportDetails });
      getOffers(building.Id, report.Id);
    }
  }, [report]);

  useEffect(() => {
    if (error) {
      showAlert({
        text: error,
        severity: errorSeverity || "error",
        duration: 5000,
      });
      clearError();
    }
  }, [error]);

  const handleShowReport = async (reportId: string) => {
    try {
      await getReport(reportId);
      await getCustomerState(reportId);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRequestInspection = () => {
    dispatch({ type: "showRequestInspection", payload: true });
  };

  const handleActiveStep = (index: number) => {
    dispatch({ type: "stepToShow", payload: index + 1 });
  };

  const handleSetItemsToFix = (items: ServiceBookItemToFix[]) => {
    setItemsToFix(items);
    dispatch({ type: "stepToShow", payload: StepsToShow.ChooseCrafstmen });
    scrollToTop();
  };

  const handleSendOfferRequest = (requestData: ServiceBookOfferRequestContract) => {
    if (user?.Phone) {
      dispatch({ type: "loadingOffers", payload: true });
      sendOfferRequest(requestData).then(() => {
        dispatch({ type: "loadingOffers", payload: false });
        if (building && report) {
          getOffers(building.Id, report.Id);
        }
        dispatch({ type: "createdDialogShow", payload: true });
        dispatch({ type: "stepToShow", payload: StepsToShow.Tasks });
      });
    } else {
      dispatch({ type: "requestData", payload: requestData });
      dispatch({ type: "showNoPhoneDialog", payload: true });
    }
  };

  const setUserPhone = async (result: boolean) => {
    if (!user) return;
    dispatch({ type: "noPhoneDialogResult", payload: result });
    dispatch({ type: "showNoPhoneDialog", payload: false });
  };

  useEffect(() => {
    if (state.noPhoneDialogResult && state.requestData) {
      handleSendOfferRequest(state.requestData);
    }
    return () => {};
  }, [state.noPhoneDialogResult]);

  const scrollToTop = () => {
    setTimeout(
      () =>
        headerRef.current!.scrollIntoView({
          behavior: "smooth",
          block: "start",
        }),
      0
    );
  };

  return (
    <>
      <LoadingOverlay loading={loading || updating} />
      <ServiceBookBanner direction="column" justifyContent="center" alignItems="flex-start" container>
        <Container maxWidth="md">
          <Typography variant="h2" style={{ color: "#fff" }}>
            {" "}
            {t("ServiceBook.YourServiceBook")}
          </Typography>
          <Typography variant="body1" paragraph style={{ color: "#fff", maxWidth: "370px" }}>
            {t("ServiceBook.BannerText")}
          </Typography>
          <Button color="secondary" variant="contained" onClick={handleRequestInspection}>
            {" "}
            {t("ServiceBook.ReportList.RequestInspection")}
          </Button>
        </Container>
      </ServiceBookBanner>
      {!loading && reportPreviewsLoaded && reportPreviews.length === 0 && <NewToServiceBookComponent />}
      {!loading && reportPreviewsLoaded && reportPreviews.length > 0 && (
        <Container maxWidth="md">
          <Grid container justifyContent="center" ref={headerRef}>
            <ServicebookStepper
              withStyles="pointer"
              activeStep={state.stepToShow - 1}
              setActiveStep={handleActiveStep}
            />
          </Grid>
          {state.stepToShow === StepsToShow.SelectReport && (
            <ReportsListStep
              reports={reportPreviews}
              showReport={handleShowReport}
              requestInspection={handleRequestInspection}
            />
          )}
          {state.stepToShow === StepsToShow.ReportDetails && (
            <>
              {report ? (
                <ReportDetailsStep
                  report={report}
                  unitType={building?.MainBuilding.Type}
                  taskLists={offers}
                  customerState={customerState}
                  setItemsToFix={handleSetItemsToFix}
                />
              ) : (
                <Grid container direction="column" alignItems={"center"}>
                  <Grid item>
                    <Alert severity="warning" sx={{ marginBottom: 2 }}>
                      {t("ServiceBook.ReportDetails.NoReport")}
                    </Alert>
                  </Grid>
                  <Grid item mb={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        dispatch({
                          type: "stepToShow",
                          payload: StepsToShow.SelectReport,
                        })
                      }
                    >
                      {t("General.Buttons.Back")}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </>
          )}
          {state.stepToShow === StepsToShow.ChooseCrafstmen && report && building && user && (
            <ChooseWorkerStep
              serviceTargetId={building.Id}
              serviceTargetTyle="building"
              address={building.Address}
              items={itemsToFix}
              reportId={report.Id}
              sendOfferRequest={handleSendOfferRequest}
              goBack={() =>
                dispatch({
                  type: "stepToShow",
                  payload: StepsToShow.ReportDetails,
                })
              }
            />
          )}
          {state.stepToShow === StepsToShow.Tasks && report && (
            <TaskListStep disabled={state.loadingOffers} taskLists={offers} />
          )}
        </Container>
      )}

      <RequestInspectionDialog
        open={state.showRequestInspection}
        handleClose={() => dispatch({ type: "showRequestInspection", payload: false })}
        userName={user?.Name ?? ""}
        userPhone={user?.Phone ?? ""}
        loading={landing.loading || loading || updating}
        addLead={addLead}
      />
      <NoPhoneDialog
        open={state.showNoPhoneDialog}
        handleCancel={() => dispatch({ type: "showNoPhoneDialog", payload: false })}
        handleOk={setUserPhone}
      />
      <CreatedTasksDIalog
        open={state.createdDialogShow}
        handleClose={() => dispatch({ type: "createdDialogShow", payload: false })}
      />
    </>
  );
};

export default BuildingServiceReportsPage;
