import { Box, Button, Collapse, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ParameterDescription } from "../../../../definitions/model/ServiceBook";

const TaskDescription = (props: { descriptions: ParameterDescription[] }) => {
  const [open, setOpen] = useState(false);
  const [showLine, setShowLine] = useState(true);
  const [showOpen, setshowOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const height = 50;

  useEffect(() => {
    if (ref.current && ref.current.clientHeight > height) {
      setshowOpen(true);
    }

    return () => {};
  }, []);

  const handleTransitionEnd = () => {
    setShowLine(!open);
  };

  return (
    <>
      <Collapse
        collapsedSize={height}
        in={open}
        onEntered={handleTransitionEnd}
        onExiting={handleTransitionEnd}
      >
        {!!props.descriptions?.length && (
          <div ref={ref}>
            {props.descriptions.map((d) => (
              <Typography key={d.Id} variant="body1" gutterBottom>
                {d.Description}
              </Typography>
            ))}
          </div>
        )}
      </Collapse>
      {showOpen && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderTop: () =>
              showLine ? "1px solid " + theme.palette.divider : "none",
            paddingTop: "2px",
          }}
        >
          <Button
            variant="outlined"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? t("General.Buttons.ShowLess") : t("General.Buttons.ShowMore")}
          </Button>
        </Box>
      )}
    </>
  );
};

export default TaskDescription;
