import React, { useEffect } from "react";
import { Grid, styled, Typography } from "@mui/material";
import { convertToCurency } from "../../../services/converter.service";
import { connect, ConnectedProps } from "react-redux";
import { getUsersCount } from "../../../actions/landing.actions";
import { ApplicationState } from "../../../reducers/store";
import { AppThunkDispatch } from "../../../definitions/Action";
import { useTranslation } from "react-i18next";

const CircleWrapper = styled(Grid)(({ theme }) => ({
 height: "auto",
  [theme.breakpoints.up("md")]: {
   height: 0
  },
}));

const Circle = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  width: 170,
  height: 170,
  borderRadius: "50%",
  textAlign: "center",
  padding: 30,
  boxSizing: "border-box",
  position: "static",
  [theme.breakpoints.up("md")]: {
    position: "relative",
    top: 20,
    zIndex: 2,
  },
  [theme.breakpoints.up("lg")]: {
    position: "relative",
    top: 40,
  },
}));

const UserCount = styled("span")(() => ({
  fontWeight: "bold",
  display: "block",
  height: 21,
}));

type CircleLabelProps = {
  isGrapesComponent?: boolean;
};

const CircleLabel = ({
  usersCount,
  getUsersCount,
  isGrapesComponent,
}: CircleLabelConnectedProps & CircleLabelProps): JSX.Element => {
  const { t } = useTranslation("translation");
  useEffect(() => {
    if (!usersCount) {
      getUsersCount();
    }
  }, []);

  return (
    <CircleWrapper
      container
      justifyContent="center"
    >
      <Circle
        container
        alignItems="center"
        sx={{ top: isGrapesComponent ? 0 : -80 }}
      >
        <Typography variant="body1" sx={{ color: "#fff", lineHeight: 1.5, margin:"-15px" }}>
          <UserCount>
            {usersCount ? convertToCurency(usersCount, ".") : null}
          </UserCount>
         {t("Base.Grapes.HomeOwnersUsers", { hostname: window.location.hostname })}
        </Typography>
      </Circle>
    </CircleWrapper>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  usersCount: state.landing.usersCount,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getUsersCount: () => dispatch(getUsersCount()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type CircleLabelConnectedProps = ConnectedProps<typeof connector>;

export default connector(CircleLabel);
