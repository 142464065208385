export const AdminCompanyActionTypes = {
    GET_COMPANIES: "GET_COMPANIES",
    GET_COMPANIES_SUCCEEDED: "GET_COMPANIES_SUCCEEDED",
    ADD_COMPANY: "ADD_COMPANY",
    ADD_COMPANY_SUCCEEDED: "ADD_COMPANY_SUCCEEDED",
    EDIT_COMPANY: "EDIT_COMPANY",
    EDIT_COMPANY_SUCCEEDED: "EDIT_COMPANY_SUCCEEDED",
    DELETE_COMPANY: "DELETE_COMPANY",
    DELETE_COMPANY_SUCCEEDED: "DELETE_COMPANY_SUCCEEDED",
    ASSING_COMPANY_RATING_SUCCEEDED: "ASSING_COMPANY_RATING_SUCCEEDED",
    UPDATE_COMPANY_LOGO: "UPDATE_COMPANY_LOGO",
    UPDATE_COMPANY_LOGO_SUCCEEDED: "UPDATE_COMPANY_LOGO_SUCCEEDED",
}