import {
  WidgetPosition,
  WidgetPage,
  LandingPageDestination,
  TemplateType,
  ArticleCategory,
} from "../constants/enums";

export interface WidgetSubPage {
  Page: WidgetPage;
  SubPageId: string;
}

export interface WidgetModel {
  Id: string;
  Title: string;
  Pages: WidgetPage[];
  Position: WidgetPosition;
  SubPages: WidgetSubPage[];
  Communes: number[];
  Styles: string;
  Html: string;
  Css: string;
  Tag: string;
    SensitiveList: WidgetSensetiveData[];
    LocalizedContent: Record<string, string>;
}

export type WidgetsResponseModel = {
  Results: WidgetModel[];
  Count: number;
};

export interface WidgetCreateModel {
  Title: string;
  Pages: WidgetPage[];
  Position: WidgetPosition;
  Communes: number[];
  TemplateId?: string;
  Tag: string;
  SensitiveList: WidgetSensetiveData[];
}

export type WidgetSensetiveData = {
  Path: string;
  SensitiveAction: string;
  SensitiveAmount?: number;
};

export interface WidgetMetaModel {
  Id: string;
  Title: string;
  Tag: string;
  Pages: WidgetPage[];
  Position: WidgetPosition;
  SubPages: WidgetSubPage[];
  Communes: number[];
  SensitiveList: WidgetSensetiveData[];
}

export interface LandingModel {
  Id: string;
  Created: Date;
  Modified: Date;
  Name: string;
  GrapesData: GrapesJSLiteDTO;
}

export interface GrapesJSLiteDTO {
  styles: string;
  html: string;
  css: string;
}

export interface FormBuilderDTO {
  JsScriptUrls: string[];
  CssStyleUrls: string[];
  HtmlBody: string;
  JsInitBody: string;
}

export interface RegisterLandingContract {
  Name: string;
  TemplateId: string | null;
}

export interface LeadData {
  Name: string;
  Phone: string;
  Email: string;
  Text: string;
  UtmSource: string;
  ToBeContactedOn: Date | null;
}

export interface LandingMetaModel {
  Id: string;
  Title: string;
  UniqueName: string;
  Destination: LandingPageDestination;
  ExternalLandingId: string;
  IsPublished: boolean;
  FacebookTitle: string;
  FacebookDescription: string;
  FacebookImageUrl: string;
  Theme: string;
  LocalizedContent: Record<string, string>;
  ShowFooter: boolean;
  ShowTopMenu: boolean;
}

export interface LandingPageDto {
  Id: string;
  Title: string;
  UniqueName: string;
  FacebookTitle: string;
  FacebookDescription: string;
  FacebookImageUrl: string;
  IsPublished: boolean;
  Html: string;
  Css: string;
  Styles: string;
  Theme: string;
  ShowFooter: boolean;
  ShowTopMenu: boolean;
}

export interface TemplateModel {
  Id: string;
  Title: string;
  Type: TemplateType;
  Created: string;
}

export enum LandingType {
  Landing,
  Email,
  Template,
  News,
  Widget,
  Club,
  SMS,
  Terms,
}

export enum BenefitClubType {
  ClubsPage = 0,
  Link = 1,
}

export interface BenefitClub {
  Id: string;
  Created: Date;
  Updated: Date;
  Title: string;
  ExternalLandingId: string;
  IsPublished: boolean;
  LinkUrl: string;
  Type: BenefitClubType;
  ClubId: string | null;
  Index: number;
}

export interface RegisterBenefitClubContract {
  Title: string;
  LinkUrl: string;
  Type: BenefitClubType;
  TemplateId: string | null;
}

export interface UpdateBenefitClubContract {
  Id: string;
  Title: string;
  LinkUrl: string;
  Type: BenefitClubType;
  ClubId: string | null;
}

export interface LandingArticleDto {
  Id: string;
  Title: string;
  ExternalId: string;
  Created: Date;
  Updated: Date;
  Published: boolean;
  Html: string;
  Css: string;
  Styles: string;
  NavigateTo: string;
  Categories: ArticleCategory[];
  Tag: string;
}
