export const ClubActions = {
    GET_CLUB_GROUPS: "GET_CLUB_GROUPS",
    GET_CLUB_GROUPS_SUCCEEDED: "GET_CLUB_GROUPS_SUCCEEDED",
    GET_CLUBS: "GET_CLUBS",
    GET_CLUBS_SUCCEEDED: "GET_CLUBS_SUCCEEDED",
    GET_CLUB: "GET_CLUB",
    GET_CLUB_SUCCEEDED: "GET_CLUB_SUCCEEDED",
    GET_CLUB_GROUP: "GET_CLUB_GROUP",
    GET_CLUB_GROUP_SUCCEEDED: "GET_CLUB_GROUP_SUCCEEDED",
    ADD_CLUB: "ADD_CLUB",
    ADD_CLUB_SUCCEEDED: "ADD_CLUB_SUCCEEDED",
    ADD_CLUB_GROUP: "ADD_CLUB_GROUP",
    ADD_CLUB_GROUP_SUCCEEDED: "ADD_CLUB_GROUP_SUCCEEDED",
    EDIT_CLUB: "EDIT_CLUBS",
    EDIT_CLUB_SUCCEEDED: "EDIT_CLUB_SUCCEEDED",
    EDIT_CLUB_GROUP: "EDIT_CLUB_GROUP",
    EDIT_CLUB_GROUP_SUCCEEDED: "EDIT_CLUB_GROUP_SUCCEEDED",
    DELETE_CLUB: "DELETE_CLUB",
    DELETE_CLUB_SUCCEEDED: "DELETE_CLUB_SUCCEEDED",
    DELETE_CLUB_GROUP: "DELETE_CLUB_GROUP",
    DELETE_CLUB_GROUP_SUCCEEDED: "DELETE_CLUB_GROUP_SUCCEEDED",
    CLUB_OPERATION_FAILED: "CLUB_OPERATION_FAILED",
    ADD_POST: "ADD_POST",
    ADD_POST_SUCCEEDED: "ADD_POST_SUCCEEDED",
    REMOVE_POST: "REMOVE_POST",
    REMOVE_POST_SUCCEEDED: "REMOVE_POST_SUCCEEDED",
    POST_OPERATION_FAILED: "POST_OPERATION_FAILED",
    ADD_COMMENT: "ADD_COMMENT",
    ADD_COMMENT_SUCCEEDED: "ADD_COMMENT_SUCCEEDED",
    EDIT_COMMENT: "EDIT_COMMENT",
    EDIT_COMMENT_SUCCEEDED: "EDIT_COMMENT_SUCCEEDED",
    REMOVE_COMMENT: "REMOVE_COMMENT",
    REMOVE_COMMENT_SUCCEEDED: "REMOVE_COMMENT_SUCCEEDED",
    COMMENT_OPERATION_FAILED: "COMMENT_OPERATION_FAILED",
    ADD_ARTICLE: "ADD_ARTICLE",
    ADD_ARTICLE_SUCCEEDED: "ADD_ARTICLE_SUCCEEDED",
    UPDATE_ARTICLE: "UPDATE_ARTICLE",
    UPDATE_ARTICLE_SUCCEEDED: "UPDATE_ARTICLE_SUCCEEDED",
    GET_ARTICLE: "GET_ARTICLE",
    GET_ARTICLE_SUCCEEDED: "GET_ARTICLE_SUCCEEDED",
    GET_ARTICLE_PAGE: "GET_ARTICLE_PAGE",
    GET_ARTICLE_PAGE_SUCCEEDED: "GET_ARTICLE_PAGE_SUCCEEDED",
    GET_ARTICLE_PAGES: "GET_ARTICLE_PAGE",
    GET_ARTICLE_PAGES_SUCCEEDED: "GET_ARTICLE_PAGES_SUCCEEDED",
    GET_RECENT_ARTICLES:"GET_RECENT_ARTICLES",
    GET_RECENT_ARTICLES_SUCCEEDED:"GET_RECENT_ARTICLES_SUCCEEDED",
    DELETE_ARTICLE: "DELETE_ARTICLE",
    DELETE_ARTICLE_SUCCEEDED: "DELETE_ARTICLE_SUCCEEDED",
    GET_CLIENT_BENEFIT_CLUBS: "GET_CLIENT_BENEFIT_CLUBS",
    GET_CLIENT_BENEFIT_CLUBS_SUCCEEDED: "GET_CLIENT_BENEFIT_CLUBS_SUCCEEDED",
}