/* eslint-disable react/prop-types */
import React, { useState, useContext } from "react";
import { IconButton, Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import { AlertProps } from "@mui/lab";
import { Close } from "@mui/icons-material";

type Severity = "success" | "info" | "warning" | "error";

type ErrorText = {
  status: string;
  statusText: string;
};
interface MyhouseAlertProps {
  text: string | ErrorText;
  severity: Severity;
  duration?: number;
  position?: {
    horizontal: "center" | "left" | "right";
    vertical: "bottom" | "top";
  };
}

interface AlertState extends MyhouseAlertProps {
  open: boolean;
}

type AlertProviderType = {
  alertState: AlertState;
  showAlert: (props: MyhouseAlertProps) => void;
  closeAlert: () => void;
};

export const AlertContext = React.createContext<Partial<AlertProviderType>>({
  alertState: {
    open: false,
    severity: "success",
    text: "",
    position: {
      horizontal: "center",
      vertical: "bottom",
    },
  },
});

export const useAlertContext = (): AlertProviderType => {
  const context = useContext(AlertContext);
  if (context === undefined) throw new Error("useAlertContext must be used within a AlertProvider");
  return context as AlertProviderType;
};

type AlertProviderProps = {
  children: React.ReactNode;
};

export const AlertProvider = ({ children }: AlertProviderProps): JSX.Element => {
  const initialState: AlertState = {
    open: false,
    text: "",
    severity: "success",
    duration: 5000,
    position: {
      horizontal: "center",
      vertical: "bottom",
    },
  };

  const [AlertState, setAlertState] = useState<AlertState>(initialState);

  const showAlert = (AlertProps: MyhouseAlertProps) => {
    setAlertState({
      ...AlertState,
      open: true,
      ...AlertProps,
    });
  };

  const closeAlert = () => setAlertState({ ...initialState, severity: AlertState.severity });

  return (
    <AlertContext.Provider
      value={{
        alertState: AlertState,
        showAlert: showAlert,
        closeAlert: closeAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

const MyhouseAlert = (props: AlertProps): JSX.Element => {
  const { alertState, closeAlert } = useAlertContext();
  return (
    <Snackbar
      open={alertState.open}
      autoHideDuration={alertState.duration}
      anchorOrigin={alertState.position}
      onClose={() => {
        closeAlert();
      }}
    >
      <Alert
        severity={alertState.severity}
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={closeAlert}>
            <Close fontSize="inherit" />
          </IconButton>
        }
        {...props}
      >
        {alertState.text && (typeof alertState.text !== "string" ? alertState.text.statusText : alertState.text)}
      </Alert>
    </Snackbar>
  );
};

export default MyhouseAlert;
