import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { isReactComponent } from ".";
import { GrapesEditor, GrapesTypes, LoadComponentOptions } from "../../defenitions";

type MyhouseLinkProps = {
  text: string;
  unitSensitive: boolean;
  unitLink: string;
  link: string;
};

export const MyhouseLink = (props: MyhouseLinkProps): JSX.Element => {
  const [href, setHref] = useState(props.link);

  useEffect(() => {
    if (props.unitSensitive) {
      const unitLink = props.unitLink.startsWith("/") ? props.unitLink.substr(1) : props.unitLink;
      setHref(`${window.location.origin}/\${%[?(@.TypeName=='UnitModel')].AddressUrl%}/${unitLink}`);
    } else setHref(props.link);
  }, [props.unitSensitive]);

  return (
    <a key={`MyhouseLink_${props.link}_${props.unitLink}`} href={href}>
      <span>{props.text}</span>
    </a>
  );
};

export default function (editor: GrapesEditor, options: LoadComponentOptions): void {
  const domComponents = editor.DomComponents;
  const blockManager = editor.BlockManager;
  const defaultType = domComponents.getType("default");
  const { model, view } = defaultType;
  const compName = "MyhouseLink";

  blockManager.add(GrapesTypes.MyhouseLinkComponent, {
    label: "myHouse link",
    category: options.categoryLabel,
    attributes: { class: "fa fa-link" },
    textable: 1,
    content: `<span data-gjs-type="${GrapesTypes.MyhouseLinkComponent}"></span>`,
  });

  const linkTrait = {
    label: "Link",
    changeProp: 1,
    name: "link",
  };

  const unitLinkTrait = {
    type: "select",
    label: "Unit sub-link",
    changeProp: 1,
    name: "unitLink",
    typeid: 0,
    options: [
      { id: "/profile", name: "Unit profile" },
      { id: "/building", name: "/building" },
      { id: "/servicebook", name: "/servicebook" },
      { id: "/tasks", name: "/tasks" },
    ],
  };

  const traits = [
    {
      label: "Link text",
      name: "text",
      changeProp: 1,
    },
    {
      type: "checkbox",
      name: "unitSensitive",
      changeProp: 1,
    },
    linkTrait,
  ];

  const ReinitFieldTraits = (unitSensitive: boolean, component: any) => {
    component.removeTrait("unitLink");
    component.removeTrait("link");
    if (unitSensitive) {
      component.addTrait(unitLinkTrait);
    } else {
      component.addTrait(linkTrait);
    }
  };

  domComponents.addType(GrapesTypes.MyhouseLinkComponent, {
    isComponent(el: HTMLElement) {
      if (
        (el.getAttribute && el.getAttribute("data-gjs-type") === GrapesTypes.MyhouseLinkComponent) ||
        (el.attributes && (el.attributes as any)["data-gjs-type"] === GrapesTypes.MyhouseLinkComponent) ||
        isReactComponent(el, compName)
      ) {
        return {
          type: GrapesTypes.MyhouseLinkComponent,
        };
      }
    },
    model: {
      defaults: {
        ...model.prototype.defaults,
        droppable: false,
        draggable: true,
        text: "Link",
        unitSensitive: false,
        unitLink: "",
        link: "",
        traits: [...model.prototype.defaults.traits, ...traits],
      },
    },
    view: view.extend({
      init() {
        traits.forEach((trait) => {
          this.listenTo(this.model, `change:${trait.name}`, this.handleChanges);
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      handleChanges(e: any, value: any) {
        const root = ReactDOM.createRoot(this.el);
        root.unmount();
        if ("unitSensitive" in e.changed) {
          ReinitFieldTraits(e.changed.unitSensitive, e);
        }
        this.render();
      },
      onRender() {
        const { el } = this;
        const comps = this.model.get("components");
        const { text, unitSensitive, unitLink, link } = this.model.attributes;
        comps.reset();

        const uLink = unitLink.startsWith("/") ? unitLink.substr(1) : unitLink;
        const href = unitSensitive
          ? `${window.location.origin}/\${%[?(@.TypeName=='UnitModel')].AddressUrl%}/${uLink}`
          : link;

        const compString = `<a href=${href}><span>${text}</span></a>`;
        comps.add(compString);
        const root = ReactDOM.createRoot(el);

        root.render(<a href={href}>{text}</a>);
      },
    }),
  });
}
