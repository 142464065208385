import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from "@mui/material";
import { AdminTableCellIcon } from "./AdminTableCellIcon";
import { AdminTableHead } from "./AdminTableHead";
import { AdminTableLoading } from "./AdminTableLoading";

type Props<T> = {
  data: T[];
  columns: Column<T>[];
  loading?: boolean;
  order?: boolean;
  pagination?: Pagination;
  count?: number;
  onPaginationChange?: (options: { page: number; rows: number }) => void;
  headerButton?: JSX.Element;
  resetPaging?: boolean;
};

export type Column<T> = {
  name: string;
  accessor?: keyof T;
  format?: (data: any, row?: T) => any;
  icon?: Icon;
  callback?: (data: T) => void;
  isIndividualCell?: boolean;
};

export enum Icon {
  Edit,
  Delete,
  Rating,
  Copy,
  Disable,
  Public,
  Link,
  Custom,
  Verified,
  Pageview,
  Invisible,
}

type Pagination = "client" | "server";

type AdminTableState<T> = {
  page: number;
  rowsPerPage: number;
};

const reducer = <T,>(state: AdminTableState<T>, action: { type: keyof AdminTableState<T>; payload: any }) => ({
  ...state,
  [action.type]: action.payload,
});

const initialState = {
  page: 0,
  rowsPerPage: 10,
  editDeleteColumn: null,
};

export const AdminTable = <T,>({
  data,
  columns,
  loading,
  pagination,
  onPaginationChange,
  count,
  headerButton,
  resetPaging,
}: Props<T>) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isGroupedCellExist, SetIsGroupedCellExist] = useState(false);

  const memoizedColumns = useMemo(() => {
    SetIsGroupedCellExist(columns.some((x) => !x.isIndividualCell && x.callback));
    return columns;
  }, [columns]);

  const memoizedData = useMemo(() => {
    if (pagination === "client") {
      return data?.slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage);
    } else {
      return data;
    }
  }, [data, pagination, state.page, state.rowsPerPage]);

  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      dispatch({ type: "page", payload: newPage });
      onPaginationChange && onPaginationChange({ page: newPage, rows: state.rowsPerPage });
    },
    [state.rowsPerPage]
  );

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "rowsPerPage",
      payload: parseInt(event.target.value, 10),
    });
    dispatch({ type: "page", payload: 0 });
    onPaginationChange && onPaginationChange({ page: 0, rows: parseInt(event.target.value, 10) });
  }, []);

  useEffect(() => {
    if (resetPaging) {
      dispatch({ type: "page", payload: 0 });
    }
    return () => {};
  }, [resetPaging]);

  const tableCellHandler = (
    row: T,
    column: Column<T>,
    columnIndex: number,
    isIndividualCell?: boolean
  ): JSX.Element => {
    if (column.format) {
      return (
        <AdminTableCellIcon
          column={{
            ...column,
            icon: column.format(row),
          }}
          data={row}
          key={columnIndex}
          isIndividualCell={isIndividualCell}
        />
      );
    } else {
      return <AdminTableCellIcon column={column} data={row} key={columnIndex} isIndividualCell={isIndividualCell} />;
    }
  };

  return (
    <>
      <TableContainer>
        <Table size="small" sx={{ minHeight: 95 }}>
          <AdminTableHead columns={memoizedColumns} additionColumn={headerButton} />
          <TableBody sx={{ position: "relative" }}>
            {loading && <AdminTableLoading />}

            {memoizedData.map((row, index) => (
              <TableRow hover key={index}>
                {memoizedColumns
                  .filter((x) => x.isIndividualCell || !x.callback)
                  .map((column, columnIndex) => {
                    if (column.icon !== undefined) {
                      return tableCellHandler(row, column, columnIndex, true);
                    }
                    if (column.accessor) {
                      return (
                        <TableCell key={columnIndex}>
                          {column.format ? column.format(row[column.accessor]) : row[column.accessor]}
                        </TableCell>
                      );
                    }
                    if (column.format) {
                      return <TableCell key={columnIndex}>{column.format(row)}</TableCell>;
                    }
                  })}

                {isGroupedCellExist && (
                  <TableCell key={index + "grouped"} sx={{ display: "flex", justifyContent: "flex-start" }}>
                    {memoizedColumns
                      .filter((x) => !x.isIndividualCell && x.callback)
                      .map((column, columnIndex) => {
                        if (column.icon !== undefined) {
                          return tableCellHandler(row, column, columnIndex, false);
                        }
                      })}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count || data.length || 0}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ width: "100%" }}
        />
      )}
    </>
  );
};
