import React from "react";
import { Grid, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";


type Props = {
  rotation: number | null;
  hiddenLabel?: boolean;
  hideSmiles?: boolean;
  size?: "small" | "big";
};

type StyleProps = {
  scale: number;
  reservePlaceForSmiles: boolean;
};

const WrappedComponentGrid = styled(Grid, {
  shouldForwardProp: (propName: string) => propName !== "scale" && propName !== "reservePlaceForSmiles",
})<{ scale: number; reservePlaceForSmiles: boolean }>(({ theme, scale, reservePlaceForSmiles }) => ({
  position: "relative",
  paddingTop: "20px",
  width: reservePlaceForSmiles ? 140 * scale : "auto",
  minWidth: reservePlaceForSmiles ? 140 * scale : "auto",
  height: reservePlaceForSmiles ? 80 * scale : "auto",
  // transform: "scale(0.7)",
  [theme.breakpoints.up("md")]: reservePlaceForSmiles
    ? {
        width: 176 * scale,
        minWidth: 176 * scale,
        height: 97 * scale,
      }
    : {},
}));

const Dial = styled("svg", {
  shouldForwardProp: (propName: string) => propName !== "scale",
})<{ scale: number }>(({ theme, scale }) => ({
  width: 100 * scale,
  [theme.breakpoints.up("md")]: {
    width: 140 * scale,
  },
}));

const Arrow = styled("svg", {
  shouldForwardProp: (propName: string) => propName !== "scale",
})<{ scale: number }>(({ theme, scale }) => ({
  position: "absolute",
  transition: "0.6s all ease-in-out",
  transformOrigin: "bottom center",
  minWidth: 130 * scale,
  [theme.breakpoints.up("md")]: {
    minWidth: 176 * scale,
  },
}));

const HouseStateBarometer = ({ rotation, hiddenLabel, hideSmiles, size = "small" }: Props): JSX.Element => {
const { t} = useTranslation();
  const styleProps: StyleProps = {
    scale: size === "small" ? 0.8 : 2.3,
    reservePlaceForSmiles: !hideSmiles,
  };

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const currentRotation = rotation === null ? null : (170 * rotation) / 100 - 85; // '-85' cause arrow have wrong default position

  const setRotationLabel = (deg: number | null) => {
    if (deg === null) {
      return t("ServiceBook.Barometer.Values.NotSet");
    } else if (-90 <= deg && deg < -60) {
      return t("ServiceBook.Barometer.Values.Excellent");
    } else if (deg >= -60 && deg <= -30) {
      return t("ServiceBook.Barometer.Values.Good");
    } else if (deg > -30 && deg <= 0) {
      return t("ServiceBook.Barometer.Values.Fair");
    } else if (deg > 0 && deg <= 30) {
      return t("ServiceBook.Barometer.Values.Poor");
    } else if (deg > 30 && deg <= 60) {
      return t("ServiceBook.Barometer.Values.Bad");
    } else if (deg > 60 && deg <= 90) {
      return t("ServiceBook.Barometer.Values.VeryBad");
    } else {
      return t("ServiceBook.Barometer.Values.NotSet");
    }
  };

  return (
    <Grid container alignItems="center" direction={"column"} justifyContent="center">
      <WrappedComponentGrid
        container
        alignItems="flex-end"
        direction={"row"}
        justifyContent="center"
        scale={styleProps.scale}
        reservePlaceForSmiles={styleProps.reservePlaceForSmiles}
      >
          <Dial scale={styleProps.scale} viewBox="0 0 220 120">
            <path d=" M 10 110 A 100 100 0 0 1 39 39" stroke="green" fill="none" strokeWidth="18" />
            <path d=" M 39 39 A 100 100 0 0 1 110 10" stroke="yellow" fill="none" strokeWidth="18" />
            <path d=" M 110 10 A 100 100 0 0 1 181 39" stroke="orange" fill="none" strokeWidth="18" />
            <path d=" M 181 39 A 100 100 0 0 1 210 110" stroke="red" fill="none" strokeWidth="18" />
            <path d=" M 19 109 A 9 9 0 0 1 1 109" fill="green" />
            <path d=" M 219 109 A 9 9 0 0 1 201 109" fill="red" />
          </Dial>

          {/* we need !== null, because if currentRotation is 0, svg should be shown */}
          {currentRotation !== null && (
            <Arrow
              scale={styleProps.scale}
              viewBox="0 0 220 120"
              style={{ transform: `rotate(${currentRotation}deg)` }}
            >
              <polygon points="110 40,105 115,115 115" fill="#979797" />
              <path d=" M 115 114 A 5 5 0 0 1 105 114" fill="#979797" />
            </Arrow>
          )}
      </WrappedComponentGrid>

        {!hiddenLabel && (
          <Grid item>
            {" "}
            <Typography variant="body2">{setRotationLabel(currentRotation)}</Typography>
          </Grid>
        )}
      
    </Grid>
  );
};

export default HouseStateBarometer;
