export class House {
    Address: string
    PostalCode: number
    City: string
    PropertyType: string
    PictureUrl: string
    AddressUrl: string
    Area: string | number
    GroundArea: string
    Rooms: string | number
    BuildYear: string | number
    Price?: string | number
}

export class MapHousesData {
    House: House[]
    Latitude: number
    Longitude: number
}

export interface MapFilterFormData {
    myHouse: boolean
    estimations: boolean
    crime: boolean
    flood: boolean
    busStation: boolean
    gymnasiums: boolean
    marketValue: boolean
    roads: boolean
    ground: boolean
    plans: boolean
    antenna: boolean
    neighbors: boolean
    radon: boolean
    schools: boolean
    noise: boolean
    ecoBusinesses: boolean
}

export type MapCoords = {
    lat: () => number, 
    lng: () => number
}

export type WindowPosition = {
    x: number, 
    y: number
}

export type Neighbors = {
    Address: string,
    Family: FamilyType[],
    Latitude: number,
    Longtitude: number
}

type FamilyType = {
    Address: string,
    FlorWithSide: string,
    Members: MembersType[],
    UnitId: string
}

type MembersType = {
    Address: string,
    FacebookId: string,
    Id: string,
    ImageUrl: string,
    IsGroupOwner: boolean,
    IsMe: boolean,
    IsSelected: boolean,
    MediumAvatar: string,
    Name: string,
    OwnedProperties: string[],
    Role: number,
    SmallAvatar: string,
    UnitId: string,
    isPropertyOwner: boolean
}

export type Road = {
    Area: number,
    Created: string,
    Description: string,
    DisplayInYear: number,
    DocumentUrl: string,
    FinishedInMonth: number,
    FinishedInYear: number,
    FormattedDescription: string,
    Id: string,
    Location: any,
    MunicipalityNo: number,
    Name: string,
    ParentId: string,
    PlanId: string,
    State: number,
    StaticMapUrl: string,
    SubmittedOn: string,
    Type: number,
    TypeString: string,
    Updated: string
}

export type MarkerType = {
    C: number,
    I: string,
    X: number,
    Y: number
}

export type MarkerParams = {
    h: number,
    nelat: number,
    nelon: number,
    nwlat: number,
    nwlon: number,
    selat: number,
    selon: number,
    swlat: number,
    swlon: number,
    w: number,
    zoomLevel: number
}

export type MobileTowerInfo = {
    created: string,
    houseNo: string,
    id: string,
    location: InfoLoacationType,
    name: string,
    postalCode: string,
    street: string,
    submittedDate: string,
    type: number[],
    updated: string
}

export type GymnasiumInfo = {
    address: string,
    city: string,
    communeName: string,
    communeRating: number,
    complete: number,
    created: string,
    department: string,
    differenceWithPrevious: number,
    email: string,
    grade: number,
    group: number,
    houseBookType: number,
    id: string,
    institutionName: string,
    isValidRating: boolean,
    isValidTutorials: boolean,
    latitude: number,
    location: InfoLoacationType,
    longitude: number,
    manager: string,
    number: number,
    phone: string,
    postalCode: number,
    rating: number,
    studentsNumber: number,
    type: string,
    updated: string,
    url: string
}

export type EcoBusinessInfo = {
    address: string,
    id: string,
    latitude: number,
    longitude: number,
}

type InfoLoacationType = {
    type: string, 
    coordinates: number[]
}

export type LocalPlanData = {
    Description: string,
    DocumentUrl: string,
    FinishedInMonth: number,
    FinishedInYear: number,
    GeoJson: string,
    IsDecided: boolean,
    IsMarked: boolean,
    MapUrl: string,
    Name: string,
    PlanType: number,
    TypeString: string,
    Updated: string
}

type CrimeData = {
    class: number,
    communeCode: number,
    communeFullName: string,
    communeRiskPlace: number,
    lastQuarterValue: number,
    rankValue: number
}

export type Crime = {
    data: CrimeData,
    position: WindowPosition
}

type RadonData = {
    assessment: {[key: string]: number},
    communeCode: number,
    communeName: string,
    created: string,
    id: string,
    measurement: {[key: string]: number},
    numberOfSingleFamilyHomes: number,
    occurrenceOfSandAndGravel: number,
    updated: string,
}

export type Radon = {
    data: RadonData,
    position: WindowPosition
}

type NoiseData = {
    created: string,
    id: string,
    organisation: string | null,
    type: string,
    updated: string,
    value: string
}

export type Noise = {
    data: NoiseData,
    position: WindowPosition
}

type GroundData = {
    created: string,
    description: string | null,
    id: string,
    level: string,
    locationId: string,
    locationName: string | null,
    locationStatus: string | null,
    objectId: string,
    plan: string | null,
    projectPhases: string | null,
    regionName: string,
    regionNumber: string,
    status: number,
    statusCode: number,
    submitted: string,
    temaKode: string,
    temaNavn: string,
    uniqeObjectId: string,
    updated: string,
    versionId: string
}

export type Ground = {
    data: GroundData,
    position: WindowPosition
}

export type Flood = {
    data: number,
    position: WindowPosition
}

export type LocalPlan = {
    data: LocalPlanData,
    position: WindowPosition
}

export type EcoBusinessAd = {
    CompanyName: string;
    LogoUrl: string | null;
    WebSite: string | null;
    Phone: string | null;
    Text: string;
}

export type EcoBusinessesOnMap = {
    MarkersData: {
        Count: number,
        Markers: MarkerType[]
    }
    Ads: EcoBusinessAd[],
}