import { useSelector } from "react-redux";
import { AppThunkDispatch } from "../definitions/Action";
import { ApplicationState } from "../reducers/store";
import NeighborGroupService from "../services/neighborgroup.service";
import { operationFailedActionGeneral, useAppDispatch } from ".";
import { ChatConstants, UserConstants } from "../constants";
import {
  EventSubscriberDto,
  NeighborGroup,
  NeighborGroupAnnouncementContract,
  NeighborGroupMemberSettingsDto,
  UpdateNeighborGroupContract,
} from "../definitions/model/NeighborGroup";
import { NeighborGroupConstants } from "../constants/neighborgroup.constants";
import { waitForHubConnectionAsync } from "./chat.actions";
import NeighborGroupAdminService from "../services/admin/admin.neighborgroup.service";

const operationFailedAction = (payload: unknown) => {
  return operationFailedActionGeneral(payload, UserConstants.USER_OPERATION_FAILED);
};

const getGroups = (filter: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: NeighborGroupConstants.GET_NEIGHBORGROUPS,
    });
    const result = await NeighborGroupService.getGroups(filter);
    dispatch({
      type: NeighborGroupConstants.GET_NEIGHBORGROUPS_SUCCEEDED,
      payload: result.Results,
    });
    return result;
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const getGroup = (id: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: NeighborGroupConstants.GET_NEIGHBORGROUP,
    });
    dispatch({ type: ChatConstants.LOAD_CHATS, payload: true });
    const result = await NeighborGroupService.getGroup(id);
    dispatch({
      type: NeighborGroupConstants.GET_NEIGHBORGROUP_SUCCEEDED,
      payload: result,
    });

    dispatch({ type: ChatConstants.CLEAR_CHAT });
    return result;
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const createGroup = (request: UpdateNeighborGroupContract, global: boolean) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: NeighborGroupConstants.CREATE_NEIGHBORGROUP,
    });

    let result: NeighborGroup;

    if (global) {
      result = await NeighborGroupAdminService.createGroup(request);
    } else {
      result = await NeighborGroupService.createGroup(request);
    }
    dispatch({
      type: NeighborGroupConstants.CREATE_NEIGHBORGROUP_SUCCEEDED,
      payload: result,
    });
    dispatch({
      type: ChatConstants.SET_CHATS,
      payload: [],
    });
    return result;
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const updateGroup = (request: UpdateNeighborGroupContract) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: NeighborGroupConstants.UPDATE_NEIGHBORGROUP,
    });
    const result = await NeighborGroupService.updateGroup(request);
    dispatch({
      type: NeighborGroupConstants.UPDATE_NEIGHBORGROUP_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const deleteGroup = (id: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: NeighborGroupConstants.DELETE_NEIGHBORGROUP,
    });
    await NeighborGroupService.deleteGroup(id);
    dispatch({
      type: NeighborGroupConstants.DELETE_NEIGHBORGROUP_SUCCEEDED,
      payload: id,
    });
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const updatePicture = (groupId: string, pictureId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: NeighborGroupConstants.UPDATE_GROUP_PICTURE,
    });
    const result = await NeighborGroupService.updatePicture(groupId, pictureId);
    dispatch({
      type: NeighborGroupConstants.UPDATE_GROUP_PICTURE_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const updateDocuments = (groupId: string, documents: string[]) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: NeighborGroupConstants.UPDATE_GROUP_DOCUMENTS,
    });
    const result = await NeighborGroupService.updateDocuments(groupId, documents);
    dispatch({
      type: NeighborGroupConstants.UPDATE_GROUP_DOCUMENTS_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const addAnnouncement =
  (groupId: string, announcement: NeighborGroupAnnouncementContract) => async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({
        type: NeighborGroupConstants.ADD_ANNOUNCEMENT,
      });
      const result = await NeighborGroupService.addAnnouncement(groupId, announcement);
      dispatch({
        type: NeighborGroupConstants.ADD_ANNOUNCEMENT_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error: any) {
      dispatch(operationFailedAction(error));
    }
  };

const removeAnnnouncement = (groupId: string, announcementId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: NeighborGroupConstants.REMOVE_ANNOUNCEMENT,
    });
    const result = await NeighborGroupService.removeAnnouncement(groupId, announcementId);
    dispatch({
      type: NeighborGroupConstants.REMOVE_ANNOUNCEMENT_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const editAnnnouncement =
  (groupId: string, announcement: NeighborGroupAnnouncementContract) => async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({
        type: NeighborGroupConstants.EDIT_ANNOUNCEMENT,
      });
      const result = await NeighborGroupService.editAnnouncement(groupId, announcement);
      dispatch({
        type: NeighborGroupConstants.EDIT_ANNOUNCEMENT_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error: any) {
      dispatch(operationFailedAction(error));
    }
  };

const updateAnnouncementPicture =
  (groupId: string, announcementId: string, pictureId: string) => async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({
        type: NeighborGroupConstants.EDIT_ANNOUNCEMENT,
      });
      const result = await NeighborGroupService.updateAnnouncementPicture(groupId, announcementId, pictureId);
      dispatch({
        type: NeighborGroupConstants.EDIT_ANNOUNCEMENT_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error: any) {
      dispatch(operationFailedAction(error));
    }
  };

const updateAnnouncementDocuments =
  (groupId: string, announcementId: string, documents: string[]) => async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({
        type: NeighborGroupConstants.EDIT_ANNOUNCEMENT,
      });
      const result = await NeighborGroupService.updateAnnouncementDocuments(groupId, announcementId, documents);
      dispatch({
        type: NeighborGroupConstants.EDIT_ANNOUNCEMENT_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error: any) {
      dispatch(operationFailedAction(error));
    }
  };

const joinGroup = (id: string, receiveEmails: boolean, receiveSms: boolean) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: NeighborGroupConstants.JOIN_NEIGHBORGROUP,
    });
    const group = await NeighborGroupService.joinGroup(id, receiveEmails, receiveSms);
    const result = await NeighborGroupService.getGroups("");
    dispatch({
      type: NeighborGroupConstants.GET_NEIGHBORGROUPS_SUCCEEDED,
      payload: result.Results,
    });
    dispatch({
      type: NeighborGroupConstants.JOIN_NEIGHBORGROUP_SUCCEEDED,
      payload: group,
    });
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const leaveGroup = (id: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: NeighborGroupConstants.LEAVE_NEIGHBORGROUP,
    });
    await NeighborGroupService.leaveGroup(id);
    dispatch({
      type: NeighborGroupConstants.LEAVE_NEIGHBORGROUP_SUCCEEDED,
      payload: id,
    });
    const result = await NeighborGroupService.getGroups("");
    dispatch({
      type: NeighborGroupConstants.GET_NEIGHBORGROUPS_SUCCEEDED,
      payload: result.Results,
    });
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const getGroupMembers = (groupId: string) => async (dispatch: AppThunkDispatch) => {
  dispatch({
    type: NeighborGroupConstants.GET_NEIGHBORGROUP_MEMBERS,
  });
  try {
    const items = await NeighborGroupService.getGroupMembers(groupId);
    items.forEach((x) => {
      (x.X = x.Longitude), (x.Y = x.Latitude);
    });

    dispatch({
      type: NeighborGroupConstants.GET_NEIGHBORGROUP_MEMBERS_SUCCEEDED,
      payload: items,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const clearNotification = (groupId: string, type: NotificationType) => (dispatch: AppThunkDispatch) => {
  switch (type) {
    case NotificationType.Announcement:
      dispatch({
        type: NeighborGroupConstants.CLEAR_ANNOUNCEMENT_NOTIFICATION,
        payload: groupId,
      });
      break;
    case NotificationType.Chat:
      break;
  }
};

const subscribeToGroupUpdates =
  (groupId?: string) => async (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
    try {
      if (groupId) {
        await waitForHubConnectionAsync(getState, "SubscribeToGroupUpdates", groupId);
      } else {
        await waitForHubConnectionAsync(getState, "SubscribeToAllGroupsUpdates");
      }
    } catch (error) {
      dispatch(operationFailedAction(error));
      console.error(error);
    }
  };

const unsubscribeFromGroupUpdates =
  (groupId: string) => async (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
    try {
      await waitForHubConnectionAsync(getState, "UnsubscribeFromGroupUpdates", groupId);
    } catch (error) {
      dispatch(operationFailedAction(error));
      console.error(error);
    }
  };

const subscribe = (groupId: string, eventId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: NeighborGroupConstants.ADD_SUBSCRIBER,
    });
    const result = await NeighborGroupService.subscribe(groupId, eventId);
    dispatch({
      type: NeighborGroupConstants.ADD_SUBSCRIBER_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const unsubscribe = (groupId: string, eventId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: NeighborGroupConstants.REMOVE_SUBSCRIBER,
    });
    const result = await NeighborGroupService.unsubscribe(groupId, eventId);
    dispatch({
      type: NeighborGroupConstants.REMOVE_SUBSCRIBER_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const updateSubscribers =
  (groupId: string, eventId: string, subscribers: EventSubscriberDto[]) => async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({
        type: NeighborGroupConstants.UPDATE_SUBSCRIBERS,
      });
      const result = await NeighborGroupService.updateSubscribers(groupId, eventId, subscribers);
      dispatch({
        type: NeighborGroupConstants.UPDATE_SUBSCRIBERS_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error: any) {
      dispatch(operationFailedAction(error));
    }
  };

const checkNewAnnouncements =
  (groupId: string) => async (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
    try {
      const group = getState().neighborGroup.groups.find((x) => x.Id === groupId);
      const user = getState().user.user;
      if (user && group) {
        if (new Date(user.LastLogin) > new Date(group.Updated)) {
          dispatch({
            type: NeighborGroupConstants.SET_NEW_ANNOUNCEMENTS,
            payload: groupId,
          });
        }
      }
    } catch (error: any) {
      dispatch(operationFailedAction(error));
    }
  };

const uploadSubscribersFromFile = (fileId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    // dispatch({
    //   type: NeighborGroupConstants.UPLOAD_SUBSCRIBERS,
    // });
    const result = await NeighborGroupService.uploadSubscribersFromFile(fileId);
    // dispatch({
    //   type: NeighborGroupConstants.UPLOAD_SUBSCRIBERS_SUCCEEDED,
    //   payload: result,
    // });
    return result;
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const addGroupAdmin = (groupId: string, userId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: NeighborGroupConstants.ADD_GROUP_ADMIN,
    });
    const result = await NeighborGroupService.addGroupAdmin(groupId, userId);
    dispatch({
      type: NeighborGroupConstants.ADD_GROUP_ADMIN_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const inviteToGroup = (groupId: string, email: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: NeighborGroupConstants.INVITE_TO_GROUP,
    });
    const result = await NeighborGroupService.inviteToGroup(groupId, email);
    dispatch(getGroupMembers(groupId));
    dispatch({
      type: NeighborGroupConstants.INVITE_TO_GROUP_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const removeFromGroup = (groupId: string, userId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: NeighborGroupConstants.REMOVE_FROM_GROUP,
    });
    const result = await NeighborGroupService.removeFromGroup(groupId, userId);
    dispatch(getGroupMembers(groupId));
    dispatch({
      type: NeighborGroupConstants.REMOVE_FROM_GROUP_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const removeExternalFromGroup = (groupId: string, email: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: NeighborGroupConstants.REMOVE_FROM_GROUP,
    });
    const result = await NeighborGroupService.removeExternalFromGroup(groupId, email);
    dispatch(getGroupMembers(groupId));
    dispatch({
      type: NeighborGroupConstants.REMOVE_FROM_GROUP_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
}

const updateSettings =
  (groupId: string, settings: NeighborGroupMemberSettingsDto) => async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({
        type: NeighborGroupConstants.UPDATE_MEMBER_SETTINGS,
      });
      const result = await NeighborGroupService.updateMemberSettings(groupId, settings);
      dispatch({
        type: NeighborGroupConstants.UPDATE_MEMBER_SETTINGS_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error: any) {
      dispatch(operationFailedAction(error));
    }
  };

const updateGroupVisitedDate = (groupId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    const result = await NeighborGroupService.updateGroupVisitedDate(groupId);
    dispatch({
      type: NeighborGroupConstants.UPDATE_MEMBER_GROUP_VISITED_DATE_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const updateGroupNewMessages = (groupId: string, newMessage: boolean) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: NeighborGroupConstants.UPDATE_GROUP_NEW_MESSAGES,
      payload: { groupId: groupId, newMessage: newMessage },
    });
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const setEditorGroup = (groupId?: string) => async (dispatch: AppThunkDispatch) => {
  try {
    if (!groupId) {
      dispatch({
        type: NeighborGroupConstants.SET_EDITOR_GROUP_SUCCEEDED,
        payload: undefined,
      });
      return;
    }
    dispatch({
      type: NeighborGroupConstants.SET_EDITOR_GROUP,
    });
    const result = await NeighborGroupService.getGroup(groupId, true);
    dispatch({
      type: NeighborGroupConstants.SET_EDITOR_GROUP_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error: any) {
    dispatch(operationFailedAction(error));
  }
};

const useStoreState = () => useSelector((state: ApplicationState) => state.neighborGroup);

const useActions = () => {
  const dispatch = useAppDispatch();
  return {
    getGroups: (filter: string) => dispatch(getGroups(filter)),
    getGroup: (id: string) => dispatch(getGroup(id)),
    createGroup: (group: UpdateNeighborGroupContract, global: boolean) => dispatch(createGroup(group, global)),
    updateGroup: (group: UpdateNeighborGroupContract) => dispatch(updateGroup(group)),
    deleteGroup: (id: string) => dispatch(deleteGroup(id)),
    addAnnouncement: (groupId: string, announcement: NeighborGroupAnnouncementContract) =>
      dispatch(addAnnouncement(groupId, announcement)),
    removeAnnnouncement: (groupId: string, announcementId: string) =>
      dispatch(removeAnnnouncement(groupId, announcementId)),
    editAnnnouncement: (groupId: string, announcement: NeighborGroupAnnouncementContract) =>
      dispatch(editAnnnouncement(groupId, announcement)),
    joinGroup: (id: string, receiveEmails: boolean, receiveSms: boolean) =>
      dispatch(joinGroup(id, receiveEmails, receiveSms)),
    leaveGroup: (id: string) => dispatch(leaveGroup(id)),
    getGroupMembers: (groupId: string) => dispatch(getGroupMembers(groupId)),
    clearNotification: (groupId: string, type: NotificationType) => dispatch(clearNotification(groupId, type)),
    subscribeToGroupUpdates: (groupId?: string) => dispatch(subscribeToGroupUpdates(groupId)),
    unsubscribeFromGroupUpdates: (groupId: string) => dispatch(unsubscribeFromGroupUpdates(groupId)),
    subscribe: (groupId: string, eventId: string) => dispatch(subscribe(groupId, eventId)),
    unsubscribe: (groupId: string, eventId: string) => dispatch(unsubscribe(groupId, eventId)),
    updateSubscribers: (groupId: string, eventId: string, subscribers: EventSubscriberDto[]) =>
      dispatch(updateSubscribers(groupId, eventId, subscribers)),
    checkNewAnnouncements: (groupId: string) => dispatch(checkNewAnnouncements(groupId)),
    updatePicture: (groupId: string, pictureId: string) => dispatch(updatePicture(groupId, pictureId)),
    updateDocuments: (groupId: string, documents: string[]) => dispatch(updateDocuments(groupId, documents)),
    updateAnnouncementPicture: (groupId: string, announcementId: string, pictureId: string) =>
      dispatch(updateAnnouncementPicture(groupId, announcementId, pictureId)),
    updateAnnouncementDocuments: (groupId: string, announcementId: string, documents: string[]) =>
      dispatch(updateAnnouncementDocuments(groupId, announcementId, documents)),
    uploadSubscribersFromFile: (fileId: string) => dispatch(uploadSubscribersFromFile(fileId)),
    addGroupAdmin: (groupId: string, userId: string) => dispatch(addGroupAdmin(groupId, userId)),
    inviteToGroup: (groupId: string, email: string) => dispatch(inviteToGroup(groupId, email)),
    removeFromGroup: (groupId: string, userId: string) => dispatch(removeFromGroup(groupId, userId)),
    removeExternalFromGroup: (groupId: string, email: string) => dispatch(removeExternalFromGroup(groupId, email)),
    updateSettings: (groupId: string, settings: NeighborGroupMemberSettingsDto) =>
      dispatch(updateSettings(groupId, settings)),
    setEditorGroup: (groupId?: string) => dispatch(setEditorGroup(groupId)),
    updateGroupVisitedDate: (groupId: string) => dispatch(updateGroupVisitedDate(groupId)),
    updateGroupNewMessages: (groupId: string, newMessage: boolean) => dispatch(updateGroupNewMessages(groupId, newMessage)),
  };
};

export const useNeighborGroups = (): [ReturnType<typeof useStoreState>, ReturnType<typeof useActions>] => {
  const state = useStoreState();
  const actions = useActions();

  return [state, actions];
};

export enum NotificationType {
  Announcement = 1,
  Chat = 2,
}
