import { useSelector } from "react-redux";
import { ChatConstants } from "../constants";
import { AppThunkDispatch } from "../definitions/Action";
import { ApplicationState } from "../reducers/store";
import { operationFailedActionGeneral, useAppDispatch } from ".";
import { ChatDto } from "../definitions/model/Chat";

const operationFailedAction = (payload: unknown) => {
  return operationFailedActionGeneral(payload, ChatConstants.CHAT_OPERATION_FAILED);
};

export const sleep = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

export const waitForHubConnectionAsync = async (
  getState: () => ApplicationState,
  methodName: string,
  ...args: any[]
) => {
  try {
    const doWork = async () => {
      const hubConnection = getState().user.hubConnection;

      if (hubConnection && hubConnection.state === "Connected") {
        await hubConnection.invoke(methodName, ...args);
        return true;
      }
      return false;
    };
    const maxAttempts = 10;
    let attempts = 0;
    let result = false;
    while (attempts++ < maxAttempts) {
      result = await doWork();
      if (result) break;
      console.log("waiting for hub connection " + attempts + "/" + maxAttempts);
      await sleep(1000);
    }

    if (!result) {
      throw new Error("Hub connection not available");
    }
  } catch (error) {
    console.error(error);
  }
};

const registerChats = (parentId: string) => async (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
  try {
    dispatch({ type: ChatConstants.LOAD_CHATS, payload: true });
    await waitForHubConnectionAsync(getState, "RegisterChats", parentId);
  } catch (error) {
    dispatch(operationFailedAction(error));
    console.error(error);
  }
};

const startChat =
  (parentId: string, recipientId: string) => async (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
    try {
      await waitForHubConnectionAsync(getState, "StartChat", parentId, recipientId);
    } catch (error) {
      dispatch(operationFailedAction(error));
      console.error(error);
    }
  };

const connectToChat = (chat: ChatDto) => async (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
  try {
    await waitForHubConnectionAsync(getState, "ConnectToChat", chat.Id);
  } catch (error) {
    dispatch(operationFailedAction(error));
    console.error(error);
  }
};

const sendMessage =
  (chatId: string, recipientId: string, message: string) =>
  async (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
    try {
      await waitForHubConnectionAsync(getState, "SendChatMessage", chatId, recipientId, message);
    } catch (error) {
      dispatch(operationFailedAction(error));
      console.error(error);
    }
  };

const receiveMessageConfirmation =
  (chatId: string, messageId: string[],) =>
  async (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
    try {
      await waitForHubConnectionAsync(getState, "ReceiveChatMessageConfirmation", chatId, messageId);
      dispatch({ type: ChatConstants.RECEIVE_CHAT_MESSAGE_CONFIRMATION});
      
    } catch (error) {
      dispatch(operationFailedAction(error));
      console.error(error);
    }
  };

const clearChat = () => (dispatch: AppThunkDispatch) => {
  dispatch({ type: ChatConstants.CLEAR_CHAT });
};

const useStoreState = () => useSelector((state: ApplicationState) => state.chat);

const useActions = () => {
  const dispatch = useAppDispatch();
  return {
    registerChats: (parentId: string) => dispatch(registerChats(parentId)),
    startChat: (parentId: string, recipientId: string) => dispatch(startChat(parentId, recipientId)),
    connectToChat: (chat: ChatDto) => dispatch(connectToChat(chat)),
    sendMessage: (chatId: string, recipientId: string, message: string) =>
      dispatch(sendMessage(chatId, recipientId, message)),
    receiveMessageConfirmation: (chatId: string, messageId: string[]) =>
      dispatch(receiveMessageConfirmation(chatId, messageId)),
    clearChat: () => dispatch(clearChat()),
  };
};

export const useChat = (): [ReturnType<typeof useStoreState>, ReturnType<typeof useActions>] => {
  const state = useStoreState();
  const actions = useActions();

  return [state, actions];
};
