import { Reducer } from "redux";
import { AppAction } from "../definitions/Action";
import {
  ClubModel,
  ClubGroupModel,
  ClubArticleModel,
  ClubPostModel,
  ClubCommentModel,
} from "../definitions/model/Club";
import { ClubActions } from "../constants/club.actiontypes";
import { LandingArticleDto } from "../definitions/Landing";

export interface ClubStoreState {
  loading: boolean;
  error: string | null;
  clubs: ClubModel[];
  benefitClubs: LandingArticleDto[];
  articles: LandingArticleDto[];
  articlesCount: number;
  club: ClubModel | null;
  groups: ClubGroupModel[];
  items: (ClubArticleModel | ClubPostModel)[];
  comments: ClubCommentModel[];
}

export const initialState: ClubStoreState = {
  items: [],
  clubs: [],
  benefitClubs: [],
  articles: [],
  articlesCount: 0,
  club: null,
  groups: [],
  comments: [],
  loading: false,
  error: null,
};

const clubReducer: Reducer<ClubStoreState, AppAction> = (
  state: ClubStoreState = initialState,
  action: AppAction
): ClubStoreState => {
  switch (action.type) {
    case ClubActions.GET_CLUBS:
    case ClubActions.GET_CLUB:
    case ClubActions.ADD_CLUB:
    case ClubActions.EDIT_CLUB:
    case ClubActions.DELETE_CLUB:
    case ClubActions.GET_CLUB_GROUPS:
    case ClubActions.GET_CLUB_GROUP:
    case ClubActions.ADD_CLUB_GROUP:
    case ClubActions.EDIT_CLUB_GROUP:
    case ClubActions.DELETE_CLUB_GROUP:
    case ClubActions.ADD_ARTICLE:
    case ClubActions.DELETE_ARTICLE:
    case ClubActions.GET_CLIENT_BENEFIT_CLUBS:
    case ClubActions.GET_ARTICLE_PAGES:
      return { ...state, loading: true };
    case ClubActions.GET_CLUBS_SUCCEEDED:
      return { ...state, clubs: action.payload as ClubModel[], loading: false };
    //TODO: Check if it is needed
    case ClubActions.GET_CLUB_SUCCEEDED:
      return { ...state, club: action.payload, loading: false };
    case ClubActions.ADD_CLUB_SUCCEEDED:
      return {
        ...state,
        clubs: [...state.clubs, action.payload],
        loading: false,
      };
    case ClubActions.EDIT_CLUB_SUCCEEDED:
      return {
        ...state,
        clubs: state.clubs.map((x) => {
          if (x.Id === action.payload.id) {
            return { ...x, ...action.payload };
          }
          return x;
        }),
        loading: false,
      };
    case ClubActions.DELETE_CLUB_SUCCEEDED:
      return {
        ...state,
        clubs: state.clubs.filter((x) => x.Id !== action.payload),
        loading: false,
      };
    case ClubActions.GET_CLUB_GROUPS_SUCCEEDED:
      return {
        ...state,
        groups: action.payload as ClubGroupModel[],
        loading: false,
      };
    //TODO: Check if it is needed
    // case ClubActions.GET_CLUB_GROUP_SUCCEEDED:
    //   return { ...state };
    case ClubActions.ADD_CLUB_GROUP_SUCCEEDED:
      return {
        ...state,
        groups: [...state.groups, action.payload],
        loading: false,
      };
    case ClubActions.EDIT_CLUB_GROUP_SUCCEEDED:
      return {
        ...state,
        groups: state.groups.map((x) => {
          if (x.Id === action.payload.id) {
            return { ...x, ...action.payload };
          }
          return x;
        }),
        loading: false,
      };
    case ClubActions.DELETE_CLUB_GROUP_SUCCEEDED:
      return {
        ...state,
        groups: state.groups.filter((x) => x.Id !== action.payload),
        loading: false,
      };
    case ClubActions.CLUB_OPERATION_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ClubActions.GET_CLIENT_BENEFIT_CLUBS_SUCCEEDED:
      return { ...state, benefitClubs: action.payload, loading: false };
    // club items reducer (should be splitted into 2?)
    case ClubActions.ADD_POST:
    case ClubActions.ADD_COMMENT:
    case ClubActions.EDIT_COMMENT:
    case ClubActions.REMOVE_POST:
    case ClubActions.REMOVE_COMMENT:
      return { ...state, loading: true };
    case ClubActions.ADD_POST_SUCCEEDED:
      return { ...state, items: [state.items, action.payload], loading: false };
    case ClubActions.ADD_COMMENT_SUCCEEDED:
      return {
        ...state,
        comments: [...state.comments, action.payload],
        loading: false,
      };
    case ClubActions.EDIT_COMMENT_SUCCEEDED:
      return {
        ...state,
        comments: state.comments.map((x) => {
          if (x.Id === action.payload.Id) {
            return { ...x, ...action.payload };
          }
          return x;
        }),
        loading: false,
      };
    case ClubActions.ADD_ARTICLE_SUCCEEDED:
      return {
        ...state,
        club: state.club
          ? {
              ...state.club,
              Articles: [...state.club.Articles, action.payload],
            }
          : null,
      };
    case ClubActions.DELETE_ARTICLE_SUCCEEDED:
      return {
        ...state,
        club: state.club
          ? {
              ...state.club,
              Articles: state.club.Articles.filter((x) => x.Id !== action.payload),
            }
          : null,
      };

    case ClubActions.REMOVE_POST_SUCCEEDED:
      return {
        ...state,
        items: state.items.filter((x) => x.Id !== action.payload.Id),
        loading: false,
      };
    case ClubActions.REMOVE_COMMENT_SUCCEEDED:
      return {
        ...state,
        items: state.comments.filter((x) => x.Id !== action.payload.Id),
        loading: false,
      };
    case ClubActions.GET_ARTICLE_PAGES_SUCCEEDED:
      return {
        ...state,
        articles: action.payload.Results,
        articlesCount: action.payload.Count,
        loading: false,
      };
    case ClubActions.POST_OPERATION_FAILED:
    case ClubActions.COMMENT_OPERATION_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default clubReducer;
