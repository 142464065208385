import React, { useEffect, useRef, useState } from "react";
import { Typography, Grid, Button, Card, CardContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import ServiceBookConditionComponent from "../condition/Condition";
import ReportSkeleton from "../report/ReportSkeleton";
import {
  ConsentState,
  CraftsmanTaskList,
  CustomerReportState,
  ServiceBookItemToFix,
  ServiceBookReport,
} from "../../../../../definitions/model/ServiceBook";
import BaseDialog from "../../../../../components/Dialogs/BaseDialog";
import { useServicebook } from "../../../../../actions/servicebook.actions";

type ReportDetailsProps = {
  report: ServiceBookReport;
  unitType?: string;
  setItemsToFix: (items: ServiceBookItemToFix[]) => void;
  taskLists: CraftsmanTaskList[];
  customerState: CustomerReportState | undefined;
  readOnly?: boolean;
};

const ConditionDetailsStep = (props: ReportDetailsProps) => {
  const { t } = useTranslation("translation");

  const btnRef = useRef<any>(null);
  const reportRef = useRef<any>(null);
  const [isButtonFixed, setIsButtonFixed] = useState(true);
  const [buttonPosition, setButtonPosition] = useState(0);
  const { setItemsToFix, report, customerState } = props;
  const [consentRequests, setConsentRequests] = useState<ConsentState[]>([]);
  const [showConsentDialog, setShowConsentDialog] = useState<ConsentState | undefined>();
  const [, { manageConsent, getCustomerState }] = useServicebook();

  const handleSelectWorkersOpen = () => {
    const itemsToFix: ServiceBookItemToFix[] = [];

    report &&
      report.Objects.forEach((o) =>
        o.Sections.forEach((s) => {
          s.Parameters.forEach((p) => {
            const noNeedToFix = customerState?.CustomerItems.some((x) => x.Id === p.Id && (x.Diy || x.Finished));
            if (!noNeedToFix) {
              const itemName =
                report.Objects.length > 1 ? `${o.Name} / ${s.Name} / ${p.Parameter}` : `${s.Name} / ${p.Parameter}`;

              itemsToFix.push({
                id: p.Id,
                name: itemName,
                ownerNote: p.OwnerNote,
                missingCraftsmen: p.MissingCraftsmen,
              });
            }
          });
        })
      );

    setItemsToFix(itemsToFix);
  };

  const handleButtonPosition = () => {
    setIsButtonFixed(window.innerHeight - btnRef?.current?.getBoundingClientRect()?.top < 60);
    setButtonPosition(btnRef?.current?.getBoundingClientRect()?.left);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleButtonPosition);
    window.addEventListener("resize", handleButtonPosition);

    return () => {
      window.removeEventListener("scroll", handleButtonPosition);
      window.removeEventListener("resize", handleButtonPosition);
    };
  }, []);

  const observer = useRef(new ResizeObserver(handleButtonPosition));

  useEffect(() => {
    if (reportRef.current) {
      observer.current.observe(reportRef.current);
    }

    return () => {
      if (reportRef.current) {
        observer.current.unobserve(reportRef.current);
      }
    };
  }, [reportRef, observer]);

  useEffect(() => {
    const consentsLeft = customerState?.Consents?.filter((x) => !x.Given) || [];
    setConsentRequests(consentsLeft);
  }, [customerState]);

  useEffect(() => {
    if (!showConsentDialog && consentRequests.length) {
      setShowConsentDialog(consentRequests[0]);
    }
  }, [consentRequests]);

  const manageReportConsent = async (given: boolean) => {
    if (showConsentDialog) {
      try {
        await manageConsent(report?.Id, showConsentDialog.ManagerId, given);
        setShowConsentDialog(undefined);
        await getCustomerState(report?.Id || "");
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <Grid sx={{ position: "relative" }}>
        <Grid container direction="column" spacing={2} ref={reportRef}>
          {report ? (
            <>
              <ServiceBookConditionComponent
                {...{
                  ...report,
                  Data: {
                    ...report.Data,
                    UnitType: props.unitType,
                  },
                }}
                readOnly={props.readOnly}
              />
            </>
          ) : (
            <ReportSkeleton />
          )}
          <Grid item container direction="column">
            <Card variant="outlined">
              <CardContent>
                <Grid item xs={12}>
                  <Typography variant="h3">{t("ServiceBook.ReportDetails.HelpTitle")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom>
                    {t("ServiceBook.ReportDetails.Help1")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom>
                    {t("ServiceBook.ReportDetails.Help2")}
                  </Typography>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid ref={btnRef} style={{ height: 40, margin: "20px 0" }}>
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-end"
            style={{
              position: `${isButtonFixed ? "fixed" : "static"}`,
              right: `${buttonPosition}px`,
              bottom: 20,
            }}
          >
            {!props.readOnly && (
              <Grid item>
                <Button variant="contained" color="secondary" onClick={handleSelectWorkersOpen}>
                  {t("General.Buttons.Next")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <BaseDialog
        open={!!showConsentDialog}
        closeDialog={() => setShowConsentDialog(undefined)}
        titleComponent={<Typography>Consent</Typography>}
      >
        <Grid item>
          <Typography variant="body1">Give consent to {showConsentDialog?.Name || ""}</Typography>
        </Grid>
        <Grid item container spacing={1} justifyContent={"center"}>
          <Grid item>
            <Button
              variant="contained"
              onClick={async () => await manageReportConsent(true)}
              color="secondary"
              sx={{ maxWidth: "none" }}
            >
              {t("General.Buttons.Ok")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={async () => await manageReportConsent(false)}
              color="secondary"
              sx={{ maxWidth: "none" }}
            >
              {t("General.Buttons.Undo")}
            </Button>
          </Grid>
        </Grid>
      </BaseDialog>
    </>
  );
};

export default ConditionDetailsStep;
