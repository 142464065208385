import React from "react";
import { Box, Card, CardContent, CardHeader, Grid, LinearProgress, LinearProgressProps, Typography } from "@mui/material";
import { ServiceBookReportPreview } from "../../../../definitions/model/ServiceBook";
import {styled} from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const StyledCard = styled(Card)(({theme})=>({
  transition: "border-color 0.3s",
  cursor: "pointer",
  height: "100%",
  "&:hover": {
    borderColor: theme.palette.grey[600],
  }
}));


type ReportCardProps = {
  report: ServiceBookReportPreview;
  onSelect: (id: string) => void;
};

const ReportCard = ({ report, onSelect }: ReportCardProps): JSX.Element => {
  const { t } = useTranslation();
  const handleChangeHouseClick = (reportId: string) => (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    onSelect(reportId);
    handleClose(e);
  };
  const handleClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <StyledCard
      variant="outlined"
      onClick={handleChangeHouseClick(report.Id)}
    >
      <CardHeader
        title={`No ${report.CaseNumber}`}
        subheader={report.CreatedBy}
      />
      <CardContent>
        <Grid container justifyContent="center" style={{ marginBottom: 30 }}>
          <Typography variant="h4">
            {new Date(report.Created).toLocaleDateString("da-dk")}
          </Typography>
        </Grid>
        <Typography variant="caption">{t("ServiceBook.CompletionStatus")}</Typography>
        <Grid container justifyContent="space-between">
          </Grid>
        <LinearProgressWithLabel value={report.CompletionPercentage} />
        <Typography>{report.Name}</Typography>
        {/* <Typography>
          {report.Approved
            ? t("ServiceBook.ReportList.Approved")
            : t("ServiceBook.ReportList.NotApproved")}
        </Typography> */}
      </CardContent>
    </StyledCard>
  );
};

export default ReportCard;

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
