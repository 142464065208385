import React from "react";
import { Container } from "@mui/material";

import BaseDialog from "../BaseDialog";
import { AuthDialogChildrenProps } from "../AuthDialog";
import LoginForm from "./LoginForm";
import { useTranslation } from "react-i18next";

const LoginDialog = ({
  state,
  open,
  setStep,
  closeDialog,
  employerId,
  communeNumber,
  loginOnly,
  loginButtonText,
}: AuthDialogChildrenProps): JSX.Element => {
  const { t } = useTranslation("translation");

  console.log("LoginDialog.communeNumber", communeNumber);
  return (
    <BaseDialog
      titleComponent={t("Base.Dialog.LogInToMyhouse", { hostname: window.location.hostname }) ?? ""}
      open={open}
      closeDialog={closeDialog}
    >
      <Container maxWidth="xs">
        <LoginForm
          setStep={setStep}
          state={state}
          employerId={employerId}
          communeNumber={communeNumber}
          loginOnly={loginOnly}
          loginButtonText={loginButtonText}
        />
      </Container>
    </BaseDialog>
  );
};

export default LoginDialog;
