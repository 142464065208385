import { Dispatch } from "redux";
import { ClubActions } from "../constants/club.actiontypes";
import { AppAction, AppThunkDispatch } from "../definitions/Action";
import ClubService from "../services/club.service";
import { ClubModel, ClubGroupModel, ArticleModel } from "../definitions/model/Club";
import { LandingArticleDto } from "../definitions/Landing";
import { ArticleCategory } from "../constants/enums";
import { operationFailedActionGeneral, useAppDispatch } from ".";
import { SearchResult } from "../definitions/model/SearchResult";
import { useSelector } from "react-redux";
import { ApplicationState } from "../reducers/store";

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(payload, ClubActions.CLUB_OPERATION_FAILED);
};

const getClubGroups = () => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: ClubActions.GET_CLUB_GROUPS,
    });
    const result = await ClubService.getClubGroups();
    dispatch({
      type: ClubActions.GET_CLUB_GROUPS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const getClubGroup = (id: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: ClubActions.GET_CLUB_GROUP,
    });
    const result = await ClubService.getClubGroup(id);
    dispatch({
      type: ClubActions.GET_CLUB_GROUP_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const getClubs = (skip: number, take: number) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: ClubActions.GET_CLUBS,
    });
    const result = await ClubService.getClubs(skip, take);
    dispatch({
      type: ClubActions.GET_CLUBS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const getClub = (id: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: ClubActions.GET_CLUB,
    });
    const result = await ClubService.getClub(id);
    dispatch({
      type: ClubActions.GET_CLUB_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

const getClubByUniqueName = (uniqueName: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: ClubActions.GET_CLUB,
    });
    const result = await ClubService.getClubByUniqueName(uniqueName);
    dispatch({
      type: ClubActions.GET_CLUB_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

const addClubGroup = (group: ClubGroupModel) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: ClubActions.ADD_CLUB_GROUP,
    });
    const result = await ClubService.addClubGroup(group);
    dispatch({
      type: ClubActions.ADD_CLUB_GROUP_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const addClub = (title: string, description: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: ClubActions.ADD_CLUB,
    });
    const result = await ClubService.addClub(title, description);
    dispatch({
      type: ClubActions.ADD_CLUB_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const editClubGroup = (group: ClubGroupModel) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: ClubActions.EDIT_CLUB_GROUP,
    });
    const result = await ClubService.editClubGroup(group);
    dispatch({
      type: ClubActions.DELETE_CLUB_GROUP_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const deleteClubGroup = (id: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: ClubActions.DELETE_CLUB_GROUP,
    });
    const result = await ClubService.deleteClubGroup(id);
    dispatch({
      type: ClubActions.DELETE_CLUB_GROUP_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

const editClub = (club: ClubModel) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: ClubActions.EDIT_CLUB,
    });
    const result = await ClubService.editClub(club);
    dispatch({
      type: ClubActions.EDIT_CLUB_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const deleteClub = (id: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: ClubActions.DELETE_CLUB,
    });
    await ClubService.deleteClub(id);
    dispatch({
      type: ClubActions.DELETE_CLUB_SUCCEEDED,
      payload: id,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const createArticle =
  (clubId: string, title: string, uniqueName: string, templateId: string, categories: ArticleCategory[], tag: string) =>
  async (dispatch: AppThunkDispatch): Promise<ArticleModel> => {
    try {
      dispatch({
        type: ClubActions.ADD_ARTICLE,
      });

      const result = await ClubService.createArticle(clubId, title, uniqueName, templateId, categories, tag);

      dispatch({
        type: ClubActions.ADD_ARTICLE_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const getArticle =
  (articleId: string) =>
  async (dispatch: AppThunkDispatch): Promise<ArticleModel> => {
    try {
      dispatch({
        type: ClubActions.GET_ARTICLE,
      });
      const result = await ClubService.getArticle(articleId);
      dispatch({
        type: ClubActions.GET_ARTICLE_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const updateArticle =
  (article: ArticleModel) =>
  async (dispatch: AppThunkDispatch): Promise<ArticleModel> => {
    try {
      dispatch({
        type: ClubActions.UPDATE_ARTICLE,
      });
      const result = await ClubService.editArticle(article);
      dispatch({
        type: ClubActions.UPDATE_ARTICLE_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const getArticlePage =
  (uniqueName: string) =>
  async (dispatch: AppThunkDispatch): Promise<LandingArticleDto> => {
    try {
      dispatch({
        type: ClubActions.GET_ARTICLE_PAGE,
      });
      const result = await ClubService.getArticlePage(uniqueName);
      dispatch({
        type: ClubActions.GET_ARTICLE_PAGE_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const getArticlePages =
  (clubId?: string) =>
  async (dispatch: AppThunkDispatch): Promise<SearchResult<LandingArticleDto>> => {
    try {
      dispatch({
        type: ClubActions.GET_ARTICLE_PAGES,
      });
      const result = await ClubService.getArticlePages(clubId);
      dispatch({
        type: ClubActions.GET_ARTICLE_PAGES_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const deleteArticle =
  (articleId: string) =>
  async (dispatch: AppThunkDispatch): Promise<boolean> => {
    try {
      dispatch({
        type: ClubActions.DELETE_ARTICLE,
      });
      const isDeleted = await ClubService.deleteArticlePage(articleId);
      if (isDeleted) {
        dispatch({
          type: ClubActions.DELETE_ARTICLE_SUCCEEDED,
          payload: articleId,
        });
      } else {
        dispatch(operationFailedAction("Article was not deleted"));
      }
      return isDeleted;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const getRecentArticles =
  (articleId: string, take: number) =>
  async (dispatch: AppThunkDispatch): Promise<SearchResult<LandingArticleDto>> => {
    try {
      dispatch({
        type: ClubActions.GET_RECENT_ARTICLES,
      });
      const result = await ClubService.getRecentArticles(articleId, take);
      dispatch({
        type: ClubActions.GET_RECENT_ARTICLES_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const getBenefitClubs =
  (skip: number, take: number) =>
  async (dispatch: AppThunkDispatch): Promise<Array<LandingArticleDto> | undefined> => {
    try {
      dispatch({
        type: ClubActions.GET_CLIENT_BENEFIT_CLUBS,
      });
      const result = await ClubService.getBenefitClubs(skip, take);
      dispatch({
        type: ClubActions.GET_CLIENT_BENEFIT_CLUBS_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const useClubState = () => useSelector((state: ApplicationState) => state.club);

const useClubActions = () => {
  const dispatch = useAppDispatch();
  return {
    getArticles: (clubId?: string) => dispatch(getArticlePages(clubId)),
    getArticle: (articleId: string) => dispatch(getArticle(articleId)),
    getArticlePage: (uniqueName: string) => dispatch(getArticlePage(uniqueName)),
    getRecentArticles: (articleId: string, take: number) => dispatch(getRecentArticles(articleId, take)),
    getBenefitClubs: (skip: number, take: number) => dispatch(getBenefitClubs(skip, take)),
    getClubGroups: () => dispatch(getClubGroups()),
    getClubGroup: (id: string) => dispatch(getClubGroup(id)),
    getClubs: (skip: number, take: number) => dispatch(getClubs(skip, take)),
    getClub: (id: string) => dispatch(getClub(id)),
    getClubByUniqueName: (uniqueName: string) => dispatch(getClubByUniqueName(uniqueName)),
    addClubGroup: (group: ClubGroupModel) => dispatch(addClubGroup(group)),
    addClub: (title: string, description: string) => dispatch(addClub(title, description)),
    editClubGroup: (group: ClubGroupModel) => dispatch(editClubGroup(group)),
    deleteClubGroup: (id: string) => dispatch(deleteClubGroup(id)),
    editClub: (club: ClubModel) => dispatch(editClub(club)),
    deleteClub: (id: string) => dispatch(deleteClub(id)),
    createArticle: (
      clubId: string,
      title: string,
      uniqueName: string,
      templateId: string,
      categories: ArticleCategory[],
      tag: string
    ) => dispatch(createArticle(clubId, title, uniqueName, templateId, categories, tag)),
    updateArticle: (article: ArticleModel) => dispatch(updateArticle(article)),
    deleteArticle: (articleId: string) => dispatch(deleteArticle(articleId)),
  };
};

export const useClubs = (): [ReturnType<typeof useClubState>, ReturnType<typeof useClubActions>] => {
  const state = useClubState();
  const actions = useClubActions();
  return [state, actions];
};
