import React, { useEffect, useReducer, useRef } from "react";
import {
  Button,
  CircularProgress,
  Collapse,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ChangesAlert from "./components/ChangesAlert";
import { ApplicationState } from "../../reducers/store";
import { connect, ConnectedProps } from "react-redux";
import { AppThunkDispatch } from "../../definitions/Action";
import LocalPlanMap from "./components/LocalPlanMap";
import LocalPlanInfo from "./components/LocalPlanInfo";
import { getLocalPlans, getPlanInfo } from "../../actions/map.actions";
import { LocalPlanData, WindowPosition } from "../../definitions/Map";
import MyhouseCard from "../../components/Base/MyhouseCard";
import { FixedSizeList, ListChildComponentProps } from "react-window";

type LocalPlansSubscriptionState = {
  alertShown: boolean;
  localPlansShown: boolean;
};

const initialState: LocalPlansSubscriptionState = {
  alertShown: true,
  localPlansShown: false,
};

const reducer = (
  state: LocalPlansSubscriptionState,
  action: { type: string; payload: unknown }
) => {
  return { ...state, [action.type]: action.payload };
};

const LocalPlanSubscriptionPage = (
  props: LocalPlansSubscriptionConnectedProps
) => {
  const {
    user,
    unit,
    loading,
    localPlans,
    getLocalPlans,
    getPlanInfo,
    planInfo,
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { t } = useTranslation("translation");
  const setAlertShown = (value: boolean) =>
    dispatch({ type: "alertShown", payload: value });

  const toggleLocalPlans = () =>
    dispatch({ type: "localPlansShown", payload: !state.localPlansShown });

  useEffect(() => {
    if (unit) {
      getLocalPlans(unit.Id);
    }
  }, [unit]);

  const renderListItem = ({ index, style }: ListChildComponentProps) => {
    if (localPlans[index]) {
      const localPlan = {
        Name: localPlans[index].Name,
        Description: localPlans[index].Description,
        DocUrl: localPlans[index].DocumentUrl,
      };
      return <LocalPlanInfo localPlan={localPlan} style={style} key={index} />;
    }
    return <div style={style}></div>;
  };

  return (
    <>
        {user && state.alertShown && (
          <ChangesAlert user={user} hideAlert={() => setAlertShown(false)}>
            <Typography paragraph>
              {t("Subscriptions.NotificationAboutNewLocalPlan")}
            </Typography>
            <Typography paragraph>
              {t("Subscriptions.ForefrontOfAnyChanges")}
            </Typography>

            <Typography paragraph>
              {t("Subscriptions.AboutOverviewOfApplicableLocalPlans")}
            </Typography>

            <Typography paragraph>
              {t("Subscriptions.IfThereArePointsYouDisagreeWith")}
            </Typography>
            <Typography>{t("Subscriptions.KindRegardsMyhouseTeam", { hostname: window.location.hostname })}</Typography>
          </ChangesAlert>
        )}
        <Typography variant="h3">{t("Subscriptions.NewLocalPlans")}</Typography>
        <MyhouseCard title={t("Subscriptions.NewLocalPlans")} type="narrow">
          {localPlans[0] ? (
            <LocalPlanInfo
              localPlan={{
                Description: localPlans[0].Description,
                DocUrl: localPlans[0].DocumentUrl,
                Name: localPlans[0].Name,
              }}
            />
          ) : (
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
          )}
        </MyhouseCard>
        <Typography variant="h3">{t("Pages.House.LocalPlan")} </Typography>
        <Typography>
          {t("Subscriptions.LocalPlansContainProvisions")}
        </Typography>
        <MyhouseCard
          title={t("Subscriptions.LocalPlansIncludeYourHome")}
          type="narrow"
        >
          {!loading ? (
            <Grid container alignItems="center" direction="column">
              <Grid container item>
                <Collapse
                  in={state.localPlansShown}
                  collapsedSize={175}
                  style={{ width: "100%" }}
                >
                  <FixedSizeList
                    height={600}
                    width="100%"
                    itemCount={state.localPlansShown ? localPlans.length : 2}
                    itemSize={87.5}
                  >
                    {renderListItem}
                  </FixedSizeList>
                </Collapse>
              </Grid>
              <Button
                variant="contained"
                color="secondary"
                onClick={toggleLocalPlans}
                disabled={props.localPlans.length === 0}
              >
                {state.localPlansShown
                  ? t("General.Buttons.FoldOut")
                  : t("General.Buttons.FoldIn")}
              </Button>
            </Grid>
          ) : (
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
          )}
        </MyhouseCard>
        <Typography variant="h3">
          {t("Subscriptions.MyLocalPlansSeenOnMap")}
        </Typography>
        <LocalPlanMap
          unit={unit}
          localPlans={localPlans}
          loading={loading}
          planInfo={planInfo}
          getPlanInfo={getPlanInfo}
        />
      </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.user,
  unit: state.unit.unit,
  planInfo: state.map.planInfo,
  localPlans: state.map.localPlans,
  loading: state.map.loading,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getLocalPlans: (unitId: string) => dispatch(getLocalPlans(unitId)),
  getPlanInfo: (data: LocalPlanData, windowPosition: WindowPosition) =>
    dispatch(getPlanInfo(data, windowPosition)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type LocalPlansSubscriptionConnectedProps = ConnectedProps<typeof connector>;

export default connector(LocalPlanSubscriptionPage);
