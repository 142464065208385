import React from "react";
import { ListItem, Grid, Typography, Button } from "@mui/material";
import {
  WorkerLinkRequest,
  LinkRequestState,
} from "../../../definitions/model/Worker";
import { useTranslation } from "react-i18next";


interface WorkerLinkRequestItemprops extends WorkerLinkRequest {
  processRequest: (
    workerId: string,
    unitId: string,
    state: LinkRequestState
  ) => void;
}

const WorkerLinkRequestItem = (props: WorkerLinkRequestItemprops) => {
  const { t } = useTranslation("translation");
  
  return (
    <ListItem disableGutters>
      <Grid container style={{ minHeight: 63 }}>
        <Grid item container direction="row" justifyContent="space-between">
          <Grid item xs={12} md={7}>
            <Grid item container direction="column" alignItems="flex-start">
              <Grid item container direction="row" alignItems="center">
                <Typography variant="body1">
                  {props.CraftsmanName} {t("ServiceBook.Workers.WantToBecomeYourCraftsman")}
                </Typography>
              </Grid>
              {/* <Typography variant="body2">{worker.Address}</Typography>
                <Typography variant="body2">
                  {`${worker.PostCode} ${worker.City}`}
                </Typography> */}
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} justifyContent="flex-end" container spacing={1}>
            <Grid item>
              <Button
                sx={{width: "160px"}}
                variant="contained"
                color="secondary"
                onClick={() =>
                  props.processRequest(
                    props.CraftsmanId,
                    props.HouseId,
                    LinkRequestState.Accepted
                  )
                }
              >
                {t("ServiceBook.Workers.OkLinkToMyhouse")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{width: "160px"}}
                color="secondary"
                variant="contained"
                onClick={() =>
                  props.processRequest(
                    props.CraftsmanId,
                    props.HouseId,
                    LinkRequestState.Cancelled
                  )
                }
              >
                {t("ServiceBook.Workers.RemoveLink")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default WorkerLinkRequestItem;
