import {
  NeighborGroup,
  NeighborGroupMembersDto,
  NeighborGroupAnnouncementContract,
  EventSubscriberDto,
  NeighborGroupMemberSettingsDto,
  UpdateNeighborGroupContract,
} from "../definitions/model/NeighborGroup";
import { SearchResult } from "../definitions/model/SearchResult";
import ApiService from "./api.service";

const NeighborGroupService = {
  getGroups: async (filter: string, radius = 5000): Promise<SearchResult<NeighborGroup>> => {
    const url = `/api/neighborgroup?filter=${filter}&radius=${radius}`;
    const result = await ApiService.get<SearchResult<NeighborGroup>>(url);
    return result;
  },
  getGroup: async (groupId: string, edit?: boolean): Promise<NeighborGroup> => {
    let url = `/api/neighborgroup/${groupId}`;
    if (edit) {
      url += "?edit=true";
    }
    const result = await ApiService.get<NeighborGroup>(url);
    return result;
  },
  createGroup: async (request: UpdateNeighborGroupContract): Promise<NeighborGroup> => {
    const url = `/api/neighborgroup`;
    const result = await ApiService.post<NeighborGroup>(url, request);
    return result;
  },
  updateGroup: async (request: UpdateNeighborGroupContract): Promise<NeighborGroup> => {
    const url = `/api/neighborgroup`;
    const result = await ApiService.put<NeighborGroup>(url, request);
    return result;
  },
  deleteGroup: async (groupId: string): Promise<void> => {
    const url = `/api/neighborgroup/${groupId}`;
    await ApiService.delete(url);
  },
  updatePicture: async (groupId: string, pictureId: string): Promise<NeighborGroup> => {
    const url = `/api/neighborgroup/${groupId}/picture/${pictureId}`;
    const result = await ApiService.put<NeighborGroup>(url, null);
    return result;
  },
  updateDocuments: async (groupId: string, documents: string[]): Promise<NeighborGroup> => {
    const url = `/api/neighborgroup/${groupId}/documents`;
    const result = await ApiService.put<NeighborGroup>(url, documents);
    return result;
  },
  addAnnouncement: async (groupId: string, announcement: NeighborGroupAnnouncementContract) => {
    const url = `/api/neighborgroup/${groupId}/announcement`;
    const result = await ApiService.post<{ GroupId: string; AnnouncementId: string }>(url, announcement);
    return result;
  },
  removeAnnouncement: async (groupId: string, announcementId: string): Promise<NeighborGroup> => {
    const url = `/api/neighborgroup/${groupId}/announcement/${announcementId}`;
    const result = await ApiService.delete<NeighborGroup>(url);
    return result;
  },
  editAnnouncement: async (
    groupId: string,
    announcement: NeighborGroupAnnouncementContract
  ): Promise<NeighborGroup> => {
    const url = `/api/neighborgroup/${groupId}/announcement/`;
    const result = await ApiService.put<NeighborGroup>(url, announcement);
    return result;
  },
  joinGroup: async (groupId: string, receiveEmails: boolean, receiveSms: boolean): Promise<NeighborGroup> => {
    const url = `/api/neighborgroup/${groupId}/join`;
    return await ApiService.put(url, { receiveEmails, receiveSms });
  },
  leaveGroup: async (groupId: string): Promise<void> => {
    const url = `/api/neighborgroup/${groupId}/leave`;
    return await ApiService.put(url, {});
  },
  updateMemberSettings: async (groupId: string, settings: NeighborGroupMemberSettingsDto): Promise<NeighborGroup> => {
    const url = `/api/neighborgroup/${groupId}/settings`;
    return await ApiService.put(url, settings);
  },
  getGroupMembers: async (groupId: string) => {
    const url = `/api/neighborgroup/${groupId}/members`;
    return await ApiService.get<NeighborGroupMembersDto[]>(url);
  },

  updateGroupVisitedDate: async (groupId: string) => {
    const url = `/api/neighborgroup/${groupId}/member/updatevisitdate`;
    return await ApiService.put(url, {});
  },

  subscribe: async (groupId: string, eventId: string): Promise<NeighborGroup> => {
    const url = `/api/neighborgroup/${groupId}/event/${eventId}`;
    return await ApiService.put<NeighborGroup>(url, {});
  },
  unsubscribe: async (groupId: string, eventId: string): Promise<NeighborGroup> => {
    const url = `/api/neighborgroup/${groupId}/event/${eventId}`;
    return await ApiService.delete(url);
  },
  updateSubscribers: async (
    groupId: string,
    announcementId: string,
    subscribers: EventSubscriberDto[]
  ): Promise<NeighborGroup> => {
    const url = `/api/neighborgroup/subscribers`;
    return await ApiService.put<NeighborGroup>(url, { groupId, announcementId, subscribers });
  },
  updateAnnouncementPicture: async (
    groupId: string,
    announcementId: string,
    pictureId: string
  ): Promise<NeighborGroup> => {
    const url = `/api/neighborgroup/${groupId}/announcement/${announcementId}/picture/${pictureId}`;
    const result = await ApiService.put<NeighborGroup>(url, null);
    return result;
  },
  updateAnnouncementDocuments: async (
    groupId: string,
    announcementId: string,
    documents: string[]
  ): Promise<NeighborGroup> => {
    const url = `/api/neighborgroup/${groupId}/announcement/${announcementId}/documents`;
    const result = await ApiService.put<NeighborGroup>(url, documents);
    return result;
  },
  uploadSubscribersFromFile: async (fileId: string): Promise<any> => {
    const url = `/api/neighborgroup/subscribersfile/${fileId}`;
    return await ApiService.post(url, null);
  },
  addGroupAdmin: async (groupId: string, userId: string): Promise<NeighborGroup> => {
    const url = `/api/neighborgroup/${groupId}/admin/${userId}`;
    return await ApiService.post<NeighborGroup>(url, {});
  },
  inviteToGroup: async (groupId: string, email: string): Promise<void> => {
    const url = `/api/neighborgroup/${groupId}/invite?email=${email}`;
    return await ApiService.post<void>(url, {});
  },
  removeFromGroup: async (groupId: string, userId: string): Promise<NeighborGroup> => {
    const url = `/api/neighborgroup/${groupId}/remove/${userId}`;
    return await ApiService.post<NeighborGroup>(url, {});
  },
  removeExternalFromGroup: async (groupId: string, email: string): Promise<NeighborGroup> => {
    const url = `/api/neighborgroup/${groupId}/remove/external/${email}`;
    return await ApiService.post<NeighborGroup>(url, {});
  }
};

export default NeighborGroupService;
