import React, { useState, useEffect, Fragment, useContext } from "react";
import { CustomerItem, ServiceBookReportParameter, TimeToFinish } from "../../../../../definitions/model/ServiceBook";
import {
  Grid,
  Typography,
  ImageList,
  ImageListItem,
  RadioGroup,
  Radio,
  FormControlLabel,
  styled,
  Card,
  CardContent,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useServicebook } from "../../../../../actions/servicebook.actions";
import { CraftsmanType } from "../../../../../definitions/model/Worker";
import { ReportContext } from "./Report";

type ReportParameterProps = ServiceBookReportParameter;

const StyledCard = styled(Card)(() => ({
  // backgroundColor: theme.palette.grey[100],
  margin: 5,
}));

const GridStyled = styled(Grid)(() => ({
  paddingTop: 10,
}));

const ReportParameter = (props: ReportParameterProps) => {
  const [{ currentReport, customerState }, { setCustomerReportState }] = useServicebook();
  const context = useContext(ReportContext);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { Id } = props;

  const [itemState, setItemState] = useState<Omit<CustomerItem, "Id">>({ Diy: false, Finished: false });
  const { t } = useTranslation("translation");

  useEffect(() => {
    const item = customerState?.CustomerItems.find((x) => x.Id === Id);
    if (item) {
      setItemState({ ...item });
    }
  }, []);

  const changeCustomerState = async (reason: keyof Omit<CustomerItem, "Id">, value: boolean) => {
    if (currentReport) {
      const state = customerState || { reportId: currentReport.Id, CustomerItems: [], Consents: [] };
      const newFields = state.CustomerItems.find((x) => x.Id === Id)
        ? state.CustomerItems.map((x) => (x.Id === Id ? { ...x, [reason]: value } : x))
        : [...(state.CustomerItems || []), { Id, Diy: false, Finished: false, [reason]: value }];
      if (newFields?.length) {
        state.CustomerItems = newFields;
        setItemState((prev) => ({ ...prev, [reason]: value }));
        await setCustomerReportState(state);
      }
    }
  };

  return (
    <StyledCard variant="outlined">
      <CardContent>
        <GridStyled item xs={12}>
          <Typography variant="h5">
            {props.Label} {props.Parameter}
          </Typography>
        </GridStyled>
        <GridStyled item xs={12} container>
          <Grid item xs={12} sm={8}>
            <Typography variant="subtitle1">{t("ServiceBook.ReportList.RegistrationAndNote")}</Typography>
          </Grid>
          {!mobile && (
            <Grid item sm={4}>
              <Typography variant="subtitle1">{t("ServiceBook.ReportList.Picture")}</Typography>
            </Grid>
          )}
        </GridStyled>
        <Grid container>
          {props.Descriptions.length ? (
            props.Descriptions.map((x) => (
              <Fragment key={x.Id}>
                <GridStyled item xs={12} sm={8}>
                  <Typography variant="body1" pr={2}>
                    {x.Description}
                  </Typography>
                </GridStyled>
                <GridStyled item xs={12} sm={4}>
                  {x.Pictures.length ? (
                    <ImageList cols={1}>
                      <ImageListItem>
                        <img src={x.Pictures[0].Url} alt="" />
                      </ImageListItem>
                    </ImageList>
                  ) : (
                    <Typography variant="subtitle1">{t("ServiceBook.ReportList.No")}</Typography>
                  )}
                </GridStyled>
              </Fragment>
            ))
          ) : (
            <Typography variant="subtitle1">{t("ServiceBook.ReportList.No")}</Typography>
          )}
        </Grid>
        <Grid container>
          <Grid container item xs={12} sm={8} direction={"column"}>
            <Grid container justifyContent={"space-between"} alignItems={"center"}>
              <GridStyled item>
                <Typography variant="subtitle1">{t("ServiceBook.ReportList.Craftsmen")}</Typography>
              </GridStyled>
              <GridStyled item>
                <Typography variant="body1" pr={2}>
                  {props.MissingCraftsmen.map((x) => t(CraftsmanType.find((q) => q.key === x)?.value || ""))
                    .sort((a, b) => (a > b ? 1 : -1))
                    .join(", ")}
                </Typography>
              </GridStyled>
            </Grid>
            <Grid container justifyContent={"space-between"} alignItems={"center"}>
              <GridStyled item>
                <Typography variant="subtitle1">{t("ServiceBook.ReportList.PriceEstimate")}</Typography>
              </GridStyled>
              <GridStyled item>
                <Typography variant="body1" pr={2}>
                  {props.PriceEstimate.toLocaleString("da", {
                    currency: "DKK",
                    style: "currency",
                  })}
                </Typography>
              </GridStyled>
            </Grid>
            <Grid container justifyContent={"space-between"} alignItems={"center"}>
              <GridStyled item>
                <Typography variant="subtitle1">{t("ServiceBook.ReportList.TimeEstimate.Header")}</Typography>
              </GridStyled>
              <GridStyled item>
                <Typography variant="body1" pr={2}>
                  {t("ServiceBook.ReportList.TimeEstimate.Values." + TimeToFinish[props.TimeEstimate])}
                </Typography>
              </GridStyled>
            </Grid>
            {/* </Grid>
          <Grid container item xs={12} sm={4} direction={"column"} alignItems={"flex-end"}> */}
            <Grid container item direction={"column"}>
              <GridStyled item>
                <Typography variant="subtitle1">{t("ServiceBook.ReportList.Diy")}</Typography>
                <RadioGroup
                  row
                  defaultChecked
                  onClick={() => {
                    if (!context?.readOnly && !itemState.Finished) {
                      changeCustomerState("Diy", !itemState.Diy);
                    }
                  }}
                  value={itemState.Diy}
                >
                  <FormControlLabel
                    value={true}
                    //labelPlacement="start"
                    control={<Radio disabled={!!itemState.Finished || context?.readOnly} />}
                    label={t("General.Buttons.Yes").toLowerCase()}
                  />
                  <FormControlLabel
                    value={false}
                    //labelPlacement="start"
                    control={<Radio disabled={!!itemState.Finished || context?.readOnly} />}
                    label={t("General.Buttons.No").toLowerCase()}
                  />
                </RadioGroup>
              </GridStyled>
              <GridStyled item>
                <Typography variant="subtitle1">{t("ServiceBook.ReportList.Finished")}</Typography>
                <RadioGroup
                  row
                  defaultChecked
                  onClick={() => {
                    if (!context?.readOnly) {
                      changeCustomerState("Finished", !itemState.Finished);
                    }
                  }}
                  value={itemState.Finished}
                >
                  <FormControlLabel
                    value={true}
                    //labelPlacement="start"
                    control={<Radio disabled={context?.readOnly} />}
                    label={t("General.Buttons.Yes").toLowerCase()}
                  />
                  <FormControlLabel
                    value={false}
                    //labelPlacement="start"
                    control={<Radio disabled={context?.readOnly} />}
                    label={t("General.Buttons.No").toLowerCase()}
                  />
                </RadioGroup>
              </GridStyled>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};

export default ReportParameter;
