import React, { useEffect, useState } from "react";
import { Button, Container, Grid, IconButton, Menu, MenuItem,  styled } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { replace, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AnnouncementForm from "./Components/AnnouncementForm";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  NeighborGroupFileType,
  AnnouncementType,
  EventSubscriberDto,
  NeighborGroupAnnouncement,
  NeighborGroupAnnouncementContract,
  NeighborGroupFile,
} from "../../definitions/model/NeighborGroup";
import { useNeighborGroups } from "../../actions/neighborgroups.actions";
import { NIL } from "uuid";
import { useUser } from "../../actions";
import { useDocument } from "../../actions/document.actions";
import { FileType } from "../../definitions/Document";
import FileInput from "../../components/Base/FileInput";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const AnnouncementEditorPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { groupId, id } = useParams<{ groupId: string; id: string }>();
  const [groupState, actions] = useNeighborGroups();
  const [{ user }] = useUser();
  const [announcement, setAnnouncement] = useState<NeighborGroupAnnouncement | undefined>(undefined);
  const [, { uploadFile, deleteFile }] = useDocument();
  const [picture, setPicture] = useState<FileType | undefined>();
  const [fileList, setFileList] = useState<NeighborGroupFile[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openSetting = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeSettings = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const getAsync = async () => {
      try {
        if (groupId && id) {
          const group = await actions.getGroup(groupId);
          if (!group?.Settings.ConfirmedMember) {
            replace("/neighbor-groups");
            return;
          }
          //await actions.getGroupMembers(groupId);

          const a = group.Announcements.find((a) => a.Id === id);
          if (a) {
            setAnnouncement(a);
          }
        }
      } catch (error) {
        console.error(error);
        replace("/neighbor-groups");
      }
    };
    getAsync();
    return () => {};
  }, [groupId, id]);

  const updateAnnouncement = async (item: NeighborGroupAnnouncementContract) => {
    try {
      if (!groupId) return;
      await actions.editAnnnouncement(groupId, {
        Id: item.Id || NIL,
        Created: new Date(),
        Updated: new Date(),
        OwnerId: user?.Id || "",
        Topic: item?.Topic || "",
        Description: item?.Description || "",
        Type: item.Type || AnnouncementType.Other,
        MaxSubscribers: item.MaxSubscribers || 0,
        Subscribers: item.Subscribers || [],
        Range: item.Range || groupState.currentGroup.Range,
      });
      const announcement = groupState.currentGroup.Announcements.find((a) => a.Id === id);
      setAnnouncement(announcement);
    } catch (error) {
      console.error(error);
    }
  };

  const removeAnnouncement = async (itemId: string) => {
    try {
      if (!groupId) return;
      await actions.removeAnnnouncement(groupId, itemId);
      navigate("/neighbor-groups/" + groupId, { state: { openAnnouncements: true } });
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  };

  const goBack = async () => {
    if (!announcement?.Description && announcement) {
      await removeAnnouncement(announcement?.Id);
    }
    navigate("/neighbor-groups/" + groupId, { state: { openAnnouncements: true } });
  };

  const getPicture = async (file: File) => {
    try {
      const fileType = await uploadAnnouncementFile(file, NeighborGroupFileType.Picture);
      setPicture(fileType);
    } catch (e) {
      console.log(e);
    }
  };

  const addFile = async (file: File) => {
    try {
      const fileType = await uploadAnnouncementFile(file, NeighborGroupFileType.Document);
      if (fileType) {
        setFileList([...fileList, fileType]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const uploadAnnouncementFile = async (file: File, type: NeighborGroupFileType) => {
    try {
      if (!announcement || !groupId) return;
      const fileType = await uploadFile(file);
      if (!fileType) return;
      switch (type) {
        case NeighborGroupFileType.Picture:
          await actions.updateAnnouncementPicture(groupId, announcement.Id, fileType.Id);
          break;
        case NeighborGroupFileType.Document:
          await actions.updateAnnouncementDocuments(groupId, announcement.Id, [
            ...announcement.Documents.map((x) => x.Id),
            fileType.Id,
          ]);
          break;
      }

      // const updated = groupState.currentGroup.Announcements.find((a) => a.Id === id);
      // setAnnouncement(updated);
      return fileType;
    } catch (error) {
      console.error(error);
    }
  };

  const deleteAnnouncementFile = async (fileId: string) => {
    try {
      if (!announcement || !groupId) return;
      await deleteFile(fileId);
      await actions.updateAnnouncementDocuments(
        groupId,
        announcement.Id,
        announcement.Documents.filter((x) => x.Id !== fileId).map((x) => x.Id)
      );
      // const updated = groupState.currentGroup.Announcements.find((a) => a.Id === id);
      // setAnnouncement(updated);
    } catch (error) {
      console.error(error);
    }
  };

  const updateSubscribers = async (subscribers: EventSubscriberDto[]) => {
    try {
      if (!announcement || !groupId) return;
      const group = await actions.updateSubscribers(groupId, announcement.Id, subscribers);
      if (!group) return;
      const updated = group.Announcements.find((a) => a.Id === id);
      setAnnouncement(updated);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {/* <Container maxWidth="md">
        <Box mb={4}>
          <Typography variant="body1">{t("NeighborGroups.NeighborGroupsTeaserText")}</Typography>
        </Box>
      </Container> */}
      <Grid container sx={(theme) => ({ color: theme.palette.primary.dark })} justifyContent="space-between">
        {(window.history.state.idx !== 0) && (
          <Button onClick={goBack} sx={{ marginBottom: "10px" }}>
            <ArrowBack fontSize="small" />
            {t("General.Buttons.Back")}
          </Button>
        )}
        <Grid item>
          <IconButton color="secondary" onClick={openSetting}>
            <SettingsOutlinedIcon />
          </IconButton>
          <Menu open={open} onClose={closeSettings} anchorEl={anchorEl}>
            <MenuItem>
              <FileInput getPicture={getPicture}>
                <Grid>{t("General.Buttons.ChangePicture")}</Grid>
              </FileInput>
            </MenuItem>
            <StyledMenuItem>
              <FileInput getPicture={addFile}>
                <Grid>{t("General.Buttons.AttachDoc")}</Grid>
              </FileInput>
            </StyledMenuItem>
            <hr />
            <MenuItem
              onClick={() => {
                if (announcement?.Id) {
                  removeAnnouncement(announcement?.Id);
                }
              }}
              style={{ color: "red" }}
            >
              {t("General.Buttons.Delete")}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      <Container maxWidth="md">
        <AnnouncementForm
          addOrUpdate={updateAnnouncement}
          announcement={announcement}
          type={announcement?.Type || AnnouncementType.Other}
          uploadFile={uploadAnnouncementFile}
          deleteFile={deleteAnnouncementFile}
          updateSubscribers={updateSubscribers}
          goBack={goBack}
          picture={picture}
          fileList={fileList}
          setFileList={setFileList}
        />
      </Container>
      </>
  );
};

export default AnnouncementEditorPage;
