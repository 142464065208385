import React from "react";
import ReactDOM from "react-dom/client";
import { isReactComponent } from ".";
import {
  GrapesEditor,
  GrapesTypes,
  LoadComponentOptions,
} from "../../defenitions";

export default function (
  editor: GrapesEditor,
  options: LoadComponentOptions
): void {
  const domComponents = editor.DomComponents;
  const blockManager = editor.BlockManager;
  const defaultType = domComponents.getType("default");
  const { model, view } = defaultType;
  const compName = "EmailEntityField";

  blockManager.add(GrapesTypes.EmailEntityFieldComponent, {
    label: "Entity field",
    category: options.categoryLabel,
    attributes: { class: "fa fa-gears" },
    textable: 1,
    content: `<span data-gjs-type="${GrapesTypes.EmailEntityFieldComponent}"></span>`,
  });

  const UserFields = ["Name", "Email", "Address", "Phone"];
  const UnitFields = [
    "PropertyType",
    "AddressString",
    "Address.City",
    "Address.DoorNumber",
    "Address.Floor",
    "Address.HouseNo",
    "Address.PostalCode",
    "Address.Street",
  ];
  const LeadFields = [
    "Name",
    "Email",
    "Phone",
    "LandingName",
    "UtmSource",
    "Address",
    "City",
    "Organization",
  ];
  const CraftsmanFields = [
    "Email",
    "Organization",
    "CVR",
    "Phone",
    "Mobile",
    "Address",
    "PostCode",
    "City",
    "FirstName",
    "LastName",
    "TravelRange",
    "Rating",
  ];

  const traits = [
    {
      type: "select",
      label: "Etity",
      changeProp: 1,
      name: "entity",
      typeid: 0,
      options: [
        { id: "Craftsman", name: "Craftsman" },
        { id: "Lead", name: "Lead" },
        { id: "Unit", name: "Unit" },
        { id: "User", name: "User" },
      ],
    },
    {
      type: "select",
      label: "Field",
      changeProp: 1,
      name: "field",
      typeid: 0,
      options: [],
    },
  ];
  const subscriptions = traits.map((trait) => trait.name);

  const ReinitFieldTraits = (entity: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const component = editor.getSelected() as any;
    let options: Array<{ id: string; name: string }> = [];
    switch (entity) {
      case "Unit":
        options = UnitFields.sort().map((f) => ({ id: f, name: f }));
        break;
      case "User":
        options = UserFields.sort().map((f) => ({ id: f, name: f }));
        break;
      case "Craftsman":
        options = CraftsmanFields.sort().map((f) => ({ id: f, name: f }));
        break;
      case "Lead":
        options = LeadFields.sort().map((f) => ({ id: f, name: f }));
        break;
    }

    component.getTrait("field").set("options", options);
  };

  domComponents.addType(GrapesTypes.EmailEntityFieldComponent, {
    isComponent(el: HTMLElement) {
      if (
        (el.getAttribute &&
          el.getAttribute("data-gjs-type") ===
            GrapesTypes.EmailEntityFieldComponent) ||
        (el.attributes &&
          (el.attributes as any)["data-gjs-type"] ===
            GrapesTypes.EmailEntityFieldComponent) ||
        isReactComponent(el, compName)
      ) {
        return {
          type: GrapesTypes.EmailEntityFieldComponent,
        };
      }
    },
    model: {
      defaults: {
        ...model.prototype.defaults,
        droppable: false,
        draggable: true,
        entity: "",
        field: "",
        traits: [...model.prototype.defaults.traits, ...traits],
      },
    },
    view: view.extend({
      init() {
        subscriptions.forEach((subscription) => {
          this.listenTo(
            this.model,
            `change:${subscription}`,
            this.handleChanges
          );
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      handleChanges(e: any, value: any) {
        const root = ReactDOM.createRoot(this.el);
        root.unmount();
        if (e.changed.entity) {
          ReinitFieldTraits(value);
        }
        this.render();
      },
      onRender() {
        const { el } = this;
        const comps = this.model.get("components");
        const { entity, field } = this.model.attributes;
        comps.reset();
        const compString = `<span>\${%[?(@.TypeName == '${entity}')].${field}%}</span>`;
        comps.add(compString);
        const root = ReactDOM.createRoot(el);

        root.render(<span>{`\${%[?(@.TypeName == '${entity}')].${field}%`}</span>);
      },
    }),
  });
}
