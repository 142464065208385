import { Reducer } from "redux";
import { AppAction } from "../../../../definitions/Action";
import { NavigationItem } from "../../../../definitions/Menu";
import { AdminPartnersActionTypes } from "./admin.partners.actiontypes";

export interface AdminPartnersStoreState {
  partnersItems: NavigationItem[];
  currentItem: NavigationItem | undefined;
  loading: boolean;
  error: string | null;
}

export const initialState: AdminPartnersStoreState = {
  partnersItems: [],
  currentItem: undefined,
  loading: false,
  error: null,
};

const partnersAdminReducer: Reducer<AdminPartnersStoreState, AppAction> = (
  state: AdminPartnersStoreState = initialState,
  action: AppAction
): AdminPartnersStoreState => {
  switch (action.type) {
    case AdminPartnersActionTypes.PARTNERS_OPERATION_FAILED:
      return { ...state, loading: false, error: action.payload };
    case AdminPartnersActionTypes.GET_PARTNERS_ITEM:
    case AdminPartnersActionTypes.GET_PARTNERS_ITEMS:
    case AdminPartnersActionTypes.DELETE_PARTNERS_ITEM:
    case AdminPartnersActionTypes.REORDER_PARTNERS_ITEMS:
    case AdminPartnersActionTypes.SET_PARTNERS_ITEM_PUBLISH:
    case AdminPartnersActionTypes.UPDATE_PARTNERS_ITEM:
    case AdminPartnersActionTypes.CREATE_PARTNERS_ITEM:
      return { ...state, loading: true };
    case AdminPartnersActionTypes.CREATE_PARTNERS_ITEM_SUCCEEDED:
      return {
        ...state,
        partnersItems: [...state.partnersItems, action.payload],
      };
    case AdminPartnersActionTypes.GET_PARTNERS_ITEMS_SUCCEEDED:
      return {
        ...state,
        partnersItems: action.payload,
      };
    case AdminPartnersActionTypes.GET_PARTNERS_ITEM_SUCCEEDED:
      return {
        ...state,
        currentItem: state.partnersItems.find(
          (item) => item.Id === action.payload
        ),
      };
    case AdminPartnersActionTypes.DELETE_PARTNERS_ITEM_SUCCEEDED:
      return {
        ...state,
        partnersItems: state.partnersItems
          .filter((item) => item.Id !== action.payload)
          .map((item, index) => ({
            ...item,
            OrderNumber: index,
          })),
      };
    case AdminPartnersActionTypes.REORDER_PARTNERS_ITEMS_SUCCEEDED:
      return {
        ...state,
        partnersItems: action.payload,
      };
    case AdminPartnersActionTypes.SET_PARTNERS_ITEM_PUBLISH_SUCCEEDED:
    case AdminPartnersActionTypes.UPDATE_PARTNERS_ITEM_SUCCEEDED:
      return {
        ...state,
        partnersItems: state.partnersItems.map((item) => {
          if (item.Id === action.payload.Id) {
            return action.payload;
          }
          return item;
        }),
      };
    default:
      return state;
  }
};

export default partnersAdminReducer;
