import ApiService from "./api.service";
import { GroupMembersDto } from "../definitions/model/Group";
import User from "../definitions/model/User";
// import { handleUser } from "./user.service";

const FamilyService = {
  getUserFamily: async (unitId: string | null): Promise<GroupMembersDto[]> => {
    const url = `/api/users/family/${unitId}`;
    const result = await ApiService.get<GroupMembersDto[]>(url);
    return result;
  },
  getUserOneFamily: async (unitId: string): Promise<GroupMembersDto> => {
    const url = `/api/users/family/${unitId}/one`;
    const result = await ApiService.get<GroupMembersDto>(url);
    return result;
  },
  assingNewFamilyOwner: async (familyId: string, newFamilyOwnerId: string): Promise<void> => {
    const url = `/api/users/family/${familyId}/${newFamilyOwnerId}/owner`;
    await ApiService.put(url, {});
  },
  sendFamilyJoinRequest: async (unitId: string): Promise<User> => {
    const url = `/api/users/family`;
    const result = await ApiService.post<User>(url, {
      UnitId: unitId,
    });

    // handleUser(result);
    return result;
  },
  acceptFamilyJoinRequest: async (userId: string, unitId: string): Promise<GroupMembersDto> => {
    const url = `/api/users/family/acceptRequest/${userId}/${unitId}`;
    const result = await ApiService.put<GroupMembersDto>(url, {});
    return result;
  },
  declineFamilyJoinRequest: async (userId: string, unitId: string): Promise<GroupMembersDto> => {
    const url = `/api/users/family/declineRequest/${userId}/${unitId}`;
    const result = await ApiService.put<GroupMembersDto>(url, {});
    return result;
  },
  removeFamilyMember: async (userId: string, unitId: string, buildingGroupId?: string): Promise<GroupMembersDto> => {
    let url = `/api/users/family/${userId}/${unitId}`;
    if (buildingGroupId) url += `/${buildingGroupId}`;
    const result = await ApiService.delete<GroupMembersDto>(url);
    return result;
  },
  acceptInviteMember: async (unitId: string, token: string) => {
    const url = `/api/users/${unitId}/members/${token}`;
    return await ApiService.put(url, null);
  },
};

export default FamilyService;
