import { NavigationActionTypes } from "./navigation.actiontypes";
import { AppAction, AppActionThunk } from "../../definitions/Action";
import { NavigationItem, PartnersItem } from "../../definitions/Menu";
import NavigationService from "./navigation.service";
import { UserRating } from "../../definitions/model/User";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../reducers/store";
import { operationFailedActionGeneral, useAppDispatch } from "../../actions";

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(
    payload,
    NavigationActionTypes.NAVIGATION_OPERATION_FAILED
  );
};

const useNavigationActions = () => {
  const dispatch = useAppDispatch();
  return {
    getNavigationItems: (userRating?: UserRating) =>
      dispatch(getNavigationItems(userRating)),
    getPartnersItems: () => dispatch(getPartnersItems()),
  };
};

const useNavigationState = () =>
  useSelector((state: ApplicationState) => state.navigation);

  export const useNavigation = ():[ReturnType<typeof useNavigationState>, ReturnType<typeof useNavigationActions>] => {
    const state = useNavigationState();
    const actions = useNavigationActions();
    return [state, actions]
  }

export const getNavigationItems =
  (
    userRating?: UserRating
  ): AppActionThunk<Promise<NavigationItem[] | undefined>> =>
  async (dispatch) => {
    try {
      dispatch({ type: NavigationActionTypes.GET_NAVIGATION_ITEMS });
      const result = await NavigationService.getNavigationItems();
      dispatch({
        type: NavigationActionTypes.GET_NAVIGATION_ITEMS_SUCCEEDED,
        payload: {
          items: result,
          userRating,
        },
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getPartnersItems =
  (): AppActionThunk<Promise<PartnersItem[] | undefined>> =>
  async (dispatch) => {
    try {
      dispatch({ type: NavigationActionTypes.GET_PARTNERS_ITEMS });
      const result = await NavigationService.getPartnersItems();
      dispatch({
        type: NavigationActionTypes.GET_PARTNERS_ITEMS_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };
