import User from "../definitions/model/User";

export const handleResponse = <T>(response: Response): Promise<T> =>
  new Promise((resolve, reject) => {
    if (response.ok) {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        response.json().then(resolve);
      } else {
        resolve({} as T);
      }
    } else {
      response.text().then(reject);
    }
  });

export const handleError = (error: any) => {
  return Promise.reject(error && error.message);
};

let refreshTokenPromise: Promise<User> | null;
export const headers = async () => {
  const headerOptions: any = {
    "Content-Type": "application/json",
  };

  const user = localStorage.getItem("user");

  if (user !== null) {
    let parsedUser = JSON.parse(user) as User;

    if (parsedUser) {
      const tryUser = localStorage.getItem("tryUser");
      const parsedTryUser = tryUser ? (JSON.parse(tryUser) as User) : null;
      parsedUser = parsedTryUser || parsedUser;

      let accessToken = parsedUser.Token;

      const base64Url = accessToken.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      const decoded = JSON.parse(jsonPayload);
      const exp = new Date(decoded.exp * 1000);

      if (exp < new Date(Date.now() - 5 * 600)) {
        if (!refreshTokenPromise) {
          refreshTokenPromise = refreshToken().then((r) => {
            refreshTokenPromise = null; // clear state
            return r; // resolve with the new token
          });
        }

        refreshTokenPromise
          .then((user) => {
            accessToken = user.Token;
            localStorage.setItem("user", JSON.stringify(user));
          })
          .catch(() => {
            localStorage.removeItem("user");
            accessToken = "";
          });
      }

      headerOptions["Authorization"] = `Bearer ${accessToken}`;
    }
  }

  return headerOptions;
};

const refreshToken = async (): Promise<User> => {
  const refreshResponse = await fetch("/api/users/refresh-token", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  });
  if (refreshResponse.status === 200) {
    const newUser = await refreshResponse.json();
    return newUser;
  } else {
    throw "error";
  }
};
