import React from "react";
import { Skeleton } from '@mui/material';
import { Grid } from "@mui/material";
import { repeatSkeleton } from "../../../../../components/Base/DetailsSkeleton";

const ReportSkeleton = () => {
  return (
    <Grid
      container
      style={{ marginTop: 20 }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Skeleton width="100%" variant="rectangular" height={333} />
        <Skeleton variant="text" width="100%" height={100} />
      </Grid>
      <Grid item>
        <Skeleton variant="text" height={30} width={100} />
      </Grid>
      <Grid item>
        <Skeleton variant="text" height={60} width={80} />
      </Grid>
      <Grid item xs={12}>
        {repeatSkeleton({variant: "text", height: 50}, 3)}
      </Grid>
      <Grid item container justifyContent="flex-end">
        <Grid item>
          <Skeleton variant="text" height={60} width={80} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportSkeleton;
