import React from "react";
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type RatingDialogProps = {
  open: boolean;
  handleClose: () => void;
};

const AddToHomeScreenDialog = (props: RatingDialogProps): JSX.Element => {
  const { t } = useTranslation("translation");

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">
        {t("Base.Dialog.AddToHomeScreenTitle")}
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Typography>{t("Base.Dialog.OpenBrowserMenu")}</Typography>
          <Typography>
            {t("Base.Dialog.SelectItem")} {'"'} {t("Base.Dialog.Install")}
            {'"'} {t("Base.Dialog.Or").toLowerCase()} {'"'}{" "}
            {t("Base.Dialog.AddToHomePage")}
            {'"'}
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.handleClose}
          variant="contained"
          color="secondary"
        >
          {t("General.Buttons.Undo")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddToHomeScreenDialog;
