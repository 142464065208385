import { Box, Grid, useTheme } from "@mui/material";
import React from "react";
import TopBar from "../../components/Layout/TopBar";
import Footer from "../../components/Layout/Footer";

type LayoutProps = {
  children: React.ReactNode;
};

const MyClimatePlanPageLayout = ({ children }: LayoutProps): JSX.Element => {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex" }}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        style={{
          minHeight: "100vh",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <TopBar startPage hideAuth />
        {children}
        <Footer />
      </Grid>
    </Box>
  );
};

export default MyClimatePlanPageLayout;
