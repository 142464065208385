import { CircularProgress, Grid, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentSourceType } from "../../../definitions/Document";

const HiddenInput = styled("input")({
  display: "none",
});

type FileUploadInputProps = {
  uploadFiles: (files: File[]) => Promise<void>;
  loading?: boolean;
  sourceType: DocumentSourceType;
};

const FileUploadInput = (props: FileUploadInputProps) => {
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation("translation");
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  const showFileUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event && event.currentTarget && event.currentTarget.files && event.currentTarget.files.length) {
      const files = event.currentTarget.files;
      if (files.length) {
        try {
          setLoading(true);
          await props.uploadFiles(Array.from(files));
        } finally {
          setLoading(false);
        }
      }
    }
  };

  return (
    <Grid container justifyContent={"center"} alignItems={"center"} m={1}>
      {(loading || props.loading) && (
        <Grid item mr={1} sx={{ display: "flex" }}>
          <CircularProgress size={20} />
        </Grid>
      )}

      <Grid item onClick={showFileUpload}>
        <Typography sx={{ textDecoration: loading || props.loading ? "none" : "underline" }}>
          {loading || props.loading
            ? t("Documents.Processing")
            : isXs
            ? t("Documents.ClickForUploadFiles", {
                destination:
                  props.sourceType === DocumentSourceType.Unit
                    ? t("Documents.DocumentsDestination.House")
                    : t("Documents.DocumentsDestination.Building"),
              })
            : t("Documents.DragDropFiles", {
                destination:
                  props.sourceType === DocumentSourceType.Unit
                    ? t("Documents.DocumentsDestination.House")
                    : t("Documents.DocumentsDestination.Building"),
              })}
        </Typography>
        <HiddenInput ref={inputRef} type="file" multiple onChange={uploadFile} />
      </Grid>
    </Grid>
  );
};

export default FileUploadInput;
