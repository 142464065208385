import {
  Card,
  CardContent,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ServiceBookReportParameter, TimeToFinish } from "../../../../definitions/model/ServiceBook";
import { CraftsmanType } from "../../../../definitions/model/Worker";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useServicebook } from "../../../../actions/servicebook.actions";

type SummaryItem = {
  competencies: (number | string)[] | null;
  rowTotal: number;
  tasks: ServiceBookReportParameter[];
  [TimeToFinish.Now]: number;
  [TimeToFinish.Undefined]: number;
  [TimeToFinish.OneToThreeYears]: number;
  [TimeToFinish.ThreeToFiveYears]: number;
  diy: number;
  finished: number;
};

type Summary = {
  [key: string]: SummaryItem;
};

const ReportSummary = () => {
  const { t } = useTranslation("translation");
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const [{ currentReport: report, offers: taskLists, customerState }] = useServicebook();

  const [summary, setSummary] = useState<Summary>({
    Total: {
      competencies: null,
      tasks: [],
      [TimeToFinish.Undefined]: 0,
      [TimeToFinish.Now]: 0,
      [TimeToFinish.OneToThreeYears]: 0,
      [TimeToFinish.ThreeToFiveYears]: 0,
      diy: 0,
      finished: 0,
      rowTotal: 0,
    },
  });

  const calculateSummary = () => {
    const sum: Summary = {
      Total: {
        competencies: null,
        tasks: [],
        [TimeToFinish.Undefined]: 0,
        [TimeToFinish.Now]: 0,
        [TimeToFinish.OneToThreeYears]: 0,
        [TimeToFinish.ThreeToFiveYears]: 0,
        diy: 0,
        finished: 0,
        rowTotal: 0,
      },
    };

    const tasks = taskLists
      .flatMap((x) => x.Tasks)
      .filter((task, i, arr) => arr.findIndex((t) => t.Id === task.Id) === i);

    report?.Objects.forEach((o) => {
      o.Sections.forEach((s) => {
        s.Parameters.forEach((p) => {
          p.MissingCraftsmen.forEach((c) => {
            const key = c;
            //code below is commented due to the requirement of https://dev.azure.com/cdmua/Klimaplan-myHouse/_boards/board/t/Klimaplan-myHouse%20Team/Stories/?workitem=1403
            //the sum is caclulated wrong because the combined craftsman tasks aren't split

            //   const key = p.MissingCraftsmen.sort((a, b) =>
            //     a > b ? 1 : -1
            //   ).reduce((a, c) => a + c, "");

            if (!Object.hasOwn(sum, key)) {
              sum[key] = {
                //https://dev.azure.com/cdmua/Klimaplan-myHouse/_boards/board/t/Klimaplan-myHouse%20Team/Stories/?workitem=1403
                competencies: [key],
                tasks: s.Parameters.filter((x) => x.MissingCraftsmen.find((cr) => cr == key)) || [],
                [TimeToFinish.Undefined]: 0,
                [TimeToFinish.Now]: 0,
                [TimeToFinish.OneToThreeYears]: 0,
                [TimeToFinish.ThreeToFiveYears]: 0,
                diy: 0,
                finished: 0,
                rowTotal: 0,
              };
            }

            if (customerState?.CustomerItems.some((x) => x.Id === p.Id && x.Diy)) {
              sum[key].diy += p.PriceEstimate;
              sum[key][p.TimeEstimate] -= p.PriceEstimate;
            }

            const totalParams = report.Objects.flatMap((object) =>
              object.Sections.flatMap((section) => section.Parameters)
            );

            const keyTotal = totalParams.filter((p) => p.MissingCraftsmen.includes(key));
            if (keyTotal.length) {
              const finishedCount =
                keyTotal.filter((p) => customerState?.CustomerItems.some((c) => c.Id === p.Id && c.Finished))?.length ||
                0;
              sum[key].finished = finishedCount / keyTotal.length;
            }

            switch (p.TimeEstimate) {
              case TimeToFinish.Now:
                sum[key][TimeToFinish.Now] += p.PriceEstimate;
                break;
              case TimeToFinish.OneToThreeYears:
                sum[key][TimeToFinish.OneToThreeYears] += p.PriceEstimate;
                break;
              case TimeToFinish.ThreeToFiveYears:
                sum[key][TimeToFinish.ThreeToFiveYears] += p.PriceEstimate;
                break;
            }

            sum[key].rowTotal += p.PriceEstimate;
            if (customerState?.CustomerItems.some((x) => x.Id === p.Id && x.Diy)) {
              sum["Total"].diy += p.PriceEstimate;
              sum["Total"][p.TimeEstimate] -= p.PriceEstimate;
            }

            const totalFinishedCount =
              totalParams.filter((p) => customerState?.CustomerItems.some((c) => c.Id === p.Id && c.Finished))
                ?.length || 0;
            sum["Total"].finished = totalFinishedCount / totalParams.length;

            switch (p.TimeEstimate) {
              case TimeToFinish.Now:
                sum["Total"][TimeToFinish.Now] += p.PriceEstimate;
                break;
              case TimeToFinish.OneToThreeYears:
                sum["Total"][TimeToFinish.OneToThreeYears] += p.PriceEstimate;
                break;
              case TimeToFinish.ThreeToFiveYears:
                sum["Total"][TimeToFinish.ThreeToFiveYears] += p.PriceEstimate;
                break;
            }

            sum["Total"].rowTotal += p.PriceEstimate;
          });
        });
      });
    });
    setSummary(sum);
  };

  useEffect(() => {
    if (report) {
      calculateSummary();
    }

    return () => {};
  }, [report?.Id, customerState?.CustomerItems]);

  return (
    <Grid item container direction="column">
      <Card variant="outlined">
        <CardContent>
          <Grid item xs={12}>
            <Typography variant="h3">{t("ServiceBook.Summary.Title")}</Typography>
          </Grid>
          <Grid item xs={12}>
            {!isXs ? (
              <>
                <Grid container item>
                  <Grid item xs={3}>
                    <Typography variant="h6">{t("ServiceBook.Craftsman", { count: 5 })}</Typography>
                  </Grid>
                  <Grid item sm={1.6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Typography variant="h6">{t("ServiceBook.Summary.Total")}</Typography>
                  </Grid>
                  <Grid item sm={1.6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Typography variant="h6">{t("ServiceBook.Summary.Now")}</Typography>
                  </Grid>
                  <Grid item sm={1.6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Typography variant="h6">{t("ServiceBook.Summary.1to3")}</Typography>
                  </Grid>
                  <Grid item sm={1.6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Typography variant="h6">{t("ServiceBook.Summary.3to5")}</Typography>
                  </Grid>
                  <Grid item sm={1.6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Typography variant="h6">{t("ServiceBook.Summary.Diy")}</Typography>
                  </Grid>
                  <Grid item sm={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Typography variant="h6">{t("ServiceBook.Summary.Finished")}</Typography>
                  </Grid>
                </Grid>
                <Divider variant="middle" sx={{ marginBottom: 1 }} />
                {Object.values(summary)
                  .filter((x) => x.competencies)
                  .sort((a, b) => {
                    return t(CraftsmanType.find((q) => q.key === a.competencies![0])?.value as string) >
                      t(CraftsmanType.find((q) => q.key === b.competencies![0])?.value as string)
                      ? 1
                      : -1;
                  })
                  .map((x, i) => (
                    <SummaryItemDesktopLayout key={i} {...x} />
                  ))}
                <Divider variant="middle" sx={{ marginBottom: 1 }} />
                <SummaryItemDesktopLayout
                  boldTitle
                  {...summary["Total"]}
                  competencies={[t("ServiceBook.Summary.Total") ?? ""]}
                />
              </>
            ) : (
              <>
                {Object.values(summary)
                  .filter((x) => x.competencies)
                  .sort((a, b) => {
                    return t(CraftsmanType.find((q) => q.key == a.competencies![0])?.value as string) >
                      t(CraftsmanType.find((q) => q.key == b.competencies![0])?.value as string)
                      ? 1
                      : -1;
                  })
                  .map((x, i) => (
                    <>
                      <SummaryItemMobileLayout key={i} {...x} />
                      <Divider variant="middle" sx={{ marginBottom: 1 }} />
                    </>
                  ))}
                <SummaryItemMobileLayout {...summary["Total"]} competencies={[t("ServiceBook.Summary.Total") ?? ""]} />
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ReportSummary;

const SummaryItemDesktopLayout = (props: SummaryItem & { boldTitle?: boolean }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <Grid container item>
      <Grid item xs={3} container>
        <Grid item xs={10}>
          <Typography variant={props.boldTitle ? "h6" : "body1"} paddingTop={"2px"}>
            {props.competencies
              ?.map((c) =>
                CraftsmanType.find((q) => q.key == c)?.value
                  ? t(CraftsmanType.find((q) => q.key == c)?.value as string)
                  : c
              )
              .join(", ")}
          </Typography>
        </Grid>
        {props.tasks.length ? (
          <Grid item xs={1}>
            <IconButton onClick={() => setOpen(!open)}>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
          </Grid>
        ) : null}
      </Grid>

      <Grid item xs={12} sm={1.6} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography variant="body1" gutterBottom>
          {props.rowTotal.toLocaleString("da-DK", {
            currency: "DKK",
            style: "currency",
          })}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={1.6} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography variant="body1" gutterBottom>
          {props[TimeToFinish.Now].toLocaleString("da-DK", {
            currency: "DKK",
            style: "currency",
          })}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={1.6} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography variant="body1" gutterBottom>
          {props[TimeToFinish.OneToThreeYears].toLocaleString("da-DK", {
            currency: "DKK",
            style: "currency",
          })}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={1.6} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography variant="body1" gutterBottom>
          {props[TimeToFinish.ThreeToFiveYears].toLocaleString("da-DK", {
            currency: "DKK",
            style: "currency",
          })}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={1.6} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography variant="body1" gutterBottom>
          {props.diy.toLocaleString("da-DK", {
            currency: "DKK",
            style: "currency",
          })}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography variant="body1" gutterBottom>
          {props.finished.toLocaleString("da-DK", {
            style: "percent",
          })}
        </Typography>
      </Grid>
      <Collapse in={open}>
        {props.tasks.map((x) => (
          <Typography ml={1} variant="body2" key={x.Id}>
            {x.Label + " " + x.Parameter}
          </Typography>
        ))}
      </Collapse>
    </Grid>
  );
};

const SummaryItemMobileLayout = (props: SummaryItem) => {
  const { t } = useTranslation();
  return (
    <Grid container item>
      <Grid item xs={12}>
        <Typography variant="h6">
          {props.competencies
            ?.map((c) =>
              CraftsmanType.find((q) => q.key == c)?.value
                ? t(CraftsmanType.find((x) => x.key == c)?.value as string)
                : c
            )
            .join(", ")}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">{t("ServiceBook.Summary.Total")}</Typography>
      </Grid>
      <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography variant="body1">
          {props.rowTotal.toLocaleString("da-DK", {
            currency: "DKK",
            style: "currency",
          })}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">{t("ServiceBook.Summary.Now")}</Typography>
      </Grid>
      <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography variant="body1">
          {props[TimeToFinish.Now].toLocaleString("da-DK", {
            currency: "DKK",
            style: "currency",
          })}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">{t("ServiceBook.Summary.1to3")}</Typography>
      </Grid>
      <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography variant="body1">
          {props[TimeToFinish.OneToThreeYears].toLocaleString("da-DK", {
            currency: "DKK",
            style: "currency",
          })}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">{t("ServiceBook.Summary.3to5")}</Typography>
      </Grid>
      <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography variant="body1">
          {props[TimeToFinish.ThreeToFiveYears].toLocaleString("da-DK", {
            currency: "DKK",
            style: "currency",
          })}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">{t("ServiceBook.Summary.Diy")}</Typography>
      </Grid>
      <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography variant="body1">
          {props.diy.toLocaleString("da-DK", {
            currency: "DKK",
            style: "currency",
          })}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">{t("ServiceBook.Summary.Finished")}</Typography>
      </Grid>
      <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography variant="body1">
          {props.finished.toLocaleString("da-DK", {
            style: "percent",
          })}
        </Typography>
      </Grid>
    </Grid>
  );
};
