import ApiService from "./api.service";
import User, {
  UserProfileDTO,
  UserSettingsDTO,
  CompleteRegistrationData,
  UserInterestModel,
  UserRegistrationDTO,
} from "../definitions/model/User";
import { headers } from "./http.service";
import { UserSettingsHistoryModel } from "../definitions/model/UserSettings";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import WinChan from "winchan";
import { UserInterest } from "../constants/enums";
import { UserType } from "../constants/enums";
import { SubscriptionModel } from "../definitions/model/Subscriptions";
import Unit from "../definitions/model/unit/Unit";
import {
  AdminInfoAggregate,
  AdminInfoGroup,
  AdminUserinfo,
  isEmail,
} from "../pages/AdminPanel/pages/AggregateViewPage/AggregateViewPage";
import { LanguageType } from "../definitions/Menu";
import { Building } from "../definitions/model/Building";
import { UserLogMessage } from "../definitions/model/UserMail";

const UserService = {
  login: async (email: string, password: string): Promise<User> => {
    return handleUser(
      await ApiService.post<User>("/api/users/authenticate", {
        email,
        password,
      })
    );
  },
  getCurrentServerUser: async (): Promise<{
    User: User;
    MainUser: User | null;
    Unit: Unit | null;
    PendingUnit: Unit | null;
  } | null> => {
    const userData = await ApiService.get<{
      User: User;
      MainUser: User | null;
      Unit: Unit | null;
      PendingUnit: Unit | null;
    }>("/api/users/current");
    if (!userData || !userData?.User || Object.keys(userData.User).length === 0) return null;

    if (userData.MainUser) {
      handleUser(userData.MainUser);
      handleTryUser(userData.User);
    } else {
      handleUser(userData.User);
    }
    return userData;
  },
  openFacebookDialog: (
    //dispatch: Dispatch<any>,
    isregister = false
  ) => {
    const chrome = 100,
      width = 800,
      height = 500;
    const left = (window.screen.width - width) / 2;
    const topValue = (window.screen.height - height - chrome) / 2;
    const options = `status=0,toolbar=0,location=1,resizable=1,scrollbars=1,left=${left},top=${topValue},width=${width},height=${height}`;
    const url = `/api/users/authenticate/external?returnUrl=${encodeURIComponent(window.location.href)}`;
    return new Promise<{ facebookId: string; isregister: boolean }>((resolve, reject) => {
      WinChan.open(
        {
          url: url,
          //   relay_url: `/api/account?isPersistent=${request.isPersistent}`,
          window_name: "_blank",
          window_features: options,
        },
        (error: any, response: any) => {
          if (error) {
            switch (error) {
              case "User closed the popup window":
                console.log(error);
                reject("Log ind aflyst af en bruger");
                break;
              case "unsupported browser":
              case "client closed window":
                console.log(error);
                reject(error);
                break;
              default:
                break;
            }
          } else {
            const facebookId = response;

            resolve({ facebookId, isregister });
          }
        }
      );
    });
  },
  authenticateFacebookUser: async (fKey: string): Promise<User> => {
    return handleUser(
      await ApiService.post<User>("/api/users/authenticatFacebookUser", {
        FKey: fKey,
      })
    );
  },
  isFacebookUserExist(fkey: string): Promise<boolean> {
    return ApiService.get<boolean>(`/api/users/facebookUserExist/${fkey}`);
  },
  getCurrentUser: getCurrentUser,
  logout: (): void => {
    const tempLang = localStorage.getItem("language");
    const pwaInstalled = localStorage.getItem("pwaInstalled");
    localStorage.clear();
    localStorage.setItem("language", tempLang || "da");
    localStorage.setItem("pwaInstalled", pwaInstalled || "0");
    return;
  },

  register: async (userData: UserRegistrationDTO): Promise<User> => {
    return handleUser(await ApiService.put<User>("/api/users", userData));
  },

  completeRegistration: async (userData: CompleteRegistrationData): Promise<User> => {
    return handleUser(await ApiService.put<User>("/api/users/completeregistration", userData));
  },

  getAdministrators: async (): Promise<User[]> => {
    return await ApiService.get<User[]>("/api/admin/users/admins");
  },

  leaveAllCommunes: async (id: string): Promise<User> => {
    return handleUser(await ApiService.delete<User>(`/api/users/${id}/communes`, null));
  },

  changeUserRole: async (userId: string, role: UserType, communeNumber: number): Promise<void> => {
    await ApiService.post("/api/admin/users/changeUserRole", {
      UserId: userId,
      Role: role,
      CommuneNumber: communeNumber,
    });
  },

  getUsersByEmailPart: async (filter: string): Promise<User[]> => {
    return await ApiService.get<User[]>(`/api/admin/users/byEmailPart?filter=${filter}`);
  },

  getUserSettingsHistory: async (signal?: AbortSignal): Promise<UserSettingsHistoryModel> => {
    return await ApiService.get<UserSettingsHistoryModel>(`/api/users/history`, null, signal);
  },

  getUserSettings: async (signal?: AbortSignal): Promise<UserSettingsDTO> => {
    return await ApiService.get<UserSettingsDTO>(`/api/users/settings`, null, signal);
  },

  updateUserProfile: async (userProfileData: UserProfileDTO): Promise<User> => {
    return handleUser(await ApiService.put<User>("/api/users/updateprofile", userProfileData));
  },

  updateUserLanguage: async (language: LanguageType): Promise<User> => {
    return handleUser(await ApiService.put<User>("/api/users/updatelanguage", { Language: language }));
  },

  updateNotificationSettigns: async (
    notifications: boolean,
    receiveCalls: boolean,
    facebookNotifications: boolean
  ): Promise<UserSettingsDTO> => {
    return await ApiService.put<UserSettingsDTO>(`/api/users/notifications`, {
      ReceiveNotifications: notifications,
      ReceiveCalls: receiveCalls,
      ReceiveFacebookNotifications: facebookNotifications,
    });
  },

  changeUserPassword: async (password: string): Promise<any> => {
    return await ApiService.put<any>(`/api/users/changepassword`, { password });
  },

  resetPassword: async (email: string): Promise<any> => {
    return await ApiService.put<any>(`/api/users/resetpassword`, {
      Email: email,
    });
  },

  confirmPassword: async (token: string, newPassword: string): Promise<any> => {
    return await ApiService.put<any>(`/api/users/confirmpassword`, {
      Token: token,
      NewPassword: newPassword,
    });
  },

  tokenValidate: async (token: string): Promise<any> => {
    return await ApiService.post<any>(`/api/users/validate-password-reset-token`, {
      Token: token,
    });
  },

  getUserTheme: (): string | null => {
    return localStorage.getItem("user-theme");
  },

  setUserTheme: (themeName: string | null): void => {
    if (themeName) localStorage.setItem("user-theme", themeName);
    else localStorage.removeItem("user-theme");
  },

  updateLocalStorageUser: (user: User): void => {
    localStorage.setItem(
      "user",
      JSON.stringify({
        ...user,
      })
    );
  },

  moveInToProperty: async (unitId: string, ignoreNotifications: boolean): Promise<User> => {
    const url = "/api/users/movein/unit";
    const moveinRequest = {
      UnitId: unitId,
      IgnoreNotifications: ignoreNotifications,
    };
    const user = await ApiService.post<User>(url, moveinRequest);
    //save new user data to update Address in localstorage
    handleUser(user);
    return user;
  },

  updateUserInterests: async (interests: UserInterest[]): Promise<UserInterestModel> => {
    const url = `/api/users/updateinterests`;
    const updateInterestsRequest = { UserInterests: interests };
    const result = await ApiService.put<UserInterestModel>(url, updateInterestsRequest);
    const user = getCurrentUser();
    if (user) {
      user.UserInterest = result;
      UserService.updateLocalStorageUser(user);
    }
    return result;
  },

  removeUser: async (): Promise<any> => {
    return await ApiService.delete("/api/users");
  },

  updateUserPicture: async (picture: File): Promise<any> => {
    const formData = new FormData();
    const blob = new Blob([picture], { type: picture.type });
    formData.append("File", blob);

    const headersNoContentType = await headers();
    delete headersNoContentType["Content-Type"];

    const response = await fetch("/api/users/UploadProfileImage", {
      method: "POST",
      body: formData,
      headers: headersNoContentType,
    });
    const result = await response.text();
    if (response.status === 200) {
      const user = getCurrentUser();
      if (user) {
        user.AvatarUrl = result;
        UserService.updateLocalStorageUser(user);
      }
      return result;
    } else {
      throw result;
    }
  },
  subscribeOnHousePhotosUpdate: async (unitId: string): Promise<any> => {
    const url = `/api/users/photosubscrption`;
    const result = await ApiService.put(url, { unitId });
    return result;
  },
  subscribeOnHousePriceChanges: async (unitId: string): Promise<any> => {
    const url = `/api/users/pricesubscrption`;
    const result = await ApiService.put(url, { unitId });
    return result;
  },
  isUserSubscribedToHousePhotoUpdate: async (unitId: string): Promise<boolean> => {
    const url = `/api/users/photosubscrption/issubscribed/${unitId}`;
    const result = await ApiService.get<boolean>(url);
    return result;
  },
  moveOutUnit: async (unitId: string): Promise<{ Unit: Unit; User: User }> => {
    const url = `/api/users/moveOut/${unitId}/unit`;
    const result = await ApiService.delete<{ Unit: Unit; User: User }>(url);
    return result;
  },
  getUserSubscriptions: async (): Promise<SubscriptionModel[]> => {
    const url = `/api/subscriptions`;
    const result = await ApiService.get<SubscriptionModel[]>(url);
    return result;
  },
  updateUserCurrentHouse: async (id: string): Promise<{ Unit: Unit; User: User }> => {
    const url = `/api/users/updatecurrenthouse`;
    const result = await ApiService.put<{ Unit: Unit; User: User }>(url, {
      NewPropertyId: id,
    });
    handleUser(result.User);
    return result;
  },
  updateUserCurrentBuilding: async (id: string): Promise<Building> => {
    const url = `/api/users/updatecurrentbuilding/${id}`;
    const result = await ApiService.put<Building>(url, null);
    return result;
  },
  getUnSubscribedToLeadAlarmAdmins: async (emailPart: string): Promise<User[]> => {
    const url = `/api/admin/users/leads/notsubscribed?emailPart=${emailPart}`;
    const result = await ApiService.get<User[]>(url);
    return result;
  },
  getSubscribedToLeadAlarm: async (): Promise<User[]> => {
    const url = `/api/admin/users/leads/subscribed`;
    const result = await ApiService.get<User[]>(url);
    return result;
  },
  subscribeToLeadAlarm: async (userId: string, utmSource: string): Promise<User> => {
    const url = `/api/admin/users/leads/subscribe`;
    const data = {
      UserId: userId,
      UtmSource: utmSource,
    };

    const result = await ApiService.put<User>(url, data);
    return result;
  },
  unsubscribeFromLeadAlarm: async (userId: string): Promise<User> => {
    const url = `/api/admin/users/leads/unsubscribe`;
    const result = await ApiService.put<User>(url, { UserId: userId });
    return result;
  },
  getUtmSources: async (): Promise<User[]> => {
    const url = `/api/admin/users/leads/subscribed`;
    const result = await ApiService.get<User[]>(url);
    return result;
  },
  setUserPreferencesStyle: async (style: string) => {
    const url = "/api/users/style-preferences";
    await ApiService.post(url, { StyleName: style });

    const user = getCurrentUser();
    if (user) {
      user.DesignAssistantStyle = style;
      UserService.updateLocalStorageUser(user);
    }
  },

  getAdminUserInfo: async (searchData: string): Promise<AdminUserinfo> => {
    if (isEmail(searchData)) {
      const result = await ApiService.get<AdminUserinfo>(`/api/admin/aggregate-view/email/${searchData}`);
      return result;
    } else {
      const result = await ApiService.get<AdminUserinfo>(`/api/admin/aggregate-view/guid/${searchData}`);
      return result;
    }
  },

  getAdminUserGroups: async (userId: string): Promise<AdminInfoGroup> => {
    const result = await ApiService.get<AdminInfoGroup>(`/api/admin/aggregate-view/group/user/${userId}`);

    return result;
  },

  getAdminAggregate: async (aggregateId: string): Promise<AdminInfoAggregate> => {
    const result = await ApiService.get<AdminInfoAggregate>(`/api/admin/aggregate-view/events/${aggregateId}`);

    return result;
  },

  setLanguage: (language: LanguageType): void => {
    localStorage.setItem("language", language);
  },

  getLanguage: (): LanguageType => {
    const lang = localStorage.getItem("language");
    return lang ? (lang as LanguageType) : "da";
  },
  acceptNewTerms: async () => {
    const result = await ApiService.post<any>(`/api/users/accept-terms`, null);

    return result;
  },
  getUserLogMessages: async (userId: string, logSources: string[], signal?: AbortSignal) => {
    let url = `/api/users/${userId}/log-messages`;
    logSources.map((source, i) => (url += `${i === 0 ? "?" : "&"}logSources=${source}`));

    return await ApiService.get<UserLogMessage[]>(url, null, signal);
  },
  tryUser: async (userId: string) => {
    const result = await ApiService.post<{ Unit: Unit; User: User }>(`/api/users/${userId}/try`, null);

    handleTryUser(result.User);
    return result;
  },
  unTryUser: async () => {
    localStorage.removeItem("tryUser");
  },
};

function getCurrentUser(): User | null {
  const user = localStorage.getItem("user");

  if (user === null) return null;
  const parsedUser = JSON.parse(user) as User;

  //need to add Language to local storage user if Language did not come from back end
  if (!parsedUser.Language) parsedUser.Language = "da";
  UserService.updateLocalStorageUser(parsedUser);

  return parsedUser;
}

export function handleUser(user: User) {
  const localStorageUser = localStorage.getItem("user");
  if (localStorageUser && user && !user.Token) {
    const parsedUser = JSON.parse(localStorageUser) as User;
    user.Token = parsedUser.Token;
  }

  // login successful if there's a jwt token in the response
  if (user && user.Token) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem("user", JSON.stringify(user));

    //for keep system languse after logout
    localStorage.setItem("language", user.Language);
  }

  return user;
}

function handleTryUser(tryUser: User) {
  const localStorageTryUser = localStorage.getItem("tryUser");
  if (localStorageTryUser && tryUser && !tryUser.Token) {
    const parsedUser = JSON.parse(localStorageTryUser) as User;
    tryUser.Token = parsedUser.Token;
  }

  // login successful if there's a jwt token in the response
  if (tryUser && tryUser.Token) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem("tryUser", JSON.stringify(tryUser));
  }

  return tryUser;
}

export default UserService;
