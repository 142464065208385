import React, { cloneElement, memo } from "react";
import {
  Grid,
  ListItem,
  Divider,
  Skeleton,
  SkeletonProps,
} from "@mui/material";

const DetailsSkeleton = () => {
  return (
    <>
      <ListItem>
        <Grid container justifyContent="space-between" direction="row">
          <Grid item container direction="column" xs={2}>
            {repeatSkeleton(
              { variant: "text", animation: "wave", width: 200 },
              3
            )}
          </Grid>
          <Grid item container alignItems="center" xs={1}>
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={70.75}
              height={33.5}
            />
          </Grid>
        </Grid>
      </ListItem>
      <Divider />
    </>
  );
};

export default DetailsSkeleton;

export const repeatSkeleton = (
  skeletonProps: SkeletonProps,
  times: number,
  wrapper?: React.ReactElement
) => {
  return [...Array(times)].map((_, index) => {
    if (wrapper) {
      return cloneElement(
        wrapper,
        { key: index },
        <Skeleton {...skeletonProps} />
      );
    } else {
      return <Skeleton {...skeletonProps} key={index} />;
    }
  });
};

export const randomSkeleton = (
  skeletonProps: SkeletonProps,
  times: number,
  maxLinesKoef?: number
) => {
  return [...Array(times)].map((_, index) => {
    return (
      <Skeleton
        {...skeletonProps}
        sx={{
          width: `${Math.floor(Math.random() * 90) + 10}%`,
          visibility:
            Math.floor(Math.random() * (maxLinesKoef || 5)) == 0
              ? "hidden"
              : "visible",
        }}
        key={index}
      />
    );
  });
};

export const RandomLineTermsSkeleton = memo(() => (
  <Grid item container direction="column">
    {randomSkeleton({ variant: "text", height: 30 }, 30, 50)}
  </Grid>
));
RandomLineTermsSkeleton.displayName = "RandomLineSkeleton";