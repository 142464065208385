import React from "react";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import DocViewer, {
  IConfig,
  IDocument,
  DocViewerRenderers,
  BMPRenderer,
  HTMLRenderer,
  JPGRenderer,
  MSDocRenderer,
  PDFRenderer,
  PNGRenderer,
  TIFFRenderer,
  TXTRenderer,
  CSVRenderer,
  GIFRenderer,
  VideoRenderer,
  WebPRenderer,
} from "@cyntler/react-doc-viewer";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloseIcon from "@mui/icons-material/Close";
import Slide, { SlideProps } from "@mui/material/Slide";
import { Alert, Button, styled } from "@mui/material";
import { EmailRenderer, TextRenderer } from "../CustomDocRenderers";
import { useTranslation } from "react-i18next";

const DocViewerStyled = styled(DocViewer)(() => ({
  height: "100%",
  "& > div > div": {
    backgroundImage: "none",
  },
}));

const Transition = React.forwardRef(function Transition(
  props: SlideProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type FilePreviewDialogProps = {
  open: boolean;
  document: IDocument | undefined;
  overrideConfig?: IConfig;
  onClose: () => void;
};

const FilePreviewDialog = (props: FilePreviewDialogProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const NoRenderer = (noRendererData: { document: IDocument | undefined; fileName: string }) => {
    const fileText = noRendererData.fileName || noRendererData.document?.fileType || "";

    return (
      <Alert
        severity="info"
        icon={false}
        sx={{
          flex: "auto",
          height: "fit-content",
        }}
        action={
          <Link download href={noRendererData.document && noRendererData.document.uri}>
            <Button size="large" color="info" variant="outlined" endIcon={<CloudDownloadIcon />}>
              {t("General.Buttons.Download")}
            </Button>
          </Link>
        }
      >
        {t("Documents.CantPreviewDocument", { filename: fileText })}
      </Alert>
    );
  };

  return (
    <Dialog fullScreen open={props.open} onClose={props.onClose} TransitionComponent={Transition}>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Link download href={props.document && props.document.uri}>
            <IconButton size="large">
              <CloudDownloadIcon />
            </IconButton>
          </Link>
        </Grid>
        <Grid item>
          <IconButton onClick={props.onClose} color="inherit" size="large">
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      <DocViewerStyled
        documents={props.document ? [props.document] : []}
        pluginRenderers={[
          ...DocViewerRenderers,
          BMPRenderer,
          HTMLRenderer,
          JPGRenderer,
          MSDocRenderer,
          PDFRenderer,
          PNGRenderer,
          TIFFRenderer,
          TXTRenderer,
          CSVRenderer,
          GIFRenderer,
          VideoRenderer,
          WebPRenderer,
          TextRenderer,
          EmailRenderer,
        ]}
        config={
          props.overrideConfig || {
            header: {
              //disableFileName: true,
              disableHeader: true,
            },
            noRenderer: {
              overrideComponent: NoRenderer,
            },
          }
        }
      />
    </Dialog>
  );
};

export default FilePreviewDialog;
